import http from './http';

export function updateHomework(schoolId, schoolClassId, homeworkId, data) {
	return http
		.put(`/schools/${schoolId}/classes/${schoolClassId}/homework/${homeworkId}`, data)
		.then(resp => resp.data);
}

export function createHomework(schoolId, schoolClassId, data) {
	return http.post(`/schools/${schoolId}/classes/${schoolClassId}/homework`, data).then(resp => resp.data.data);
}

export function validateExistingHomeworkAnswers(schoolId, schoolClassId, data) {
	return http
		.post(`/schools/${schoolId}/classes/${schoolClassId}/homework/validate`, data)
		.then(resp => resp.data.data);
}

export function getHomework(schoolId, schoolClassId, homeworkId) {
	return http
		.get(`/schools/${schoolId}/classes/${schoolClassId}/homework/${homeworkId}`)
		.then(resp => resp.data.data);
}

export function deleteHomework(schoolId, schoolClassId, homeworkId) {
	return http.delete(`/schools/${schoolId}/classes/${schoolClassId}/homework/${homeworkId}`);
}

export function copyHomework(schoolId, schoolClassId, homeworkId, data) {
	return http
		.post(`/schools/${schoolId}/classes/${schoolClassId}/homework/${homeworkId}/copy`, data)
		.then(resp => resp.data.data);
}

export function getUserHomework(schoolId) {
	return http.get(`/schools/${schoolId}/user/homework`).then(resp => resp.data.data);
}

export function getClassHomework(schoolId, schoolClassId) {
	return http
		.get(`/schools/${schoolId}/user/homework/class`, {
			params: {
				class_id: schoolClassId,
			},
		})
		.then(resp => resp.data.data);
}

export function getStudentHomework(homeworkId) {
	return http.get(`/homework/${homeworkId}`).then(resp => resp.data.data);
}

export function getStudentHomeworkSectionOverview(homeworkId) {
	return http.get(`/homework/${homeworkId}/section-overview`).then(resp => resp.data.data);
}

export function getStudentHomeworkSections(homeworkId) {
	return http.get(`/homework/${homeworkId}/sections`).then(resp => resp.data.data);
}

export function completeHomework(homeworkId) {
	return http.post(`/homework/${homeworkId}/complete`).then(resp => resp.data.data);
}

export function undoUserHomeworkCompletion(schoolId, schoolClassId, homeworkId, userId) {
	return http
		.delete(`/schools/${schoolId}/classes/${schoolClassId}/homework/${homeworkId}/complete/${userId}`)
		.then(resp => resp.data);
}

export function getTrainingExercise(homework_id, avoid_media = false) {
	return http.get(`/training/${homework_id}/exercise`, { params: { avoid_media } }).then(resp => resp.data);
}

export function getTrainingStatus(homeworkId) {
	return http.get(`/training/${homeworkId}/status`).then(resp => resp.data.data);
}

export function startTraining(homeworkId) {
	return http.post(`/training/${homeworkId}/start`).then(resp => resp.data.data);
}

export function stopTraining(homeworkId, forceEnd) {
	return http.post(`/training/${homeworkId}/stop`, { forceEnd }).then(resp => resp.data.data);
}

export function resetTrainingTime(homeworkId) {
	return http.post(`/training/${homeworkId}/resettime`).then(resp => resp.data.data);
}

export function getTrainingResults(homeworkId, userIds = []) {
	const params = userIds?.length ? { user_ids: userIds } : {};
	return http.get(`/training/${homeworkId}/results`, { params }).then(resp => resp.data.data);
}

export function getTrainingFocusAreas(homeworkId) {
	return http.get(`/training/${homeworkId}/focusareas`).then(resp => resp.data.data);
}

export function resetStudentHomeworkPeriods(homeworkId, userId) {
	return http.delete(`/training/${homeworkId}/periods/${userId}`).then(resp => resp.data);
}

export function getHomeworkFeedbackMessages(schoolId, schoolClassId, homeworkId) {
	return http
		.get(`/schools/${schoolId}/classes/${schoolClassId}/homework/${homeworkId}/messages`)
		.then(resp => resp.data.data);
}

export function createHomeworkFeedbackMessage(schoolId, schoolClassId, homeworkId, payload) {
	return http
		.post(`/schools/${schoolId}/classes/${schoolClassId}/homework/${homeworkId}/messages`, payload)
		.then(resp => resp.data.data);
}

export function updateHomeworkFeedbackMessage(schoolId, schoolClassId, homeworkId, messageId, payload) {
	return http
		.put(
			`/schools/${schoolId}/classes/${schoolClassId}/homework/${homeworkId}/messages/${messageId}`,
			payload,
		)
		.then(resp => resp.data.data);
}

export function deleteHomeworkFeedbackMessage(schoolId, schoolClassId, homeworkId, messageId) {
	return http.delete(
		`/schools/${schoolId}/classes/${schoolClassId}/homework/${homeworkId}/messages/${messageId}`,
	);
}
