import Vue from 'vue';
import { getSchoolClass } from '@/api/school';
import Section from '@/models/section/Section';
import Assignment from '@/models/assignment/Assignment';
import Book from '@/models/book/Book';
import ItemsTree from './ItemsTree';
import Designation from '@/models/designation/Designation';
import { sortAlphabetical } from '@/utils/sort';
import { round } from '@/utils/math';

// Helper methods
function defaultItemMeta() {
	return { progress: 0, excluded_students: [] };
}
function defaultSettings() {
	return {
		title: '',
		released_at: null,
		deadline_at: null,
		message: '',
		designation: null,
		symbols: null,
		has_visible_results: false,
		has_visible_instructions: false,
		has_visible_points: false,
		shuffle_questions: false,
		student_time_overrides: [],
		duration: 0,
		start_level: null,
		is_pausable: false,
		is_private: false,
	};
}

const state = () => ({
	selectedParents: [],
	selectedSections: [],
	tree: new ItemsTree(),
	parentsMeta: {},
	sectionsMeta: {},
	globalExcludedStudents: [],
	schoolClasses: [],
	settings: defaultSettings(),
	designationSettings: null,
	sectionTimeLimits: {},
});

const getters = {
	/**
	 * Get currently loaded/selected schoolClass.
	 * Only valid for non-screening homework. Screenings can have multiple schoolClasses.
	 * @return Object|null
	 */
	schoolClass: state => {
		if (state.schoolClasses.length > 0) {
			return state.schoolClasses[0];
		}
		return null;
	},

	/**
	 * Check if an SchoolClass is selected.
	 * @param Object schoolClass
	 * @return Boolean
	 */
	schoolClassIsSelected: (state, getters) => schoolClass => {
		return getters.schoolClassSelectedIndex(schoolClass) >= 0;
	},

	/**
	 * Get index of selected SchoolClass. Return -1 if not selected.
	 * @param Object schoolClass
	 * @return Number
	 */
	schoolClassSelectedIndex: state => schoolClass => {
		return state.schoolClasses.findIndex(selectedSchoolClass => {
			return selectedSchoolClass.id === schoolClass.id;
		});
	},

	/**
	 * Get students from currently loaded/selected schoolClasses.
	 * @return Array
	 */
	students: state => {
		return state.schoolClasses.reduce((students, schoolClass) => {
			return students.concat(sortAlphabetical(schoolClass.students));
		}, []);
	},

	/**
	 * Get selectabled (not excluded) students from currently loaded/selected schoolClasses.
	 * @return Array
	 */
	selectableStudents: (state, getters) => {
		return getters.students.filter(user => !state.globalExcludedStudents.includes(user.id));
	},

	/**
	 * Get flat Array of all selected SectionRelation ID's.
	 * @return Array
	 */
	sectionRelationIds: state => {
		const sectionRelationIds = [];
		state.tree.items.forEach(item => {
			sectionRelationIds.push(...state.tree.getItemSectionRelationIds(item));
		});
		return sectionRelationIds;
	},

	/**
	 * Get flat Array of all selected parent (Book/Assignment) ID's.
	 * @return Array
	 */
	parentIds: state => {
		return state.selectedParents.map(parent => parent.id);
	},

	/**
	 * Check if an item (Section/parent) is selected.
	 * @param Object item
	 * @return Boolean
	 */
	itemIsSelected: (state, getters) => item => {
		return getters.itemSelectedIndex(item) >= 0;
	},

	/**
	 * Get index of selected item (Section/parent). Return -1 if not selected.
	 * @param Object item
	 * @return Number
	 */
	itemSelectedIndex: state => item => {
		if (item instanceof Section) {
			return state.selectedSections.findIndex(section => {
				return section.getIdentifier() === item.getIdentifier();
			});
		}
		return state.selectedParents.findIndex(parent => parent.isSameAs(item));
	},

	/**
	 * Get "meta object" for item (Section/parent).
	 * Contains progress info + selected students.
	 * @param Object item
	 * @return Object
	 */
	itemMeta: state => item => {
		if (item instanceof Section) {
			return state.sectionsMeta[item.section_relation_id] ?? defaultItemMeta();
		}
		return state.parentsMeta[item.id] ?? defaultItemMeta();
	},

	/**
	 * Get array students selected for item (Section/parent).
	 * @param Object item
	 * @return Array
	 */
	studentsSelectedForItem: (state, getters) => item => {
		const excludedIds = getters.itemMeta(item).excluded_students ?? [];
		return getters.selectableStudents.filter(student => !excludedIds.includes(student.id));
	},

	/**
	 * Get student progress value of item (Section/parent).
	 * @param Object item
	 * @return Number
	 */
	progressOfItem: (state, getters) => item => {
		return getters.itemMeta(item).progress ?? 0;
	},

	/**
	 * Get average student progress value of items (Sections/parents).
	 * @param Array items
	 * @return Number
	 */
	avgProgressOfItems: (state, getters) => items => {
		if (!items || !items.length) {
			return 0;
		}
		const totalProgress = items.reduce((progress, item) => {
			return progress + getters.progressOfItem(item);
		}, 0);
		return totalProgress > 0 ? totalProgress / items.length : 0;
	},

	/**
	 * Get collection of all loaded student progress values for Sections.
	 * @return Object
	 */
	allSectionProgresses: state => {
		const progresses = {};
		for (const sectionRelationId in state.sectionsMeta) {
			progresses[sectionRelationId] = state.sectionsMeta[sectionRelationId].progress;
		}
		return progresses;
	},

	/**
	 * Get first level children selected for parent item (Section/parent) from ItemsTree.
	 * If parent is null root level items from ItemsTree is returned.
	 * @param  Object|null parent = null
	 * @return Array
	 */
	treeItems:
		state =>
		(parent = null) => {
			let items = [];
			if (parent) {
				const treeItem = state.tree.findItem(parent);
				if (treeItem) {
					items = treeItem.items;
				}
			} else {
				items = state.tree.items;
			}

			return items.map(item => {
				if (item instanceof Section) {
					return state.selectedSections.find(section => {
						return section.isSameAs(item);
					});
				} else {
					return state.selectedParents.find(parentItem => {
						return parentItem.isSameAs(item);
					});
				}
			});
		},

	/**
	 * Find all sub selected items for item in ItemsTree.
	 * @param  Object item
	 * @return Array
	 */
	allTreeSubItems: state => item => {
		const treeItem = state.tree.findItem(item);
		if (!treeItem) {
			return [];
		}
		return state.tree.getItemSubItems(treeItem);
	},

	/**
	 * Get total duration of all selected Sections.
	 * @return Number
	 */
	totalDuration: state => {
		return state.selectedSections.reduce((totalDuration, section) => {
			const key = `duration${section.isExercise() ? '_total' : ''}`;
			const duration = parseFloat(section[key]);
			if (duration > 0) {
				return totalDuration + duration;
			}
			return totalDuration;
		}, 0);
	},

	/**
	 * Get total normal_pages of all selected Sections.
	 * @return Number
	 */
	totalNormalPages: state => {
		return state.selectedSections.reduce((totalPages, section) => {
			const key = `normal_pages${section.isExercise() ? '_total' : ''}`;
			const normalPages = parseFloat(section[key]);
			if (normalPages > 0) {
				return totalPages + normalPages;
			}
			return totalPages;
		}, 0);
	},

	/**
	 * Get collection of excluded students for selected Sections.
	 * Excluded students are all not selected students.
	 * All students are selected by default.
	 * If schoolClass param is falsy, default schoolClass will be used.
	 * @param  Object|null schoolClass
	 * @return Object
	 */
	excludedStudents: (state, getters) => schoolClass => {
		const exclusions = {};
		if (state.globalExcludedStudents.length) {
			exclusions.students = state.globalExcludedStudents;
		}
		const studentIds = (schoolClass?.students ?? getters.students).map(student => student.id);
		const exclusionsBySectionRelation = state.selectedSections.reduce((carry, section) => {
			const excludedIds = (getters.itemMeta(section).excluded_students ?? []).filter(id => {
				return !state.globalExcludedStudents.includes(id) && studentIds.includes(id);
			});
			if (excludedIds.length) {
				carry[section.section_relation_id] = excludedIds;
			}
			return carry;
		}, {});
		if (Object.keys(exclusionsBySectionRelation).length) {
			exclusions.section_relations = exclusionsBySectionRelation;
		}
		return Object.keys(exclusions).length ? exclusions : null;
	},

	/**
	 * Check if Homework flow state should reset based on parent.
	 * E.g. if already selected Sections are from same Book as parent,
	 * reset is not necessary.
	 * @param  Object parent
	 * @return Boolean
	 */
	shouldResetBasedOnParent: state => parent => {
		if (state.selectedParents.length > 0) {
			const firstParent = state.selectedParents[0];
			if (parent instanceof Book) {
				if (!(firstParent instanceof Book)) {
					return true;
				}
				return parent.id != firstParent.id;
			} else if (parent instanceof Assignment) {
				return !(firstParent instanceof Assignment);
			}
		}

		return false;
	},

	/**
	 * Get setting value.
	 * @param  String key
	 * @return mixed
	 */
	setting: state => key => {
		return state.settings[key] !== undefined ? state.settings[key] : null;
	},

	/**
	 * Determine if a configured date span has an error:
	 * released_at is later than deadline_at.
	 * @return Boolean
	 */
	hasDateSpanWithError: state => {
		const spanWithError = [state.settings]
			.concat(state.settings.student_time_overrides)
			.find(({ released_at, deadline_at }) => {
				if (!released_at || !deadline_at) {
					return false;
				}
				return released_at.isSameOrAfter(deadline_at);
			});
		return spanWithError !== undefined;
	},

	/**
	 * Get current designation.
	 * @return Designation
	 */
	currentDesignation: state => {
		return new Designation(state.designationSettings);
	},

	/**
	 * Get object of all selected SectionRelation ID's with time_limit.
	 * @return Object
	 */
	sectionRelationConfs: (state, getters) => {
		const confs = {};
		state.selectedSections.forEach(section => {
			const time_limit = getters.getSectionTimeLimit(section);
			if (time_limit) {
				confs[section.section_relation_id] = { time_limit: time_limit * 60 };
			}
		});
		return confs;
	},

	/**
	 * Get time_limit value for Section.
	 * @param  Object section
	 * @return Number|null
	 */
	getSectionTimeLimit: state => section =>
		section.section_relation_id in state.sectionTimeLimits
			? state.sectionTimeLimits[section.section_relation_id]
			: null,
};

const actions = {
	/**
	 * Completely reset Homework flow state.
	 */
	resetAll({ commit }) {
		commit('resetSchoolClasses');
		commit('resetSelectedParents');
		commit('resetSelectedSections');
		commit('resetTree');
		commit('resetParentsMeta');
		commit('resetSectionsMeta');
		commit('resetGlobalExcludedStudents');
		commit('resetSettings');
		commit('resetSectionTimeLimits');
	},

	/**
	 * Reset part of Homework flow state.
	 */
	reset({ commit }, resets = []) {
		resets.forEach(reset => commit(reset));
	},

	/**
	 * Load schoolClass with students & set all students to globally selected.
	 */
	loadSchoolClass({ commit }, { schoolId, schoolClassId }) {
		return getSchoolClass(schoolId, schoolClassId).then(schoolClass => {
			commit('setSchoolClass', schoolClass);
		});
	},

	/**
	 * Toggle select a schoolClass for screening flow.
	 */
	toggleSchoolClass({ commit, getters }, schoolClass) {
		const index = getters.schoolClassSelectedIndex(schoolClass);
		if (index >= 0) {
			commit('deselectSchoolClass', index);
		} else {
			commit('selectSchoolClass', schoolClass);
		}
	},

	/**
	 * Add item (Section/parent) to selection + ItemsTree.
	 */
	selectItem({ commit, getters }, { item, parent, options = {} }) {
		if (getters.itemIsSelected(item)) {
			// Abort if already selected
			return;
		}

		// Select item (Section/parent) and add item to ItemsTree
		if (item instanceof Section) {
			commit('selectSection', item);
			let timeLimit = item.time_limit;
			if (!timeLimit && !options?.ignoreDefaultTimeLimit && item.default_time_limit) {
				timeLimit = item.default_time_limit;
			}
			if (timeLimit) {
				commit('setSectionTimeLimit', { section: item, value: round(timeLimit / 60, 1) });
			}
		} else {
			commit('selectParent', item);
		}
		commit('addItemToTree', { item, parent });
	},

	/**
	 * Deselect item (Section/parent) + all selected sub Sections.
	 * Also remove from ItemsTree.
	 */
	deselectItem({ commit, getters }, { item, parent }) {
		commit(`deselect${item instanceof Section ? 'Section' : 'Parent'}`, getters.itemSelectedIndex(item));

		getters.allTreeSubItems(item).forEach(subItem => {
			commit(
				`deselect${subItem instanceof Section ? 'Section' : 'Parent'}`,
				getters.itemSelectedIndex(subItem),
			);
		});
		commit('removeItemFromTree', { item, parent });
	},

	updateSectionTimeLimit({ commit, getters }, { section, value }) {
		if (!getters.itemIsSelected(section)) {
			// Abort if section is not selected
			return;
		}
		commit('setSectionTimeLimit', { section, value });
	},

	/**
	 * Update order of ItemsTree root level.
	 */
	updateTreeOrder({ commit, state }, orderedItems) {
		const items = [];
		orderedItems.forEach(orderedItem => {
			const item = state.tree.findItem(orderedItem);
			if (item) {
				items.push(item);
			}
		});
		commit('updateTree', items);
	},

	/**
	 * Update globally excluded students.
	 */
	updateGlobalExcludedStudents({ commit }, userIds) {
		commit('setGlobalExcludedStudents', userIds);
	},

	/**
	 * Update excluded students for multiple items.
	 */
	updateExludedStudents({ commit }, { items, value }) {
		items.forEach(item => {
			if (item instanceof Section) {
				commit('setSectionMeta', { section: item, key: 'excluded_students', value });
			} else {
				commit('setParentMeta', { parent: item, key: 'excluded_students', value });
			}
		});
	},

	/**
	 * Update student progress value for multiple items.
	 */
	updateProgress({ commit }, { items, value }) {
		items.forEach(item => {
			if (item instanceof Section) {
				commit('setSectionMeta', { section: item, key: 'progress', value });
			} else {
				commit('setParentMeta', { parent: item, key: 'progress', value });
			}
		});
	},

	/**
	 * Update multiple setting values.
	 */
	updateSettings({ commit }, settings) {
		for (const key in settings) {
			commit('setSetting', { key, value: settings[key] });
		}
	},

	/**
	 * Update designation settings.
	 */
	updateDesignationSettings({ commit }, settings) {
		commit('setDesignationSettings', settings);
	},
};

const mutations = {
	resetSchoolClasses(state) {
		state.schoolClasses = [];
	},
	setSchoolClass(state, schoolClass) {
		state.schoolClasses = [schoolClass];
	},
	setSchoolClasses(state, schoolClasses) {
		state.schoolClasses = schoolClasses;
	},
	selectSchoolClass(state, schoolClass) {
		state.schoolClasses.push(schoolClass);
	},
	deselectSchoolClass(state, index) {
		state.schoolClasses.splice(index, 1);
	},
	resetSelectedParents(state) {
		state.selectedParents = [];
	},
	selectParent(state, parent) {
		state.selectedParents.push(parent);
	},
	deselectParent(state, index) {
		if (index < 0 || state.selectedParents[index] === undefined) {
			return;
		}
		state.selectedParents.splice(index, 1);
	},
	resetSelectedSections(state) {
		state.selectedSections = [];
	},
	selectSection(state, section) {
		state.selectedSections.push(section);
	},
	deselectSection(state, index) {
		if (index < 0 || state.selectedSections[index] === undefined) {
			return;
		}
		state.selectedSections.splice(index, 1);
	},
	resetTree(state) {
		state.tree = new ItemsTree();
	},
	updateTree(state, items) {
		state.tree = new ItemsTree(items);
	},
	addItemToTree(state, { item, parent }) {
		const newTreeItem = state.tree.createItem(item);

		if (!newTreeItem) {
			return;
		}

		const treeParent = parent ? state.tree.findItem(parent) : null;

		if (treeParent) {
			treeParent.items.push(newTreeItem);
		} else {
			state.tree.items.push(newTreeItem);
		}
	},
	removeItemFromTree(state, { item, parent }) {
		const parentTreeItem = parent ? state.tree.findItem(parent) : null;

		let index;
		if (parentTreeItem) {
			index = state.tree.findItemIndex(item, parentTreeItem.items);
			if (index >= 0) {
				parentTreeItem.items.splice(index, 1);
			}
		} else {
			index = state.tree.findItemIndex(item);
			if (index >= 0) {
				state.tree.items.splice(index, 1);
			}
		}
	},
	resetGlobalExcludedStudents(state) {
		state.globalExcludedStudents = [];
	},
	setGlobalExcludedStudents(state, ids) {
		state.globalExcludedStudents = ids;
	},
	resetParentsMeta(state) {
		state.parentsMeta = {};
	},
	setParentMeta(state, { parent, key, value }) {
		const changes = {};
		changes[key] = value;
		Vue.set(state.parentsMeta, parent.id, Object.assign({}, state.parentsMeta[parent.id], changes));
	},
	resetSectionsMeta(state) {
		state.sectionsMeta = {};
	},
	setSectionMeta(state, { section, key, value }) {
		const changes = {};
		changes[key] = value;
		Vue.set(
			state.sectionsMeta,
			section.section_relation_id,
			Object.assign({}, state.sectionsMeta[section.section_relation_id], changes),
		);
	},
	resetSettings(state) {
		state.settings = defaultSettings();
	},
	setSetting(state, { key, value }) {
		Vue.set(state.settings, key, value);
	},
	setDesignationSettings(state, settings) {
		state.designationSettings = settings;
	},
	resetSectionTimeLimits(state) {
		state.sectionTimeLimits = {};
	},
	setSectionTimeLimit(state, { section, value }) {
		state.sectionTimeLimits = { ...state.sectionTimeLimits, [section.section_relation_id]: value };
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
