import UserNotification from './UserNotification';

export default class NewCourses extends UserNotification {
	get title() {
		return 'Nye screeninger';
	}

	get body() {
		if (this.data.courses.length > 1) {
			return `Du har fået adgang til screeninger i ${this.data.courses.length} fag`;
		}
		return `Du har fået adgang til screeninger i ${this.data.courses[0].title.toLowerCase()}`;
	}

	get actionText() {
		return 'Gå til screeninger';
	}

	get actionRoute() {
		return { name: 'screenings' };
	}
}
