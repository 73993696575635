import http from './http';
import axios from 'axios';

export function readTextForSpeech(text) {
	return new Promise(resolve => {
		http.post('/texttospeech', { text }).then(({ data }) => {
			let srcs = data.data;
			if (srcs.length === 0) {
				return resolve([]);
			}

			const promises = srcs.map(src => {
				return fetchMetaData(src.metaUrl).then(response => {
					if (!response) {
						return [];
					}
					if (typeof response.data === 'string' && response.data) {
						return response.data
							.trim()
							.split('\n')
							.map(row => JSON.parse(row));
					}
					return [response.data];
				});
			});

			Promise.all(promises).then(metaData => {
				resolve(
					srcs.map((src, index) => {
						src.metaData = metaData[index];
						return src;
					}),
				);
			});
		});
	});
}

export function getTextToSpeechUrls(text, language) {
	const params = language ? { language } : {};
	return http.post('/texttospeech', { text }, { params }).then(resp => resp.data.data);
}

function fetchMetaData(url) {
	return axios.get(url).then(resp => resp);
}
