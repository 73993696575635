<template>
	<div>
		<nav
			v-click-outside="vcoMobileConfig"
			class="navbar has-shadow"
			role="navigation"
			aria-label="main navigation"
		>
			<div class="navbar-brand">
				<router-link class="navbar-item" to="/">
					<img src="/images/logo/logo.svg" class="menu-logo-img" alt="logo" />
				</router-link>

				<a
					role="button"
					class="navbar-burger burger"
					:class="{ 'is-active': mobileMenuOpen }"
					aria-label="menu"
					aria-expanded="false"
					data-target="mainNavbar"
					@click="mobileMenuOpen = !mobileMenuOpen"
				>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
				</a>
			</div>

			<div id="mainNavbar" class="navbar-menu" :class="{ 'is-active': mobileMenuOpen }">
				<div class="navbar-start">
					<router-link class="navbar-item" :to="{ name: 'homework' }">
						<span>Lektier</span>
					</router-link>
					<template v-if="isTeacher">
						<a
							href="#"
							class="navbar-item is-hidden-touch"
							:class="{ 'is-active': routeIsActive(new RegExp('^/hold')) }"
							@click.prevent="onClickSchoolClassesMenuItem"
						>
							<span>Hold</span>
						</a>
						<router-link
							id="vue-book-menu-button"
							class="navbar-item is-hidden-desktop"
							:class="{ 'is-active': routeIsActive(new RegExp('^/hold')) }"
							:to="{ name: 'school_classes' }"
						>
							<span>Hold</span>
						</router-link>
					</template>
					<div class="navbar-item is-hidden-touch">
						<div class="navbar-separator" />
					</div>
					<a
						href="#"
						class="navbar-item is-hidden-touch"
						:class="{ 'is-active': routeIsActive(new RegExp('^(/bog/|/boeger)')) }"
						@click.prevent="onClickBooksMenuItem"
					>
						<span>Bøger</span>
					</a>
					<router-link
						id="vue-book-menu-button"
						class="navbar-item is-hidden-desktop"
						:class="{ 'is-active': routeIsActive(new RegExp('^/bog/')) }"
						:to="{ name: 'books' }"
					>
						<span>Bøger</span>
					</router-link>
					<a
						href="#"
						class="navbar-item is-hidden-touch"
						:class="{ 'is-active': routeIsActive(new RegExp('^/opgavesaet')) }"
						@click.prevent="onClickAssignmentsMenuItem"
					>
						<span>Opgavesæt</span>
					</a>
					<router-link
						id="assignment-link"
						class="navbar-item is-hidden-desktop"
						:class="{ 'is-active': routeIsActive(new RegExp('^/opgavesaet')) }"
						:to="{ name: 'assignments' }"
					>
						<span>Opgavesæt</span>
					</router-link>
					<router-link class="navbar-item" :to="{ name: 'screenings' }">
						<span>{{ vocab('Screeninger', { ucFirst: true }) }}</span>
					</router-link>
				</div>

				<div class="navbar-end">
					<a
						v-show="enableLixMenuItem"
						class="navbar-item navbar-lix"
						:href="lixURL"
						target="_blank"
						rel="noopener noreferrer"
					>
						Ordbog
						<span class="mb-4 tag is-info is-rounded">Beta</span>
					</a>
					<div class="navbar-item">
						<BaseSearch v-model="search" @submit="doSearch" />
					</div>
					<UserMenu
						v-click-outside="vcoConfig"
						:open="userMenuOpen"
						class="navbar-item"
						:class="{ 'mr-3': !isTeacher && !isStudentImpersonation }"
						@toggle="userMenuOpen = !userMenuOpen"
						@close="userMenuOpen = false"
					/>
					<StudentImpersonationIcon
						v-if="isTeacher || isStudentImpersonation"
						v-tippy="{
							content: isStudentImpersonation
								? 'Log ud som elev'
								: 'Vis som elev',
						}"
						:user="isStudentImpersonation ? user : null"
						class="navbar-item is-clickable"
						@click.native="onClickStudentImpersonation"
					/>
				</div>
			</div>
		</nav>

		<transition name="mega-menu">
			<BookMegaMenu v-if="bookMenuOpen" @close="onBookMenuClose" />
		</transition>
		<transition name="mega-menu">
			<AssignmentTypeMegaMenu v-if="assignmentMenuOpen" @close="onAssignmentMenuClose" />
		</transition>
		<transition name="mega-menu">
			<SchoolClassMegaMenu v-if="schoolClassesMenuOpen" @close="onSchoolClassesMenuClose" />
		</transition>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BaseSearch from '@/components/base/BaseSearch';
import BookMegaMenu from './mega-menu/BookMegaMenu';
import AssignmentTypeMegaMenu from './mega-menu/AssignmentTypeMegaMenu';
import SchoolClassMegaMenu from './mega-menu/SchoolClassMegaMenu';
import UserMenu from './UserMenu';
import StudentImpersonationIcon from '@/components/studentimpersonation/icon/StudentImpersonationIcon';
import vocabulary from '@/mixins/vocabulary';

export default {
	name: 'Menu',
	components: {
		BaseSearch,
		BookMegaMenu,
		AssignmentTypeMegaMenu,
		SchoolClassMegaMenu,
		UserMenu,
		StudentImpersonationIcon,
	},
	mixins: [vocabulary],
	data() {
		return {
			search: '',
			userMenuOpen: false,
			mobileMenuOpen: false,
			bookMenuOpen: false,
			assignmentMenuOpen: false,
			schoolClassesMenuOpen: false,
			vcoConfig: {
				handler: this.clickOutside,
				middleware: this.clickOutsideMiddleware,
			},
			vcoMobileConfig: {
				handler: this.clickOutsideMobile,
				middleware: this.clickOutsideMobileMiddleware,
			},
		};
	},
	computed: {
		...mapState('user', ['user']),
		...mapGetters('user', ['isTeacher', 'school']),
		...mapGetters('studentImpersonation', { isStudentImpersonation: 'isActive' }),
		lixURL() {
			let baseURL = this.$store.state.app.config.lixURL;
			if (!baseURL) {
				return '#';
			}
			if (baseURL.endsWith('/')) {
				baseURL = baseURL.slice(0, -1);
			}
			const token = this.$store.state.lix.token;
			return `${baseURL}/token-login?token=${token}`;
		},
		enableLixMenuItem() {
			return (
				this.$store.state.app.config?.environment !== 'prod' ||
				this.lixURL.toLowerCase().startsWith('https://app.lix.dk')
			);
		},
	},
	watch: {
		$route() {
			this.mobileMenuOpen = false;
			this.bookMenuOpen = false;
			this.assignmentMenuOpen = false;
			this.schoolClassesMenuOpen = false;
		},
		school: {
			handler(school) {
				if (!school) {
					return;
				}
				this.loadCourseDesignations();
				this.loadBooks();
				this.loadAssignmentsByCourse();
				if (this.isTeacher || this.isStudentImpersonation) {
					this.loadSchoolClasses();
				}
			},
			immediate: true,
		},
	},
	methods: {
		clickOutsideMobile() {
			this.mobileMenuOpen = false;
		},
		clickOutsideMobileMiddleware() {
			return this.mobileMenuOpen;
		},
		clickOutside() {
			this.userMenuOpen = false;
		},
		clickOutsideMiddleware() {
			return this.userMenuOpen;
		},
		routeIsActive(regex) {
			return regex.exec(this.$route.path) !== null;
		},
		doSearch() {
			this.$router.push({
				name: 'search',
				query: {
					q: this.search,
				},
			});
			this.search = '';
		},
		onClickBooksMenuItem() {
			this.bookMenuOpen = !this.bookMenuOpen;
		},
		onBookMenuClose() {
			this.bookMenuOpen = false;
		},
		onClickAssignmentsMenuItem() {
			this.assignmentMenuOpen = !this.assignmentMenuOpen;
		},
		onAssignmentMenuClose() {
			this.assignmentMenuOpen = false;
		},
		onClickSchoolClassesMenuItem() {
			this.schoolClassesMenuOpen = !this.schoolClassesMenuOpen;
		},
		onSchoolClassesMenuClose() {
			this.schoolClassesMenuOpen = false;
		},
		loadCourseDesignations() {
			return this.$store.dispatch('designation/getSchoolUserCoursesSettings');
		},
		loadBooks() {
			return this.$store.dispatch('books/getMenuBooks');
		},
		loadAssignmentsByCourse() {
			return this.$store.dispatch('assignments/getAssignmentsByCourse');
		},
		loadSchoolClasses() {
			return this.$store.dispatch('school/getSchoolClasses');
		},
		onClickStudentImpersonation() {
			return this.isStudentImpersonation
				? this.exitStudentImpersonation()
				: this.toggleStudentImpersonationBar();
		},
		toggleStudentImpersonationBar() {
			return this.$store.dispatch('studentImpersonation/toggleBar');
		},
		exitStudentImpersonation() {
			return this.$store.dispatch('studentImpersonation/exit');
		},
	},
};
</script>

<style lang="scss" scoped>
.navbar-brand {
	.menu-logo-img:hover {
		filter: brightness(140%);
	}
}

.mega-menu-enter-active,
.mega-menu-leave-active {
	transition: all 250ms ease-in-out;
}
.mega-menu-enter,
.mega-menu-leave-to {
	transform: translateY(-10%);
	opacity: 0;
}

:deep(.base-search) {
	input {
		width: 10rem;
		&:focus {
			width: 12.5rem;
		}
	}
}
</style>
