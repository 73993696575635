import Model from '@/models/Model';
import SectionCollection from '@/models/section/SectionCollection';
import Book from '@/models/book/Book';
import Assignment from '@/models/assignment/Assignment';
import ScreeningAssignment from '@/models/screeningassignment/ScreeningAssignment';
import { newDate } from '@/utils/date';
import { timeHumanized } from '@/utils/date';
import dayjs from 'dayjs';

function resolveParent(parent) {
	const type = parent?.type ?? parent?.material_type;
	if (type == 'grammar' || type == 'book') {
		return new Book(parent);
	}
	if (type == 'assignment') {
		return new Assignment(parent);
	}
	if (type == 'screening_assignment') {
		return new ScreeningAssignment(parent);
	}
	return parent;
}

export default class Homework extends Model {
	processValues(values = {}) {
		if (values.sections && !(values.sections instanceof SectionCollection)) {
			values.sections = new SectionCollection(values.sections);
		}
		return values;
	}

	hasSections() {
		return this.sections?.length > 0;
	}

	getSections() {
		return new SectionCollection(this.hasSections() ? this.sections : []);
	}

	getType() {
		return this.type || '';
	}

	isType(type) {
		return this.getType() === (type || '').toLowerCase();
	}

	isScreening() {
		return this.is_screening === true;
	}

	hasParents() {
		return this.parents !== undefined && this.parents?.length > 0;
	}

	getParents() {
		return (this.hasParents() ? this.parents : []).map(resolveParent);
	}

	getParent(index = 0) {
		return this.hasParents() ? resolveParent(this.parents[index]) : null;
	}

	hasHiddenResults() {
		return !this.has_visible_results;
	}

	hasHiddenPoints() {
		return !this.has_visible_points;
	}

	hasHiddenInstructions() {
		return !this.has_visible_instructions;
	}

	getReleasedAt(user = null) {
		if (user) {
			const dates = this.getStudentTimeOverride(user);
			if (dates) {
				return newDate(dates.released_at);
			}
		}
		return newDate(this.released_at);
	}

	getDeadlineAt(user = null) {
		if (user) {
			const dates = this.getStudentTimeOverride(user);
			if (dates) {
				return newDate(dates.deadline_at);
			}
		}
		return newDate(this.deadline_at);
	}

	getStudentTimeOverride(user) {
		return this.student_time_overrides?.find(row => row.user_id === user.id);
	}

	isLive(user = null) {
		const date = newDate();
		return (
			date.unix() >= this.getReleasedAt(user)?.unix() &&
			date.unix() <= this.getDeadlineAt(user)?.unix()
		);
	}

	getUsersCompleted() {
		return Array.isArray(this.users_completed) ? {} : this.users_completed;
	}

	getUserManuallyCompletedAt(user) {
		if (!this.users_completed || !(user.id in this.users_completed)) {
			return null;
		}
		return newDate(this.users_completed[user.id]);
	}

	getDuration() {
		if (this.isScreening()) {
			return this.getDeadlineAt().diff(this.getReleasedAt(), 'minute');
		}

		if (this.isType('training')) {
			return this.training_duration;
		}

		return this.duration;
	}

	getDurationFormatted() {
		let duration;
		if (this.isScreening()) {
			const released_at = dayjs(this.getReleasedAt());
			const deadline_at = dayjs(this.getDeadlineAt());
			duration = deadline_at.diff(released_at, 'minute');
			if (duration > 720) {
				return '--';
			}
		} else if (this.isType('training')) {
			duration = this.training_duration;
		} else {
			duration = this.duration;
		}

		return timeHumanized(duration);
	}

	includesSection(section, user = null) {
		if (!this.section_relation_ids.includes(section.section_relation_id)) {
			return false;
		}
		return !user || !this.studentIsExcludedFromSection(user, section);
	}

	studentIsExcluded(user) {
		return !!this.excluded_students?.students?.includes(user.id);
	}

	studentIsExcludedFromSection(user, section = null) {
		if (!this.excluded_students) {
			return false;
		}
		if (this.studentIsExcluded(user)) {
			return true;
		}
		if (!section) {
			return false;
		}
		return !!this.excluded_students.section_relations?.[section.section_relation_id]?.includes(user.id);
	}

	getLatestDeadline() {
		const deadlines = [this.getDeadlineAt()];
		if (this.student_time_overrides?.length) {
			this.student_time_overrides.forEach(override => {
				deadlines.push(newDate(override.deadline_at));
			});
		}
		return deadlines.sort((date1, date2) => {
			return date2.unix() - date1.unix();
		})[0];
	}

	allowFeedback() {
		if (!this.isScreening()) {
			return true;
		}
		return this.getLatestDeadline().isBefore(newDate());
	}

	getSourceTitle() {
		if (this.isType('training')) {
			return 'Adaptiv træning';
		}
		if (this.isType('grammar') && this.hasParents()) {
			return this.getParent().title;
		}
		if (this.course) {
			return this.course.title;
		}
		return 'Lektie';
	}
}
