<template>
	<div class="base-go-back is-clickable" @mouseenter="isHovered = true" @mouseleave="isHovered = false">
		<div class="is-flex is-align-items-center">
			<BaseIcon
				icon="arrow-left"
				:color="isHovered ? hoverColor : iconColor"
				:size="iconSize"
				clickable
				has-transition
			/>
			<p
				class="title is-flex-grow-1 mb-0"
				:class="[`has-text-${isHovered ? hoverColor : textColor}`, `is-${textSize}`]"
				v-text="text"
			/>
		</div>
	</div>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon';

export default {
	name: 'BaseGoBack',
	components: { BaseIcon },
	props: {
		iconColor: {
			type: String,
			default: 'grey-light',
		},
		textColor: {
			type: String,
			default: 'grey-light',
		},
		hoverColor: {
			type: String,
			default: 'grey-dark',
		},
		text: {
			type: String,
			default: 'Tilbage',
		},
		textSize: {
			type: Number,
			default: 6,
		},
		iconSize: {
			type: Number,
			default: 5,
		},
	},
	data() {
		return {
			isHovered: false,
		};
	},
};
</script>

<style lang="scss" scoped>
.base-go-back {
	display: inline-block;
	margin-left: -0.75rem;

	&:hover {
		.title {
			transition: color 200ms linear;
		}
		.base-icon-wrapper {
			margin-left: -5px;
			margin-right: 5px;
			transition: margin 200ms linear;
		}
	}
}
</style>
