import { getStudentInfo } from '@/api/students';

const state = {};

const getters = {};

const actions = {
	getStudentInfo({ rootState }, studentId) {
		return getStudentInfo(rootState.user.schoolId, studentId);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
