import { getTooltip } from '@/api/tooltips';

const state = () => ({});

const getters = {};

const actions = {
	getTooltip(_, { id }) {
		return getTooltip(id);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
