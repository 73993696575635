import { getGroups, getStudents, getGroupStudents, getJoincodeUrl, getRedirectUrl } from '@/api/unilogin';
import { getCachedItem, cacheItem, forgetItem } from '../cachePersistent';

const authCacheKey = 'UNILOGIN_AUTH_DATA';

const state = () => ({});

const getters = {};

const actions = {
	getGroups({ rootState }) {
		return getGroups(rootState.user.schoolId);
	},
	getStudents({ rootState }) {
		return getStudents(rootState.user.schoolId);
	},
	getGroupStudents({ rootState }, { groupId }) {
		return getGroupStudents(rootState.user.schoolId, groupId);
	},
	getJoincodeUrl(_, { code }) {
		return getJoincodeUrl(code);
	},
	getRedirectUrl(_, { redirect }) {
		return getRedirectUrl(redirect);
	},
	setAuthDataCache(_, data) {
		return cacheItem(authCacheKey, data);
	},
	clearAuthDataCache() {
		return forgetItem(authCacheKey);
	},
	getAuthDataCache() {
		return getCachedItem(authCacheKey);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
