import VueCookie from 'vue-cookie';
import { getLixToken } from '@/api/app';

const tokenKey = 'lix_token';

const state = () => ({
	token: VueCookie.get(tokenKey) || null,
});

const getters = {
	isAuthenticated: state => !!state.token,
};

const actions = {
	getToken({ commit }) {
		return getLixToken().then(resp => {
			const token = resp.access_token;
			commit('setToken', { token });
			return token;
		});
	},
	clearToken({ commit }) {
		commit('clearToken');
	},
};

const mutations = {
	setToken(state, { token }) {
		VueCookie.set(tokenKey, token, {
			SameSite: 'strict',
			secure: 'https:' === document.location.protocol,
			expires: 28,
		});
		state.token = token;
	},
	clearToken(state) {
		VueCookie.delete(tokenKey);
		state.token = null;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
