<template>
	<SchoolClassCardContainer
		:style="{ 'background-color': hasSingleCourse ? schoolClass.courses[0].color : '' }"
		:class="{ 'has-background-grey-dark': !hasSingleCourse }"
		animate
		has-background
	>
		<template #title>
			<div class="is-flex is-align-items-center">
				<CourseFlag v-if="hasSingleCourse" :course="schoolClass.courses[0]" size="medium" />
				<p class="title is-6 has-text-white" :class="{ 'ml-3': hasSingleCourse }">
					{{ schoolClass.name }}
				</p>
			</div>
		</template>
		<template v-if="hasMultipleCourses" #courses>
			<div class="is-flex is-align-items-center">
				<CourseFlag
					v-for="course in displayedCourses"
					:key="course.id"
					:course="course"
					size="medium"
					class="mr-3"
				/>
				<div
					v-if="extraCourses > 0"
					class="has-background-grey-darker has-text-white has-text-weight-bold has-border-radius px-3 is-size-5 extra-courses"
				>
					+{{ extraCourses }}
				</div>
			</div>
		</template>
		<template v-if="schoolClass.level" #level>
			<BaseTag color="white" text-color="black" class="m-4">{{ schoolClass.level.title }}</BaseTag>
		</template>
		<template v-if="schoolClass.students.length" #avatars>
			<div
				class="has-background-white has-border-bottom-right-radius-large has-border-top-left-radius-large p-2"
			>
				<AvatarStack :users="schoolClass.students" size="small" />
			</div>
		</template>
		<template v-if="!hideWarnings && (uniloginSyncDisabled || hasNoStudents)" #warning>
			<router-link
				v-tippy="{ content: warningTooltip }"
				:to="warningRoute"
				class="schoolclass-warning has-background-red has-border-radius-large has-border-white is-flex is-flex-direction-column is-justify-content-center"
			>
				<p class="title is-5 has-text-white has-text-centered">!</p>
			</router-link>
		</template>
	</SchoolClassCardContainer>
</template>

<script>
import SchoolClassCardContainer from './SchoolClassCardContainer';
import CourseFlag from '@/components/course/CourseFlag';
import AvatarStack from '@/components/ui/user/AvatarStack';
import BaseTag from '@/components/base/BaseTag';

export default {
	name: 'SchoolClassCard',
	components: {
		SchoolClassCardContainer,
		CourseFlag,
		AvatarStack,
		BaseTag,
	},
	props: {
		schoolClass: {
			type: Object,
			required: true,
		},
		hideWarnings: {
			type: Boolean,
			required: false,
		},
	},
	computed: {
		displayedCourses() {
			return this.schoolClass.courses.slice(0, 4);
		},
		extraCourses() {
			return this.schoolClass.courses.length - 4;
		},
		hasSingleCourse() {
			return this.schoolClass.courses.length === 1;
		},
		hasMultipleCourses() {
			return this.schoolClass.courses.length > 1;
		},
		uniloginSyncDisabled() {
			return !!this.schoolClass.unilogin_id && !this.schoolClass.unilogin_sync_active;
		},
		hasNoStudents() {
			return !this.schoolClass.unilogin_id && !this.schoolClass.students.length;
		},
		warningTooltip() {
			if (this.uniloginSyncDisabled) {
				return 'Synkroniserer ikke med UNI Login';
			}
			if (this.hasNoStudents) {
				return 'Holdet har ingen elever';
			}
			return '';
		},
		warningRoute() {
			let query = {};
			if (this.uniloginSyncDisabled) {
				query = { uniloginsync: true };
			} else if (this.hasNoStudents) {
				query = { addstudents: true };
			}
			return {
				name: 'school_class_details',
				params: { school_class_id: this.schoolClass.id },
				query,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.extra-courses {
	height: 2rem;
	line-height: 2rem;
}
.schoolclass-warning {
	width: 3rem;
	height: 3rem;
	line-height: 3rem;
	border: 4px solid;
}
</style>
