<template>
	<li class="tab" :class="{ 'is-active': isActive }" @click="handleClick">
		<a :style="activeColor ? { color: activeColor } : {}">
			<span class="is-size-6 is-family-secondary">
				<slot name="title" />
			</span>
			<slot name="badge" />
		</a>
	</li>
</template>

<script>
export default {
	name: 'BaseTab',
	inject: ['tabs'],
	props: {
		id: {
			type: String,
			required: true,
		},
		activeColor: {
			type: String,
			default: null,
		},
	},
	computed: {
		isActive() {
			return this.id === this.tabs.active;
		},
	},
	mounted() {
		this.tabs.addItemInstance(this);
	},
	destroyed() {
		this.tabs.removeItemInstance(this);
	},
	methods: {
		handleClick() {
			this.tabs.$emit.apply(this.tabs, ['tab-clicked', this]);
		},
	},
};
</script>
