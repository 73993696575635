<template>
	<g>
		<circle v-if="!asText" cx="16" cy="16" r="10" stroke-width="3" />
		<text v-else x="0" y="24">V</text>
	</g>
</template>

<script>
export default {
	name: 'VerbRightSymbol',
	props: {
		asText: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.$emit('config', {
				symbolWidth: this.$el.getBoundingClientRect().width,
				lineLeft: true,
			});
		});
	},
};
</script>
