<template>
	<div
		class="is-flex-desktop"
		:class="[
			{ 'grammar-outer-container': !noContainer },
			`is-align-items-${verticalAlign}`,
			`is-justify-content-${horizontalAlign}`,
		]"
	>
		<div v-if="!noGutters" class="grammar-gutter is-left">
			<slot name="left" />
		</div>

		<div class="grammar-content" :class="{ 'full-width': fullWidth, 'text-to-speech': textToSpeech }">
			<div class="content">
				<slot />
			</div>
			<GrammarSymbol
				v-for="(element, i) in symbolElements"
				:key="`symbol-${i}`"
				:element="element"
				:as-text="symbolsAsText"
				:is-white="isWhiteText"
			/>
		</div>

		<div v-if="!noGutters" class="grammar-gutter is-right">
			<slot name="right" />
		</div>
	</div>
</template>

<script>
import GrammarSymbol from './symbols/GrammarSymbol';
import hasGlossaryTooltips from '@/mixins/traits/hasGlossaryTooltips';
import designation from '@/mixins/designation';
import {
	findGrammarTablesInElement,
	wrapGrammarTableInContainer,
	centerGrammarTableContainerInWiewport,
} from '@/utils/grammarTables';
import debounce from 'lodash/debounce';

export default {
	name: 'GrammarContent',
	components: { GrammarSymbol },
	mixins: [hasGlossaryTooltips, designation],
	props: {
		verticalAlign: {
			type: String,
			default: 'stretch',
		},
		horizontalAlign: {
			type: String,
			default: 'center',
		},
		textToSpeech: {
			type: Boolean,
			default: false,
		},
		noContainer: {
			type: Boolean,
			default: false,
		},
		noGutters: {
			type: Boolean,
			default: false,
		},
		fullWidth: {
			type: Boolean,
			default: false,
		},
		isWhiteText: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			symbolElements: [],
			onWindowResize: debounce(this.postResizeProcessing, 200),
		};
	},
	computed: {
		symbolsAsText() {
			return this.$designation().symbol === 2;
		},
	},
	mounted() {
		window.addEventListener('resize', this.onWindowResize);
		this.initProcessing();
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onWindowResize);
	},
	methods: {
		initProcessing() {
			// Wrap tables in containers and center them
			this.wrapTablesInContainers();
			this.centerTablesInWiewport();

			// Setup glossary tooltip listeners
			// Needed because table wrapping process resets listeners
			this.setupGlossaryTooltipListeners(this.$el);

			// Find and setup grammar symbols
			this.updateSymbols();
		},
		postResizeProcessing() {
			// Center grammar tables in viewport
			this.centerTablesInWiewport();

			// Find and setup grammar symbols
			this.updateSymbols();
		},
		updateSymbols() {
			this.symbolElements = [];
			setTimeout(() => {
				this.symbolElements = this.findSymbolElements();
			}, 100);
		},
		findSymbolElements() {
			return Array.from(this.$el.querySelectorAll('span[class*="under-"], span.ml-under'));
		},
		wrapTablesInContainers() {
			if (!this.$slots.default?.length) {
				return;
			}

			this.$slots.default.forEach(vnode => {
				const tables = findGrammarTablesInElement(vnode.elm);
				tables.forEach(table => wrapGrammarTableInContainer(table));
			});
		},
		centerTablesInWiewport() {
			const grammarContainer = this.$el.querySelector('.grammar-content');
			if (!grammarContainer) {
				return;
			}
			findGrammarTablesInElement(grammarContainer).forEach(table => {
				centerGrammarTableContainerInWiewport(table, grammarContainer);
			});
		},
	},
};
</script>
