export function getNodeInnerDimensions(node) {
	const computedStyle = getComputedStyle(node);

	let width = node.clientWidth; // width with padding
	let height = node.clientHeight; // height with padding

	height -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
	width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

	return { height, width };
}

export function setCaretPositionToEndOfEditable(div) {
	if (!div?.textContent?.length) {
		return;
	}

	const startNode = div.childNodes[div.childNodes.length - 1];
	if (!(startNode instanceof Text)) {
		// TODO: Investigate why child of editable div is not a text node
		// This causes IndexSizeError on range.setStart()
		// https://minlaering.sentry.io/issues/4110042440/events/f2e09a2a13d94c188df40fa1270c448c/?project=5274330
		return;
	}

	const range = document.createRange();
	const selection = window.getSelection();
	range.setStart(startNode, startNode.textContent?.length ?? 0);
	range.collapse(true);
	selection.removeAllRanges();
	selection.addRange(range);
}

export function calculateTextWidth(text, context = null) {
	if (!(context instanceof HTMLElement)) {
		context = document.body;
	}
	const div = document.createElement('div');
	div.appendChild(document.createTextNode(text));
	div.style.setProperty('visibility', 'hidden');
	div.style.setProperty('position', 'absolute');
	context.appendChild(div);
	const width = div.clientWidth;
	div.remove();
	return width;
}

export function scrollToElement(element) {
	if (!(element instanceof Element)) {
		return;
	}
	element?.scrollIntoView({
		block: 'start',
		inline: 'start',
		behavior: 'smooth',
	});
}
