<template>
	<svg
		class="base-checkbox is-flex-shrink-0"
		:class="{
			'is-active': isFullySelected,
			'is-rounded': rounded,
			[`has-background-${activeColor}`]: isFullySelected,
			[`has-background-${inactiveColor}`]: !isFullySelected,
			[`is-${size}`]: true,
		}"
		@mouseenter="isHovered = true"
		@mouseleave="isHovered = false"
	>
		<use :xlink:href="url"></use>
	</svg>
</template>

<script>
export default {
	name: 'BaseCheckbox',
	props: {
		selected: {
			type: [Boolean, Number],
			default: false,
			validator: function (value) {
				// Value 2 is considered as 'partly selected'
				return [true, false, 0, 1, 2].indexOf(value) >= 0;
			},
		},
		size: {
			type: String,
			default: 'medium',
		},
		rounded: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		inactiveColor: {
			type: String,
			default: 'grey-lighterer',
		},
		activeColor: {
			type: String,
			default: 'blue',
		},
	},
	data() {
		return {
			isHovered: false,
		};
	},
	computed: {
		isSelected() {
			return this.isFullySelected || this.isPartlySelected;
		},
		isFullySelected() {
			return this.selected === true || this.selected === 1;
		},
		isPartlySelected() {
			return this.selected === 2;
		},
		url() {
			let icon = this.isSelected || this.isHovered ? 'active' : '';

			if (this.disabled) {
				icon = 'disabled';
			}

			return require('@/assets/images/icons.svg') + `#icon-checkbox-${icon}`;
		},
	},
};
</script>

<style lang="scss" scoped>
svg.base-checkbox {
	box-sizing: content-box;
	width: 1.5rem;
	height: 1.5rem;
	padding: 0.1875rem;

	&.is-large {
		width: 2.2rem;
		height: 2.2rem;
	}

	border-radius: 0.5rem;
	&.is-rounded {
		border-radius: 50%;
	}
}
</style>
