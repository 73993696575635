<template>
	<div :class="classes">
		<div class="has-text-centered message-text" v-html="message"></div>
		<BaseIcon
			v-if="dismissible"
			class="close-icon"
			color="white"
			hover-color="grey"
			clickable
			:size="5"
			icon="cross"
			@click.native="dismiss"
		/>
	</div>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon';

export default {
	name: 'AppMessage',
	components: {
		BaseIcon,
	},
	props: {
		message: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			default: 'info',
		},
		dismissible: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		classes() {
			return ['app-message', `has-background-${this.type}`];
		},
	},
	methods: {
		dismiss() {
			this.$emit('dismiss');
		},
	},
};
</script>
<style lang="scss" scoped>
.app-message {
	position: relative;
	padding: 1.2rem 0;
	z-index: 1;

	.message-text {
		font-size: 0.9rem;
		font-weight: 700;
		color: white;
	}

	.close-icon {
		position: absolute;
		top: 2px;
		right: 10px;
	}
}
</style>
