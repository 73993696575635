<template>
	<section class="section">
		<div class="container has-text-centered mt-5">
			<div class="has-text-centered mb-5">
				<p v-if="header" class="title is-3 has-text-grey-darker" v-text="header" />
				<p v-if="text" class="subtitle is-6 has-text-grey-dark" v-text="text" />
				<p v-if="withContact" class="title is-6 has-text-grey">
					Du kan dog
					<router-link :to="{ name: 'trial' }"
						>starte en gratis prøveadgang her</router-link
					>.<br />
					Du er også altid velkommen til at
					<a href="https://support.minlaering.dk/kontakt-os" target="_blank"
						>kontakte os</a
					>. Så sørger vi for at hjælpe dig i gang.
				</p>

				<slot name="buttons" />
			</div>
			<img :src="imagePath" width="50%" class="mt-5" />
		</div>
	</section>
</template>

<script>
export default {
	name: 'BaseNoContent',
	props: {
		header: {
			type: String,
			default: '',
		},
		text: {
			type: String,
			default: '',
		},
		imagePath: {
			type: String,
			default: '/images/classroom.svg',
		},
		withContact: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
.base-no-content {
	margin: 40px auto;
}
</style>
