import {
	getAssignmentsByCourse,
	getAssignmentsByEducation,
	getCourseAssignments,
	getAssignment,
	getAssignmentSections,
	getSchoolAssignment,
} from '@/api/assignment';

const state = () => ({
	menuAssignmentsByCourse: [],
});

const getters = {};

const actions = {
	getAssignmentsByCourse({ commit, rootState }) {
		return getAssignmentsByCourse(rootState.user.schoolId).then(assignmentsByCourse => {
			commit('setMenuAssignmentsByCourse', assignmentsByCourse);
			return assignmentsByCourse;
		});
	},
	getAssignmentsByEducation({ rootState }, { courseId, typeId }) {
		return getAssignmentsByEducation(rootState.user.schoolId, courseId, typeId);
	},
	getCourseAssignments(_, { courseId }) {
		return getCourseAssignments(courseId);
	},
	getAssignment(_, { id }) {
		return getAssignment(id);
	},
	getAssignmentSections(_, { id }) {
		return getAssignmentSections(id);
	},
	getSchoolAssignment({ rootState }, { id, homeworkId }) {
		return getSchoolAssignment(rootState.user.schoolId, id, homeworkId);
	},
};

const mutations = {
	setMenuAssignmentsByCourse(state, assignmentsByCourse) {
		state.menuAssignmentsByCourse = assignmentsByCourse;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
