import http from './http';

export function getTaskSolution(task_ids = [], homework_id = null, course_id = null) {
	const params = { task_ids };
	if (homework_id) {
		params.homework_id = homework_id;
	} else if (course_id) {
		params.course_id = course_id;
	}
	return http.get('/tasks/solution', { params }).then(resp => resp.data.data);
}
