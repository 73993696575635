import UserNotification from './UserNotification';

export default class StudentReceivedHomeworkFeedbackMessage extends UserNotification {
	get title() {
		return 'Ny feedback';
	}

	get body() {
		return `Din lærer har givet dig feedback på: ${this.data.homework_title}`;
	}

	get actionText() {
		return 'Gå til lektie';
	}

	get actionRoute() {
		if (this.data.is_screening) {
			return {
				name: 'screening',
				params: { id: this.data.parent_id },
				query: { homeworkId: this.data.homework_id },
			};
		}
		return { name: 'homework', query: { vis: this.data.homework_id } };
	}
}
