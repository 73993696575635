<template>
	<div class="spinner" :class="[`is-${size}`]">
		<div class="bounce1" :class="[`has-background-${color}`]"></div>
		<div class="bounce2" :class="[`has-background-${color}`]"></div>
		<div class="bounce3" :class="[`has-background-${color}`]"></div>
	</div>
</template>

<script>
export default {
	name: 'BaseLoader',
	props: {
		size: {
			type: String,
			default: 'medium',
		},
		color: {
			type: String,
			default: 'grey-light',
		},
	},
};
</script>

<style lang="scss" scoped>
.spinner {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	width: 100%;
	max-width: 200px;

	&.is-small {
		width: 50px;
	}
	&.is-medium {
		width: 70px;
	}
	&.is-large {
		width: 120px;
	}

	> div {
		width: 25.714%;
		padding-top: 25%;
		background-color: #666;

		border-radius: 100%;
		display: inline-block;
		-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
		animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	}

	.bounce1 {
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s;
	}
	.bounce2 {
		-webkit-animation-delay: -0.16s;
		animation-delay: -0.16s;
	}
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
</style>
