export default class UserStats {
	constructor(values = {}) {
		values = { ...getDefaultValues(), ...values };
		this.sectionRelationIds = values.sections.map(section => section.section_relation_id);
		this.progress = values.progress;
		this.points = values.points;
		this.startTime = values.startTime;
		this.endTime = values.endTime;
		this.sectionStats = values.sectionStats;
	}

	getSectionStats(section) {
		return this.sectionStats[section.section_relation_id];
	}
}

function getDefaultValues() {
	return {
		sections: [],
		progress: 0,
		points: 0,
		startTime: null,
		endTime: null,
		sectionStats: {},
	};
}
