import {
	updateHomework,
	createHomework,
	getHomework,
	getUserHomework,
	getClassHomework,
	deleteHomework,
	copyHomework,
	validateExistingHomeworkAnswers,
	getStudentHomework,
	getStudentHomeworkSectionOverview,
	getStudentHomeworkSections,
	completeHomework,
	undoUserHomeworkCompletion,
	resetStudentHomeworkPeriods,
	getTrainingExercise,
	getTrainingStatus,
	getTrainingResults,
	getTrainingFocusAreas,
	startTraining,
	stopTraining,
	resetTrainingTime,
	getHomeworkFeedbackMessages,
	createHomeworkFeedbackMessage,
	updateHomeworkFeedbackMessage,
	deleteHomeworkFeedbackMessage,
} from '@/api/homework';

const state = {};

const getters = {};

const actions = {
	updateHomework({ rootState }, { school_class_id, homework_id, data }) {
		return updateHomework(rootState.user.schoolId, school_class_id, homework_id, data);
	},
	createHomework({ rootState }, { school_class_id, data }) {
		return createHomework(rootState.user.schoolId, school_class_id, data);
	},
	getHomework({ rootState }, { school_class_id, homework_id }) {
		return getHomework(rootState.user.schoolId, school_class_id, homework_id);
	},
	deleteHomework({ rootState }, { school_class_id, homework_id }) {
		return deleteHomework(rootState.user.schoolId, school_class_id, homework_id);
	},
	copyHomework({ rootState }, { school_class_id, homework_id, data }) {
		return copyHomework(rootState.user.schoolId, school_class_id, homework_id, data);
	},
	getUserHomework({ rootState }) {
		return getUserHomework(rootState.user.schoolId);
	},
	getClassHomework({ rootState }, { class_id }) {
		return getClassHomework(rootState.user.schoolId, class_id);
	},
	validateExistingHomeworkAnswers({ rootState }, { school_class_id, data }) {
		return validateExistingHomeworkAnswers(rootState.user.schoolId, school_class_id, data);
	},
	getStudentHomework(_, { homework_id }) {
		return getStudentHomework(homework_id);
	},
	getStudentHomeworkSectionOverview(_, { homework_id }) {
		return getStudentHomeworkSectionOverview(homework_id);
	},
	getStudentHomeworkSections(_, { homework_id }) {
		return getStudentHomeworkSections(homework_id);
	},
	completeHomework(_, homeworkId) {
		return completeHomework(homeworkId);
	},
	undoUserHomeworkCompletion({ rootState }, { schoolClassId, homeworkId, userId }) {
		return undoUserHomeworkCompletion(rootState.user.schoolId, schoolClassId, homeworkId, userId);
	},
	resetStudentHomeworkPeriods(_, { homeworkId, userId }) {
		return resetStudentHomeworkPeriods(homeworkId, userId);
	},
	getTrainingExercise(_, { homeworkId, avoidMedia = false }) {
		return getTrainingExercise(homeworkId, avoidMedia);
	},
	getTrainingStatus(_, homeworkId) {
		return getTrainingStatus(homeworkId);
	},
	getTrainingResults(_, { homeworkId, userIds }) {
		return getTrainingResults(homeworkId, userIds);
	},
	getTrainingFocusAreas(_, homeworkId) {
		return getTrainingFocusAreas(homeworkId);
	},
	startTraining(_, homeworkId) {
		return startTraining(homeworkId);
	},
	stopTraining(_, { homeworkId, forceEnd }) {
		return stopTraining(homeworkId, forceEnd);
	},
	resetTrainingTime(_, homeworkId) {
		return resetTrainingTime(homeworkId);
	},
	getHomeworkFeedbackMessages({ rootState }, { schoolClassId, homeworkId }) {
		return getHomeworkFeedbackMessages(rootState.user.schoolId, schoolClassId, homeworkId);
	},
	createHomeworkFeedbackMessage({ rootState }, { schoolClassId, homeworkId, data }) {
		return createHomeworkFeedbackMessage(rootState.user.schoolId, schoolClassId, homeworkId, data);
	},
	updateHomeworkFeedbackMessage({ rootState }, { schoolClassId, homeworkId, messageId, data }) {
		return updateHomeworkFeedbackMessage(
			rootState.user.schoolId,
			schoolClassId,
			homeworkId,
			messageId,
			data,
		);
	},
	deleteHomeworkFeedbackMessage({ rootState }, { schoolClassId, homeworkId, messageId }) {
		return deleteHomeworkFeedbackMessage(rootState.user.schoolId, schoolClassId, homeworkId, messageId);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
