<template>
	<div class="background">
		<svg class="svg" viewBox="0 0 1440 927" fill="none" xmlns="http://www.w3.org/2000/svg">
			{{ /* eslint-disable max-len */ }}
			<mask
				id="mask0_1_1102"
				style="mask-type: alpha"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="1440"
				height="927"
			>
				<rect width="1440" height="927" fill="#11405E" />
			</mask>
			<g mask="url(#mask0_1_1102)">
				<!-- Background (stars) -->
				<KinesisElement tag="g" axis="x" :strength="-2">
					<path
						d="M559.69 377.977C565.01 379.973 569 384.631 569.998 390.286L570.995 395.609C571.328 396.939 572.99 396.939 573.323 395.609L574.32 390.286C575.318 384.631 579.308 379.973 584.628 377.977C585.625 377.645 585.625 375.981 584.628 375.649C579.308 373.653 575.318 368.995 574.32 363.34L573.323 358.017C572.99 356.687 571.328 356.687 570.995 358.017L569.998 363.34C569 368.995 565.01 373.653 559.69 375.649C558.693 375.981 558.693 377.312 559.69 377.977Z"
						fill="white"
					/>
					<path
						d="M836.662 410.579C840.652 411.909 843.645 415.236 844.31 419.228L844.975 423.22C845.307 424.218 846.637 424.218 846.637 423.22L847.302 419.228C847.967 415.236 850.96 411.909 854.95 410.579C855.615 410.246 855.615 409.248 854.95 408.915C850.96 407.585 847.967 404.258 847.302 400.266L846.637 396.274C846.305 395.276 844.975 395.276 844.975 396.274L844.31 400.266C843.645 404.258 840.652 407.585 836.662 408.915C835.665 409.248 835.665 410.246 836.662 410.579Z"
						fill="#E0B76C"
					/>
					<path
						d="M555.7 662.74H552.375C550.38 662.74 549.05 661.409 549.05 659.413V656.087C549.05 654.091 550.38 652.76 552.375 652.76H555.7C557.695 652.76 559.025 654.091 559.025 656.087V659.413C559.025 661.409 557.695 662.74 555.7 662.74Z"
						fill="#55D38C"
					/>
					<path
						d="M352.875 592.88H349.55C347.555 592.88 346.225 591.549 346.225 589.553V586.227C346.225 584.231 347.555 582.9 349.55 582.9H352.875C354.87 582.9 356.2 584.231 356.2 586.227V589.553C356.2 591.549 354.87 592.88 352.875 592.88Z"
						fill="#55D38C"
					/>
					<path
						d="M382.8 486.427H379.475C377.48 486.427 376.15 485.096 376.15 483.1V479.773C376.15 477.777 377.48 476.447 379.475 476.447H382.8C384.795 476.447 386.125 477.777 386.125 479.773V483.1C386.125 485.096 384.795 486.427 382.8 486.427Z"
						fill="white"
					/>
					<path
						d="M888.2 449.833H884.875C882.88 449.833 881.55 448.503 881.55 446.507V443.18C881.55 441.184 882.88 439.853 884.875 439.853H888.2C890.195 439.853 891.525 441.184 891.525 443.18V446.507C891.525 448.503 890.195 449.833 888.2 449.833Z"
						fill="white"
					/>
					<path
						d="M419.375 429.873H416.05C414.055 429.873 412.725 428.543 412.725 426.547V423.22C412.725 421.224 414.055 419.893 416.05 419.893H419.375C421.37 419.893 422.7 421.224 422.7 423.22V426.547C422.7 428.543 421.37 429.873 419.375 429.873Z"
						fill="#E0B76C"
					/>
					<path
						d="M589.948 297.472H587.62C585.293 297.472 583.298 295.476 583.298 293.147V290.819C583.298 288.49 585.293 286.494 587.62 286.494H589.948C592.275 286.494 594.27 288.49 594.27 290.819V293.147C594.603 295.476 592.608 297.472 589.948 297.472Z"
						fill="#45AB71"
					/>
				</KinesisElement>

				<!-- Fog -->
				<KinesisElement tag="g" axis="x" :strength="2">
					<path
						opacity="0.5"
						d="M1259.6 429.873C1242.98 429.873 1227.35 436.527 1215.38 448.17L1135.58 528.01C1123.94 539.653 1107.98 546.307 1091.36 546.307H977.642C943.063 546.307 909.813 533 885.208 508.715L852.955 477.445C828.018 453.16 795.1 439.853 760.52 439.853H535.75C480.555 439.853 436 484.431 436 539.653V552.627C436 575.581 426.69 597.205 410.398 613.173L402.085 621.157C391.445 631.469 378.478 638.455 364.18 642.447C336.583 650.099 316.3 675.049 316.3 704.323V728.941C316.3 750.897 324.945 772.187 340.573 787.823L418.71 865.667H1333.75V429.873H1259.6Z"
						fill="#1F7289"
					/>
				</KinesisElement>

				<!-- Bridge -->
				<KinesisElement tag="g" axis="x" :strength="5">
					<path
						d="M929.762 649.433C842.647 649.433 771.825 512.042 771.825 343.38H782.132C782.132 506.054 848.3 638.455 929.762 638.455C1011.23 638.455 1077.39 506.054 1077.39 343.38H1087.7C1087.7 512.042 1016.88 649.433 929.762 649.433Z"
						fill="#2A9E9E"
					/>
					<path
						d="M662.1 609.513V436.527C662.1 432.867 659.107 429.873 655.45 429.873C651.792 429.873 648.8 426.879 648.8 423.22V303.46C648.8 299.801 645.807 296.807 642.15 296.807H628.85C625.192 296.807 622.2 299.801 622.2 303.46V423.22C622.2 426.879 619.207 429.873 615.55 429.873C611.892 429.873 608.9 432.867 608.9 436.527V609.513C608.9 613.173 605.907 616.167 602.25 616.167C598.592 616.167 595.6 619.161 595.6 622.82V868.993H675.4V622.82C675.4 619.161 672.407 616.167 668.75 616.167C665.092 616.167 662.1 613.173 662.1 609.513Z"
						fill="#3BC4AD"
					/>
					<path
						d="M781.8 609.513V436.527C781.8 432.867 778.807 429.873 775.15 429.873C771.492 429.873 768.5 426.879 768.5 423.22V303.46C768.5 299.801 765.507 296.807 761.85 296.807H748.55C744.892 296.807 741.9 299.801 741.9 303.46V423.22C741.9 426.879 738.907 429.873 735.25 429.873C731.593 429.873 728.6 432.867 728.6 436.527V609.513C728.6 613.173 725.608 616.167 721.95 616.167C718.293 616.167 715.3 619.161 715.3 622.82V868.993H795.1V622.82C795.1 619.161 792.107 616.167 788.45 616.167C784.792 616.167 781.8 613.173 781.8 609.513Z"
						fill="#3BC4AD"
					/>
					<path
						d="M675.4 609.513V436.527C675.4 432.867 672.407 429.873 668.75 429.873C665.092 429.873 662.1 426.879 662.1 423.22V303.46C662.1 299.801 659.107 296.807 655.45 296.807H642.15C638.493 296.807 635.5 299.801 635.5 303.46V423.22C635.5 426.879 632.508 429.873 628.85 429.873C625.193 429.873 622.2 432.867 622.2 436.527V609.513C622.2 613.173 619.208 616.167 615.55 616.167C611.893 616.167 608.9 619.161 608.9 622.82V868.993H688.7V622.82C688.7 619.161 685.707 616.167 682.05 616.167C678.392 616.167 675.4 613.173 675.4 609.513Z"
						fill="#2A9E9E"
					/>
					<path
						d="M795.1 609.513V436.527C795.1 432.867 792.107 429.873 788.45 429.873C784.792 429.873 781.8 426.879 781.8 423.22V303.46C781.8 299.801 778.808 296.807 775.15 296.807H761.85C758.193 296.807 755.2 299.801 755.2 303.46V423.22C755.2 426.879 752.208 429.873 748.55 429.873C744.893 429.873 741.9 432.867 741.9 436.527V609.513C741.9 613.173 738.908 616.167 735.25 616.167C731.593 616.167 728.6 619.161 728.6 622.82V868.993H808.4V622.82C808.4 619.161 805.407 616.167 801.75 616.167C798.092 616.167 795.1 613.173 795.1 609.513Z"
						fill="#2A9E9E"
					/>
					<path
						d="M1087.7 609.513V436.527C1087.7 432.867 1084.71 429.873 1081.05 429.873C1077.39 429.873 1074.4 426.879 1074.4 423.22V303.46C1074.4 299.801 1071.41 296.807 1067.75 296.807H1054.45C1050.79 296.807 1047.8 299.801 1047.8 303.46V423.22C1047.8 426.879 1044.81 429.873 1041.15 429.873C1037.49 429.873 1034.5 432.867 1034.5 436.527V609.513C1034.5 613.173 1031.51 616.167 1027.85 616.167C1024.19 616.167 1021.2 619.161 1021.2 622.82V868.993H1101V622.82C1101 619.161 1098.01 616.167 1094.35 616.167C1090.69 616.167 1087.7 613.173 1087.7 609.513Z"
						fill="#3BC4AD"
					/>
					<path
						d="M1101 609.513V436.527C1101 432.867 1098.01 429.873 1094.35 429.873C1090.69 429.873 1087.7 426.879 1087.7 423.22V303.46C1087.7 299.801 1084.71 296.807 1081.05 296.807H1067.75C1064.09 296.807 1061.1 299.801 1061.1 303.46V423.22C1061.1 426.879 1058.11 429.873 1054.45 429.873C1050.79 429.873 1047.8 432.867 1047.8 436.527V609.513C1047.8 613.173 1044.81 616.167 1041.15 616.167C1037.49 616.167 1034.5 619.161 1034.5 622.82V868.993H1114.3V622.82C1114.3 619.161 1111.31 616.167 1107.65 616.167C1103.99 616.167 1101 613.173 1101 609.513Z"
						fill="#2A9E9E"
					/>
					<path d="M1061.1 489.753H968V509.713H1061.1V489.753Z" fill="#2A9E9E" />
					<path d="M1061.1 383.3H968V403.26H1061.1V383.3Z" fill="#2A9E9E" />
					<path d="M755.2 489.753H662.1V509.713H755.2V489.753Z" fill="#2A9E9E" />
					<path d="M755.2 383.3H662.1V403.26H755.2V383.3Z" fill="#2A9E9E" />
					<path d="M755.2 616.167H662.1V636.127H755.2V616.167Z" fill="#2A9E9E" />
					<path d="M1061.1 616.167H968V636.127H1061.1V616.167Z" fill="#2A9E9E" />
					<path
						d="M632.508 349.035C634.17 509.381 700.338 638.788 781.8 638.788C864.26 638.788 931.092 506.387 931.092 343.713H941.4C941.4 512.375 869.912 649.766 781.8 649.766C694.685 649.766 623.863 515.369 622.2 349.368L632.508 349.035Z"
						fill="#3BC4AD"
					/>
					<path
						d="M968 609.513V436.527C968 432.867 965.008 429.873 961.35 429.873C957.693 429.873 954.7 426.879 954.7 423.22V303.46C954.7 299.801 951.708 296.807 948.05 296.807H934.75C931.093 296.807 928.1 299.801 928.1 303.46V423.22C928.1 426.879 925.108 429.873 921.45 429.873C917.793 429.873 914.8 432.867 914.8 436.527V609.513C914.8 613.173 911.808 616.167 908.15 616.167C904.493 616.167 901.5 619.161 901.5 622.82V868.993H981.3V622.82C981.3 619.161 978.308 616.167 974.65 616.167C970.993 616.167 968 613.173 968 609.513Z"
						fill="#3BC4AD"
					/>
					<path
						d="M981.3 609.513V436.527C981.3 432.867 978.308 429.873 974.65 429.873C970.993 429.873 968 426.879 968 423.22V303.46C968 299.801 965.008 296.807 961.35 296.807H948.05C944.393 296.807 941.4 299.801 941.4 303.46V423.22C941.4 426.879 938.408 429.873 934.75 429.873C931.092 429.873 928.1 432.867 928.1 436.527V609.513C928.1 613.173 925.107 616.167 921.45 616.167C917.792 616.167 914.8 619.161 914.8 622.82V868.993H994.6V622.82C994.6 619.161 991.608 616.167 987.95 616.167C984.293 616.167 981.3 613.173 981.3 609.513Z"
						fill="#2A9E9E"
					/>
					<path d="M1101 742.58H608.9V809.113H1101V742.58Z" fill="#2A9E9E" />
					<path d="M914.8 742.58H688.7V755.887H914.8V742.58Z" fill="#3BC4AD" />
					<path
						d="M1021.2 729.273V742.58H994.6V755.887H1021.2H1034.5V742.58V729.273H1021.2Z"
						fill="#3BC4AD"
					/>
					<path d="M994.6 809.113H901.5V829.073H994.6V809.113Z" fill="#2A9E9E" />
					<path d="M1114.3 809.113H1021.2V829.073H1114.3V809.113Z" fill="#2A9E9E" />
					<path d="M808.4 809.113H715.3V829.073H808.4V809.113Z" fill="#2A9E9E" />
					<path d="M688.7 809.113H595.6V829.073H688.7V809.113Z" fill="#2A9E9E" />
					<path d="M635.5 429.873H615.55V449.833H635.5V429.873Z" fill="#3BC4AD" />
				</KinesisElement>

				<!-- Big ben -->
				<KinesisElement tag="g" axis="x" :strength="10">
					<path d="M1340.4 403.26H1260.6V888.953H1340.4V403.26Z" fill="#DBA05A" />
					<path
						d="M1307.15 443.18H1293.85C1301.17 443.18 1307.15 449.168 1307.15 456.487V875.647C1307.15 882.965 1301.17 888.953 1293.85 888.953H1307.15C1314.47 888.953 1320.45 882.965 1320.45 875.647V456.487C1320.45 449.168 1314.47 443.18 1307.15 443.18Z"
						fill="#E0B76C"
					/>
					<path
						d="M1307.15 875.647V456.487C1307.15 449.168 1301.16 443.18 1293.85 443.18C1286.53 443.18 1280.55 449.168 1280.55 456.487V875.647C1280.55 882.965 1286.53 888.953 1293.85 888.953C1301.16 888.953 1307.15 882.965 1307.15 875.647Z"
						fill="#CE904D"
					/>
					<path d="M1260.6 403.26H1180.8V888.953H1260.6V403.26Z" fill="#E0B76C" />
					<path
						d="M1227.35 888.953H1214.05C1206.74 888.953 1200.75 882.965 1200.75 875.647V456.487C1200.75 449.168 1206.74 443.18 1214.05 443.18H1227.35C1234.67 443.18 1240.65 449.168 1240.65 456.487V875.647C1240.65 882.965 1234.67 888.953 1227.35 888.953Z"
						fill="#CE904D"
					/>
					<path
						d="M1214.05 443.18H1227.35C1220.03 443.18 1214.05 449.168 1214.05 456.487V875.647C1214.05 882.965 1220.03 888.953 1227.35 888.953H1214.05C1206.73 888.953 1200.75 882.965 1200.75 875.647V456.487C1200.75 449.168 1206.73 443.18 1214.05 443.18Z"
						fill="#DBA05A"
					/>
					<path
						d="M1287.2 236.927H1300.5L1333.75 270.193H1253.95L1287.2 236.927Z"
						fill="#1F7289"
					/>
					<path d="M1287.2 216.967H1220.7V236.927H1287.2V216.967Z" fill="#E0B76C" />
					<path d="M1360.35 310.113H1260.6V403.26H1360.35V310.113Z" fill="#CE904D" />
					<path d="M1280.55 310.113H1160.85V403.26H1280.55V310.113Z" fill="#E0B76C" />
					<path
						d="M1360.35 403.26H1160.85C1160.85 414.238 1169.83 423.22 1180.8 423.22H1340.4C1351.37 423.22 1360.35 414.238 1360.35 403.26Z"
						fill="#CE904D"
					/>
					<path d="M1360.35 290.153H1160.85V310.113H1360.35V290.153Z" fill="#CE904D" />
					<path
						d="M1220.7 396.607C1239.06 396.607 1253.95 378.734 1253.95 356.687C1253.95 334.64 1239.06 316.767 1220.7 316.767C1202.34 316.767 1187.45 334.64 1187.45 356.687C1187.45 378.734 1202.34 396.607 1220.7 396.607Z"
						fill="white"
					/>
					<path
						d="M1320.45 396.607C1331.47 396.607 1340.4 378.734 1340.4 356.687C1340.4 334.64 1331.47 316.767 1320.45 316.767C1309.43 316.767 1300.5 334.64 1300.5 356.687C1300.5 378.734 1309.43 396.607 1320.45 396.607Z"
						fill="#E0B76C"
					/>
					<path
						d="M1287.2 236.927H1220.7L1187.45 270.193H1253.95L1287.2 236.927Z"
						fill="#298C8C"
					/>
					<path
						d="M1242.31 361.677H1225.69C1223.03 361.677 1220.7 359.348 1220.7 356.687C1220.7 354.025 1223.03 351.697 1225.69 351.697H1242.31C1244.97 351.697 1247.3 354.025 1247.3 356.687C1247.3 359.348 1244.97 361.677 1242.31 361.677Z"
						fill="#CE904D"
					/>
					<path
						d="M1335.41 361.677H1325.44C1322.78 361.677 1320.45 359.348 1320.45 356.687C1320.45 354.025 1322.78 351.697 1325.44 351.697H1335.41C1338.07 351.697 1340.4 354.025 1340.4 356.687C1340.4 359.348 1338.07 361.677 1335.41 361.677Z"
						fill="#CE904D"
					/>
					<path d="M1333.75 270.193H1253.95V290.153H1333.75V270.193Z" fill="#DBA05A" />
					<path d="M1253.95 270.193H1187.45V290.153H1253.95V270.193Z" fill="#E0B76C" />
					<path
						d="M1357.03 263.54H1350.37C1348.38 263.54 1347.05 264.871 1347.05 266.867V306.787C1347.05 308.783 1348.38 310.113 1350.37 310.113H1357.03C1359.02 310.113 1360.35 308.783 1360.35 306.787V266.867C1360.35 264.871 1359.02 263.54 1357.03 263.54Z"
						fill="#CE904D"
					/>
					<path
						d="M1170.82 263.54H1164.18C1162.18 263.54 1160.85 264.871 1160.85 266.867V306.787C1160.85 308.783 1162.18 310.113 1164.18 310.113H1170.82C1172.82 310.113 1174.15 308.783 1174.15 306.787V266.867C1174.15 264.871 1172.82 263.54 1170.82 263.54Z"
						fill="#CE904D"
					/>
					<path
						d="M1230.67 197.007H1224.03C1222.03 197.007 1220.7 198.337 1220.7 200.333V233.6C1220.7 235.596 1222.03 236.927 1224.03 236.927H1230.67C1232.67 236.927 1234 235.596 1234 233.6V200.333C1234 198.337 1232.67 197.007 1230.67 197.007Z"
						fill="#E0B76C"
					/>
					<path
						d="M1297.18 197.007H1290.53C1288.53 197.007 1287.2 198.337 1287.2 200.333V236.927H1300.5V200.333C1300.5 198.337 1299.17 197.007 1297.18 197.007Z"
						fill="#CE904D"
					/>
					<path
						d="M1250.96 135.463L1234 216.967H1287.2L1270.24 135.463C1267.91 124.485 1253.28 124.485 1250.96 135.463Z"
						fill="#298C8C"
					/>
				</KinesisElement>

				<!-- Clouds -->
				<KinesisElement tag="g" axis="x" :strength="15">
					<path
						opacity="0.75"
						d="M480.223 903.591H1341.4L1142.9 691.682C1114.63 661.742 1068.75 656.752 1034.83 680.039L998.923 704.656C970.993 723.951 934.085 724.616 905.823 705.654C871.908 682.7 826.023 688.023 798.425 717.963L781.467 736.259C753.205 766.865 706.655 771.522 672.408 747.57L634.17 720.624C603.248 699.001 561.685 700.664 532.758 724.616C499.175 752.56 479.89 794.143 479.89 837.723V903.591H480.223Z"
						fill="#D1FFF7"
					/>
					<path
						d="M515.8 839.386L621.867 700.331C651.792 661.077 707.985 654.423 746.223 685.694L856.612 776.512C891.193 805.121 941.733 802.46 973.32 770.857L994.6 749.566C1024.86 718.961 1072.74 715.301 1107.32 740.917L1154.2 775.181C1185.79 798.468 1229.34 797.47 1259.93 772.853L1285.87 752.227C1326.77 719.626 1386.62 729.939 1414.22 774.516C1431.84 802.793 1431.51 839.053 1413.88 867.33L1368.33 938.853H534.42L515.8 839.386Z"
						fill="white"
					/>
					<path
						d="M1223.03 853.025L1173.15 873.651C1157.86 879.971 1140.57 878.641 1126.6 869.659L1054.45 823.418C1038.49 813.105 1018.21 812.773 1001.92 822.087L938.74 858.348C923.113 867.33 903.828 867.33 888.533 858.348L726.938 765.534C701.335 750.897 668.75 757.217 650.795 780.837L572.325 883.298L582.633 938.853H1287.53L1292.52 908.581C1299.17 868.993 1259.94 837.723 1223.03 853.025Z"
						fill="#D1FFF7"
					/>
					<path
						d="M318.628 350.033H512.807C528.767 350.033 541.735 337.059 541.735 321.091C541.735 305.123 528.767 292.149 512.807 292.149H497.845C482.55 292.149 468.253 286.161 457.28 275.516L442.65 260.213C432.01 249.568 417.38 243.58 402.085 243.58H361.853C346.558 243.58 332.26 249.568 321.288 260.213L306.325 275.183C295.685 285.829 289.7 300.466 289.7 315.769V321.091C289.7 337.059 302.668 350.033 318.628 350.033Z"
						fill="#D1FFF7"
					/>
					<path
						d="M513.473 291.817H498.51C483.215 291.817 468.917 285.829 457.945 275.183L442.982 260.213C432.342 249.568 417.712 243.58 402.417 243.58H373.49C388.785 243.58 403.082 249.568 414.055 260.213L429.017 275.183C439.657 285.829 454.287 291.817 469.582 291.817H484.545C500.505 291.817 513.473 304.791 513.473 320.759C513.473 336.727 500.505 349.701 484.545 349.701H513.473C529.432 349.701 542.4 336.727 542.4 320.759C542.4 305.123 529.432 291.817 513.473 291.817Z"
						fill="#A1EDE1"
					/>
				</KinesisElement>

				<!-- Nearest foreground (rocks) -->
				<KinesisElement tag="g" axis="x" :strength="30">
					<path
						d="M223.2 938.853L286.043 858.681C292.36 850.697 302.003 845.707 312.31 845.707H314.305C327.938 845.707 340.24 837.39 345.228 824.749L355.203 799.799C360.19 787.157 372.493 778.841 386.125 778.841H517.795C531.428 778.841 543.73 787.157 548.718 799.799L558.028 823.085C563.68 837.39 574.32 849.366 587.953 856.685L619.208 873.651C632.84 880.969 643.48 892.945 649.133 907.25L662.1 938.188H223.2V938.853Z"
						fill="#6C8080"
					/>
					<path
						d="M395.768 816.099L380.805 862.007C377.48 872.32 367.838 878.973 357.197 878.973H343.565C333.923 878.973 324.945 884.629 320.955 893.611L299.675 938.853H223.2L286.043 858.681C292.36 850.697 302.003 845.707 312.31 845.707H314.305C327.938 845.707 340.24 837.39 345.228 824.749L355.203 799.799C360.19 787.157 372.493 778.841 386.125 778.841H517.795C531.095 778.841 543.065 786.825 548.385 798.801H419.375C408.735 799.133 399.093 806.119 395.768 816.099Z"
						fill="#859C9C"
					/>
					<path
						d="M460.273 885.959L438.993 938.853H407.073L436.665 865.334C439.325 859.013 445.31 854.689 452.293 854.689H515.468C521.785 854.689 527.438 858.015 530.43 863.671L536.748 875.647H475.9C468.918 875.314 462.933 879.639 460.273 885.959Z"
						fill="#859C9C"
					/>
					<path
						d="M1086.37 822.42H1099.01C1106.99 822.42 1114.3 817.763 1117.29 810.444L1140.57 755.887C1143.56 748.568 1150.87 743.911 1158.52 743.911H1175.15C1184.12 743.911 1192.11 737.923 1194.1 728.941L1209.73 666.732C1211.72 659.081 1218.37 654.091 1226.02 654.091H1257.94L1268.25 938.521H1015.22L1068.08 833.065C1072.07 826.745 1078.72 822.42 1086.37 822.42Z"
						fill="#859C9C"
					/>
					<path
						d="M1232.01 770.857L1241.65 654.423H1260.6C1268.58 654.423 1275.23 659.746 1276.89 667.397L1317.46 785.827C1319.45 794.809 1325.77 802.127 1334.42 805.454L1363.01 816.765C1371.66 820.424 1378.64 827.41 1381.96 836.059L1420.53 938.521H1121.95L1168.83 801.462C1171.82 793.811 1179.14 788.488 1187.45 788.488H1211.72C1222.36 788.821 1231.01 781.169 1232.01 770.857Z"
						fill="#6C8080"
					/>
					<path
						d="M528.435 938.853L556.365 890.284C569.333 867.663 596.598 857.683 621.203 866.665C644.145 874.981 669.748 866.997 683.38 847.037C686.04 843.045 688.7 839.386 691.36 835.394C702.998 818.428 723.613 810.111 743.563 813.438L768.5 817.43C786.788 820.424 802.415 833.065 809.065 850.031L814.053 862.673C821.7 881.302 841.65 891.947 861.268 887.623C879.555 883.631 898.508 892.613 907.153 909.579L921.783 939.186H528.435V938.853Z"
						fill="#45AB71"
					/>
					<path
						d="M780.137 830.404C759.855 827.077 739.572 835.727 727.935 852.36L719.622 864.336C705.657 883.963 680.387 891.947 657.777 883.963C633.505 875.314 606.572 884.961 593.605 907.25L574.985 939.186H528.768L557.362 890.284C570.33 867.995 597.262 858.348 621.535 866.997C644.145 875.314 669.415 867.33 683.38 847.37L691.692 835.394C703.33 818.428 723.945 810.111 743.895 813.438L768.832 817.43C780.47 819.426 790.777 825.081 798.757 833.398L780.137 830.404Z"
						fill="#55D38C"
					/>
					<path
						d="M742.897 938.853L726.605 917.23C725.275 915.567 726.937 913.238 728.932 913.903C733.255 915.234 737.91 916.897 742.897 918.893C747.885 920.889 752.207 923.218 756.197 925.547V883.963C756.197 882.3 758.525 881.302 759.522 882.965C762.847 886.957 766.505 892.28 769.497 899.266C774.485 909.911 775.815 919.559 776.147 925.879C778.807 922.22 782.797 917.23 789.447 912.573C792.44 910.577 795.1 908.913 797.76 907.915C799.423 907.25 801.417 908.913 800.752 910.909C797.095 920.557 793.105 929.871 789.447 939.519H742.897V938.853Z"
						fill="#437A5C"
					/>
					<path
						d="M1335.08 530.339L1305.49 571.257C1297.17 582.567 1299.83 598.535 1311.14 606.852L1331.42 621.489C1336.74 625.481 1343.72 627.145 1350.37 626.147L1393.27 619.493C1399.92 618.495 1405.9 614.836 1409.89 609.181L1428.84 582.9C1437.16 571.589 1434.5 555.621 1423.19 547.305L1378.3 514.703C1367 506.387 1351.04 509.048 1342.73 520.359L1335.08 530.339Z"
						fill="#6C8080"
					/>
					<path
						d="M1305.49 571.257L1335.08 530.339L1342.4 520.026C1349.71 509.713 1363.34 506.719 1374.32 512.042C1370.99 513.705 1368.33 516.367 1366 519.361L1358.69 529.673L1329.1 570.591C1320.78 581.902 1323.44 597.87 1334.75 606.187L1355.03 620.824C1357.03 622.155 1359.02 623.485 1361.35 624.151L1350.38 625.814C1343.73 626.812 1336.74 625.149 1331.42 621.157L1311.14 606.519C1299.5 598.535 1297.18 582.567 1305.49 571.257Z"
						fill="#859C9C"
					/>
				</KinesisElement>
			</g>
		</svg>
	</div>
</template>

<script>
import { KinesisElement } from 'vue-kinesis';

export default {
	name: 'AuthLayoutBackground',
	components: {
		KinesisElement,
	},
};
</script>

<style lang="scss" scoped>
.background {
	background-color: #11405e;

	.svg {
		position: fixed;
		bottom: 0;
	}
}
</style>
