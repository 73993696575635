import dayjs from 'dayjs';

// Base date functions
export function newDate(value = null) {
	return value ? dayjs(value) : dayjs();
}

export function isDate(value) {
	return dayjs.isDayjs(value) || value instanceof Date;
}

export function isValidDate(value) {
	return dayjs.isDayjs(value) && value.isValid();
}

export function isValidDateSpan(date1, date2) {
	if (!isValidDate(date1) || !isValidDate(date2)) {
		return false;
	}
	return date1.isBefore(date2);
}

// Date formatting
const FORMAT_DATE = 'DD/MM/YYYY';
const FORMAT_DATETIME = 'DD/MM/YYYY HH:mm';

export function formatDate(dateValue, format = FORMAT_DATE) {
	const dateObj = dayjs(dateValue);
	return dateObj.isValid() ? dateObj.format(format) : dateValue;
}

export function formatDateTime(date) {
	return formatDate(date, FORMAT_DATETIME);
}

export function formatDateUtc(value, format = 'YYYY-MM-DD HH:mm:ss') {
	if (!isValidDate(value)) {
		value = dayjs(value);
	}
	return value.utc().format(format);
}

export function payloadDate(value) {
	return formatDateUtc(value, 'YYYY-MM-DD HH:mm:ss');
}

// Date diffing
export function getSecondsElapsedSince(date) {
	return newDate().unix() - date.unix();
}

export function getSecondsRemaining(timeLimitSeconds, date) {
	return Math.max(timeLimitSeconds - getSecondsElapsedSince(date), 0);
}

export function timeHumanized(minutes) {
	if (minutes > 60) {
		const hours = Math.floor(minutes / 60) + ' t. ';
		const min = Math.floor(minutes % 60);
		return min == 0 ? hours : hours + min + ' min.';
	}
	return Math.round(minutes) + ' min.';
}
