import http from './http';

export function resetExercise(school_id, section_relation_id, homework_id = null) {
	const payload = homework_id ? { homework_id } : {};
	return http
		.post(`/schools/${school_id}/exercise/${section_relation_id}/reset`, payload)
		.then(resp => resp.data.data);
}

export function resetStudentExercise(school_id, section_relation_id, user_id, homework_id = null) {
	const payload = { user_id };
	if (homework_id) {
		payload.homework_id = homework_id;
	}
	return http
		.post(`/schools/${school_id}/exercise/${section_relation_id}/reset-student-exercise`, payload)
		.then(resp => resp.data.data);
}

export function startExercise(school_id, section_relation_id, homework_id = null) {
	const payload = homework_id ? { homework_id } : {};
	return http
		.post(`/schools/${school_id}/exercise/${section_relation_id}/start`, payload)
		.then(resp => resp.data.data);
}

export function answerQuestionTask(school_id, exercise_section_relation_id, section_relation_id, task_id, data) {
	return http
		.post(
			`/schools/${school_id}/exercise/${exercise_section_relation_id}` +
				`/sections/${section_relation_id}/tasks/${task_id}/answer`,
			data,
		)
		.then(resp => resp.data.data);
}
