var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"is-clickable",on:{"click":_vm.showPopup}},[_c('div',{staticClass:"is-flex is-flex-direction-column is-align-items-center has-text-centered"},[_c('div',{staticClass:"pb-5"},[_c('div',{staticClass:"is-relative"},[_c('img',{class:{ 'is-incomplete': _vm.achievedLevels === 0 },attrs:{"src":_vm.achievement.badge_image_url,"width":"130","alt":"achievement badge"}}),_vm._l((_vm.achievement.levels.length),function(starIndex){return _c('div',{key:starIndex,style:(_vm.starStyle(starIndex, 3))},[_c('BaseIcon',{attrs:{"color":_vm.achievedLevels >= starIndex
									? 'yellow-dark'
									: 'grey-light',"icon":"star","size":3,"no-padding":""}})],1)})],2)]),_c('div',{staticClass:"progress-wrapper mx-auto"},[(_vm.progress < 100)?_c('BaseProgressBar',{staticClass:"progressbar my-4",attrs:{"progress":_vm.progress,"color-name":_vm.achievement.color,"height":"small"}}):_vm._e(),(_vm.progress === 100)?_c('BaseCheckbox',{staticClass:"my-1",attrs:{"active-color":_vm.achievement.color,"selected":true,"rounded":true}}):_vm._e()],1),_c('div',{staticClass:"is-size-6 is-family-secondary"},[_vm._v(_vm._s(_vm.achievement.title))]),_c('div',{staticClass:"is-size-8"},[_vm._v(_vm._s(_vm.achievement.description))])])]),_c('SplitModal',{ref:"achievementModal",attrs:{"image-width":5,"left-column-classes":[_vm.modalBackground, 'has-text-centered', 'has-fixed-img']},scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('div',{staticClass:"my-6 mx-auto pb-6",staticStyle:{"width":"250px"}},[_c('div',{staticClass:"is-relative"},[_c('img',{class:{ 'is-incomplete': _vm.achievedLevels === 0 },attrs:{"src":_vm.achievement.badge_image_url,"alt":"achievement badge"}}),_vm._l((_vm.achievement.levels.length),function(starIndex){return _c('div',{key:starIndex,style:(_vm.starStyle(starIndex, 8))},[_c('BaseIcon',{attrs:{"color":_vm.achievedLevels >= starIndex
									? 'yellow'
									: 'grey-light',"icon":"star","size":8,"no-padding":""}})],1)})],2)])]},proxy:true},{key:"body",fn:function(){return [_c('p',{staticClass:"title is-3 mb-0"},[_vm._v(_vm._s(_vm.achievement.title))]),_c('p',{staticClass:"mb-5 is-flex is-align-items-center"},[_vm._v(" "+_vm._s(_vm.achievement.description)+" "),(_vm.difficulty)?_c('ExerciseIcon',{staticClass:"ml-2",attrs:{"type":_vm.exerciseIcon}}):_vm._e()],1),_c('div',{staticClass:"mb-5"},_vm._l((_vm.achievement.levels),function(level){return _c('div',{key:level.id,staticClass:"columns is-vcentered is-gapless"},[_c('div',{staticClass:"column is-2"},[_c('div',{staticClass:"is-flex is-align-items-center"},[_c('BaseIcon',{attrs:{"color":_vm.isLevelCompleted(level)
										? 'yellow'
										: 'grey',"icon":"star","size":3,"no-padding":""}})],1)]),_c('div',{staticClass:"column is-relative"},[_c('span',{class:{ 'completed-step': _vm.isLevelCompleted(level) }},[_vm._v(" "+_vm._s(_vm.getLevelText(level))+" ")]),_c('div',{staticStyle:{"position":"absolute","width":"60%"}},[_c('BaseProgressBar',{staticClass:"progressbar",attrs:{"progress":_vm.getLevelProgress(level),"color-name":_vm.achievement.color,"height":"mini"}})],1)])])}),0)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"progress-wrapper"},[_c('BaseProgressBar',{staticClass:"progressbar",attrs:{"progress":_vm.progress,"color-name":_vm.achievement.color}}),(_vm.progress === 100)?_c('BaseCheckbox',{staticClass:"ml-4",attrs:{"active-color":_vm.achievement.color,"selected":true,"rounded":true}}):_vm._e()],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }