const separator = ' | ';

const state = () => ({
	baseTitle: '',
	parts: [],
});

const getters = {
	title(state) {
		if (state.parts.length === 0) {
			return state.baseTitle;
		}
		return state.parts.join(separator) + separator + state.baseTitle;
	},
};

const actions = {
	prepend({ commit }, { title }) {
		commit('prepend', title);
	},
	append({ commit }, { title }) {
		commit('append', title);
	},
	set({ commit }, { titles }) {
		commit('set', titles);
	},
	reset({ commit }) {
		commit('reset');
	},
	setBaseTitle({ commit }, { title }) {
		commit('setBaseTitle', title);
	},
};

const mutations = {
	prepend(state, title) {
		state.parts = [title, ...state.parts];
	},
	append(state, title) {
		state.parts = [...state.parts, title];
	},
	set(state, titles) {
		if (typeof titles === 'string') {
			titles = [titles];
		}
		state.parts = titles;
	},
	reset(state) {
		state.parts = [];
	},
	setBaseTitle(state, title) {
		state.baseTitle = title;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
