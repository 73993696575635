export default class Errors {
	constructor(errors = {}) {
		this.errors = errors;
	}

	record(errors) {
		this.errors = errors ?? {};
	}

	clear(field) {
		if (field) {
			delete this.errors[field];
			return;
		}
		this.errors = {};
		return this;
	}

	any() {
		return Object.keys(this.errors).length > 0;
	}

	has(field) {
		return field in this.errors;
	}

	get(field) {
		if (!this.has(field)) {
			return '';
		}
		const error = this.errors[field];
		return Array.isArray(error) && error.length > 0 ? error[0] : error;
	}
}
