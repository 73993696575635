import http from './http';

export function getCourseCategories(schoolId, courseId) {
	return http.get(`/schools/${schoolId}/training/categories/${courseId}`).then(resp => resp.data.data);
}

export function getLevelRequirements() {
	return http.get('training/levelrequirements').then(resp => resp.data.data);
}

export function getSchoolClassStartLevel(schoolClassId) {
	return http.get(`/training/startlevel/${schoolClassId}`).then(resp => resp.data.data);
}
