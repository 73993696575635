<template>
	<g class="grammar-symbol-cotainer">
		<line v-if="showLineLeft" v-bind="lineLeftAttrs" stroke-width="2" />
		<g class="grammar-symbol-item" :transform="symbolTransform">
			<slot />
		</g>
		<line v-if="showLineRight" v-bind="lineRightAttrs" stroke-width="2" />
	</g>
</template>

<script>
export default {
	name: 'SymbolContainer',
	props: {
		width: {
			type: Number,
			required: true,
		},
		symbolWidth: {
			type: Number,
			default: 0,
		},
		symbolScale: {
			type: Number,
			default: 1,
		},
		height: {
			type: Number,
			default: 0,
		},
		lineLeft: {
			type: Boolean,
			default: false,
		},
		lineRight: {
			type: Boolean,
			default: false,
		},
		asText: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		showLineLeft() {
			if (!this.symbolWidth) {
				return false;
			}
			return this.lineLeft && this.hasRoomForLines;
		},
		showLineRight() {
			if (!this.symbolWidth) {
				return false;
			}
			return this.lineRight && this.hasRoomForLines;
		},
		spacing() {
			return 12;
		},
		xMargin() {
			return 10;
		},
		hasRoomForLines() {
			return this.width > this.symbolWidth + this.spacing + this.xMargin * 2;
		},
		lineLeftAttrs() {
			if (!this.lineLeft || !this.hasRoomForLines || !this.symbolWidth) {
				return {};
			}
			return {
				x1: this.xMargin,
				y1: this.height / 2,
				x2: this.width / 2 - (this.spacing / 2 + this.symbolWidth / 2),
				y2: this.height / 2,
			};
		},
		lineRightAttrs() {
			if (!this.lineRight || !this.hasRoomForLines || !this.symbolWidth) {
				return {};
			}
			return {
				x1: this.width / 2 + this.spacing / 2 + this.symbolWidth / 2,
				y1: this.height / 2,
				x2: this.width - this.xMargin,
				y2: this.height / 2,
			};
		},
		symbolTransform() {
			const offset = this.width / 2 - this.symbolWidth / 2 - (!this.asText ? this.spacing / 4 : 0);
			return `translate(${offset},0) scale(${this.symbolScale})`;
		},
	},
};
</script>
