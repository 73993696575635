import { getArticle } from '@/api/article';

const state = {};

const getters = {};

const actions = {
	getArticle(_, { id }) {
		return getArticle(id);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
