const designationTypes = [
	{ id: 1, name: 'latin', title: 'Latinske betegnelser' },
	{ id: 2, name: 'danish', title: 'Danske betegnelser' },
];
const symbolTypes = [
	{ id: 1, name: 'symbols', title: 'Symboler' },
	{ id: 2, name: 'letters', title: 'Bogstaver' },
];

function getDefaultDesignation() {
	return {
		default: 1,
		symbol: 1,
		groups: {},
	};
}

export default class Designation {
	constructor(data = {}) {
		const defaultData = getDefaultDesignation();
		this.data = {
			default: data?.default ?? defaultData.default,
			symbol: data?.symbol ?? defaultData.symbol,
			groups: data?.groups ?? defaultData.groups,
		};
	}

	get default() {
		return this.data.default;
	}

	get symbol() {
		return this.data.symbol;
	}

	get groups() {
		return this.data.groups;
	}

	getDesignationIndex(group = 0) {
		return this.data.groups[group] ?? this.data.default;
	}

	getDesignationType() {
		return (
			designationTypes.find(type => type.id === this.data.default) ??
			designationTypes.find(type => type.id === getDefaultDesignation().default)
		);
	}

	getSymbolType() {
		return (
			symbolTypes.find(type => type.id === this.data.symbol) ??
			symbolTypes.find(type => type.id === getDefaultDesignation().symbol)
		);
	}

	getDesignationName() {
		return this.getDesignationType().name;
	}

	getSymbolName() {
		return this.getSymbolType().name;
	}

	static getDefaultDesignation() {
		return getDefaultDesignation();
	}

	static getDefaultPrimarySchoolDesignation() {
		return {
			...getDefaultDesignation(),
			...{ default: 2 },
		};
	}

	static getDesignationTypes() {
		return designationTypes;
	}

	static getSymbolTypes() {
		return symbolTypes;
	}

	static make(designation = {}) {
		return designation instanceof Designation ? designation : new Designation(designation);
	}
}
