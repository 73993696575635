<template>
	<div
		class="chevron"
		:class="[
			direction,
			`is-${size}`,
			`has-border-${color}`,
			hoverColor ? `has-hover-border-${hoverColor}` : '',
		]"
	></div>
</template>

<script>
export default {
	name: 'BaseChevron',
	props: {
		direction: {
			type: String,
			default: 'down',
		},
		color: {
			type: String,
			default: 'grey-lighter',
		},
		hoverColor: {
			type: String,
			default: '',
		},
		size: {
			type: String,
			default: 'medium',
		},
	},
};
</script>

<style lang="scss" scoped>
.chevron {
	border-style: solid;

	&.is-small {
		width: 8px;
		height: 8px;
		border-width: 0 2px 2px 0;

		&.down {
			transform: rotate(45deg) translate(-4px);
			-webkit-transform: rotate(45deg) translate(-4px);
		}
		&.up {
			transform: rotate(-135deg) translate(0, -4px);
			-webkit-transform: rotate(-135deg) translate(0, -4px);
		}
		&.left {
			transform: rotate(135deg);
			-webkit-transform: rotate(135deg);
		}
		&.right {
			transform: rotate(-45deg) translate(-4px, -4px);
			-webkit-transform: rotate(-45deg) translate(-4px, -4px);
		}
	}

	&.is-medium {
		width: 10px;
		height: 10px;
		border-width: 0 3px 3px 0;

		&.down {
			transform: rotate(45deg) translate(-5px);
			-webkit-transform: rotate(45deg) translate(-5px);
		}
		&.up {
			transform: rotate(-135deg) translate(0, -5px);
			-webkit-transform: rotate(-135deg) translate(0, -5px);
		}
		&.left {
			transform: rotate(135deg);
			-webkit-transform: rotate(135deg);
		}
		&.right {
			transform: rotate(-45deg) translate(-5px, -5px);
			-webkit-transform: rotate(-45deg) translate(-5px, -5px);
		}
	}
}
</style>
