<template>
	<div class="is-flex is-justify-content-center">
		<Transition :css="false" @leave="onLeave" @after-leave="nextPhase">
			<div v-if="phase === 1">
				<div class="is-flex is-justify-content-center">
					<Avatar
						:avatar="fromUser.avatar"
						:color="fromUser.color"
						size="large"
						class="from-user"
					/>
				</div>
				<p class="title is-4 has-text-centered">{{ fromUser.name }}</p>
			</div>
		</Transition>
		<Transition :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @after-enter="setEndPhase">
			<div v-if="phase >= 3">
				<div class="is-flex is-justify-content-center">
					<Avatar
						:avatar="toUser.avatar"
						:color="toUser.color"
						size="large"
						class="to-user"
					/>
				</div>
				<p class="title is-4 has-text-centered" :class="{ 'has-text-transparent': phase < 4 }">
					{{ toUser.name }}
				</p>
			</div>
		</Transition>
	</div>
</template>

<script>
import gsap from 'gsap';
import Avatar from '@/components/ui/user/Avatar';

export default {
	name: 'UserSwapper',
	components: {
		Avatar,
	},
	props: {
		toUser: {
			type: Object,
			required: true,
		},
		fromUser: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			phase: 1,
		};
	},
	mounted() {
		this.nextPhase();
	},
	methods: {
		setPhase(value = 1) {
			this.phase = value;
		},
		nextPhase() {
			this.setPhase(this.phase + 1);
		},
		setEndPhase() {
			this.setPhase(4);
			this.$emit('completed');
		},
		onBeforeEnter,
		onEnter,
		onLeave,
	},
};

function onBeforeEnter(el) {
	gsap.set(el, {
		scaleX: 0.25,
		scaleY: 0.25,
		opacity: 1,
	});
}

function onEnter(el, done) {
	gsap.to(el, {
		duration: 0.5,
		scaleX: 1,
		scaleY: 1,
		opacity: 1,
		ease: 'elastic.inOut(2.5, 1)',
		onComplete: done,
	});
}

function onLeave(el, done) {
	gsap.to(el, {
		duration: 0.5,
		scaleX: 1,
		scaleY: 1,
		x: 300,
		ease: 'elastic.inOut(2.5, 1)',
	});
	gsap.to(el, {
		duration: 0.2,
		delay: 0.25,
		opacity: 0,
		onComplete: done,
	});
}
</script>
