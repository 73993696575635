export default class ItemCollection {
	constructor(items = []) {
		this.setItems(items);
	}

	setItems(items = []) {
		this.items = items.map(item => this.newItem(item));
		return this;
	}

	get(index = 0, defaultValue = null) {
		return this.items[index] ?? defaultValue;
	}

	all() {
		return this.items;
	}

	filter(filter = null) {
		if (typeof filter !== 'function') {
			return this.all();
		}
		return this.all().filter(filter);
	}

	push(item) {
		this.items.push(this.newItem(item));
		return this;
	}

	unshift(item) {
		this.items.unshift(this.newItem(item));
		return this;
	}

	put(index, item) {
		this.items[index] = this.newItem(item);
		return this;
	}

	map(callback) {
		return this.all().map(callback);
	}

	reduce(callback, defaultValue = 0) {
		return this.all().reduce(callback, defaultValue);
	}

	forEach(callback) {
		return this.all().forEach(callback);
	}

	first() {
		return this.items.length > 0 ? this.items[0] : null;
	}

	last() {
		return this.items.length > 0 ? this.items[this.items.length - 1] : null;
	}

	find(value, field = 'id') {
		if (value instanceof Function) {
			return this.items.find(value);
		}
		return this.items.find(item => item[field] == value);
	}

	findIndex(value, field = 'id') {
		if (value instanceof Function) {
			return this.items.findIndex(value);
		}
		return this.items.findIndex(item => item[field] == value);
	}

	newItem(item = null) {
		return item;
	}

	get length() {
		return this.items.length;
	}
}
