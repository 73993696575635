<template>
	<div
		class="dropdown-item is-flex is-flex-wrap-nowrap is-align-items-center"
		:class="{
			'is-clickable': !disabled,
			[`is-size-${textSize}`]: true,
		}"
	>
		<BaseCheckbox class="mr-4" :selected="selected" :rounded="rounded" :disabled="disabled" />
		<span
			:class="{
				'has-text-grey': disabled,
			}"
		>
			<slot />
		</span>
	</div>
</template>

<script>
import BaseCheckbox from '@/components/base/input/BaseCheckbox';
export default {
	name: 'BaseDropdownSelectItem',
	components: { BaseCheckbox },
	props: {
		selected: {
			type: [Boolean, Number],
			default: false,
			validator: function (value) {
				return [true, false, 0, 1, 2].indexOf(value) >= 0;
			},
		},
		rounded: {
			type: Boolean,
			default: false,
		},
		textSize: {
			type: [Number, String],
			default: 7,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
