<template>
	<div v-click-outside="onClickOutside" class="mega-menu-wrapper">
		<div class="container pt-5">
			<BaseNoContent
				v-if="menuBooks.length === 0 && isStudent"
				image-path="/images/bookshelf.svg"
				header="Du har ingen bøger"
				text="Tag fat i din lærer for at få adgang til bøger på Minlæring."
			/>
			<div v-else class="menu-grid">
				<router-link
					v-for="(book, i) in books"
					:key="i"
					:to="{ name: 'book', params: { id: book.id } }"
				>
					<BookCard
						:book="book"
						:designation="getBookDesignation(book)"
						class="book-card"
					/>
				</router-link>
			</div>

			<div class="mt-5 mb-4 is-flex is-justify-content-center">
				<router-link
					:to="{ name: 'books' }"
					active-class=""
					class="button is-medium"
					@click.native="$emit('close')"
					>Se alle bøger</router-link
				>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BaseNoContent from '@/components/base/BaseNoContent';
import BookCard from './BookCard';

export default {
	name: 'BookMegaMenu',
	components: {
		BaseNoContent,
		BookCard,
	},
	computed: {
		...mapState('books', ['menuBooks']),
		...mapGetters('user', ['isStudent']),
		...mapGetters('designation', ['getDesignationByCourseId']),
		books() {
			return this.menuBooks.slice(0, 12);
		},
	},
	methods: {
		onClickOutside() {
			this.$emit('close');
		},
		getBookDesignation(book) {
			return this.getDesignationByCourseId(book?.course?.id ?? 0);
		},
	},
};
</script>

<style lang="scss" scoped>
.mega-menu-wrapper {
	position: absolute;
	z-index: 30;
	width: 100%;
	background-color: white;
	box-shadow: 28px 28px 25px 0 rgba(40, 51, 56, 0.04);

	.menu-grid {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 8px 12px;
	}
}
</style>
