var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseKeyboardWrapper',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({ handler: _vm.clickOutside, middleware: _vm.clickOutsideMiddleware }),expression:"{ handler: clickOutside, middleware: clickOutsideMiddleware }"}],staticClass:"dropdown",class:{
		'is-disabled': _vm.disabled,
		'is-active': _vm.isActive,
		'is-right': _vm.isRight,
		'is-wide': _vm.isWide,
		'is-fullwidth': _vm.isFullwidth,
		'no-height-cap': _vm.noHeightCap,
		'has-fullwidth-trigger': _vm.fullWidthTrigger,
	},attrs:{"active":_vm.isActive,"tabindex":!_vm.disabled ? 0 : -1},on:{"arrow-up":function($event){return _vm.$emit('prev-option')},"arrow-down":function($event){return _vm.$emit('next-option')},"enter":_vm.toggle}},[_c('div',{staticClass:"dropdown-trigger has-arrow",class:{ 'is-clickable': !_vm.disabled },on:{"click":_vm.toggle}},[_vm._t("trigger",null,{"active":_vm.isActive})],2),_c('div',{staticClass:"dropdown-menu",attrs:{"role":"menu"}},[_c('div',{staticClass:"dropdown-content",on:{"click":function($event){$event.stopPropagation();return _vm.onContentClick.apply(null, arguments)}}},[_vm._t("content-header"),_c('div',{staticClass:"dropdown-items"},[_vm._t("default")],2),_vm._t("content-footer")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }