import EventEmitter from 'events';
import axios from 'axios';

export default class TTSPlayer extends EventEmitter {
	constructor(srcs = []) {
		super();
		this.setSrcs(srcs);
		this.setSrcIndex(0);
		this.initPlayer();
		this.wordIndex = null;
	}

	setSrcs(srcs = []) {
		this.srcs = Array.isArray(srcs) ? srcs : [srcs];
		return this;
	}

	hasSrc(index = 0) {
		return this.srcs.length > index;
	}

	getSrc(index = this.srcIndex) {
		return this.hasSrc(index) ? this.srcs[index] : null;
	}

	start() {
		const src = this.getSrc();
		if (!src) {
			return;
		}

		// Load before playing
		return this.loadSrc(src).then(() => this.play());
	}

	play() {
		if (this.isActive()) {
			this.player.play();
		} else {
			this.start();
		}
		return this;
	}

	pause() {
		this.player.pause();
		this.emit('pause');
		return this;
	}

	stop() {
		if (this.player) {
			if (this.isPlaying()) {
				this.pause();
			}
		}
		this.initPlayer();
		this.setSrcIndex(0);
		this.emit('stop');
		return this;
	}

	isActive() {
		return !!this.player?.currentSrc;
	}

	isPlaying() {
		return this.isActive() && !this.player?.paused;
	}

	isPaused() {
		return this.isActive() && this.player?.paused;
	}

	initPlayer() {
		this.player = new Audio('');
		this.player.onplaying = event => {
			this.emit('playing', { event });
		};
		this.player.ontimeupdate = () => {
			if (!this.isActive() || this.isPaused()) {
				return;
			}
			const src = this.getSrc();
			if (!src?.marks) {
				return;
			}
			const time = this.player.currentTime * 1000;
			const index = this.getWordIndexByTime(src, time);
			if (index === this.wordIndex || !src.marks[index]) {
				return;
			}

			this.emit('word', src.marks[index].value);

			this.wordIndex = index;
		};
		this.player.onended = event => {
			this.emit('end', { event });
			if (this.srcIndex === this.srcs.length - 1) {
				this.emit('done', { event });
			}
			if (this.srcIndex + 1 < this.srcs.length) {
				this.setSrcIndex(this.srcIndex + 1);
				this.start();
			} else {
				this.setSrcIndex(0);
			}
		};
	}

	getWordIndexByTime(src, time) {
		const nextIndex = src.marks.findIndex(row => row.time > time);
		return nextIndex >= 0 ? Math.max(0, nextIndex - 1) : src.marks.length - 1;
	}

	setSrcIndex(index = 0) {
		this.srcIndex = index;
	}

	preparePlayerSrc(src) {
		return new Promise(resolve => {
			this.player.src = src.speechUrl;
			this.player.oncanplaythrough = event => {
				resolve(event);
			};
		});
	}

	loadSrc(src) {
		const promises = [this.preparePlayerSrc(src)];
		if (!src.marks) {
			promises.push(this.loadSpeechMarks(src));
		}
		return Promise.all(promises);
	}

	loadSpeechMarks(src) {
		return axios.get(src.metaUrl).then(({ data }) => {
			src.marks = parseSpeechMarks(data);
			return src.marks;
		});
	}
}

function parseSpeechMarks(data) {
	if (!data) {
		return [];
	}
	if (typeof data === 'string') {
		return data
			.trim()
			.split('\n')
			.map(row => JSON.parse(row))
			.filter(mark => mark.type === 'word');
	}
	return [data];
}
