<template>
	<div class="columns is-mobile is-multiline is-variable" :class="`is-${gapSize}`">
		<div
			v-for="(item, i) in items"
			:key="`material-card-item-${i}`"
			class="column is-full-mobile is-narrow"
		>
			<slot name="item" :item="item" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'MaterialCardList',
	props: {
		items: {
			type: Array,
			required: true,
		},
		gapSize: {
			type: Number,
			default: 7,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
@import '@/assets/sass/abstracts/mixins';

:deep(.material-card) {
	@include mixin-mobile-only {
		margin-left: auto;
		margin-right: auto;
	}
}
</style>
