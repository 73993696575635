<template>
	<div
		class="base-progressbar"
		:class="[
			`has-background-${backgroundColor}`,
			`height-${height}`,
			{
				'is-flex': showCompletedIcon,
				'is-align-items-center': showCompletedIcon,
				'is-justify-content-flex-end': showCompletedIcon,
				'is-completed': showCompletedIcon && progress === 100,
			},
		]"
	>
		<div
			v-if="progress"
			class="base-progressbar-status"
			:class="{
				'no-animation': noAnimation,
				[`has-background-${colorName}`]: !color,
			}"
			:style="{
				width: progress + '%',
				...(color ? { 'background-color': color } : {}),
			}"
		>
			<div v-if="showShine" class="base-progressbar-shine" />
		</div>
		<BaseIcon
			v-if="showCompletedIcon && progress === 100"
			icon="check"
			color="white"
			:size="2"
			class="has-background-green"
			show-background
		/>
	</div>
</template>

<script>
import BaseIcon from './BaseIcon';

export default {
	name: 'BaseProgressBar',
	components: { BaseIcon },
	props: {
		progress: {
			type: [String, Number],
			required: true,
		},
		backgroundColor: {
			type: String,
			default: 'grey-lighter',
		},
		colorName: {
			type: String,
			default: 'blue',
		},
		color: {
			type: String,
			default: '',
		},
		showCompletedIcon: {
			type: Boolean,
			default: false,
		},
		showShine: {
			type: Boolean,
			default: false,
		},
		noAnimation: {
			type: Boolean,
			default: false,
		},
		height: {
			type: String,
			default: 'normal', // normal, small, mini
		},
	},
};
</script>

<style lang="scss" scoped>
.base-progressbar {
	position: relative;
	height: 1rem;
	border-radius: 0.5rem;
	width: 100%;

	&.height-small {
		height: 0.4rem;
	}

	&.height-mini {
		height: 0.1rem;
	}

	&:not(.is-completed) {
		overflow: hidden;
	}

	.base-progressbar-status {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 0;
		border-radius: 0.5rem;
		&:not(.no-animation) {
			-webkit-transition: width 2s ease-in-out;
			-moz-transition: width 2s ease-in-out;
			-o-transition: width 2s ease-in-out;
			transition: width 2s ease-in-out;
		}
	}
	.base-progressbar-shine {
		position: absolute;
		top: 3px;
		left: 10px;
		right: 10px;
		height: 4px;
		background-color: rgba(255, 255, 255, 0.3);
	}
}
</style>
