import Vue from 'vue';

import store from '../store';

export function ifTeacher(to, from, next) {
	if (!store.getters['user/isTeacher']) {
		Vue.toasted.show('Du har ikke adgang til denne del af siden.', {
			position: 'top-center',
			type: 'info',
			duration: 5000,
		});

		next({ name: 'dashboard' });
		return;
	}

	next();
}

export function ifStudent(to, from, next) {
	if (!store.getters['user/isStudent']) {
		Vue.toasted.show('Denne del af siden kan kun tilgås som elev.', {
			position: 'top-center',
			type: 'info',
			duration: 5000,
		});

		next({ name: 'dashboard' });
		return;
	}

	next();
}

export function ifAuthenticated(to, from, next) {
	if (!store.getters['auth/isAuthenticated']) {
		next({ name: 'login', query: { redirect: to.fullPath } });
		return;
	}

	const promises = [];

	// If user school is invalid (schoolId is not set or user.schools do not match)
	if (!store.getters['user/school']) {
		if (!store.getters['user/hasMultipleSchools'] && store.state.user.user?.schools?.length) {
			// If there is only one school then select it
			promises.push(
				store.dispatch('user/setSchoolId', { schoolId: store.state.user.user.schools[0].id }),
			);
		} else if (to.name !== 'select-school') {
			// Otherwise redirect to select school page
			next({ name: 'select-school', query: { redirect: to.fullPath } });
			return;
		}
	}

	if (promises.length === 0) {
		next();
		return;
	}

	Promise.all(promises)
		.then(() => next())
		.catch(err => next(err));
}

export function ifNotAuthenticated(to, from, next) {
	if (store.getters['auth/isAuthenticated']) {
		next({ name: 'dashboard' });
		return;
	}
	next();
}
