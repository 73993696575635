import { numberToLetter, ucFirst, truncate, truncateWords } from '@/utils/string';

export default {
	filters: {
		numberToLetter,
		ucFirst,
		truncate,
		truncateWords,
	},
};
