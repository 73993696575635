export class ExtendableError extends Error {
	constructor(message) {
		super(message);
		this.name = 'ExtendableError';
		if (typeof Error.captureStackTrace === 'function') {
			Error.captureStackTrace(this, this.constructor);
		} else {
			this.stack = new Error(message).stack;
		}
	}
}

export class ResponseError extends ExtendableError {
	constructor(err) {
		super(responseErrorMessage(err));
		this.name = 'ResponseError';
		this.err = err;
	}

	get data() {
		return this.err.response.data;
	}

	get status() {
		return this.err.response.status;
	}

	get internalStatus() {
		return this.data?.status_code;
	}

	get headers() {
		return this.err.response.headers;
	}

	get request() {
		return this.err.response.request;
	}
}

function responseErrorMessage(err) {
	const data = err.response.data;
	return (
		// For some reason Laravel uses the key "error" for some errors and "message" for others.
		(data?.error ? data?.error : data?.message) || 'Der opstod en uventet fejl. Prøv igen om lidt.'
	);
}

export class ServerError extends ResponseError {
	constructor(err) {
		super(err);
		this.name = 'ServerError';
	}
}

export class ClientError extends ResponseError {
	constructor(err) {
		super(err);
		this.name = 'ClientError';
	}
}

export class UnauthorizedError extends ClientError {
	constructor(err) {
		super(err);
		this.name = 'UnauthorizedError';
	}
}

export class ForbiddenError extends ClientError {
	constructor(err) {
		super(err);
		this.name = 'ForbiddenError';
	}
}

export class NotFoundError extends ClientError {
	constructor(err) {
		super(err);
		this.name = 'NotFoundError';
	}
}

export class RequestEntityTooLarge extends ClientError {
	constructor(err) {
		super(err);
		this.name = 'RequestEntityTooLarge';
	}
}

export class ValidationError extends ClientError {
	constructor(err) {
		super(err);
		this.name = 'ValidationError';
	}

	get validationErrors() {
		return this.data.errors;
	}
}

export class RequestError extends ExtendableError {
	constructor(err) {
		super('Der opstod en uventet fejl. Prøv venligst igen.');
		this.name = 'RequestError';
		this.err = err;
	}

	get request() {
		return this.err.request;
	}
}

export class RequestTimeoutError extends RequestError {
	constructor(err) {
		super(err);
		this.name = 'RequestTimeoutError';
	}
}

export class RequestAbortedError extends RequestError {
	constructor(err) {
		super(err);
		this.name = 'RequestAbortedError';
	}
}

export class NetworkError extends ExtendableError {
	constructor(err) {
		super('Der opstod en netværksfejl. Tjek din internetforbindelse og prøv igen.');
		this.name = 'NetworkError';
		this.err = err;
	}
}

export class OfflineError extends ExtendableError {
	constructor(err) {
		super('Du er i øjeblikket offline. Tjek din internetforbindelse og prøv igen.');
		this.name = 'OfflineError';
		this.err = err;
	}
}

export class UnknownError extends ExtendableError {
	constructor(err) {
		super('Der opstod en uventet fejl. Tjek din internetforbindelse og prøv igen.');
		this.name = 'UnknownError';
		this.err = err;
	}
}

/**
 * Error factory
 *
 * @param Error err
 * @returns Error
 */
export function makeError(err) {
	// eslint-disable-next-line no-console
	console.log('Axios error err.code', err.code);

	if (err.response) {
		if (!err.response.status) {
			// eslint-disable-next-line no-console
			console.log('Request aborted (status code is zero)');
			return new RequestAbortedError(err);
		}

		let wrapper = new ResponseError(err);
		if (wrapper.status >= 500) {
			wrapper = new ServerError(err);
		} else if (wrapper.status === 401) {
			wrapper = new UnauthorizedError(err);
		} else if (wrapper.status === 403) {
			wrapper = new ForbiddenError(err);
		} else if (wrapper.status === 404) {
			wrapper = new NotFoundError(err);
		} else if (wrapper.status === 408) {
			wrapper = new RequestTimeoutError(err);
		} else if (wrapper.status === 413) {
			wrapper = new RequestEntityTooLarge(err);
		} else if (wrapper.status === 422) {
			wrapper = new ValidationError(err);
		} else {
			wrapper = new ClientError(err);
		}

		return wrapper;
	} else if (err.request) {
		if (err.code === 'ETIMEDOUT') {
			return new RequestTimeoutError(err);
		} else if (err.code === 'ECONNABORTED') {
			// eslint-disable-next-line no-console
			console.log('Request aborted');
			return new RequestAbortedError(err);
		} else if (err.code === 'ERR_NETWORK') {
			if (!navigator.onLine) {
				// eslint-disable-next-line no-console
				console.log('Browser is offline');
				return new OfflineError(err);
			}

			// eslint-disable-next-line no-console
			console.log('Network error');
			return new NetworkError(err);
		}
	}

	return new UnknownError(err);
}
