<template>
	<div>
		<div v-for="(course, i) in courses" :key="`course-${i}`" :class="`mb-${itemSpacing}`">
			<div class="is-flex is-align-items-center is-clickable" @click="toggleCourse(course)">
				<BaseCheckbox
					v-if="!noCourseSelection"
					:selected="courseIsSelected(course)"
					class="mr-3"
				/>
				<CourseFlag :course="course" class="mr-3" />
				<p class="title" v-text="course.title" />
			</div>
			<slot v-if="courseIsSelected(course)" :name="course.id" />
		</div>
	</div>
</template>

<script>
import BaseCheckbox from '@/components/base/input/BaseCheckbox';
import CourseFlag from '@/components/course/CourseFlag';

export default {
	name: 'CourseSelector',
	components: { BaseCheckbox, CourseFlag },
	props: {
		courses: {
			type: Array,
			required: true,
		},
		value: {
			type: Array,
			required: true,
		},
		itemSpacing: {
			type: Number,
			default: 3,
		},
		noCourseSelection: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		getSelectedCourseIndex(course) {
			return this.value.findIndex(s => s.id == course.id);
		},
		courseIsSelected(course) {
			return this.getSelectedCourseIndex(course) >= 0;
		},
		toggleCourse(course) {
			if (this.noCourseSelection) {
				return;
			}

			this.$emit('toggle-course', course);
			const index = this.getSelectedCourseIndex(course);
			const courses = this.value.slice(0);
			if (index >= 0) {
				courses.splice(index, 1);
			} else {
				courses.push(course);
			}
			this.$emit('input', courses);
		},
	},
};
</script>
