import Section from '@/models/section/Section';
import Assignment from '@/models/assignment/Assignment';

export default class ItemsTree {
	constructor(items = []) {
		this.items = items;
	}

	findItem(item, items = this.items) {
		for (const subItem of items) {
			if (subItem.isSameAs(item)) {
				return subItem;
			} else if (subItem.items?.length) {
				const subResult = this.findItem(item, subItem.items);
				if (subResult) {
					return subResult;
				}
			}
		}
		return null;
	}

	findItemIndex(item, items = this.items) {
		return items.findIndex(subItem => subItem.isSameAs(item));
	}

	getItemSubItems(item) {
		return item.items.reduce((items, subItem) => {
			return items.concat(subItem, this.getItemSubItems(subItem));
		}, []);
	}

	getItemSectionRelationIds(item) {
		const sectionRelationIds = [];
		if (item instanceof Section) {
			sectionRelationIds.push(item.section_relation_id);
		}
		if (item.items.length) {
			item.items.forEach(subItem => {
				sectionRelationIds.push(...this.getItemSectionRelationIds(subItem));
			});
		}
		return sectionRelationIds;
	}

	createItem(item) {
		if (item instanceof Section) {
			return new Section({ section_relation_id: item.section_relation_id, items: [] });
		} else if (item instanceof Assignment) {
			return new Assignment({ id: item.id, items: [] });
		}
		return null;
	}
}
