export function findGrammarTablesInElement(elm) {
	if (!(elm instanceof HTMLElement)) {
		return [];
	}
	return Array.from(elm.querySelectorAll('table.table-template'));
}

export function wrapGrammarTableInContainer(tableElm) {
	const parent = tableElm.parentNode;
	if (parent.classList.contains('table-container')) {
		parent.classList.replace('table-container', 'grammar-table-container');
	}
	if (parent.classList.contains('grammar-table-container')) {
		return tableElm;
	}

	const containerElm = document.createElement('DIV');
	containerElm.className = 'grammar-table-container';
	containerElm.appendChild(tableElm.cloneNode(true));
	parent.replaceChild(containerElm, tableElm);
	return tableElm;
}

const contentWidth = 662;
const desktopBreakpoint = 1024;
export function centerGrammarTableContainerInWiewport(tableElm, containerElm) {
	const tableContainerElm = tableElm.parentNode;
	if (!tableContainerElm) {
		return;
	}

	// Abort & reset style if not on desktop or no specific width set for table.
	if (window.innerWidth < desktopBreakpoint || !tableElm.style.width) {
		return (tableContainerElm.style = '');
	}

	const tableWidth = tableElm.style.width.match(/%$/)
		? tableElm.clientWidth + 30
		: parseInt(tableElm.style.width.replace('px', ''));

	// Abort & reset if table width is less than content area width.
	if (!tableWidth || tableWidth <= contentWidth) {
		return (tableContainerElm.style = '');
	}

	const minMargin = 60;
	const maxWidth = document.documentElement.clientWidth - minMargin;
	const containerWidth = Math.min(maxWidth, tableWidth);
	// Lock table container to specific width
	tableContainerElm.style.setProperty('width', `${containerWidth}px`);

	// Determine and set left margin for table container.
	// Wide tables are centered in viewport.
	const offsetLeft = containerElm.offsetLeft;
	const offsetRight = window.innerWidth - (offsetLeft + containerElm.clientWidth);
	const containerOverflow = (containerWidth - contentWidth) / 2;
	const marginLeft = containerOverflow + (offsetLeft - offsetRight) / 2;
	tableContainerElm.style.setProperty('margin-left', `-${marginLeft}px`);
}
