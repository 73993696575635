<template>
	<div>
		<div class="is-flex is-align-items-center is-justify-content-space-between">
			<div class="tabs is-medium">
				<ul>
					<slot />
				</ul>
			</div>
			<div>
				<slot name="right" />
			</div>
		</div>
		<div class="highlight">
			<div
				class="indicator"
				:style="{
					transform: 'translate(' + indicator_offset + 'px, 0)',
					width: indicator_size + 'px',
					...(activeColor ? { 'background-color': activeColor } : {}),
				}"
			/>
		</div>
	</div>
</template>

<script>
import { getNodeInnerDimensions } from '@/utils/dom';

export default {
	name: 'BaseTabs',
	provide() {
		return {
			tabs: this,
		};
	},
	model: {
		prop: 'active',
		event: 'change',
	},
	props: {
		active: {
			type: String,
			default: null,
		},
		activeColor: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			tabs: [],
			indicator_offset: 0,
			indicator_size: 0,
		};
	},
	computed: {
		activeTab() {
			return this.tabs.find(tab => tab.id === this.active) || null;
		},
	},
	watch: {
		active() {
			this.$nextTick(this.updateIndicator);
		},
	},
	created() {
		this.$on('tab-clicked', this.handleTabClick);
	},
	mounted() {
		this.$nextTick(() => {
			if (this.active === null && this.tabs.length) {
				this.$emit('change', this.tabs[0].id);
			} else {
				// We need to delay updating indicator slighty to make sure
				// that the indicator element has a measurable width.
				// Otherwise the indicator will fail on browser reload.
				setTimeout(this.updateIndicator, 50);
			}
		});
	},
	methods: {
		addItemInstance(instance) {
			this.tabs.push(instance);
		},
		removeItemInstance(instance) {
			this.tabs = this.tabs.filter(t => t !== instance);
		},
		handleTabClick(tab) {
			this.$emit('change', tab.id);
			this.updateIndicator();
		},
		updateIndicator() {
			if (!this.activeTab?.$el) {
				return;
			}
			const anchor = this.activeTab.$el.querySelector('a');
			const title = this.activeTab.$el.querySelector('a > span');
			if (anchor && title) {
				this.indicator_offset = title.offsetLeft;
				this.indicator_size = getNodeInnerDimensions(anchor).width;
			} else {
				this.indicator_offset = this.activeTab.$el.offsetLeft;
				this.indicator_size = this.activeTab.$el.offsetWidth;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';

.tabs {
	position: relative;
	margin-bottom: 0.5em;

	ul :deep(li:first-child a) {
		padding-left: 0;
	}
}

.highlight {
	background: $grey-lighter;
	height: 5px;
	margin-bottom: 1.5em;
	border-radius: 2px;

	.indicator {
		border-radius: 2px;
		background-color: $blue;
		height: 5px;
		transition: transform 0.2s ease-in-out;
	}
}
</style>
