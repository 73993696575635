<template>
	<div v-show="isActive">
		<slot />
	</div>
</template>

<script>
export default {
	name: 'BaseTabContent',
	inject: ['tabsContainer'],
	props: {
		id: {
			type: String,
			required: true,
		},
	},
	computed: {
		isActive() {
			return this.id === this.tabsContainer.active;
		},
	},
};
</script>
