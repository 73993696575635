import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
Vue.config.ignoredElements = ['lang'];
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
import 'dayjs/locale/da';
dayjs.locale('da');

import VueCookie from 'vue-cookie';
Vue.use(VueCookie);

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import VueTippy, { TippyComponent } from 'vue-tippy';
Vue.use(VueTippy, { theme: 'ml-light', a11y: false });
Vue.component('TippyTooltip', TippyComponent);

import Nanobar from 'nanobar';
window.nanobar = new Nanobar({ id: 'mynano' });

import Toasted from 'vue-toasted';
Vue.use(Toasted);

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

import vClickOutside from 'v-click-outside';
Vue.use(vClickOutside);

import VuePortal from '@linusborg/vue-simple-portal';
Vue.use(VuePortal, { selector: '#overlay-portal' });

import './polyfills';

Vue.config.productionTip = false;

import * as Sentry from '@sentry/vue';

import VueGtag from 'vue-gtag';

import PusherService from './libs/PusherService';

import InstantSearch from 'vue-instantsearch';
Vue.use(InstantSearch);

import { shouldErrorStopPropagation } from './utils/error';

/* global BUILD_DATE */
/* global gtag */

if (
	dayjs().isAfter(dayjs(BUILD_DATE).add(2, 'months')) &&
	navigator.onLine &&
	// Avoid infinite loop if no new release in two months (unlikely)
	(!localStorage.getItem('last-reboot') || dayjs().isAfter(dayjs(localStorage.getItem('last-reboot')), 'month'))
) {
	// eslint-disable-next-line no-console
	console.log('App is over 2 months old. Doing a reboot...');

	const params = new URLSearchParams();
	params.set('redirect', window.location.pathname + window.location.search + window.location.hash);
	window.location.replace(`/reboot.html?${params.toString()}`);
}

// Configure the app before bootstrapping.
store.dispatch('app/getConfig').then(config => {
	Sentry.init({
		Vue,
		dsn: config.sentryDSN,
		integrations: [
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracingOrigins: ['localhost', config.apiURL, /^\//],
			}),
		],
		environment: config.environment,
		release: `ml-frontend@${BUILD_DATE}`,
		enabled: !!config.sentryDSN,
		ignoreErrors: [
			/ServiceWorker/i,
			/service-worker/i,
			/newestWorker/i,
			/ResizeObserver/i,
			/Redirected when going from/i,
			/Avoided redundant navigation/i,
			/Navigation cancelled/i,
			/Loading CSS chunk/i,
			/Loading chunk/i,
		],
		beforeSend(event, hint) {
			const err = hint.originalException;
			if (shouldErrorStopPropagation(err)) {
				return null;
			}

			if (store.state.user.user) {
				event.user = {
					id: store.state.user.user.id,
					username: store.state.user.user.name,
					email: store.state.user.user.email,
				};
			}
			return event;
		},
		beforeSendTransaction(event, hint) {
			const err = hint.originalException;
			if (shouldErrorStopPropagation(err)) {
				return null;
			}
			return event;
		},
	});

	if (config?.pusher?.appKey) {
		PusherService.init(config.pusher.appKey, config.pusher.host, config.apiURL);
	}

	if (config.gaID) {
		Vue.use(
			VueGtag,
			{
				config: {
					id: config.gaID,
					params: {
						app_name: 'Minlæring App',
						app_version: BUILD_DATE,
						user_id: store.state.user.user?.id || null,
					},
				},
				appName: 'Minlæring App',
				pageTrackerScreenviewEnabled: true,
				onBeforeTrack() {
					gtag('set', {
						user_id: store.state.user.user?.id || null,
					});
					gtag('set', 'user_properties', {
						role: store.getters['user/school']?.roles?.join() || 'guest',
						school_id: store.state.user.schoolId || null,
						school_name: store.getters['user/school']?.name || null,
						app_version: BUILD_DATE,
					});
				},
				onReady() {
					// 4 minutes.
					const interval = 1000 * 60 * 4;

					function heartbeat() {
						gtag('event', 'heartbeat', {
							event_category: 'heartbeat',
							event_label: 'heartbeat',
						});
					}

					setInterval(heartbeat, interval);
				},
			},
			router,
		);
	}

	new Vue({
		router,
		store,
		render: h => h(App),
	}).$mount('#app');
});
