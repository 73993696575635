import { newDate, isValidDate, getSecondsRemaining } from './date';

export function getUserSectionSecondsRemaining(section, startedAt) {
	if (!section?.time_limit || !startedAt) {
		return null;
	}
	const date = newDate(startedAt);
	if (!isValidDate(date)) {
		return null;
	}

	return getSecondsRemaining(section.time_limit, date);
}
