<template>
	<portal>
		<SplitModal ref="modal" title="Vælg fag" :dismissible="false" @hide="$emit('hide')">
			<template #img>
				<img src="/images/modals/ufo.png" />
			</template>

			<template #body>
				<BaseLoader v-if="loading" />
				<div v-else-if="courses.length">
					<p class="mb-2">
						Hvilke fag underviser du i? Vi sørger for primært at vise dig relevante
						læremidler.
					</p>
					<CourseSelector v-model="selectedCourses" :courses="courses" />
				</div>
			</template>

			<template #footer>
				<div class="buttons is-right">
					<button class="button is-medium" @click="onSubmit">Spring over</button>
					<div
						v-tippy="{ placement: 'bottom' }"
						content="Vælg mindst et fag før du kan gemme"
						@show="() => !hasSelectedCourses"
					>
						<button
							class="button is-medium is-primary"
							:disabled="!hasSelectedCourses"
							@click="onSubmit"
						>
							Gem
						</button>
					</div>
				</div>
			</template>
		</SplitModal>
	</portal>
</template>

<script>
import { mapState } from 'vuex';
import SplitModal from '@/components/ui/modal/SplitModal';
import BaseLoader from '@/components/base/BaseLoader';
import CourseSelector from '@/components/course/CourseSelector';

export default {
	name: 'PreferredCoursesModal',
	components: {
		SplitModal,
		BaseLoader,
		CourseSelector,
	},
	data() {
		return {
			loading: false,
			courses: [],
			selectedCourses: [],
		};
	},
	computed: {
		...mapState('user', ['user', 'schoolId']),
		hasSelectedCourses() {
			return this.selectedCourses.length > 0;
		},
	},
	mounted() {
		this.loadCourses();
		if (this.user?.preferred_courses?.length) {
			this.selectedCourses = this.user.preferred_courses;
		}
		this.$nextTick(() => this.$refs.modal.show());
	},
	methods: {
		loadCourses() {
			this.loading = true;
			return this.$store
				.dispatch('courses/getSchoolCourses', this.schoolId)
				.then(courses => {
					this.courses = courses;
				})
				.finally(() => (this.loading = false));
		},
		onSubmit() {
			this.loading = true;
			this.$store
				.dispatch('user/updateProfile', {
					course_ids: this.selectedCourses.map(course => course.id),
				})
				.then(() => {
					this.$toasted.show('Dine valgte fag er blevet gemt', {
						position: 'top-center',
						type: 'success',
						duration: 5000,
					});
				})
				.finally(() => (this.loading = false));
		},
	},
};
</script>
