<template>
	<div v-click-outside="onClickOutside" class="mega-menu-wrapper">
		<div class="container pt-5">
			<BaseNoContent
				v-if="menuAssignmentsByCourse.length === 0 && isStudent"
				image-path="/images/bookshelf.svg"
				header="Du har ingen opgavesæt"
				text="Tag fat i din lærer for at få adgang til opgavesæt på Minlæring."
			/>
			<div v-else class="columns">
				<div v-for="(course, index) in assignmentsByCourseSlice" :key="index" class="column">
					<p class="title is-6 ml-4 mb-3">{{ course.title }}</p>

					<div>
						<router-link
							v-for="(assignmentGroup, groupIndex) in course.assignmentTypes"
							:key="groupIndex"
							:to="{
								name: 'assignments_by_course_and_type',
								params: {
									course_id: assignmentGroup.course_id,
									type_id: assignmentGroup.type_id,
								},
							}"
							class="assignment-type"
						>
							<AssignmentTypeCard
								:assignment-group="assignmentGroup"
								:designation="getDesignationByCourseId(course.id)"
							/>
						</router-link>
					</div>
				</div>
			</div>

			<div class="mt-5 mb-4 is-flex is-justify-content-center">
				<router-link
					:to="{ name: 'assignments' }"
					active-class=""
					class="button is-medium"
					@click.native="$emit('close')"
					>Se alle opgavesæt</router-link
				>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BaseNoContent from '@/components/base/BaseNoContent';
import AssignmentTypeCard from './AssignmentTypeCard';

export default {
	name: 'AssignmentTypeMegaMenu',
	components: {
		BaseNoContent,
		AssignmentTypeCard,
	},
	computed: {
		...mapState('assignments', ['menuAssignmentsByCourse']),
		...mapGetters('user', ['isStudent']),
		...mapGetters('designation', ['getDesignationByCourseId']),
		assignmentsByCourseSlice() {
			return this.menuAssignmentsByCourse.slice(0, 4);
		},
	},
	methods: {
		onClickOutside() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="scss" scoped>
.mega-menu-wrapper {
	position: absolute;
	z-index: 30;
	width: 100%;
	background-color: white;
	box-shadow: 28px 28px 25px 0 rgba(40, 51, 56, 0.04);
}
</style>
