import { getSchoolClassHomeworkAnswerRevisionSummaries } from '@/api/results';
import HomeworkStats from './HomeworkStats';
import Homework from '@/models/homework/Homework';

const state = () => ({
	isLoading: false,
	homework: null,
	students: [],
	stats: new HomeworkStats(),
	isAnonymousView: false,
});

const getters = {
	isLoadingStats: state => state.isLoading,
	answerRevisions: state => state.stats.answerRevisions,
	getUserStats: state => user => state.stats.getUserStats(user),
	getSectionStats: state => user => state.stats.getSectionStats(user),
	getWorstExercises: state => (count, user) => {
		if (user) {
			return state.stats.getUserWorstExercises(count, user);
		}
		return state.stats.getWorstExercises(count);
	},
	progress: state => state.stats.getTotalProgress(),
	totalAnswerCounts: state => state.stats.totalStats.answerCounts,
	exercises: state => state.stats.exercises,
	isLive: state => !!state.homework?.isLive(),
	liveCompletedExercises: state => count => state.stats.getLiveCompletedExercises(count),
	students: state => {
		return state.students.filter(student => {
			if (!state.homework) {
				return true;
			}
			return (
				state.homework.section_relation_ids.filter(section_relation_id => {
					return !state.homework.studentIsExcludedFromSection(student, {
						section_relation_id,
					});
				}).length > 0
			);
		});
	},
	getSectionStudents: (state, getters) => section => {
		return getters.students.filter(
			student => !state.homework?.studentIsExcludedFromSection(student, section),
		);
	},
	getSectionCompletedStudents: (state, getters) => section => {
		return getters.getSectionStudents(section).filter(student => {
			const stats = state.stats.getUserStats(student).getSectionStats(section);
			return stats?.progress >= 100;
		});
	},
};

const actions = {
	reset({ commit }) {
		commit('setHomework', null);
		commit('setStudents', []);
		commit('setStats', []);
	},
	init({ commit, rootState }, { homework, students }) {
		commit('setHomework', homework);
		commit(
			'setStudents',
			students.filter(student => !homework.studentIsExcluded(student)),
		);
		commit('setIsLoading', true);
		return getSchoolClassHomeworkAnswerRevisionSummaries(
			rootState.user.schoolId,
			homework.school_class_id,
			homework.id,
		)
			.then(answerRevisions => commit('setStats', answerRevisions))
			.finally(() => commit('setIsLoading', false));
	},
	addAnswerRevision({ commit }, answerRevision) {
		commit('addAnswerRevision', answerRevision);
	},
	processUsers({ commit }) {
		commit('processUsers');
	},
};

const mutations = {
	setIsLoading(state, loading) {
		state.isLoading = loading;
	},
	setHomework(state, homework) {
		state.homework = homework ? new Homework(homework) : null;
	},
	setStudents(state, students) {
		state.students = students;
	},
	setStats(state, answerRevisions = []) {
		state.stats = new HomeworkStats(state.homework, state.students, answerRevisions);
	},
	processUsers(state) {
		state.stats.processUsers();
	},
	addAnswerRevision(state, answerRevision) {
		state.stats.addAnswerRevision(answerRevision);
	},
	setIsAnonymousView(state, value = false) {
		state.isAnonymousView = value;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
