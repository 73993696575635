<template>
	<div class="book-card p-4 is-flex is-align-items-center">
		<div class="is-flex-shrink-0 image-container">
			<img :src="coverImage" />
		</div>

		<div class="ml-4">
			<p class="title is-6" v-text="book.title"></p>
			<p class="subtitle is-10" v-html="$designate(book.description)"></p>
		</div>
	</div>
</template>

<script>
import designation from '@/mixins/designation';

export default {
	name: 'BookCard',
	mixins: [designation],
	props: {
		book: {
			type: Object,
			required: true,
		},
	},
	computed: {
		coverImage() {
			return !this.isRestricted ? this.book.cover_image : this.book.cover_no_access_image;
		},
		isRestricted() {
			return !!this.book.bundle_access?.is_restricted;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';

.book-card {
	border-radius: $radius;

	&:hover {
		background-color: #f0f8ff;
	}

	.image-container {
		width: 65px;

		img {
			border-radius: $radius;
			width: 100%;
			vertical-align: middle;
		}
	}
}
</style>
