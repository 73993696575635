import ItemCollection from '@/libs/ItemCollection';
import AnswerRevision from './AnswerRevision';

export default class AnswerRevisionCollection extends ItemCollection {
	newItem(item = {}) {
		if (item instanceof AnswerRevision) {
			return item;
		}
		return new AnswerRevision(item);
	}

	bySectionRelationId(sectionRelationId) {
		return new AnswerRevisionCollection(
			this.items.filter(item => item.section_relation_id === sectionRelationId),
		);
	}

	byUserId(userId) {
		return new AnswerRevisionCollection(this.items.filter(item => item.user_id === userId));
	}
}
