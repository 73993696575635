<template>
	<section :class="{ section: hasContainer }">
		<div :class="{ container: hasContainer, 'is-widescreen': hasContainer }">
			<slot />
		</div>
	</section>
</template>

<script>
export default {
	name: 'PublicLayout',
	computed: {
		hasContainer() {
			return this.$route.meta.hasFullWidthLayout !== true;
		},
	},
	created() {
		document.body.classList.add('public-layout');
	},
	destroyed() {
		document.body.classList.remove('public-layout');
	},
};
</script>
