<template>
	<MaterialCard
		:image="image"
		:title="title"
		:description="description"
		:progress="progress"
		:clickable="clickable"
		:size="size"
		:animate="animate"
		:is-stacked="isStacked"
	></MaterialCard>
</template>

<script>
import MaterialCard from '@/components/course/MaterialCard';

export default {
	name: 'CourseAssignmentsCard',
	components: {
		MaterialCard,
	},
	props: {
		image: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			default: 'Opgavesæt',
		},
		description: {
			type: String,
			default: 'Eksamenssæt baseret på autentiske sæt.',
		},
		count: {
			type: Number,
			default: null,
		},
		clickable: {
			type: Boolean,
			default: false,
		},
		animate: {
			type: Boolean,
			default: false,
		},
		progress: {
			type: Number,
			default: undefined,
		},
		isStacked: {
			type: Boolean,
			default: true,
		},
		size: {
			type: String,
			default: 'normal',
			validator: value => ['small', 'normal', 'large'].includes(value),
		},
	},
};
</script>
