import * as stringUtils from '@/utils/string';

// Map words for vocabulary.
const words = {
	screening: 'prøve',
	screeninger: 'prøver',
	screeningen: 'prøven',
};

export default {
	methods: {
		vocab(key, options = {}) {
			return this.processVocabulation(this.getVocabulatedWord(key.toLowerCase()), options);
		},
		getVocabulatedWord(key) {
			return this.$store.getters['user/schoolIsPrimarySchool'] && words[key] ? words[key] : key;
		},
		processVocabulation(word, options) {
			for (const option in options) {
				if (!options[option] || typeof stringUtils[option] != 'function') {
					continue;
				}
				word = stringUtils[option](word);
			}
			return word;
		},
	},
};
