<template>
	<portal>
		<SplitModal ref="modal" :title="title" :dismissible="dismissible" @hide="$emit('hide')">
			<template #img>
				<img :src="image" />
			</template>

			<template v-if="material && course" #body>
				<template v-if="isTrial">
					<p>
						Det ser desværre ud til, at du allerede har haft en gratis prøveadgang
						på læremidlerne målrettet
						<strong>{{ course.title.toLowerCase() }}</strong> på Minlæring.
					</p>
					<p>
						Hør nærmere ved at tage fat i os på mail
						<a href="mailto:info@minlaering.dk">info@minlaering.dk</a> eller på tlf.
						<a href="tel:50 59 88 20">50 59 88 20</a>.
					</p>
					<p>Du kan også bestille adgang med det samme.</p>
				</template>

				<template v-else>
					<p>
						Lås op for en gratis prøveadgang til alle læremidlerne målrettet
						<strong>{{ course.title.toLowerCase() }}</strong
						>.
					</p>
					<p>Arbejd med læremidlerne sammen med dine elever i 30 dage!</p>
					<p class="has-text-grey is-6">Adgangen forlænges ikke automatisk.</p>
				</template>
			</template>

			<template #footer>
				<div class="buttons is-right">
					<a
						v-if="isTrial"
						href="https://support.minlaering.dk/kontakt-os/kob-adgang"
						target="_blank"
						class="button is-medium is-primary pull-right"
						>Få adgang</a
					>
					<button
						v-else
						class="button is-medium is-primary pull-right"
						@click="startBundleTrial"
					>
						Start prøveperiode
					</button>
				</div>
			</template>
		</SplitModal>
	</portal>
</template>

<script>
import { mapGetters } from 'vuex';
import SplitModal from '@/components/ui/modal/SplitModal';
import designation from '@/mixins/designation';

export default {
	name: 'CourseTrialModal',
	components: {
		SplitModal,
	},
	mixins: [designation],
	props: {
		material: {
			type: Object,
			required: true,
		},
		dismissible: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		...mapGetters('designation', ['getDesignationByCourseId']),
		courseDesignation() {
			return this.getDesignationByCourseId(this.course?.id ?? 0);
		},
		course() {
			return this.material ? this.material.course : null;
		},
		image() {
			return this.course ? `/images/courses/${this.course.id}.svg` : null;
		},
		title() {
			const materialTitle = this.$designate(this.material.title, this.courseDesignation) || '';

			if (this.isTrial) {
				return 'Prøveperiode udløbet';
			}
			return `Få adgang til ${materialTitle}`;
		},
		access() {
			return this.material ? this.material.bundle_access : null;
		},
		isTrial() {
			return this.access ? this.access.is_trial : null;
		},
	},
	mounted() {
		this.$nextTick(() => this.$refs.modal.show());
	},
	methods: {
		startBundleTrial() {
			return this.$store
				.dispatch('bundles/startBundleTrial', {
					courseId: this.course.id,
				})
				.then(response => this.$emit('trial-started', response.message))
				.catch(err => this.$emit('trial-error', err))
				.finally(() => {
					this.$refs.modal.hide();
					this.$emit('hide');
				});
		},
	},
};
</script>
