<template>
	<div class="student-impersonation-overlay has-background-white">
		<div class="impersonation-container">
			<BaseLoader v-if="isLoading" />
			<UserSwapper
				v-else-if="fromUser && toUser"
				:from-user="fromUser"
				:to-user="toUser"
				@completed="onSwapCompleted"
			/>
		</div>
	</div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters, mapState } from 'vuex';
import BaseLoader from '@/components/base/BaseLoader';
import UserSwapper from '@/components/ui/user/UserSwapper';

export default {
	name: 'StudentImpersonationOverlay',
	components: {
		BaseLoader,
		UserSwapper,
	},
	data() {
		return {
			fromUser: null,
			toUser: null,
		};
	},
	computed: {
		...mapState('user', ['user']),
		...mapGetters('studentImpersonation', ['student', 'isLoading', 'isEntering', 'isExiting']),
	},
	watch: {
		student: {
			handler() {
				this.detectUsers();
			},
			immediate: true,
		},
	},
	methods: {
		detectUsers() {
			this.fromUser = cloneDeep(this.isEntering ? this.user : this.student);
			this.toUser = cloneDeep(this.isEntering ? this.student : this.user);
		},
		onSwapCompleted() {
			setTimeout(this.finish, 200);
		},
		finish() {
			if (this.isExiting) {
				return this.resetStudentImpersonation().finally(() => this.$router.go());
			} else {
				this.$router.go();
			}
		},
		resetStudentImpersonation() {
			return this.$store.dispatch('studentImpersonation/reset');
		},
	},
};
</script>

<style lang="scss" scoped>
.student-impersonation-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 31;
}
.impersonation-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
</style>
