import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import store from '../store';
import routes from './routes';

import dayjs from 'dayjs';

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	linkActiveClass: 'is-active',
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		}
		// Prevent scroll to top when savePosition param is present.
		// Used when updating query params with .replace().
		if (to.params.savePosition) {
			return {};
		}
		// Scroll to element, but only if url hash is valid.
		// See: https://github.com/vuejs/vue-router/issues/3008 and
		// https://github.com/vuejs/rfcs/blob/master/active-rfcs/0035-router-scroll-position.md
		if (to.hash && /^#\w+[\w\d-]*$/i.test(to.hash)) {
			return { selector: to.hash };
		}
		return { x: 0, y: 0 };
	},
});

router.beforeEach((to, from, next) => {
	window.nanobar.go(0);

	const promises = [];

	// Get the UNILogin config.
	if (!store.state.app.uniloginConfig) {
		promises.push(store.dispatch('app/getUniloginConfig'));
	}

	// If we are authenticated
	if (store.getters['auth/isAuthenticated']) {
		// If we don't have a user in store.
		if (store.state.user.user === null) {
			promises.push(store.dispatch('user/getUser'));
		}

		// Check if we already have a Lix token
		if (!store.getters['lix/isAuthenticated']) {
			promises.push(store.dispatch('lix/getToken'));
		}

		// Get notifications on dashboard.
		if (to.name == 'dashboard') {
			promises.push(store.dispatch('notification/getAppNotifications'));
		}

		// Do a ping every 24 hours.
		const activityTime = store.state.user?.activity ? dayjs(store.state.user.activity) : null;

		if (!activityTime || dayjs().diff(activityTime, 'hour') >= 24) {
			promises.push(store.dispatch('user/ping'));
		}
	} else {
		// Always fetch notifications for anon.
		promises.push(store.dispatch('notification/getAppNotifications'));
	}

	if (promises.length === 0) {
		next();
		return;
	}

	Promise.all(promises).finally(() => next());
});

router.beforeResolve((to, from, next) => {
	window.nanobar.go(80);
	next();
});

router.afterEach(to => {
	if (to.meta.title) {
		store.dispatch('documentTitle/set', { titles: to.meta.title });
	}

	window.nanobar.go(100);

	// Trigger app update if we have a newer version waiting
	if (navigator.serviceWorker) {
		navigator.serviceWorker.getRegistration().then(registration => {
			if (registration && registration.waiting) {
				registration.waiting.postMessage('skipWaiting');
			}
		});
	}
});

export default router;
