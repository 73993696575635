import http from './http';

export function getAssignmentsByCourse(schoolId) {
	return http.get(`/schools/${schoolId}/user/assignments/course`).then(resp => resp.data.data);
}

export function getAssignmentsByEducation(schoolId, courseId, typeId) {
	return http
		.get(`/schools/${schoolId}/user/assignments/education`, {
			params: {
				course_id: courseId,
				type_id: typeId,
			},
		})
		.then(resp => resp.data.data);
}

export function getCourseAssignments(courseId) {
	return http.get(`/assignments/courses/${courseId}`).then(resp => resp.data.data);
}

export function getAssignment(id) {
	return http.get(`/assignments/${id}`).then(resp => resp.data.data);
}

export function getAssignmentSections(id) {
	return http.get(`/assignments/${id}/sections`).then(resp => resp.data.data);
}

export function getSchoolAssignment(schoolId, id, homeworkId = null) {
	return http
		.get(`/schools/${schoolId}/assignments/${id}`, {
			params: homeworkId ? { homeworkId } : {},
		})
		.then(({ data }) => {
			const assignment = data.data;
			const homework = data.meta?.homework?.length ? data.meta.homework : [];
			return {
				assignment,
				homework,
			};
		});
}
