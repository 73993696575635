<template>
	<portal>
		<SplitModal ref="modal" title="Indtast email" :dismissible="false" @hide="$emit('hide')">
			<template #img>
				<img src="/images/modals/pushing-buttons.png" />
			</template>

			<template #body>
				<p class="mb-4">
					Du bedes indtaste din arbejdsrelaterede mailadresse herunder, så vi kan genskabe
					dit login ved nedbrud i UNI-Login.
				</p>

				<BaseFieldHorizontal :error="emailValidationError">
					<BaseInput
						v-model.trim="$v.email.$model"
						type="email"
						placeholder="Indtast email"
						:has-error="!!emailValidationError"
					/>
				</BaseFieldHorizontal>

				<BaseFieldHorizontal :error="confirmEmailValidationError">
					<BaseInput
						v-model.trim="$v.confirmEmail.$model"
						type="email"
						placeholder="Bekræft email"
						:has-error="!!confirmEmailValidationError"
					/>
				</BaseFieldHorizontal>
			</template>

			<template #footer>
				<div class="buttons is-right">
					<button
						class="button is-medium"
						:disabled="!!email || !!confirmEmail"
						@click="onSkip"
					>
						Spring over
					</button>
					<div
						v-tippy="{ placement: 'bottom' }"
						content="Indtast en email før du kan gemme"
						@show="() => $v.$invalid"
					>
						<button
							class="button is-medium is-primary"
							:class="{ 'is-loading': submitting }"
							:disabled="submitting || $v.$invalid"
							@click="onSubmit"
						>
							Gem
						</button>
					</div>
				</div>
			</template>
		</SplitModal>
	</portal>
</template>

<script>
import SplitModal from '@/components/ui/modal/SplitModal';
import BaseFieldHorizontal from '@/components/base/input/BaseFieldHorizontal';
import BaseInput from '@/components/base/input/BaseInput';
import { required, email, sameAs } from 'vuelidate/lib/validators';
import { ValidationError } from '@/api/errors';

export default {
	name: 'ProvideEmailModal',
	components: {
		SplitModal,
		BaseFieldHorizontal,
		BaseInput,
	},
	data() {
		return {
			submitting: false,
			error: null,
			email: '',
			confirmEmail: '',
			validationErrors: {},
		};
	},
	validations: {
		email: {
			required,
			email,
		},
		confirmEmail: {
			required,
			sameAsEmail: sameAs('email'),
		},
	},
	computed: {
		emailValidationError() {
			if (this.$v.email.$error) {
				return 'Du skal indtaste en korrekt email';
			}
			if (this.validationErrors.email) {
				return this.validationErrors.email.join(', ');
			}
			return '';
		},
		confirmEmailValidationError() {
			return this.$v.confirmEmail.$error ? 'De to felter matcher ikke hinanden' : '';
		},
	},
	mounted() {
		this.$nextTick(() => this.$refs.modal.show());
	},
	methods: {
		onSkip() {
			this.$store.commit('user/skipProvideEmail');
		},
		onSubmit() {
			if (this.$v.$invalid) {
				return;
			}

			this.error = null;
			this.submitting = true;
			this.validationErrors = {};
			this.$store
				.dispatch('user/updateProfile', {
					email: this.email,
				})
				.then(() => {
					this.$toasted.show('Din mailadresse er nu gemt', {
						position: 'top-center',
						type: 'success',
						duration: 5000,
					});
				})
				.catch(err => {
					if (err instanceof ValidationError) {
						this.error = err.message;
						this.validationErrors = err.validationErrors;
					}
					this.error = err.message;
				})
				.finally(() => (this.submitting = false));
		},
	},
};
</script>
