<template>
	<div class="base-input">
		<input
			class="input"
			:class="cssClasses"
			:type="type"
			:value="value"
			:disabled="disabled"
			:readonly="readonly"
			:placeholder="placeholder"
			:maxlength="maxlength ? maxlength : null"
			v-bind="$attrs"
			v-on="listeners"
			@mouseenter="isHovered = true"
			@mouseleave="isHovered = false"
			@focus="isFocused = true"
			@blur="isFocused = false"
		/>
		<slot :is-hovered="isHovered" :is-focused="isFocused" />
	</div>
</template>

<script>
export default {
	name: 'BaseInput',
	inheritAttrs: false,
	props: {
		value: {
			type: [String, Number],
			default: null,
		},
		type: {
			type: String,
			default: 'text',
		},
		size: {
			type: String,
			default: 'medium',
		},
		defaultInputEvent: {
			type: Boolean,
			default: true,
		},
		hasError: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		active: {
			type: Boolean,
			default: false,
		},
		isStatic: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: () => '',
		},
		maxlength: {
			type: Number,
			default: null,
		},
		contextClass: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isHovered: false,
			isFocused: false,
		};
	},
	computed: {
		cssClasses() {
			const classNames = [`is-${this.size}`];
			if (this.hasError) {
				classNames.push('is-danger');
			}
			if (this.active) {
				classNames.push('is-active');
			}
			if (this.contextClass) {
				classNames.push(this.contextClass);
			}
			if (this.isStatic) {
				classNames.push('is-static');
			}
			return classNames;
		},
		listeners() {
			const listeners = {};

			for (const eventName in this.$listeners) {
				if (this.defaultInputEvent && eventName == 'input') {
					listeners.input = event => this.$emit('input', event?.target?.value);
				} else {
					listeners[eventName] = this.$listeners[eventName];
				}
			}

			return listeners;
		},
	},
};
</script>
