<template>
	<MaterialCard
		image="/images/exercises/exercises-card.svg"
		title="Øvelser"
		:description="`Find øvelser på tværs af alle bøger i ${courseName}.`"
		:clickable="clickable"
		:size="size"
		:animate="animate"
	>
	</MaterialCard>
</template>

<script>
import MaterialCard from '@/components/course/MaterialCard';

export default {
	name: 'CourseExercisesCard',
	components: { MaterialCard },
	props: {
		course: {
			type: Object,
			required: true,
		},
		clickable: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: 'normal',
			validator: value => ['small', 'normal', 'large'].includes(value),
		},
		animate: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		courseName() {
			if (!this.course?.title) {
				return '';
			}
			return this.course.title.toLowerCase();
		},
	},
};
</script>
