export function hasRole(school, role) {
	if (school.roles === null) {
		return false;
	}

	for (const schoolRole of school.roles) {
		if (schoolRole == role) {
			return true;
		}
	}

	return false;
}
export function hasMultipleSchools(user) {
	return user?.schools?.length > 1;
}
