<template>
	<span
		class="tag has-text-weight-bold"
		:class="[
			`is-${size}`,
			{
				'is-rounded': rounded,
				'is-clickable': clickable,
				'has-icon': removeable,
			},
			...colorClasses,
		]"
		:style="colorCode ? { 'background-color': colorCode } : {}"
	>
		<div class="is-flex is-align-items-center is-justify-content-space-between">
			<span><slot /></span>
			<BaseIcon v-if="removeable" icon="cross" color="white" :size="2" no-padding />
		</div>
	</span>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon';

export default {
	name: 'BaseTag',
	components: {
		BaseIcon,
	},
	props: {
		size: {
			type: String,
			default: 'medium',
		},
		colorCode: {
			type: String,
			default: undefined,
		},
		color: {
			type: String,
			default: 'blue',
		},
		textColor: {
			type: String,
			default: 'white',
		},
		hoverColor: {
			type: String,
			default: '',
		},
		hoverTextColor: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: () => '',
		},
		rounded: {
			type: Boolean,
			default: true,
		},
		clickable: {
			type: Boolean,
			default: false,
		},
		removeable: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		colorClasses() {
			if (this.type) {
				return [`is-${this.type}`];
			}

			const classNames = [`has-text-${this.textColor}`];
			if (!this.colorCode) {
				classNames.push(`has-background-${this.color}`);
			}
			if (this.hoverColor) {
				classNames.push(`has-hover-background-${this.hoverColor}`);
			}
			if (this.hoverTextColor) {
				classNames.push(`has-hover-text-${this.hoverTextColor}`);
			}
			return classNames;
		},
	},
};
</script>

<style lang="scss" scoped>
.tag {
	transition: background-color 200ms linear;

	&.is-normal {
		padding: 0.5rem 0.75rem;
		font-size: 0.9rem;
	}
	&.is-medium {
		padding: 1rem 1.3rem;
	}
	&.is-large {
		padding: 1.2rem 1.5rem;
	}

	.base-icon-wrapper {
		margin-right: -0.5rem;
	}
}
</style>
