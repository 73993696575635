<template>
	<div class="base-icon-wrapper is-flex is-flex-shrink-0" :class="cssClasses">
		<span v-if="hasDot" class="icon-dot"></span>
		<svg class="base-icon is-flex-shrink-0">
			<use :xlink:href="url"></use>
		</svg>
	</div>
</template>

<script>
export default {
	name: 'BaseIcon',
	props: {
		icon: {
			type: String,
			required: true,
		},
		size: {
			type: Number,
			default: 4,
		},
		color: {
			type: String,
			default: 'grey',
		},
		activeColor: {
			type: String,
			default: null,
		},
		hoverColor: {
			type: String,
			default: null,
		},
		active: {
			type: Boolean,
			default: false,
		},
		activeOnHover: {
			type: Boolean,
			default: true,
		},
		showBackground: {
			type: Boolean,
			default: false,
		},
		clickable: {
			type: Boolean,
			default: false,
		},
		noPadding: {
			type: Boolean,
			default: false,
		},
		hasDot: {
			type: Boolean,
			default: false,
		},
		hasTransition: {
			type: Boolean,
			default: true,
		},
		square: {
			type: Boolean,
			default: false,
		},
		isFill: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		url() {
			return require('@/assets/images/icons.svg') + `#icon-${this.icon}`;
		},
		baseColor() {
			if (this.active && this.activeColor) {
				return this.activeColor;
			}
			return this.color || 'grey';
		},
		sizeNumber() {
			return Math.max(1, Math.min(this.size, 9));
		},
		cssClasses() {
			const classNames = [`is-${this.baseColor}`, `is-size-${this.sizeNumber}`];
			if (this.active) {
				classNames.push('is-active');
			}
			if (this.activeOnHover) {
				classNames.push('active-on-hover', `is-active-${this.hoverColor || this.activeColor}`);
			}
			if (this.showBackground) {
				classNames.push('show-background');
			}
			if (this.noPadding) {
				classNames.push('no-padding');
			}
			if (this.clickable) {
				classNames.push('is-clickable');
			}
			if (this.hasTransition) {
				classNames.push('has-transition');
			}
			if (this.square) {
				classNames.push('square');
			}
			if (this.isFill) {
				classNames.push('is-fill');
			}

			return classNames;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';

.base-icon-wrapper {
	position: relative;
	border-radius: 50%;
	text-align: center;

	svg.base-icon {
		width: 100%;
		height: 100%;
		padding: 12.5%;
	}

	&.square {
		border-radius: 1rem;
	}

	.icon-dot {
		background: red;
		height: 15px;
		width: 15px;
		border: 3px solid #fff;
		border-radius: 50%;
		position: absolute;
		top: -5%;
		right: -8%;
	}

	&.is-size-1 {
		width: 1rem;
		height: 1rem;
	}
	&.is-size-2 {
		width: 1.5rem;
		height: 1.5rem;
	}
	&.is-size-3 {
		width: 2rem;
		height: 2rem;
	}
	&.is-size-4 {
		width: 2.5rem;
		height: 2.5rem;
	}
	&.is-size-5 {
		width: 3.1875rem;
		height: 3.1875rem;
	}
	&.is-size-6 {
		width: 3.5rem;
		height: 3.5rem;
	}
	&.is-size-7 {
		width: 4rem;
		height: 4rem;
	}
	&.is-size-8 {
		width: 4.5rem;
		height: 4.5rem;
	}
	&.is-size-9 {
		width: 5rem;
		height: 5rem;
	}

	&.has-transition {
		transition: background-color 200ms linear;
		svg.base-icon {
			transition: color 200ms linear, fill 200ms linear;
		}
	}

	&.no-padding {
		svg.base-icon {
			padding: 0 !important;
		}
	}

	/* stylelint-disable */
	&.is-white,
	&.is-active-white.active-on-hover:hover {
		&.show-background {
			background-color: $grey-lighter;
		}

		svg.base-icon {
			fill: white;
			color: white;
		}
	}
	&.is-black,
	&.is-active-black.active-on-hover:hover {
		&.show-background {
			background-color: white;
		}

		svg.base-icon {
			fill: #000;
			color: $grey-dark;
		}
	}
	&.is-grey-dark,
	&.is-active-grey-dark.active-on-hover:hover {
		&.show-background {
			background-color: $grey-lighterer;
		}

		svg.base-icon {
			fill: $grey-dark;
			color: $grey;
		}
	}
	&.is-grey,
	&.is-active-grey.active-on-hover:hover {
		&.show-background {
			background-color: $grey-lighterer;
		}
		svg.base-icon {
			fill: $grey;
			color: $grey-light;
		}
		&.is-fill svg.base-icon {
			color: $grey;
		}
	}
	&.is-grey-light,
	&.is-active-grey-light.active-on-hover:hover {
		&.show-background {
			background-color: $grey-lighterer;
		}
		svg.base-icon {
			fill: $grey-light;
			color: $grey-lighter;
		}
		&.is-fill svg.base-icon {
			color: $grey-light;
		}
	}
	&.is-blue,
	&.is-active-blue.active-on-hover:hover {
		&.show-background {
			background-color: $blue-lightest;
		}
		svg.base-icon {
			fill: $blue;
			color: $blue-lighter;
		}
		&.is-fill svg.base-icon {
			color: $blue;
		}
	}
	&.is-blue-dark,
	&.is-active-blue-dark.active-on-hover:hover {
		&.show-background {
			background-color: $blue-lightest;
		}
		svg.base-icon {
			fill: $blue-dark;
			color: $blue;
		}
		&.is-fill svg.base-icon {
			color: $blue-dark;
		}
	}
	&.is-yellow,
	&.is-active-yellow.active-on-hover:hover {
		&.show-background {
			background-color: $yellow-lighter;
		}
		svg.base-icon {
			fill: $yellow;
			color: $yellow-light;
		}
		&.is-fill svg.base-icon {
			color: $yellow;
		}
	}
	&.is-yellow-dark,
	&.is-active-yellow-dark.active-on-hover:hover {
		&.show-background {
			background-color: $yellow-light;
		}
		svg.base-icon {
			fill: $yellow-dark;
			color: $yellow;
		}
		&.is-fill svg.base-icon {
			color: $yellow-dark;
		}
	}
	&.is-purple,
	&.is-active-purple.active-on-hover:hover {
		svg.base-icon {
			fill: $purple;
			color: $purple-light;
		}
		&.is-fill svg.base-icon {
			color: $purple;
		}
		&.show-background {
			background-color: $purple-lighter;
		}
	}
	&.is-red,
	&.is-active-red.active-on-hover:hover {
		svg.base-icon {
			fill: $red;
			color: $red-light;
		}
		&.is-fill svg.base-icon {
			color: $red;
		}
		&.show-background {
			background-color: $red-lighter;
		}
	}
	&.is-green,
	&.is-active-green.active-on-hover:hover {
		svg.base-icon {
			fill: $green;
			color: $green-light;
		}
		&.is-fill svg.base-icon {
			color: $green;
		}
		&.show-background {
			background-color: $green-lighter;
		}
	}
	&.is-orange,
	&.is-active-orange.active-on-hover:hover {
		svg.base-icon {
			fill: $orange;
			color: $orange-dark;
		}
		&.is-fill svg.base-icon {
			color: $orange;
		}
		&.show-background {
			background-color: $orange-light;
		}
	}
	&.is-transparent-dark,
	&.is-active-transparent-dark.active-on-hover:hover {
		svg.base-icon {
			fill: rgba(0, 0, 0, 0.4);
			color: rgba(0, 0, 0, 0.2);
		}
		&.is-fill svg.base-icon {
			color: rgba(0, 0, 0, 0.4);
		}
		&.show-background {
			background-color: rgba(0, 0, 0, 0.1);
		}
	}
	&.is-transparent-light,
	&.is-active-transparent-light.active-on-hover:hover {
		svg.base-icon {
			fill: rgba(255, 255, 255, 0.4);
			color: rgba(255, 255, 255, 0.2);
		}
		&.is-fill svg.base-icon {
			color: rgba(255, 255, 255, 0.4);
		}
		&.show-background {
			background-color: rgba(255, 255, 255, 0.1);
		}
	}
	&.is-white-on-green,
	&.is-active-white-on-green.active-on-hover:hover {
		svg.base-icon {
			fill: white;
			color: white;
		}
		&.is-fill svg.base-icon {
			color: white;
		}
		&.show-background {
			background-color: $green;
		}
	}
	/* stylelint-enable */
}
</style>
