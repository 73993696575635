import { getEmailSubscriptions, updateEmailSubscriptions } from '@/api/email';

const state = {};

const getters = {};

const actions = {
	getEmailSubscriptions({ rootState }) {
		return getEmailSubscriptions(rootState.user.schoolId);
	},
	updateEmailSubscriptions(_, data) {
		return updateEmailSubscriptions(data);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
