export default {
	methods: {
		getExerciseIcon(difficulty) {
			switch (difficulty) {
				default:
				case 1:
					return 'difficulty1';
				case 2:
					return 'difficulty2';
				case 3:
					return 'difficulty3';
				case 0:
				case 4:
					return 'exam';
			}
		},
	},
};
