<template>
	<div class="has-text-centered">
		<div class="error-image-container mx-auto">
			<img src="/images/500.svg" alt="500.svg" />
		</div>
		<div class="error-delimiter has-background-grey-light mx-auto my-4"></div>
		<h1 class="title is-3 has-text-grey-dark">
			{{ isMaintenance ? 'Minlæring er under vedligeholdelse' : 'Vi oplever i øjeblikket problemer' }}
		</h1>
		<p v-if="isMaintenance" class="has-text-grey">
			Vi er ved at gennemgå en serveropdatering af Minlæring, og vi skulle gerne være tilbage i løbet
			af kort tid.
		</p>
		<p v-else class="has-text-grey">
			Vi oplever desværre i øjeblikket problemer og vi arbejder på højtryk for at løse dem. Vi
			beklager ulejligheden.
		</p>

		<p class="has-text-grey">
			Følg med på <a href="https://status.minlaering.dk/">status.minlaering.dk</a>
		</p>

		<p class="has-text-grey">Siden opdaterer automatisk hvert minut</p>
	</div>
</template>

<script>
export default {
	name: 'ServerError',
	props: {
		err: {
			type: Error,
			required: true,
		},
		isMaintenance: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
.error-image-container {
	max-width: 500px;
}

.error-delimiter {
	height: 0.25rem;
	border-radius: 0.5rem;
	width: 5rem;
}
</style>
