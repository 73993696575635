<template>
	<g>
		{{/* eslint-disable max-len */}}
		<path
			v-if="!asText"
			stroke-width="0"
			fill="black"
			d="M25.4 6.6c-.7-.7-2-.7-2.7 0L16 13.3 9.3 6.6c-.7-.7-2-.7-2.7 0-.7.7-.7 2 0 2.7l6.7 6.7-6.7 6.7c-.7.7-.7 2 0 2.7.7.7 2 .7 2.7 0l6.7-6.7 6.7 6.7c.7.7 2 .7 2.7 0s.7-2 0-2.7L18.7 16l6.7-6.7c.8-.9.8-2 0-2.7z"
		/>
		<text v-else x="0" y="24">S</text>
	</g>
</template>

<script>
export default {
	name: 'SubjectSymbol',
	props: {
		asText: {
			type: Boolean,
			default: false,
		},
		hasMultipleWords: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.$emit('config', {
				symbolWidth: this.$el.getBoundingClientRect().width,
				lineLeft: this.hasMultipleWords,
				lineRight: this.hasMultipleWords,
			});
		});
	},
};
</script>
