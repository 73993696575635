import { doSearch, getSearchConfig, getExercisesSearchConfig } from '@/api/search';

const state = () => ({});

const getters = {};

const actions = {
	doSearch(_, { schoolId, query, page, types, courses, materials }) {
		return doSearch(schoolId, query, page, types, courses, materials);
	},
	getSearchConfig(_, { schoolId }) {
		return getSearchConfig(schoolId);
	},
	getExercisesSearchConfig(_, { schoolId, params }) {
		return getExercisesSearchConfig(schoolId, params);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
