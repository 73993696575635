<template>
	<BaseDropdown ref="dropdown" :is-wide="schoolClass !== null" full-width-trigger is-right @hide="$emit('close')">
		<template #trigger>
			<BaseInputDropdown :value="triggerText" />
		</template>

		<StudentImpersonationSelector
			v-if="validSchoolClasses.length > 0"
			:school-class="schoolClass"
			:student="student"
			:school-classes="validSchoolClasses"
			@select-school-class="selectSchoolClass"
			@select-student="selectStudent"
		/>
		<p v-else class="title has-text-grey-dark px-4">
			<em>Opret først et hold for at kunne få siden vist som elev.</em>
		</p>
	</BaseDropdown>
</template>

<script>
import BaseDropdown from '@/components/base/dropdown/BaseDropdown';
import BaseInputDropdown from '@/components/base/input/BaseInputDropdown';
import StudentImpersonationSelector from './StudentImpersonationSelector';

export default {
	name: 'StudentImpersonationDropdown',
	components: {
		BaseDropdown,
		BaseInputDropdown,
		StudentImpersonationSelector,
	},
	props: {
		schoolClass: {
			type: Object,
			default: null,
		},
		student: {
			type: Object,
			default: null,
		},
		schoolClasses: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		triggerText() {
			if (this.student) {
				return `${this.student.name}` + (this.schoolClass ? ` i ${this.schoolClass.name}` : '');
			}
			return 'Vælg elev';
		},
		validSchoolClasses() {
			return this.schoolClasses.filter(schoolClass => schoolClass.students.length > 0);
		},
	},
	methods: {
		selectSchoolClass(schoolClass) {
			this.$emit('select-school-class', schoolClass);
		},
		selectStudent(student) {
			this.$emit('select-student', student);
		},
		open() {
			this.$refs.dropdown?.show();
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
@import '@/assets/sass/abstracts/mixins';

:deep(.input) {
	color: white !important;
	border-bottom-color: transparent !important;
	text-overflow: ellipsis;
}
@include mixin-desktop-up {
	:deep(.dropdown-items) {
		max-height: 500px !important;
	}
	:deep(.dropdown-menu) {
		min-width: 300px !important;
	}
	.is-wide :deep(.dropdown-menu) {
		min-width: 550px !important;
	}
}
</style>
