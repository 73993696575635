<template>
	<footer class="footer">
		<div class="container">
			<div class="columns is-desktop is-centered">
				<div class="column is-narrow">
					<router-link :to="{ name: 'dashboard' }" class="logo-link">
						<img src="/images/logo/logobund.svg" class="logo" />
					</router-link>
				</div>
				<div class="column is-narrow content">
					<h3>Kom godt i gang</h3>
					<p>
						<a
							target="_blank"
							href="https://support.minlaering.dk/ovrige/kom-godt-i-gang-pa-5-minutter"
							>Kom godt i gang på 5 minutter</a
						>
					</p>
					<p>
						<a target="_blank" href="https://support.minlaering.dk/resultater"
							>Indblik i elevernes arbejde</a
						>
					</p>
					<p>
						<a target="_blank" href="https://support.minlaering.dk/lektier"
							>Opret differentierede lektier</a
						>
					</p>
				</div>
				<div class="column is-narrow content">
					<h3>Minlæring</h3>

					<p>
						<a href="https://blog.minlaering.dk" target="_blank">Blog</a>
					</p>
					<p>
						<a
							target="_blank"
							href="https://blog.minlaering.dk/blogindlaeg/
								minlring-funktionelt-sprogsyn-danner-bagtppe-for-teori-og-velser"
							>Om Minlæring</a
						>
					</p>
					<p>
						<a href="https://www.facebook.com/minlaering" target="_blank"
							>Facebook</a
						>
					</p>
				</div>
				<div class="column is-narrow content">
					<h3>Support</h3>
					<p>
						<a target="_blank" href="https://support.minlaering.dk/"
							>Hjælp og support</a
						>
					</p>
					<p>
						<a target="_blank" href="https://support.minlaering.dk/kontakt-os"
							>Kontakt os</a
						>
					</p>
				</div>
			</div>
			<div class="has-text-centered">© Alle rettigheder forbeholdes Minlæring {{ year }}</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: 'Footer',
	data() {
		return {
			year: new Date().getFullYear(),
		};
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';

.footer {
	font-family: $family-primary;
	font-size: $size-7;
	color: $grey-dark;

	h3 {
		font-size: $size-6;
		font-weight: 600;
		color: $grey-dark;
	}

	.column {
		padding: 1rem 4rem;
	}

	a {
		font-size: $size-6;
		color: $grey;

		&:hover {
			color: $grey-dark;
		}

		&.logo-link {
			font-size: 24px;
			font-weight: 700;

			img.logo {
				width: 240px;
				vertical-align: middle;
			}
		}
	}
}
</style>
