import { getDesignationDefaults } from '@/api/designation';
import Vue from 'vue';
import Form from '@/models/form/Form';

const state = () => ({
	info: new Form({
		name: '',
		schoolClassLevel: null,
		education: null,
		teachers: [],
	}),
	selectedCourses: [],
	selectedBooks: [],
	uniLoginGroup: null,
	uniLoginStudents: [],
	designationDefaults: null,
	courseDesignations: {},
});

const getters = {
	infoByKey: state => key => {
		return state.info.get(key);
	},
	hasValidInfo: state => {
		return state.info.name && state.info.schoolClassLevel && state.info.education;
	},
	hasSelectedCourses: state => state.selectedCourses.length > 0,
	courseIds: state => state.selectedCourses.map(course => course.id),
	bookSelectedIndex: state => book => {
		return state.selectedBooks.findIndex(selectedBook => {
			return selectedBook.id === book.id;
		});
	},
	bookIsSelected: (state, getters) => book => {
		return getters.bookSelectedIndex(book) >= 0;
	},
	schoolClassPayload: state => {
		return {
			name: state.info.name,
			education_id: state.info.education?.id,
			school_class_level_id: state.info.schoolClassLevel?.id,
			teacher_ids: state.info.teachers.map(teacher => teacher.id),
			course_ids: state.selectedCourses.map(course => course.id),
			book_ids: state.selectedBooks.map(course => course.id),
			designation: state.courseDesignations,
			unilogin_group: state.uniLoginGroup,
			unilogin_students: !state.uniLoginGroup ? state.uniLoginStudents : [],
		};
	},
	defaultDesignation: () => {
		return { default: 1, symbol: 1 };
	},
	getCourseDesignation: (state, getters) => course => {
		return course.id in state.courseDesignations
			? state.courseDesignations[course.id]
			: getters.defaultDesignation;
	},
	designationGroups: state => {
		return state.designationDefaults?.groups ?? {};
	},
	expirationDate: (state, getters) => {
		const level = getters.infoByKey('schoolClassLevel');
		return level?.school_class_expiration ?? null;
	},
};

const actions = {
	reset({ commit }) {
		commit('reset');
	},
	updateInfo({ commit }, values) {
		for (const key in values) {
			commit('setInfoKey', { key, value: values[key] });
		}
	},
	updateSelectedCourses({ commit, getters, state }, courses) {
		commit('setSelectedCourses', courses);
		const filteredBooks = state.selectedBooks.filter(book => {
			return getters.courseIds.indexOf(book.course_id) >= 0;
		});
		commit('setSelectedBooks', filteredBooks);
	},
	toggleBook({ commit, getters }, book) {
		const index = getters.bookSelectedIndex(book);
		if (index >= 0) {
			commit('deselectBook', index);
		} else {
			commit('selectBook', book);
		}
	},
	selectBook({ commit, getters }, book) {
		if (getters.bookIsSelected(book)) {
			return;
		}
		commit('selectBook', book);
	},
	updateUniLoginStudents({ commit }, students) {
		commit('setUniLoginStudents', students);
	},
	updateUniLoginGroup({ commit }, group) {
		commit('setUniLoginGroup', group);
	},
	loadDesignationDefaults({ commit }) {
		return getDesignationDefaults().then(designationDefaults => {
			commit('setDesignationDefaults', designationDefaults);
			return designationDefaults;
		});
	},
	updateCourseDesignation({ commit }, { course, designation }) {
		commit('setCourseDesignation', { course, designation });
	},
};

const mutations = {
	reset(state) {
		state.info.reset();
		state.selectedCourses = [];
		state.selectedBooks = [];
		state.uniLoginGroup = null;
		state.uniLoginStudents = [];
	},
	setInfoKey(state, { key, value }) {
		Vue.set(state.info, key, value);
	},
	setSelectedCourses(state, courses) {
		state.selectedCourses = courses;
	},
	setSelectedBooks(state, books) {
		state.selectedBooks = books;
	},
	selectBook(state, book) {
		state.selectedBooks.push(book);
	},
	deselectBook(state, index) {
		state.selectedBooks.splice(index, 1);
	},
	setUniLoginStudents(state, students) {
		state.uniLoginStudents = students;
	},
	setUniLoginGroup(state, group) {
		state.uniLoginGroup = group;
	},
	setDesignationDefaults(state, designationDefaults) {
		state.designationDefaults = designationDefaults;
	},
	setCourseDesignation(state, { course, designation }) {
		Vue.set(state.courseDesignations, course.id, designation);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
