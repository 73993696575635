<template>
	<BaseInput
		:value="value"
		:size="size"
		:active="active || hasSelection"
		:has-error="hasError"
		:context-class="contextClass"
		readonly
	>
		<span class="icon is-right">
			<BaseChevron
				:direction="active ? 'up' : 'down'"
				class="ml-3"
				v-bind="chevronColor ? { color: chevronColor } : {}"
			/>
		</span>
	</BaseInput>
</template>

<script>
import BaseInput from './BaseInput';
import BaseChevron from '@/components/base/BaseChevron';

export default {
	name: 'BaseInputDropdown',
	components: { BaseInput, BaseChevron },
	props: {
		value: {
			type: [String, Number],
			default: null,
		},
		size: {
			type: String,
			default: 'medium',
		},
		hasError: {
			type: Boolean,
			default: false,
		},
		active: {
			type: Boolean,
			default: false,
		},
		hasSelection: {
			type: Boolean,
			default: false,
		},
		chevronColor: {
			type: String,
			default: null,
		},
		contextClass: {
			type: String,
			default: null,
		},
	},
};
</script>
