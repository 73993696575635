import http from './http';

export function getMaterials() {
	return http.get('/trial/materials').then(resp => resp.data.data);
}

export function unilogin(timestamp, user, auth) {
	return http
		.post('/trial/unilogin', {
			timestamp,
			user,
			auth,
		})
		.then(resp => resp.data.data);
}

export function uniloginUser(code) {
	return http.post('/trial/uniloginuser', { code }).then(resp => resp.data.data);
}

export function register(payload) {
	return http.post('/trial/register', payload).then(resp => resp.data);
}
