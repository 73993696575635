import Model from '@/models/Model';

export default class Answer extends Model {
	getDefaultValues() {
		return {
			id: 0,
			task_id: 0,
			user_id: 0,
			answer_revision_id: 0,
			answer_status_id: 0,
			had_error: false,
			attempts: 0,
			points: 0,
			is_completed: false,
			conf: {},
			status: null,
			task: null,
		};
	}

	get isMocked() {
		return !this.id;
	}

	get isCorrect() {
		return this.isStatus('correct');
	}

	get isSemiCorrect() {
		return this.isStatus('semi_correct');
	}

	get isWrong() {
		return this.isStatus('wrong');
	}

	get isUngraded() {
		return this.isStatus('ungraded');
	}

	getStatusName() {
		return this.status?.machine_name ?? '';
	}

	isStatus(status, fallback = false) {
		return this.status ? this.status.machine_name === status : fallback;
	}

	getOptions() {
		return this.conf?.options ?? [];
	}
}
