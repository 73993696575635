import UserNotification from './UserNotification';

export default class HomeworkExportReady extends UserNotification {
	get title() {
		return 'Eksport klar';
	}

	get body() {
		return 'Din eksport er klar til download. Klik her.';
	}

	get actionText() {
		return 'Klik her';
	}

	get actionRoute() {
		return this.data.url;
	}

	get homeworkId() {
		return this.data.homework_id;
	}

	get exportType() {
		return this.data.type;
	}

	get exportTitle() {
		return this.data.title;
	}
}
