import UserNotification from './UserNotification';

export default class NewBooks extends UserNotification {
	get title() {
		if (this.data.books.length > 1) {
			return 'Nye læremidler';
		}
		return 'Ny bog';
	}

	get body() {
		if (this.data.books.length > 1) {
			return `Du har fået adgang til ${this.data.books.length} læremidler`;
		}
		return `Du har fået adgang til ${this.data.books[0].title}`;
	}

	get actionText() {
		if (this.data.books.length === 1) {
			return 'Gå til bogen';
		}
		return 'Gå til bøger';
	}

	get actionRoute() {
		if (this.data.books.length === 1) {
			return { name: 'book', params: { id: this.data.books[0].id } };
		}
		return { name: 'books' };
	}
}
