import UserNotification from './UserNotification';

export default class StudentAssignedToSchoolClass extends UserNotification {
	get title() {
		return 'Nyt hold';
	}

	get body() {
		return `Du er blevet tilmeldt holdet ${this.data.name}`;
	}
}
