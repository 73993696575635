import dayjs from 'dayjs';

export default {
	filters: {
		dateFormat(value, format) {
			if (!format) {
				format = 'DD/MM/YYYY HH:mm';
			}
			if (!dayjs.isDayjs(value)) {
				value = dayjs(value);
			}
			return value.format(format);
		},
		relativeTime(value) {
			if (!dayjs.isDayjs(value)) {
				value = dayjs(value);
			}
			return value.fromNow();
		},
		timeDiffHumanized(from, to = dayjs()) {
			return to.from(from, true);
		},
	},
};
