import merge from 'lodash/merge';

const CHART_COLORS = {
	black: '0, 0, 0',
	white: '255, 255, 255',
	grey: '165, 176, 190',
	blue: '30, 144, 255',
	green: '46, 213, 140',
	red: '251, 52, 89',
	yellow: '255, 198, 2',
};

function colorToRgbCode(color) {
	return CHART_COLORS[color] ?? '0, 0, 0';
}

export default class ChartOptions {
	static options(options = []) {
		return merge(...options);
	}
	static rgbColor(color) {
		return `rgb(${colorToRgbCode(color)})`;
	}
	static rgbaColor(color, alpha = 0.2) {
		return `rgba(${colorToRgbCode(color)}, ${alpha})`;
	}
	static radarDatasetColor(color) {
		return {
			fill: true,
			backgroundColor: ChartOptions.rgbaColor(color),
			borderColor: ChartOptions.rgbColor(color),
			pointBackgroundColor: ChartOptions.rgbColor(color),
			pointBorderColor: '#fff',
			pointHoverBackgroundColor: '#fff',
			pointHoverBorderColor: ChartOptions.rgbColor(color),
		};
	}
}
