<template>
	<div v-click-outside="onClickOutside" class="mega-menu-wrapper">
		<div class="container pt-5">
			<div class="menu-grid">
				<router-link
					v-for="(schoolClass, i) in menuSchoolClasses"
					:key="i"
					:to="{
						name: 'school_class_details',
						params: { school_class_id: schoolClass.id },
					}"
					class="is-flex is-justify-content-center"
				>
					<SchoolClassCard :school-class="schoolClass" />
				</router-link>
				<router-link
					v-tippy="{ content: 'Opret et hold' }"
					:to="{ name: 'create_school_class' }"
					class="is-flex is-justify-content-center"
				>
					<SchoolClassCreateCard />
				</router-link>
			</div>

			<div class="mt-5 mb-4 is-flex is-justify-content-center">
				<router-link
					:to="{ name: 'school_classes' }"
					active-class=""
					class="button is-medium"
					@click.native="$emit('close')"
					>Se alle hold</router-link
				>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import SchoolClassCard from '@/components/schoolclass/SchoolClassCard';
import SchoolClassCreateCard from '@/components/schoolclass/SchoolClassCreateCard';

export default {
	name: 'SchoolClassMegaMenu',
	components: {
		SchoolClassCard,
		SchoolClassCreateCard,
	},
	computed: {
		...mapState('school', ['schoolClasses']),
		menuSchoolClasses() {
			return this.schoolClasses.slice(0, 7);
		},
	},
	methods: {
		onClickOutside() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
@import '@/assets/sass/abstracts/mixins';
.mega-menu-wrapper {
	position: absolute;
	z-index: 30;
	width: 100%;
	background-color: white;
	box-shadow: 28px 28px 25px 0 rgba(40, 51, 56, 0.04);

	.menu-grid {
		display: grid;
		grid-row-gap: 2rem;
		grid-template-columns: repeat(1, 1fr);
		@include mixin-tablet-up {
			grid-template-columns: repeat(2, 1fr);
		}
		@include mixin-desktop-up {
			grid-template-columns: repeat(3, 1fr);
		}
		@include mixin-fullhd-up {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}
</style>
