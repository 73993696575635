<template>
	<div class="field is-horizontal">
		<div v-if="label" class="field-label" :class="[`is-${size}`]">
			<label class="label" v-text="label" />
		</div>
		<div class="field-body">
			<BaseField
				:error="error"
				:has-icon-left="hasIconLeft"
				:has-icon-right="hasIconRight"
				:is-narrow="isNarrow"
			>
				<slot />
			</BaseField>
		</div>
	</div>
</template>

<script>
import BaseField from './BaseField';
export default {
	name: 'BaseFieldHorizontal',
	components: { BaseField },
	props: {
		size: {
			type: String,
			default: 'medium',
		},
		label: {
			type: String,
			default: null,
		},
		error: {
			type: String,
			default: null,
		},
		isNarrow: {
			type: Boolean,
			default: false,
		},
		hasIconLeft: {
			type: Boolean,
			default: false,
		},
		hasIconRight: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
