var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"tag has-text-weight-bold",class:[
		`is-${_vm.size}`,
		{
			'is-rounded': _vm.rounded,
			'is-clickable': _vm.clickable,
			'has-icon': _vm.removeable,
		},
		..._vm.colorClasses,
	],style:(_vm.colorCode ? { 'background-color': _vm.colorCode } : {})},[_c('div',{staticClass:"is-flex is-align-items-center is-justify-content-space-between"},[_c('span',[_vm._t("default")],2),(_vm.removeable)?_c('BaseIcon',{attrs:{"icon":"cross","color":"white","size":2,"no-padding":""}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }