<template>
	<div class="exercise-image-outer-container is-flex-shrink-0" :class="{ 'is-hoverable': hoverable }">
		<div class="exercise-image-container">
			<img :src="iconUrl" class="exercise-image" :class="classes" />
			<div v-if="withDifficultyIcon" class="exercise-icon-container">
				<ExerciseIcon :type="icon" size="medium" stroked />
			</div>
		</div>
	</div>
</template>

<script>
import icon from '@/mixins/icon';
import ExerciseIcon from '@/components/exercises/ExerciseIcon';
import Section from '@/models/section/Section';

export default {
	name: 'ExerciseImage',
	components: { ExerciseIcon },
	mixins: [icon],
	props: {
		exercise: {
			type: Object,
			required: true,
		},
		size: {
			type: String,
			default: 'medium',
		},
		restricted: {
			type: Boolean,
			default: false,
		},
		withDifficultyIcon: {
			type: Boolean,
			default: false,
		},
		hoverable: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		classes() {
			const classes = [`is-${this.size}`];

			if (this.restricted) {
				classes.push('is-greyscale');
			}

			return classes;
		},
		icon() {
			let difficulty = 1;
			if (this.exercise instanceof Section) {
				difficulty = this.exercise.getDifficulty();
			} else if (this.exercise?.conf?.difficulty) {
				difficulty = this.exercise?.conf?.difficulty;
			}
			return this.getExerciseIcon(difficulty);
		},
		iconUrl() {
			if (this.exercise instanceof Section) {
				return this.exercise.getIconUrl();
			}

			return this.exercise.icon_url;
		},
	},
};
</script>

<style lang="scss" scoped>
.exercise-image-container {
	position: relative;

	.exercise-icon-container {
		position: absolute;
		bottom: -5px;
		right: -5px;
	}
	.exercise-image {
		&.is-small {
			width: 2rem;
			height: 2rem;
		}
		&.is-medium {
			width: 4rem;
			height: 4rem;
		}
		&.is-large {
			width: 5rem;
			height: 5rem;
		}

		// See https://www.coderomeos.org/utils/vuejs-css3-image-filter/
		&.is-greyscale {
			filter: sepia(0.3) contrast(1.1) brightness(1.1) grayscale(1) opacity(0.3);
		}
		&:not(.is-greyscale) {
			transition: filter 200ms ease;
		}
	}
}
.exercise-image-outer-container.is-hoverable:hover {
	padding-bottom: 0.25rem;
	margin-top: -0.25rem;
	.exercise-image {
		filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.5));
	}
}
</style>
