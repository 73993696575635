import http from './http';
import dayjs from 'dayjs';

export function createScreening(schoolId, classId, data) {
	return http.post(`/schools/${schoolId}/classes/${classId}/homework`, data).then(resp => resp.data);
}

export function getScreeningCreateFlow(schoolId, id) {
	return http.get(`/schools/${schoolId}/user/screenings/create/${id}`).then(resp => resp.data.data);
}

export function getSchoolScreeningAssignmentType(schoolId, typeId) {
	return http.get(`/schools/${schoolId}/user/screeningassignments/type/${typeId}`).then(resp => resp.data.data);
}

export function getScreeningAssignments(schoolId) {
	return http.get(`/schools/${schoolId}/user/screeningassignments`).then(resp => resp.data.data);
}

export function getUserScreenings(schoolId, archived, page) {
	return http
		.get(`/schools/${schoolId}/user/screenings`, {
			params: {
				archived: archived,
				page: page,
			},
		})
		.then(resp => resp.data);
}

export function getColleagueScreenings(schoolId, page) {
	return http
		.get(`/schools/${schoolId}/user/screenings/colleague`, {
			params: {
				page: page,
			},
		})
		.then(resp => resp.data);
}

export function getScreeningHomeworkCounts(schoolId) {
	return http.get(`/schools/${schoolId}/user/screenings/counts`).then(resp => resp.data.data);
}

export function getClassScreenings(schoolId, schoolClassId) {
	return http
		.get(`/schools/${schoolId}/user/screenings/class`, {
			params: {
				class_id: schoolClassId,
			},
		})
		.then(resp => resp.data.data);
}

export function getSchoolScreeningAssignment(schoolId, id, homeworkId) {
	return http
		.get(`/schools/${schoolId}/screeningassignments/${id}`, { params: { homeworkId } })
		.then(({ data, headers }) => {
			const screening = data.data;
			const homework = data.meta?.homework?.length ? data.meta.homework : [];
			return {
				screening,
				homework,
				apiTime: dayjs(headers.date),
			};
		});
}
