<template>
	<g>
		<!-- eslint-disable max-len -->
		<circle class="circle-blue" cx="21" cy="21" r="13" fill="url(#paint0_linear_1787_7)" />
		<path
			class="avatar-head"
			d="M21 14C18.2181 14 15.963 16.2833 15.963 19.1C15.963 21.9167 18.2181 24.2 21 24.2C23.7819 24.2 26.037 21.9167 26.037 19.1C26.037 16.2833 23.7819 14 21 14Z"
			fill="white"
		/>
		<path
			class="avatar-body"
			d="M23.9725 26.4689C22.0033 26.1507 19.9967 26.1507 18.0275 26.4689L17.8164 26.503C15.0417 26.9514 13 29.3747 13 32.2196C13 33.7552 14.2294 35 15.746 35H26.254C27.7706 35 29 33.7552 29 32.2196C29 29.3747 26.9583 26.9514 24.1836 26.503L23.9725 26.4689Z"
			fill="white"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_1787_7"
				x1="21"
				y1="8"
				x2="21"
				y2="34"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#01A0FF" />
				<stop offset="1" stop-color="#01CBFF" />
			</linearGradient>
		</defs>
		<!-- eslint-enable max-len -->
	</g>
</template>

<script>
export default {
	name: 'IconAvatar',
};
</script>
