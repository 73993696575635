<template>
	<BaseHoverable>
		<template #default="slotProps">
			<SchoolClassCardContainer
				class="has-background-transparent has-border has-border-radius-large is-relative"
				:class="`has-border-${currentColor(slotProps.isHovered)}`"
			>
				<template #title>
					<div class="is-flex is-align-items-center">
						<BaseSkeleton
							rounded
							width="3rem"
							height="2rem"
							:style="{ width: '3rem' }"
							:animate="false"
							:color-name="currentColor(slotProps.isHovered)"
						/>
						<BaseSkeleton
							size="normal"
							width="120px"
							:animate="false"
							:color-name="currentColor(slotProps.isHovered)"
							class="ml-3 mt-0"
							rounded
						/>
					</div>
				</template>

				<template #level>
					<BaseSkeleton
						rounded
						width="3rem"
						height="2rem"
						:animate="false"
						:color-name="currentColor(slotProps.isHovered)"
						class="m-4"
					/>
				</template>

				<template #avatars>
					<div
						class="has-border-top-left-radius-large has-border-left has-border-top p-3 is-flex is-align-items-center"
						:class="`has-border-${currentColor(slotProps.isHovered)}`"
					>
						<BaseSkeleton
							v-for="i in 4"
							:key="i"
							width="2rem"
							height="2rem"
							:animate="false"
							:color-name="currentColor(slotProps.isHovered)"
							:style="{ 'margin-right': '-0.6rem' }"
							class="has-border has-border-transparent mt-0"
							circle
						/>
					</div>
				</template>

				<div
					class="create-icon-container is-flex is-justify-content-center is-flex-direction-column"
				>
					<div class="is-flex is-justify-content-center">
						<BaseIcon
							icon="plus"
							class="create-icon"
							:color="slotProps.isHovered ? 'white' : 'blue'"
							:class="`has-background-${
								slotProps.isHovered ? 'blue' : 'white'
							}`"
							:size="9"
							show-background
						/>
					</div>
				</div>
			</SchoolClassCardContainer>
		</template>
	</BaseHoverable>
</template>

<script>
import BaseHoverable from '@/components/base/BaseHoverable';
import BaseIcon from '@/components/base/BaseIcon';
import SchoolClassCardContainer from './SchoolClassCardContainer';
import BaseSkeleton from '@/components/base/load/BaseSkeleton';

export default {
	name: 'SchoolClassCreateCard',
	components: {
		BaseHoverable,
		BaseIcon,
		SchoolClassCardContainer,
		BaseSkeleton,
	},
	props: {
		color: {
			type: String,
			default: 'grey-lighterer',
		},
		hoverColor: {
			type: String,
			default: 'grey-lighter',
		},
	},
	methods: {
		currentColor(isHovered) {
			return isHovered ? this.hoverColor : this.color;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';

.create-icon-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	.create-icon {
		box-shadow: $box-shadow;
	}
}
</style>
