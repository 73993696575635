import { newDate } from '@/utils/date';

export default class UserNotification {
	constructor(notification) {
		this.notification = notification;
	}

	get id() {
		return this.notification.id;
	}

	get type() {
		return this.notification.type;
	}

	get data() {
		return this.notification.data;
	}

	get created_at() {
		return newDate(this.notification.created_at);
	}

	get updated_at() {
		return newDate(this.notification.updated_at);
	}

	get read_at() {
		return this.isRead() ? newDate(this.notification.read_at) : null;
	}

	isRead() {
		return !!this.notification.read_at;
	}

	get title() {
		return '';
	}

	get body() {
		return '';
	}

	get actionText() {
		return '';
	}

	get actionRoute() {
		return null;
	}
}
