<template>
	<div class="field" :class="{ 'is-narrow': isNarrow, 'is-inline-flex': isInline }">
		<div v-if="label" class="field-label" :class="[`is-${size}`]">
			<label class="label" v-text="label" />
		</div>
		<div
			class="control"
			:class="{
				'has-icons-left': hasIconLeft,
				'has-icons-right': hasIconRight,
				'is-inline-flex': isInline,
			}"
		>
			<slot />
		</div>
		<p v-if="hasError" class="help is-danger" v-text="error" />
	</div>
</template>

<script>
export default {
	name: 'BaseField',
	props: {
		label: {
			type: String,
			default: null,
		},
		size: {
			type: String,
			default: 'medium',
		},
		isNarrow: {
			type: Boolean,
			default: false,
		},
		error: {
			type: String,
			default: null,
		},
		hasIconLeft: {
			type: Boolean,
			default: false,
		},
		hasIconRight: {
			type: Boolean,
			default: false,
		},
		isInline: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		hasError() {
			return !!this.error;
		},
	},
};
</script>

<style lang="scss" scoped>
.field {
	&.is-inline-flex {
		vertical-align: super;
	}
}
</style>
