<template>
	<g>
		<g class="icon-frame" :class="{ 'is-active': isActive, 'is-hovered': isHovered }">
			<!-- eslint-disable max-len -->
			<path
				class="arrow-top"
				d="M9.89 8.25998C9.87385 7.9797 9.65153 7.75738 9.37125 7.74123L5.21375 7.5017L4.97422 3.3442C4.95807 3.06391 4.73575 2.84159 4.45547 2.82545C4.17519 2.8093 3.97709 3.0074 3.99324 3.28768L4.25968 7.91232C4.26775 8.05246 4.34455 8.17328 4.44336 8.27209C4.54217 8.3709 4.66299 8.4477 4.80313 8.45577L9.42777 8.72221C9.68604 8.76037 9.90615 8.54026 9.89 8.25998Z"
			/>
			<path
				class="arrow-bottom"
				d="M30.11 33.74C30.1261 34.0203 30.3485 34.2426 30.6287 34.2588L34.7863 34.4983L35.0258 38.6558C35.0419 38.9361 35.2642 39.1584 35.5445 39.1746C35.8248 39.1907 36.0229 38.9926 36.0068 38.7123L35.7403 34.0877C35.7322 33.9475 35.6554 33.8267 35.5566 33.7279C35.4578 33.6291 35.337 33.5523 35.1969 33.5442L30.5722 33.2778C30.314 33.2396 30.0938 33.4597 30.11 33.74Z"
			/>
			<circle class="frame-circle" cx="21" cy="21" r="20" stroke-width="2" />
			<!-- eslint-enable max-len -->
		</g>
		<g><slot :is-active="isActive" /></g>
	</g>
</template>

<script>
export default {
	name: 'IconFrame',
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
		isHovered: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';

.icon-frame {
	transition: transform 250ms ease;
	transform-origin: center center;
	path {
		fill: $grey-lighter;
		stroke: $grey-lighter;
		transition: fill 250ms linear;
		transition: stroke 250ms linear;
	}
	circle {
		stroke: $grey-lighter;
		transition: stroke 250ms linear;
	}
	&.is-active {
		path {
			fill: $blue-light;
			stroke: $blue-light;
		}
		circle {
			stroke: $blue-light;
		}
	}
	&.is-hovered {
		transform: rotate(-90deg);
	}
}
</style>
