<template>
	<div>
		<slot />
	</div>
</template>

<script>
export default {
	name: 'BaseTabsContainer',
	provide() {
		return {
			tabsContainer: this,
		};
	},
	props: {
		active: {
			type: String,
			default: null,
		},
	},
};
</script>
