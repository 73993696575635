import http from './http';

export function doSearch(schoolId, query, page, types, courses, materials) {
	const params = { query };
	if (page) {
		params.page = page;
	}
	if (types) {
		params.types = types;
	}
	if (courses) {
		params.courses = courses;
	}
	if (materials) {
		params.materials = materials;
	}

	return http.get(`/schools/${schoolId}/search`, { params }).then(resp => resp.data);
}

export function getSearchConfig(schoolId) {
	return http.get(`/schools/${schoolId}/search/config`).then(resp => resp.data.data);
}

export function getExercisesSearchConfig(schoolId, params) {
	return http.get(`/schools/${schoolId}/search/config/exercises`, { params }).then(resp => resp.data.data);
}
