import Answer from './Answer';
import ItemCollection from '@/libs/ItemCollection';

export default class AnswerCollection extends ItemCollection {
	newItem(item = {}) {
		if (item instanceof Answer) {
			return item;
		}
		return new Answer(item);
	}

	byTaskId(taskId) {
		return new AnswerCollection(this.items.filter(item => item.task_id === taskId));
	}
}
