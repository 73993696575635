import { getSchoolClass, getSchoolTeachers, getSchoolClassLevels } from '@/api/school';
import { getSchoolCourses } from '@/api/course';
import { getClassHomework } from '@/api/homework';
import { getClassScreenings } from '@/api/screening';
import { getBooksByCourse } from '@/api/book';
import {
	getSchoolClassStudentCourseTotals,
	getSchoolClassResultsOfBooks,
	getSchoolClassResultsOfAssignmentCourseTypes,
	getSchoolClassScreeningFocusareas,
	getSchoolClassHomeworkStudentProgresses,
} from '@/api/results';
import { getAssignmentsByCourse } from '@/api/assignment';
import { getDesignationDefaults } from '@/api/designation';
import Homework from '@/models/homework/Homework';
import { getAvgOfValues } from '@/utils/math';

const state = () => ({
	loading: false,
	loadingCourseTotals: [],

	// Base/school data
	designationDefaults: null,
	schoolClass: null,
	courseStudentTotals: {},
	schoolCourses: [],
	schoolTeachers: [],
	schoolClassLevels: [],

	// Homework
	homeworkByCourse: null,
	screeningHomeworkByCourse: null,
	studentHomeWorkProgresses: null,
	screeningFocusareas: null,

	// Books
	schoolBooksByCourse: null,
	bookResults: {},

	// Assignments
	schoolAssignmentData: null,
	assignmentCourseTypeResults: {},

	// Student results
	studentResultsOpenRef: null,
});

function flattenHomeworkByCourse(homeworkByCourse) {
	return Object.values(homeworkByCourse).reduce((acc, homework) => [...acc, ...homework], []);
}
function getTotalDurationOfManyHomework(entries) {
	return entries.reduce(
		(duration, homework) =>
			duration + (homework.type === 'training' ? homework.training_duration : homework.duration),
		0,
	);
}
function getTotalNormalpagesOfManyHomework(entries) {
	return entries.reduce((value, homework) => value + homework.normal_pages, 0);
}
function getAvgProgressOfManyHomework(entries) {
	return getAvgOfValues(entries.map(homework => homework.progress));
}
function getAvgPointsOfManyHomework(entries) {
	return getAvgOfValues(entries.map(homework => homework.points || 0));
}

const getters = {
	isLoading: state => state.loading,
	isLoadingTotals: state => state.loadingCourseTotals.length > 0,
	isLoadingCourseTotals: state => courseId => state.loadingCourseTotals.includes(courseId),
	schoolClass: state => state.schoolClass,
	getStudentTotalPoints: state => user =>
		Object.entries(state.courseStudentTotals).reduce(
			(points, courseEntries) => points + (courseEntries[1][user.id]?.total_points ?? 0),
			0,
		),
	getCourseStudentsTotals: state => courseId =>
		courseId in state.courseStudentTotals ? state.courseStudentTotals[courseId] : {},
	hasLoadedCourseStudentsTotals: state => courseId => courseId in state.courseStudentTotals,
	getStudentHomeworkProgress: state => user =>
		state.studentHomeWorkProgresses && user.id in state.studentHomeWorkProgresses
			? state.studentHomeWorkProgresses[user.id]
			: 0,
	students: (state, getters) =>
		(state.schoolClass?.students ?? []).map(student => ({
			...student,
			totalPoints: getters.getStudentTotalPoints(student),
			homeworkProgress: getters.getStudentHomeworkProgress(student),
		})),
	hasLoadedHomeworkByCourse: state => state.homeworkByCourse !== null,
	getCourseHomework: state => courseId =>
		state.homeworkByCourse && courseId in state.homeworkByCourse ? state.homeworkByCourse[courseId] : [],
	allHomework: state => flattenHomeworkByCourse(state.homeworkByCourse ?? {}),
	homeworkTotalDuration: (_, getters) => getTotalDurationOfManyHomework(getters.allHomework),
	homeworkTotalNormalpages: (_, getters) => getTotalNormalpagesOfManyHomework(getters.allHomework),
	homeworkAvgProgress: (_, getters) => getAvgProgressOfManyHomework(getters.allHomework),
	homeworkAvgPoints: (_, getters) => getAvgPointsOfManyHomework(getters.allHomework),
	hasLoadedScreeningHomeworkByCourse: state => state.screeningHomeworkByCourse !== null,
	getCourseScreeningHomework: state => courseId =>
		state.screeningHomeworkByCourse && courseId in state.screeningHomeworkByCourse
			? state.screeningHomeworkByCourse[courseId]
			: [],
	allScreeningHomework: state => flattenHomeworkByCourse(state.screeningHomeworkByCourse ?? {}),
	screeningHomeworkTotalDuration: (_, getters) => getTotalDurationOfManyHomework(getters.allScreeningHomework),
	screeningHomeworkNormalpages: (_, getters) => getTotalNormalpagesOfManyHomework(getters.allScreeningHomework),
	screeningHomeworkAvgPoints: (_, getters) => getAvgPointsOfManyHomework(getters.allScreeningHomework),
	screeningFocusareas: state => state.screeningFocusareas || [],
	hasLoadedSchoolBooks: state => state.schoolBooksByCourse !== null,
	getSchooolCourseBooks: state => courseId =>
		state.schoolBooksByCourse && courseId in state.schoolBooksByCourse
			? state.schoolBooksByCourse[courseId]
			: [],
	hasLoadedBookResult: state => book => book.id in state.bookResults,
	getBookResult: state => book => state.bookResults[book.id] ?? null,
	hasLoadedSchoolAssignmentData: state => state.schoolAssignmentData !== null,
	getCourseAssignmentData: state => courseId =>
		state.schoolAssignmentData && courseId in state.schoolAssignmentData
			? state.schoolAssignmentData[courseId]
			: [],
	hasLoadedAssignmentCourseTypeResults: state => course => course.id in state.assignmentCourseTypeResults,
	getAssignmentCourseTypeResults: state => courseId => state.assignmentCourseTypeResults[courseId] ?? null,
};

const actions = {
	reset({ commit }) {
		commit('reset');
	},
	loadSchoolClassWithBaseStats({ commit, getters, dispatch }, { schoolId, schoolClassId, loading }) {
		if (loading) {
			commit('setLoading', true);
		}
		getSchoolClass(schoolId, schoolClassId)
			.then(schoolClass => {
				commit('setSchoolClass', schoolClass);
				commit('setLoading', false);
				return Promise.all(
					schoolClass.courses
						.filter(course => !getters.hasLoadedCourseStudentsTotals(course.id))
						.map(course =>
							dispatch('loadSchoolClassStudentCourseTotals', {
								schoolClassId: schoolClass.id,
								courseId: course.id,
							}),
						),
				);
			})
			.finally(() => {
				commit('setLoading', false);
			});
	},
	loadSchoolClassStudentCourseTotals({ rootState, commit }, { schoolClassId, courseId }) {
		commit('setLoadingCourseTotals', { courseId, value: true });
		return getSchoolClassStudentCourseTotals(rootState.user.schoolId, schoolClassId, courseId)
			.then(totals => {
				commit('setStudentCourseTotals', { courseId, totals });
			})
			.finally(() => {
				commit('setLoadingCourseTotals', { courseId, value: false });
			});
	},
	loadSchoolOptions({ commit }, schoolId) {
		return Promise.all([
			getSchoolTeachers(schoolId).then(teachers => {
				commit('setSchoolTeachers', teachers);
			}),
			getSchoolClassLevels(schoolId).then(levels => {
				commit('setSchoolClassLevels', levels);
			}),
			getSchoolCourses(schoolId).then(courses => {
				commit('setSchoolCourses', courses);
			}),
			getDesignationDefaults().then(data => {
				commit('setDesignationDefaults', data);
			}),
		]);
	},
	loadSchoolBooks({ rootState, commit }) {
		return getBooksByCourse(rootState.user.schoolId).then(entries => {
			commit(
				'setSchoolBooksByCourse',
				Object.fromEntries(entries.map(({ course, books }) => [course.id, books])),
			);
		});
	},
	loadSchoolAssignmentData({ rootState, commit }) {
		return getAssignmentsByCourse(rootState.user.schoolId).then(entries => {
			commit(
				'setSchoolAssignmentData',
				Object.fromEntries(entries.map(({ id, assignmentTypes }) => [id, assignmentTypes])),
			);
		});
	},
	loadSchoolClassHomework({ rootState, commit }, schoolClassId) {
		return getClassHomework(rootState.user.schoolId, schoolClassId).then(({ courses }) => {
			const entries = {};
			for (const courseId in courses) {
				entries[courseId] = courses[courseId].homework.map(homework => new Homework(homework));
			}
			commit('setHomeworkByCourse', entries);
		});
	},
	loadSchoolClassScreeningHomework({ rootState, commit }, schoolClassId) {
		return getClassScreenings(rootState.user.schoolId, schoolClassId).then(courses => {
			const entries = {};
			for (const courseId in courses) {
				entries[courseId] = courses[courseId].screenings.map(
					homework => new Homework(homework),
				);
			}
			commit('setScreeningHomeworkByCourse', entries);
		});
	},
	loadSchoolClassHomeworkStudentProgresses({ rootState, commit }, schoolClassId) {
		return getSchoolClassHomeworkStudentProgresses(rootState.user.schoolId, schoolClassId).then(
			progresses => {
				commit('setStudentHomeWorkProgresses', progresses);
			},
		);
	},
	loadSchoolClassScreeningFocusareas({ rootState, commit }, schoolClassId) {
		return getSchoolClassScreeningFocusareas(rootState.user.schoolId, schoolClassId).then(entries => {
			commit('setSscreeningFocusareas', entries);
		});
	},
	loadSchoolClassResultsOfBooks({ rootState, state, commit, getters }, { schoolClassId, books }) {
		const bookIds = books.filter(book => !getters.hasLoadedBookResult(book)).map(book => book.id);
		if (bookIds.length === 0) {
			return Promise.resolve();
		}
		return getSchoolClassResultsOfBooks(rootState.user.schoolId, schoolClassId, bookIds).then(results => {
			commit('setBookResults', { ...state.bookResults, ...results });
		});
	},
	loadSchoolClassResultsOfAssignmentCourseTypes(
		{ rootState, state, commit, getters },
		{ schoolClassId, courses },
	) {
		const courseIds = courses
			.filter(course => !getters.hasLoadedAssignmentCourseTypeResults(course))
			.map(course => course.id);
		if (courseIds.length === 0) {
			return Promise.resolve();
		}
		return getSchoolClassResultsOfAssignmentCourseTypes(
			rootState.user.schoolId,
			schoolClassId,
			courseIds,
		).then(results => {
			commit('setAssignmentCourseTypeResults', { ...state.assignmentCourseTypeResults, ...results });
		});
	},
};

const mutations = {
	reset(state) {
		state.schoolClass = null;
		state.courseStudentTotals = {};
		state.schoolTeachers = [];
		state.schoolClassLevels = [];
		state.schoolCourses = [];
		state.homeworkByCourse = null;
		state.screeningHomeworkByCourse = null;
		state.studentHomeWorkProgresses = null;
		state.screeningFocusareas = null;
		state.schoolBooksByCourse = null;
		state.bookResults = {};
		state.assignmentCourseTypeResults = {};
		state.schoolAssignmentData = null;
	},
	setLoading(state, value = false) {
		state.loading = value;
	},
	setLoadingCourseTotals(state, { courseId, value = false }) {
		if (value) {
			if (!state.loadingCourseTotals.includes(courseId)) {
				state.loadingCourseTotals.push(courseId);
			}
			return;
		}
		state.loadingCourseTotals = state.loadingCourseTotals.filter(id => id !== courseId);
	},
	setSchoolClass(state, schoolClass) {
		state.schoolClass = schoolClass;
	},
	setStudentCourseTotals(state, { courseId, totals }) {
		state.courseStudentTotals = {
			...state.courseStudentTotals,
			[courseId]: totals,
		};
	},
	setHomeworkByCourse(state, entries) {
		state.homeworkByCourse = entries;
	},
	setScreeningHomeworkByCourse(state, entries) {
		state.screeningHomeworkByCourse = entries;
	},
	setSchoolTeachers(state, teachers) {
		state.schoolTeachers = teachers;
	},
	setSchoolClassLevels(state, levels) {
		state.schoolClassLevels = levels;
	},
	setSchoolCourses(state, courses) {
		state.schoolCourses = courses;
	},
	setSchoolBooksByCourse(state, booksByCourse) {
		state.schoolBooksByCourse = booksByCourse;
	},
	setSchoolAssignmentData(state, data) {
		state.schoolAssignmentData = data;
	},
	setDesignationDefaults(state, data) {
		state.designationDefaults = data;
	},
	setStudentHomeWorkProgresses(state, progresses) {
		state.studentHomeWorkProgresses = progresses;
	},
	setSscreeningFocusareas(state, entries) {
		state.screeningFocusareas = entries.map(entry => ({
			...entry,
			progress: parseFloat(entry.progress),
			points: parseFloat(entry.points),
		}));
	},
	setBookResults(state, results) {
		state.bookResults = results;
	},
	setAssignmentCourseTypeResults(state, results) {
		state.assignmentCourseTypeResults = results;
	},
	setStudentResultsOpenRef(state, openRef = null) {
		state.studentResultsOpenRef = openRef;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
