import {
	getBooksByCourse,
	getBook,
	getBookChapterSections,
	getBookSitemap,
	getSchoolBook,
	getSchoolBookChapter,
	getSchoolBookChapterSection,
	getMenuBooks,
} from '@/api/book';

const state = () => ({
	menuBooks: [],
});

const getters = {};

const actions = {
	getBooksByCourse({ rootState }, { all }) {
		return getBooksByCourse(rootState.user.schoolId, all);
	},
	getBook(_, { id, eagerLoad }) {
		return getBook(id, eagerLoad);
	},
	getBookChapterSections(_, { book_id, chapter_id }) {
		return getBookChapterSections(book_id, chapter_id);
	},
	getBookSitemap(_, { book_id }) {
		return getBookSitemap(book_id);
	},
	getSchoolBook({ rootState }, { id }) {
		return getSchoolBook(rootState.user.schoolId, id);
	},
	getSchoolBookChapter({ rootState }, { book_id, chapter_id }) {
		return getSchoolBookChapter(rootState.user.schoolId, book_id, chapter_id);
	},
	getSchoolBookChapterSection({ rootState }, { book_id, chapter_id, section_id }) {
		return getSchoolBookChapterSection(rootState.user.schoolId, book_id, chapter_id, section_id);
	},
	getMenuBooks({ commit, rootState }) {
		return getMenuBooks(rootState.user.schoolId).then(books => {
			commit('setMenuBooks', books);
			return books;
		});
	},
};

const mutations = {
	setMenuBooks(state, books) {
		state.menuBooks = books;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
