<template>
	<div class="assignment-type-card p-4 is-flex is-justify-content-stretch is-align-items-center">
		<div class="is-flex-shrink-0 image-container is-stacked">
			<img :class="{ 'no-access': isRestricted }" :src="assignmentGroup.image" />
		</div>

		<div class="ml-4">
			<p class="title is-6">{{ $designate(assignmentGroup.title) }}</p>
			<div class="subtitle is-10" v-html="$designate(assignmentGroup.description)"></div>
		</div>
	</div>
</template>

<script>
import designation from '@/mixins/designation';

export default {
	name: 'AssignmentTypeCard',
	mixins: [designation],
	props: {
		assignmentGroup: {
			type: Object,
			required: true,
		},
	},
	computed: {
		isRestricted() {
			return !!this.assignmentGroup.bundle_access?.is_restricted;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';

.assignment-type-card {
	border-radius: $radius;

	&:hover {
		background-color: #f0f8ff;
	}

	.image-container {
		width: 65px;

		img {
			border-radius: $radius;
			width: 100%;
			vertical-align: middle;

			&.no-access {
				filter: grayscale(1) contrast(0.6) brightness(1.3);
			}
		}

		&.is-stacked {
			border-bottom: 0.25rem solid $grey-light;
			border-radius: $radius;
		}
	}
}
</style>
