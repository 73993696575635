import {
	getCourse,
	getCourses,
	getSchoolCourses,
	getSchoolClassCourseDesignation,
	addCourseDesignation,
} from '@/api/course';

const state = {};

const getters = {};

const actions = {
	getCourse(_, id) {
		return getCourse(id);
	},
	getCourses() {
		return getCourses();
	},
	getSchoolCourses(_, schoolId) {
		return getSchoolCourses(schoolId);
	},
	getSchoolClassCourseDesignation(_, { school_class_id, course_id }) {
		return getSchoolClassCourseDesignation(school_class_id, course_id);
	},
	addCourseDesignation(_, { courseId, identifierType, identifier, data }) {
		return addCourseDesignation(courseId, identifierType, identifier, data);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
