<template>
	<div class="background">
		<svg class="svg" viewBox="0 0 1440 927" xmlns="http://www.w3.org/2000/svg">
			{{ /* eslint-disable max-len */ }}
			<KinesisElement tag="g" axis="x" :strength="-2">
				<path
					class="st1"
					d="M179.3,891.1V673.3c0-34.5,13.7-67.5,37.8-91.6l0,0c20-20,31.2-47.1,31.2-75.7v-44.1
		c0-28.2,11.1-55.6,31.2-75.7l58.6-58.6c20-20,47.1-31.2,75.7-31.2h96.1c28.2,0,55.6,11.1,75.7,31.2l41.2,41.2
		c20,20,47.1,31.2,75.7,31.2H735c28.2,0,55.6,11.1,75.7,31.2l6.7,6.7c20,20,47.1,31.2,75.7,31.2h84.6c28.2,0,55.6,11.1,75.7,31.2
		l18.2,18.2c20,20,31.2,47.1,31.2,75.7V644c0,28.2,11.1,55.6,31.2,75.7l6.7,6.7c20,20,31.2,47.1,31.2,75.7v106.5H179.3V891.1z"
				/>
				<g>
					<path
						class="st2"
						d="M459.8,526.4h-58.6c-6.3,0-11.5-5.2-11.5-11.5v-21.5c0-6.3-5.2-11.5-11.5-11.5h-80.9
			c-6.3,0-11.5,5.2-11.5,11.5v28.9c0,6.3-5.2,11.5-11.5,11.5h-51.2c-6.3,0-11.5,5.2-11.5,11.5v136.9h103.9v-51.9h51.9v44.5H449v44.5
			h103.9V570.9h-70.1c-6.3,0-11.5-5.2-11.5-11.5v-21.5C471.3,531.6,466.1,526.4,459.8,526.4z"
					/>
					<path
						class="st2"
						d="M886.8,542.4v-24.5c0-3.7-3-6.3-6.3-6.3h-91.3c-3.7,0-6.3,3-6.3,6.3V572c0,3.7-3,6.3-6.3,6.3H715
			c-3.7,0-6.3,3-6.3,6.3v135.8c0,3.7,3,6.3,6.3,6.3h97.6V660h74.2v37.1h103.9V555c0-3.7-3-6.3-6.3-6.3h-91.3
			C889.7,548.7,886.8,545.7,886.8,542.4z"
					/>
				</g>
				<path
					class="st3"
					d="M215.3,418.8h2.6c2.6,0,4.8-2.2,4.8-4.8v-2.6c0-2.6-2.2-4.8-4.8-4.8h-2.6c-2.6,0-4.8,2.2-4.8,4.8v2.6
		C210.1,416.6,212.3,418.8,215.3,418.8z"
				/>
				<g>
					<path
						class="st4"
						d="M442.7,192.5h2.6c2.6,0,4.8-2.2,4.8-4.8v-2.6c0-2.6-2.2-4.8-4.8-4.8h-2.6c-2.6,0-4.8,2.2-4.8,4.8v2.6
			C437.9,190.3,440.1,192.5,442.7,192.5z"
					/>
				</g>
				<path
					class="st3"
					d="M468.7,470.8h2.6c2.6,0,4.8-2.2,4.8-4.8v-2.6c0-2.6-2.2-4.8-4.8-4.8h-2.6c-2.6,0-4.8,2.2-4.8,4.8v2.6
		C463.8,468.5,466.1,470.8,468.7,470.8z"
				/>
				<path
					class="st5"
					d="M515.8,236.3L515.8,236.3c-8.5,3-15.2,10-16.7,18.9l-1.9,8.5c-0.4,2.2-3.3,2.2-3.7,0l-1.9-8.5
		c-1.9-8.9-8.2-16.3-16.7-18.9l0,0c-1.9-0.7-1.9-3,0-3.7l0,0c8.5-3,15.2-10,16.7-18.9l1.9-8.5c0.4-2.2,3.3-2.2,3.7,0l1.9,8.5
		c1.9,8.9,8.2,16.3,16.7,18.9l0,0C517.6,233.3,517.6,235.9,515.8,236.3z"
				/>
				<g>
					<path
						class="st4"
						d="M264.3,327.6L264.3,327.6c-6.3,2.2-10.8,7.4-12.2,13.7l-1.1,6.3c-0.4,1.5-2.6,1.5-2.6,0l-1.1-6.3
			c-1.1-6.3-5.9-11.5-12.2-13.7l0,0c-1.1-0.4-1.1-2.2,0-2.6l0,0c6.3-2.2,10.8-7.4,12.2-13.7l1.1-6.3c0.4-1.5,2.6-1.5,2.6,0l1.5,6.3
			c1.1,6.3,5.9,11.5,12.2,13.7l0,0C265.7,325.3,265.7,327.2,264.3,327.6z"
					/>
				</g>
				<g>
					<path
						class="st4"
						d="M778.1,349.8L778.1,349.8c-6.3,2.2-10.8,7.4-12.2,13.7l-1.1,6.3c-0.4,1.5-2.6,1.5-2.6,0l-1.1-6.3
			c-1.1-6.3-5.9-11.5-12.2-13.7l0,0c-1.1-0.4-1.1-2.2,0-2.6l0,0c6.3-2.2,10.8-7.4,12.2-13.7l1.1-6.3c0.4-1.5,2.6-1.5,2.6,0l1.1,6.3
			c1.1,6.3,5.9,11.5,12.2,13.7l0,0C779.6,347.6,779.6,349.4,778.1,349.8z"
					/>
				</g>
				<g>
					<path
						class="st4"
						d="M1155,496.7h2.6c2.6,0,4.8-2.2,4.8-4.8v-2.6c0-2.6-2.2-4.8-4.8-4.8h-2.6c-2.6,0-4.8,2.2-4.8,4.8v2.6
			C1150.2,494.5,1152.4,496.7,1155,496.7z"
					/>
				</g>
				<g>
					<path
						class="st4"
						d="M772.9,474.5h2.6c2.6,0,4.8-2.2,4.8-4.8V467c0-2.6-2.2-4.8-4.8-4.8h-2.6c-2.6,0-4.8,2.2-4.8,4.8v2.6
			C768.1,472.2,770.3,474.5,772.9,474.5z"
					/>
				</g>
				<path
					class="st3"
					d="M1282.6,739.3L1282.6,739.3c-6.3,2.2-10.8,7.4-12.2,13.7l-1.1,6.3c-0.4,1.5-2.6,1.5-2.6,0l-1.1-6.3
		c-1.1-6.3-5.9-11.5-12.2-13.7l0,0c-1.1-0.4-1.1-2.2,0-2.6l0,0c6.3-2.2,10.8-7.4,12.2-13.7l1.1-6.3c0.4-1.5,2.6-1.5,2.6,0l1.1,6.3
		c1.1,6.3,5.9,11.5,12.2,13.7l0,0C1284.1,737.1,1284.1,739,1282.6,739.3z"
				/>
			</KinesisElement>

			<KinesisElement tag="g" axis="x" :strength="2">
				<polygon class="st6" points="825.1,711.9 854.8,318.6 877.1,318.6 906.7,711.9 	" />
				<path
					class="st7"
					d="M880.8,214.8h-29.7c-6.3,0-11.1,4.8-11.1,11.1v85.3h51.9v-85.3C891.9,219.6,887.1,214.8,880.8,214.8z"
				/>
				<path
					class="st6"
					d="M862.2,214.8h-11.1c-6.3,0-11.1,4.8-11.1,11.1v85.3h51.9V289h-29.7V214.8z"
				/>
				<circle class="st3" cx="865.9" cy="359.5" r="77.9" />
				<polygon class="st7" points="862.2,459.6 854.8,711.9 906.7,711.9 887.8,459.6 	" />
				<path
					class="st8"
					d="M877.1,66.4h-22.3V42.6c0-3.3,2.6-5.9,5.9-5.9h10c3.3,0,5.9,2.6,5.9,5.9v23.7H877.1z"
				/>
				<rect x="854.8" y="66.4" class="st3" width="22.3" height="29.7" />
				<rect x="854.8" y="96.1" class="st8" width="22.3" height="29.7" />
				<rect x="854.8" y="185.1" class="st3" width="22.3" height="29.7" />
				<rect x="854.8" y="125.7" class="st3" width="22.3" height="29.7" />
				<rect x="854.8" y="155.4" class="st8" width="22.3" height="29.7" />
				<path
					class="st9"
					d="M941.6,378c1.1-4.8,1.9-9.6,2.2-14.8H830c-2.2,0-4.5-1.9-4.1-4.5c0-2.2,0.4-4.1,0.4-6.3s1.9-4.1,4.1-4.1h112.8
		c-0.7-5.2-1.9-10-3.7-14.8H835.9c-3,0-5.2-3.3-3.7-5.9c1.1-2.2,2.2-4.5,3.3-6.7c0.7-1.1,2.2-2.2,3.7-2.2h93.1
		c-3.3-5.6-7.4-10.4-11.9-14.8h-60.1c-4.1,0-5.9-5.2-2.6-7.8c7.8-5.6,17.1-10,26.7-12.2c-5.9-1.5-12.2-2.2-18.5-2.2
		c-43,0-77.9,34.9-77.9,77.9s34.9,77.9,77.9,77.9c6.3,0,12.6-0.7,18.5-2.2c-13.7-3.3-26.3-10.4-36-20c-2.6-2.6-0.7-7.4,3-7.4h75.7
		c3.7-4.5,6.7-9.6,9.3-14.8H835.5c-1.5,0-3.3-1.1-3.7-2.6c-1.1-2.2-1.9-4.5-2.6-6.7c-0.7-2.6,1.1-5.6,4.1-5.6H941.6z"
				/>
				<path
					class="st6"
					d="M892.6,496.7h-53.4c-3.7,0-6.7-3-6.7-6.7v-8.9c0-3.7,3-6.7,6.7-6.7h53.4c3.7,0,6.7,3,6.7,6.7v8.9
		C899.3,493.8,896.4,496.7,892.6,496.7z"
				/>
				<path
					class="st6"
					d="M892.6,251.9h-53.4c-3.7,0-6.7-3-6.7-6.7v-8.9c0-3.7,3-6.7,6.7-6.7h53.4c3.7,0,6.7,3,6.7,6.7v8.9
		C899.3,248.9,896.4,251.9,892.6,251.9z"
				/>
			</KinesisElement>

			<KinesisElement tag="g" axis="x" :strength="5">
				<g>
					<g>
						<path
							class="st10"
							d="M1084.5,734.9l-46.4-120.2c-1.5-4.1-5.6-6.7-9.6-6.7h-16.3h-0.4h-14.1c-4.5,0-8.2,2.6-10,6.7l-3.1,8.2h-1.3
				h-33.4h-26H731v-89V519l5.6-17.1c1.1-2.6-1.1-5.2-3.7-5.2H731v-66.8l5.2-17.4c1.1-2.2-0.7-4.8-3.3-4.8h-9.3l-35.2-91.6
				c-1.1-3-4.1-4.8-7-4.8h-3.1l-7-14.8h0.1l-7-14.8h-37.1h-14.8h-14.8l-12.3,29.9c-0.6-0.1-1.2-0.2-1.8-0.2h-2.2
				c-3.3,0-6.3,2.2-7.4,5.6l-18.3,58.9c-0.5,0.3-1,0.5-1.4,0.9l-2.2,1.5v9.3l-6.3,20.3h-9.6h-0.4c-2.2,0-4.1,2.6-3.3,4.8l5.9,17.4
				v66.8h-1.9h-0.4c-2.6,0-4.5,2.6-3.3,5.2L538,519v103.9h-55.3H359l-3.1-8.2c-1.5-4.1-5.2-6.7-9.6-6.7h-16.7h-14.1
				c-4.5,0-8.5,2.6-10,6.7l-3.2,8.2h-61l14.8,118.7h14.8v103.9h-1.1v81.6h1.1h132.4h1.1h13.7h520.5h3.7h10h1.1h0h133.6V749
				C1087.1,744.2,1086.4,739.3,1084.5,734.9z"
						/>
					</g>
					<polygon
						class="st11"
						points="1042.6,741.6 983.2,622.9 671.6,622.9 731,741.6 		"
					/>
					<rect x="723.5" y="622.9" class="st12" width="226.3" height="14.8" />
					<polygon
						class="st12"
						points="938.7,741.6 923.9,622.9 1005.5,622.9 953.5,741.6 		"
					/>
					<rect x="404.5" y="741.6" class="st10" width="537.9" height="185.5" />
					<path
						class="st5"
						d="M997.7,608c-4.5,0-8.2,2.6-10,6.7l-44.5,115.7c-3,7.4-4.5,15.2-4.5,23.4v173.3h14.8V780.2
			c0-21.1,17.1-38.6,38.6-38.6h13.4l6.7-133.6H997.7z"
					/>
					<path
						class="st10"
						d="M1038.1,614.7c-1.5-4.1-5.6-6.7-9.6-6.7h-16.7c-4.5,0-8.2,2.6-9.6,6.7l-45.3,118c-2.2,5.9-3.3,12.2-3.3,18.5
			v175.8h133.6V749c0-4.8-0.7-9.6-2.6-14.1L1038.1,614.7z"
					/>
					<path
						class="st5"
						d="M1009.2,808.4h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
			C1012.9,806.9,1011.4,808.4,1009.2,808.4z"
					/>
					<path
						class="st5"
						d="M1038.9,808.4h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
			C1042.6,806.9,1041.1,808.4,1038.9,808.4z"
					/>
					<rect x="538" y="429.9" class="st5" width="74.2" height="311.6" />
					<path
						class="st11"
						d="M612.2,741.6l-56-112.4c-1.9-4.1-5.9-6.3-10.4-6.3H241.3l59.4,118.7H612.2z"
					/>
					<path
						class="st12"
						d="M556.2,629.2c-1.9-4.1-5.9-6.3-10.4-6.3h-63.1c4.5,0,8.5,2.6,10.4,6.3l56,112.4h63.1L556.2,629.2z"
					/>
					<path
						class="st12"
						d="M560.3,637.7l-4.1-8.5c-1.9-4.1-5.9-6.3-10.4-6.3H341.4v14.8H560.3z"
					/>
					<rect x="612.2" y="519" class="st13" width="118.7" height="222.6" />
					<rect x="612.2" y="519" class="st10" width="14.8" height="222.6" />
					<rect x="716.1" y="519" class="st10" width="14.8" height="222.6" />
					<path
						class="st5"
						d="M686.8,600.6h-30.4c-3.7,0-7-3.3-7-7v-30.4c0-3.7,3.3-7,7-7h30.4c3.7,0,7,3.3,7,7v30.4
			C693.9,597.3,690.5,600.6,686.8,600.6z"
					/>
					<path
						class="st14"
						d="M582.9,600.6h-15.6c-3.7,0-7-3.3-7-7v-30.4c0-3.7,3.3-7,7-7h15.6c3.7,0,7,3.3,7,7v30.4
			C590,597.3,586.6,600.6,582.9,600.6z"
					/>
					<rect x="612.2" y="429.9" class="st10" width="118.7" height="89" />
					<path
						class="st0"
						d="M659.4,415.1h-18.5V378l2.2-1.5c4.1-3,9.6-3,14.1,0l2.2,1.5L659.4,415.1L659.4,415.1z"
					/>
					<path
						class="st0"
						d="M689,415.1h-18.5V378l2.2-1.5c4.1-3,9.6-3,14.1,0l2.2,1.5L689,415.1L689,415.1z"
					/>
					<path
						class="st0"
						d="M570.3,415.1h-18.5V378l2.2-1.5c4.1-3,9.6-3,14.1,0l2.2,1.5L570.3,415.1L570.3,415.1z"
					/>
					<path
						class="st0"
						d="M600,415.1h-18.5V378l2.2-1.5c4.1-3,9.6-3,14.1,0l2.2,1.5V415.1L600,415.1z"
					/>
					<path
						class="st13"
						d="M731,519H538l-5.6-17.1c-0.7-2.6,1.1-5.2,3.7-5.2h196.6c2.6,0,4.8,2.6,3.7,5.2L731,519z"
					/>
					<rect x="612.2" y="519" class="st10" width="118.7" height="14.8" />
					<path
						class="st10"
						d="M535.8,496.7h76.4V519H538l-5.6-17.1C531.4,499.3,533.2,496.7,535.8,496.7z"
					/>
					<path
						class="st13"
						d="M731,429.9H538l-5.9-17.4c-0.7-2.2,1.1-4.8,3.3-4.8h197.4c2.6,0,4.5,2.6,3.3,4.8L731,429.9z"
					/>
					<path
						class="st10"
						d="M535.8,407.7h76.4v22.3H538l-5.9-17.4C531.4,410.3,533.2,407.7,535.8,407.7z"
					/>
					<path
						class="st14"
						d="M564,481.9h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
			C567.7,480.4,566.2,481.9,564,481.9z"
					/>
					<path
						class="st14"
						d="M593.7,481.9h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
			C597.4,480.4,595.9,481.9,593.7,481.9z"
					/>
					<path
						class="st14"
						d="M645.6,481.9h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
			C649.3,480.4,647.9,481.9,645.6,481.9z"
					/>
					<path
						class="st14"
						d="M675.3,481.9h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
			C679,480.4,677.5,481.9,675.3,481.9z"
					/>
					<path
						class="st14"
						d="M705,481.9h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
			C708.7,480.4,707.2,481.9,705,481.9z"
					/>
					<polygon
						class="st11"
						points="545.5,407.7 597.4,281.5 664.2,281.5 723.5,407.7 		"
					/>
					<polygon
						class="st12"
						points="612.2,407.7 627.1,281.5 597.4,281.5 545.5,407.7 		"
					/>
					<path
						class="st10"
						d="M545.5,408.7l28.2-90.9c1.1-3.3,4.1-5.6,7.4-5.6h2.2c4.1,0,7.4,3,7.8,7l6.3,89.4H545.5z"
					/>
					<path
						class="st5"
						d="M545.5,407.7l28.2-90.9c1.1-3.3,4.1-5.6,7.4-5.6h2.2c4.1,0,7.4,3,7.8,7l6.3,89.4H545.5z"
					/>
					<path
						class="st13"
						d="M627.1,408.7l35.2-91.6c1.1-3,4.1-4.8,7-4.8h11.9c3,0,5.9,1.9,7,4.8l35.2,91.6H627.1z"
					/>
					<path
						class="st10"
						d="M627.1,407.7l35.2-91.6c1.1-3,4.1-4.8,7-4.8h11.9c3,0,5.9,1.9,7,4.8l35.2,91.6H627.1z"
					/>
					<rect x="270.9" y="741.6" class="st10" width="133.6" height="185.5" />
					<polygon
						class="st12"
						points="256.1,741.6 241.3,622.9 322.9,622.9 270.9,741.6 		"
					/>
					<path
						class="st5"
						d="M256.1,741.6l49.3-126.9c1.5-4.1,5.6-6.7,10-6.7h14.1l-6.7,133.6H256.1z"
					/>
					<path
						class="st10"
						d="M270.9,741.6l49-126.9c1.5-4.1,5.6-6.7,9.6-6.7h16.7c4.5,0,8.2,2.6,9.6,6.7l48.6,126.9H270.9z"
					/>
					<path
						class="st5"
						d="M330.3,741.6h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
			C334,740.1,332.5,741.6,330.3,741.6z"
					/>
					<path
						class="st5"
						d="M360,741.6h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
			C363.7,740.1,362.2,741.6,360,741.6z"
					/>
					<polygon
						class="st12"
						points="664.2,281.5 612.2,281.5 612.2,296.4 671.2,296.4 		"
					/>
					<rect x="403.4" y="845.4" class="st5" width="549.1" height="81.6" />
					<path
						class="st14"
						d="M534.3,904.8h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
			C538,903.3,536.6,904.8,534.3,904.8z"
					/>
					<path
						class="st14"
						d="M564,904.8h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
			C567.7,903.3,566.2,904.8,564,904.8z"
					/>
					<path
						class="st14"
						d="M482.4,904.8H475c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
			C486.1,903.3,484.6,904.8,482.4,904.8z"
					/>
					<rect x="269.8" y="845.4" class="st10" width="148.4" height="81.6" />
					<g>
						<g>
							<g>
								<path
									class="st5"
									d="M452.7,808.4h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
						C456.4,806.9,454.9,808.4,452.7,808.4z"
								/>
							</g>
							<g>
								<path
									class="st5"
									d="M482.4,808.4H475c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
						C486.1,806.9,484.6,808.4,482.4,808.4z"
								/>
							</g>
							<g>
								<path
									class="st5"
									d="M534.3,808.4h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
						C538,806.9,536.6,808.4,534.3,808.4z"
								/>
							</g>
							<g>
								<path
									class="st5"
									d="M564,808.4h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
						C567.7,806.9,566.2,808.4,564,808.4z"
								/>
							</g>
							<g>
								<path
									class="st5"
									d="M615.9,808.4h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
						C619.7,806.9,618.2,808.4,615.9,808.4z"
								/>
							</g>
							<g>
								<path
									class="st5"
									d="M645.6,808.4h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
						C649.3,806.9,647.9,808.4,645.6,808.4z"
								/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path
									class="st5"
									d="M705,808.4h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
						C708.7,806.9,707.2,808.4,705,808.4z"
								/>
							</g>
							<g>
								<path
									class="st5"
									d="M734.7,808.4h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
						C738.4,806.9,736.9,808.4,734.7,808.4z"
								/>
							</g>
							<g>
								<path
									class="st5"
									d="M786.6,808.4h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
						C790.3,806.9,788.8,808.4,786.6,808.4z"
								/>
							</g>
							<g>
								<path
									class="st5"
									d="M816.3,808.4h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
						C820,806.9,818.5,808.4,816.3,808.4z"
								/>
							</g>
							<g>
								<path
									class="st5"
									d="M868.2,808.4h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
						C871.9,806.9,870.4,808.4,868.2,808.4z"
								/>
							</g>
							<g>
								<path
									class="st5"
									d="M897.9,808.4h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
						C901.6,806.9,900.1,808.4,897.9,808.4z"
								/>
							</g>
						</g>
					</g>
					<path
						class="st5"
						d="M1038.9,741.6h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
			C1042.6,740.1,1041.1,741.6,1038.9,741.6z"
					/>
					<path
						class="st10"
						d="M868.2,741.6V726c0-2.2,0.4-4.1,1.5-5.9l14.5-28.9c1.1-2.2,4.1-2.2,5.2,0l14.5,29.7c0.7,1.9,1.5,3.7,1.5,5.9
			v14.8H868.2z"
					/>
					<path
						class="st10"
						d="M816.3,741.6V726c0-2.2,0.4-4.1,1.5-5.9l14.5-28.9c1.1-2.2,4.1-2.2,5.2,0l14.5,29.7c0.7,1.9,1.5,3.7,1.5,5.9
			v14.8H816.3z"
					/>
					<path
						class="st10"
						d="M764.3,741.6V726c0-2.2,0.4-4.1,1.5-5.9l14.5-28.9c1.1-2.2,4.1-2.2,5.2,0l14.5,29.7c0.7,1.9,1.5,3.7,1.5,5.9
			v14.8H764.3z"
					/>
					<path
						class="st10"
						d="M538,745.3v-15.6c0-2.2,0.4-4.1,1.5-5.9l14.5-28.9c1.1-2.2,4.1-2.2,5.2,0l14.5,29.7c0.7,1.9,1.5,3.7,1.5,5.9
			v14.8H538z"
					/>
					<path
						class="st10"
						d="M486.1,745.3v-15.6c0-2.2,0.4-4.1,1.5-5.9l14.5-28.9c1.1-2.2,4.1-2.2,5.2,0l14.5,29.7
			c0.7,1.9,1.5,3.7,1.5,5.9v14.8H486.1z"
					/>
					<path
						class="st10"
						d="M434.2,745.3v-15.6c0-2.2,0.4-4.1,1.5-5.9l14.5-28.9c1.1-2.2,4.1-2.2,5.2,0l14.5,29.7
			c0.7,1.9,1.5,3.7,1.5,5.9v14.8H434.2z"
					/>
					<path
						class="st5"
						d="M1009.2,741.6h-7.4c-1.9,0-3.7-1.5-3.7-3.7v-26c0-4.1,3.3-7.4,7.4-7.4l0,0c4.1,0,7.4,3.3,7.4,7.4v26
			C1012.9,740.1,1011.4,741.6,1009.2,741.6z"
					/>
					<path
						class="st11"
						d="M618.5,260L618.5,260c2.6,3.3,5.6,5.2,8.5,5.2h7.4c3,0,5.9-1.9,8.5-5.2l0,0c5.2-7,7.8-20,6.3-32.3L634.5,80
			c-0.4-4.8-6.7-4.8-7,0l-14.8,147.7C611.1,239.9,613.4,252.9,618.5,260z"
					/>
					<path
						class="st11"
						d="M602.6,232.2h56c7,0,12.6,5.6,12.6,12.6v26.3c0,7-5.6,12.6-12.6,12.6h-56c-7,0-12.6-5.6-12.6-12.6v-26.3
			C590,237.7,595.5,232.2,602.6,232.2z"
					/>
					<path
						class="st11"
						d="M649.3,239.6l17.1-34.1c1.5-2.6,5.2-1.9,5.2,1.1V247L649.3,239.6z"
					/>
					<path
						class="st11"
						d="M612.2,239.6l-17.1-34.1c-1.5-2.6-5.2-1.9-5.2,1.1V247L612.2,239.6z"
					/>
					<path
						class="st15"
						d="M620.8,269.2h-9.3c-1.5,0-2.6-1.1-2.6-2.6v-9.3c0-1.5,1.1-2.6,2.6-2.6h9.3c1.5,0,2.6,1.1,2.6,2.6v9.3
			C623.4,268.1,622.3,269.2,620.8,269.2z"
					/>
					<path
						class="st15"
						d="M650.5,269.2h-9.3c-1.5,0-2.6-1.1-2.6-2.6v-9.3c0-1.5,1.1-2.6,2.6-2.6h9.3c1.5,0,2.6,1.1,2.6,2.6v9.3
			C653,268.1,651.9,269.2,650.5,269.2z"
					/>
					<circle class="st5" cx="630.8" cy="139.4" r="18.5" />
					<path
						class="st10"
						d="M630.8,120.9c-2.6,0-5.2,0.7-7.4,1.5c6.7,3,11.1,9.3,11.1,17.1c0,7.8-4.5,14.1-11.1,17.1
			c2.2,1.1,4.8,1.5,7.4,1.5c10.4,0,18.5-8.2,18.5-18.5C649.3,129,641.2,120.9,630.8,120.9z"
					/>
				</g>
			</KinesisElement>

			<KinesisElement tag="g" axis="x" :strength="10">
				<g>
					<g>
						<path
							class="st8"
							d="M1094.5,429.9h-90.9c-11.5,0-21.9,6.3-27.1,16.7l0,0c-5.2,10.4-15.6,16.7-27.1,16.7h-21.9
				c-6.3,0-11.1,4.8-11.1,11.1v77.9c0,6.3,4.8,11.1,11.1,11.1h103.9c11.5,0,21.9-6.3,27.1-16.7l0,0c5.2-10.4,15.6-16.7,27.1-16.7
				h9.3V429.9H1094.5z"
						/>
						<g>
							<path
								class="st4"
								d="M1051.1,474.1c-3.7-6.3-14.5-6.7-31.2-1.5c-8.2,3-17.1,7-26,11.9c-6.3,3.7-12.2,7.8-17.4,11.9
					c0-0.7,0.4-1.5,0.4-2.2c3.3-12.2,14.1-21.1,27.1-21.1h0.4c1.9,0,3.3-1.5,3.3-3.3c0-1.9-1.5-3.3-3.3-3.3
					c-15.6-0.4-29.7,10.4-33.8,26c-0.7,3.3-1.1,6.3-1.1,9.6c-11.9,10.8-16.3,20.4-12.6,26.7c1.9,3.3,5.9,5.2,11.9,5.2
					c4.8,0,10.4-1.1,17.1-3.3c2.6,1.5,5.6,3,8.9,3.7c3,0.7,5.6,1.1,8.5,1.1c5.9,0,11.9-1.5,17.1-4.8c7.8-4.5,13.4-11.9,15.6-21.1
					c0.7-3.3,1.1-6.3,1.1-9.6C1050,489.7,1054.5,480.4,1051.1,474.1z M1031.1,508.6c-1.9,7-6.3,13-12.6,16.7
					c-6.3,3.7-13.7,4.8-21.1,3c-6.3-1.5-11.5-5.2-15.2-10.4c3.7-3.7,10-9.3,18.5-13.4c1.5-0.7,2.2-2.6,1.5-4.5
					c-0.7-1.5-2.6-2.2-4.5-1.5c-10.4,5.6-18.5,12.2-22.3,16.7c-0.7,1.1-1.1,2.6-0.4,3.7c1.5,2.6,3.3,4.5,5.2,6.7
					c-10.8,3-16.3,1.5-17.1-0.4c-1.1-2.2,1.1-8.9,12.6-19.7c6.3-5.6,13.7-11.1,22.3-16c7.8-4.5,15.6-8.2,23.4-10.8
					C1029.6,486.3,1034.1,497.8,1031.1,508.6z M1027.7,477.1c10.8-3,16.3-1.5,17.1,0.4c1.1,1.9-0.4,7-8.2,15.2
					C1035.5,487.1,1032.6,481.5,1027.7,477.1z"
							/>
						</g>
						<path
							class="st2"
							d="M1094.5,841.7V424.4c0-3,2.6-5.6,5.6-5.6c3,0,5.6,2.6,5.6,5.6v417.4H1094.5z"
						/>
					</g>
					<g>
						<path
							class="st8"
							d="M1199.9,654.8l-5.2,5.2c-2.2,2.2-3.3,5.2-3.3,8.2v6.7c0,6.3,5.2,11.5,11.5,11.5c6.3,0,11.5-5.2,11.5-11.5
				v-28.6h-14.1v8.5H1199.9z"
						/>
						<path
							class="st8"
							d="M1115.3,614.3l-5.2-5.2c-2.2-2.2-5.2-3.3-8.2-3.3h-6.7c-6.3,0-11.5,5.2-11.5,11.5c0,6.3,5.2,11.5,11.5,11.5
				h34.1v-14.1h-14.1V614.3z"
						/>
						<path
							class="st16"
							d="M1150.9,714.9v19.3l-10,3.7c-1.9,0.7-2.6,2.6-2.6,4.5v2.6c0,2.2,1.9,4.5,4.5,4.5h21.5
				c2.2,0,4.5-1.9,4.5-4.5v-30L1150.9,714.9L1150.9,714.9z"
						/>
						<path
							class="st16"
							d="M1189.1,714.9l3.3,19.3l-9.3,3.7c-1.5,0.7-2.2,2.6-1.9,4.5l0.7,3.3c0.4,2.2,2.2,3.7,4.1,3.7h21.1
				c2.6,0,4.8-2.2,4.1-5.2l-5.2-29.3H1189.1z"
						/>
						<g>
							<path
								class="st17"
								d="M1157.2,589.1h-0.7c-6.3,0-11.1-4.8-11.1-11.1v-11.9c0-6.3,4.8-11.1,11.1-11.1h0.7
					c6.3,0,11.1,4.8,11.1,11.1V578C1168.3,584.3,1163.5,589.1,1157.2,589.1z"
							/>
							<path
								class="st8"
								d="M1167.8,595.1h16.4v-39.2c0-6.7-5.6-12.3-12.3-12.3h-0.7c-8.6,0-15.7,7.1-15.7,15.7v23.5
					C1155.5,589.5,1161.1,595.1,1167.8,595.1z"
							/>
						</g>
						<g>
							<path
								class="st16"
								d="M1208.4,591.3c-5.2-3-8.5-8.9-8.5-14.8v-16c0-9.3-7.8-17.1-17.1-17.1h-11.5c6.3,0,11.5,5.2,11.5,11.5v23
					c0,9.3-7.8,17.1-17.1,17.1l0,0v23h17.1v34.1h34.1v-45.6C1216.9,599.9,1213.6,594.3,1208.4,591.3z"
							/>
							<path
								class="st2"
								d="M1193.9,652.2c-6.3,0-11.5-5.2-11.5-11.5v-28.6c0-9.3-7.8-17.1-17.1-17.1l0,0c-9.3,0-17.1,7.8-17.1,17.1
					v102.8c0,4.8,3.7,8.5,8.5,8.5h5.6c4.8,0,8.5-3.7,8.5-8.5v-39.3c0-3,1.9-5.6,4.8-6.3c3.7-0.7,7,1.9,7.4,5.2l4.5,43.8
					c0.4,4.5,4.1,7.8,8.5,7.8h5.6c5.2,0,8.9-4.5,8.5-9.3l-4.8-47.5c6.3,0,11.5-5.2,11.5-11.5v-5.6L1193.9,652.2L1193.9,652.2z"
							/>
							<path
								class="st16"
								d="M1162.8,606.2c-3-3.7-8.2-4.1-12.2-1.1l-11.1,8.2h-22.3c-1.5,0-3,1.1-3,3v11.5c0,1.5,1.1,3,3,3h22.3
					c3.7,0,7-1.1,10-3.3l11.9-8.5C1165,615.8,1165.8,610.2,1162.8,606.2z"
							/>
						</g>
						<path
							class="st17"
							d="M1216.9,617.7h-17.4c-3,0-5.6-2.6-5.6-5.6v-0.4c0-3,2.6-5.6,5.6-5.6h17.4V617.7z"
						/>
						<path
							class="st2"
							d="M1216.9,634.7h-18.5c-2.2,0-4.1-1.9-4.1-4.1v-0.4c0-2.2,1.9-4.1,4.1-4.1h18.5V634.7z"
						/>
					</g>
					<g>
						<g>
							<path
								class="st18"
								d="M1387.6,927.8l-69.4-89.4c-7-8.9-17.8-14.5-29.3-14.5h-2.2c-15.2,0-28.9-9.3-34.5-23.4l-11.1-27.8
					c-5.6-14.1-19.3-23.4-34.5-23.4h-146.9c-15.2,0-28.9,9.3-34.5,23.4l-10.4,26c-6.3,16-18.2,29.3-33.4,37.5l-34.9,18.9
					c-15.2,8.2-27.1,21.5-33.4,37.5L899.4,927h488.2V927.8z"
							/>
							<path
								class="st6"
								d="M1196.4,790.6l16.7,51.2c3.7,11.5,14.5,18.9,26.3,18.9h15.2c10.8,0,20.8,6.3,25.2,16.3l23,50.5h85
					l-69.4-89.4c-7-8.9-17.8-14.5-29.3-14.5h-2.2c-15.2,0-28.9-9.3-34.5-23.4l-11.1-27.8c-5.6-14.1-19.3-23.4-34.5-23.4h-146.9
					c-14.8,0-28.2,8.9-34.1,22.3h143.9C1181.9,771.7,1192.7,779.5,1196.4,790.6z"
							/>
						</g>
						<path
							class="st6"
							d="M1124.2,868.1l23.7,59h35.6l-33-82c-3-7-9.6-11.9-17.4-11.9h-70.5c-7,0-13.4,3.7-16.7,10l-7,13.4h68.3
				C1114.2,856.2,1121.2,861,1124.2,868.1z"
						/>
					</g>
				</g>
				<g>
					<g>
						<path
							class="st6"
							d="M400.8,784.6H387c-8.9,0-16.7-5.9-20-14.8L341,703c-3.3-8.9-11.1-14.5-20-14.5h-18.2
				c-10,0-18.5-7.4-21.1-18.2l-17.1-76.1c-2.2-9.3-9.6-15.6-18.2-15.6h-34.9l-11.1,348.7h278.2l-58.6-129.1
				C416.4,789.8,408.9,784.6,400.8,784.6z"
						/>
						<path
							class="st18"
							d="M240.1,721.2l-10.8-142.8h-20.8c-8.5,0-16.3,6.7-18.2,16l-44.9,145.1c-2.2,11.1-9.3,20-18.5,24.1
				l-31.5,14.1c-9.6,4.5-17.4,13-21.1,23.7L31.6,927.1h329.1L309.1,759c-3-9.6-11.1-16-20.4-16H262
				C250.5,743.4,241.3,733.8,240.1,721.2z"
						/>
					</g>
					<path
						class="st11"
						d="M1116.8,927.1l-13-13c-25.2-25.2-59.4-39.3-95.3-39.3h-28.9c-35.6,0-70.1-14.1-95.3-39.3l0,0
			c-25.2-25.2-59.4-39.3-95.3-39.3H725c-35.6,0-70.1,14.1-95.3,39.3L538,927.1H1116.8z"
					/>
				</g>
				<path
					class="st12"
					d="M678.6,927.1l-21.1-28.2c-1.5-2.2,0.4-5.2,3-4.5c5.6,1.9,11.9,3.7,18.2,6.7c6.7,3,12.2,5.9,17.4,8.9v-54.5
		c0-2.2,3-3.3,4.5-1.5c4.5,5.2,9.3,12.2,13,21.1c6.3,14.1,8.2,26.7,8.9,34.9c3.3-4.8,8.9-11.5,17.4-17.4c3.7-2.6,7.8-4.8,11.1-6.3
		c2.2-1.1,4.8,1.5,3.7,3.7c-4.8,12.6-10,24.9-14.8,37.5h-61.2V927.1z"
				/>
				<g>
					<path
						class="st19"
						d="M144.4,667.4L101,864c-3.7,17.1,9.3,33.4,26.7,33.4h50.1c17.4,0,30.8-16.3,26.7-33.4l-43.4-196.6
			C159.3,658.8,146.7,658.8,144.4,667.4z"
					/>
					<rect x="138.1" y="882.5" class="st2" width="29.7" height="44.5" />
					<path
						class="st11"
						d="M156.7,882.5c-17.4,0-30.8-16.3-26.7-33.4l35.6-161l-4.5-20.8c-1.9-8.9-14.5-8.9-16.7,0L101,864
			c-3.7,17.1,9.3,33.4,26.7,33.4h50.1c10.8,0,19.7-6.3,24.5-14.8H156.7z"
					/>
					<path
						class="st19"
						d="M144.4,567.2L101,763.8c-3.7,17.1,9.3,33.4,26.7,33.4h50.1c17.4,0,30.8-16.3,26.7-33.4l-43.4-196.6
			C159.3,558.7,146.7,558.7,144.4,567.2z"
					/>
					<path
						class="st11"
						d="M156.7,782.4c-17.4,0-30.8-16.3-26.7-33.4l35.6-161l-4.5-20.8c-1.9-8.9-14.5-8.9-16.7,0L101,763.8
			c-3.7,17.1,9.3,33.4,26.7,33.4h50.1c10.8,0,19.7-6.3,24.5-14.8H156.7z"
					/>
				</g>
				<path
					class="st19"
					d="M707.6,813.9c35.6,0,70.1,14.1,95.3,39.3l0,0c25.2,25.2,59.4,39.3,95.3,39.3h28.6c33,0,64.9,12.2,89.4,34.1
		h100.5l-13-13c-25.2-25.2-59.4-39.3-95.3-39.3h-28.9c-35.6,0-70.1-14.1-95.3-39.3l0,0c-25.2-25.2-59.4-39.3-95.3-39.3H725
		c-24.1,0-47.9,6.7-68.3,18.5h50.8V813.9z"
				/>
			</KinesisElement>
		</svg>
	</div>
</template>

<script>
import { KinesisElement } from 'vue-kinesis';

export default {
	name: 'AuthLayoutBackground',
	components: {
		KinesisElement,
	},
};
</script>

<style lang="scss" scoped>
.background {
	background-color: #204f44;

	.svg {
		position: fixed;
		bottom: 0;

		.st0 {
			fill: #204f44;
		}
		.st1 {
			fill: #285649;
		}
		.st2 {
			fill: #133a34;
		}
		.st3 {
			fill: #d1e5d9;
		}
		.st4 {
			fill: #ffffff;
		}
		.st5 {
			fill: #e0b76c;
		}
		.st6 {
			fill: #85998e;
		}
		.st7 {
			fill: #9eb5a8;
		}
		.st8 {
			fill: #d94f86;
		}
		.st9 {
			fill: #b8d1c2;
		}
		.st10 {
			fill: #f2cf8f;
		}
		.st11 {
			fill: #45ab71;
		}
		.st12 {
			fill: #3f8a63;
		}
		.st13 {
			fill: #f9e5b4;
		}
		.st14 {
			fill: #ce904d;
		}
		.st15 {
			fill: #386854;
		}
		.st16 {
			fill: #a63d86;
		}
		.st17 {
			fill: #3bc4ad;
		}
		.st18 {
			fill: #657a6f;
		}
		.st19 {
			fill: #55d38c;
		}
	}
}
</style>
