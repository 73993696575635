import { startBundleTrial, getUserBundleAccess } from '@/api/bundle';

const state = () => ({
	trialMaterial: null,
	onTrialFlowComplete: () => {},
	trialModalDismissible: true,
});

const getters = {};

const actions = {
	startModalTrialFlow({ commit }, { material, onComplete, dismissible }) {
		commit('setTrialMaterial', material);
		if (onComplete && typeof onComplete === 'function') {
			commit('setTrialFlowCompleteCallback', onComplete);
		}
		if (dismissible !== undefined) {
			commit('setTrialFlowModalDismissible', dismissible);
		}
	},
	startBundleTrial({ dispatch, rootState }, { courseId }) {
		return startBundleTrial(rootState.user.schoolId, courseId).then(response => {
			// Reload books and assignments by course for the mega menus
			dispatch('books/getMenuBooks', {}, { root: true });
			dispatch('assignments/getAssignmentsByCourse', {}, { root: true });
			return response;
		});
	},
	getUserBundleAccess(_, { schoolId }) {
		return getUserBundleAccess(schoolId);
	},
};

const mutations = {
	setTrialMaterial(state, material) {
		state.trialMaterial = material;
	},
	setTrialFlowCompleteCallback(state, callback) {
		state.onTrialFlowComplete = callback;
	},
	setTrialFlowModalDismissible(state, dismissible) {
		state.trialModalDismissible = dismissible;
	},
	unsetTrialFlow(state) {
		state.trialMaterial = null;
		state.onTrialFlowComplete = () => {};
		state.trialModalDismissible = true;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
