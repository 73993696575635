import {
	getDesignationDefaults,
	updateDesignation,
	getSchoolUserCourseSettings,
	getSchoolUserCoursesSettings,
	getSchoolTeacherCourseDesignations,
} from '@/api/designation';
import Designation from '@/models/designation/Designation';
import Vue from 'vue';

const state = () => ({
	courseSettings: {
		0: Designation.getDefaultDesignation(),
	},
	schoolClassCourseSettings: {},
	designationOverride: null,
});

const getters = {
	getDesignationByCourseId:
		state =>
		(courseId = 0, schoolClassId = 0) => {
			if (state.designationOverride) {
				return new Designation({
					...Designation.getDefaultDesignation(),
					default: state.designationOverride,
				});
			}
			if (schoolClassId && state.schoolClassCourseSettings[schoolClassId]?.[courseId]) {
				return new Designation(state.schoolClassCourseSettings[schoolClassId][courseId]);
			}
			return new Designation(state.courseSettings[courseId] ?? {});
		},
};

const actions = {
	getDesignationDefaults() {
		return getDesignationDefaults();
	},
	updateDesignation(_, { id, data }) {
		return updateDesignation(id, data);
	},
	setCourseSettings({ commit }, courseSettings) {
		for (const courseId in courseSettings) {
			commit('setCourseSettings', { courseId, settings: courseSettings[courseId] });
		}
	},
	getSchoolUserCourseSettings({ rootState, commit }, { courseId, schoolClassId = null }) {
		return getSchoolUserCourseSettings(rootState.user.schoolId, courseId, schoolClassId).then(settings => {
			if (schoolClassId) {
				commit('setSchoolClassCourseSettings', {
					schoolClassId,
					courseId,
					settings,
				});
			} else {
				commit('setCourseSettings', { courseId, settings });
			}
			return new Designation(settings);
		});
	},
	getSchoolUserCoursesSettings({ rootState, commit }) {
		return getSchoolUserCoursesSettings(rootState.user.schoolId).then(courseSettings => {
			const courseDesignations = {};
			for (const courseId in courseSettings) {
				commit('setCourseSettings', { courseId, settings: courseSettings[courseId] });
				courseDesignations[courseId] = new Designation(courseSettings[courseId]);
			}
			return courseDesignations;
		});
	},
	getSchoolTeacherCourseDesignations({ rootState }, courseId) {
		return getSchoolTeacherCourseDesignations(rootState.user.schoolId, courseId);
	},
	setDesignationOverride({ commit }, designation) {
		commit('setDesignationOverride', designation.id);
	},
};

const mutations = {
	setCourseSettings(state, { courseId, settings }) {
		Vue.set(state.courseSettings, courseId, settings);
	},
	setSchoolClassCourseSettings(state, { schoolClassId, courseId, settings }) {
		if (!state.schoolClassCourseSettings[schoolClassId]) {
			Vue.set(state.schoolClassCourseSettings, schoolClassId, { [courseId]: settings });
		} else {
			Vue.set(state.schoolClassCourseSettings[schoolClassId], courseId, settings);
		}
	},
	setDesignationOverride(state, value) {
		state.designationOverride = value;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
