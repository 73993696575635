export default {
	methods: {
		animateCss(options = []) {
			if (!options.length) {
				return [];
			}
			return ['animated'].concat(options).map(option => `animate__${option}`);
		},
	},
};
