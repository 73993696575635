import Vue from 'vue';
import { getMaterials, unilogin, uniloginUser, register } from '@/api/trial';
import { getCachedItem, cacheItem, forgetItem } from '../cache';
import { ValidationError } from '@/api/errors';

const TRIAL_DATA_CACHE_KEY = 'trialData';
const TRIAL_COURSES_CACHE_KEY = 'trialSelectedCourses';
const TRIAL_TOOLS_CACHE_KEY = 'trialSelectedTools';

const state = () => ({
	trialData: getCachedItem(TRIAL_DATA_CACHE_KEY) || null,
	selectedCourses: getCachedItem(TRIAL_COURSES_CACHE_KEY) || [],
	selectedTools: getCachedItem(TRIAL_TOOLS_CACHE_KEY) || [],
	info: {
		name: '',
		schoolId: '',
		educationIds: [],
		email: '',
		message: '',
	},
	error: null,
});

const getters = {
	existingUser: state => state.trialData?.existing_user ?? null,
	uniloginUser: state => state.trialData?.unilogin_user ?? null,
	uniloginId: (state, getters) => {
		if (getters.existingUser?.unilogin_id) {
			return getters.existingUser?.unilogin_id;
		} else if (getters.uniloginUser?.id) {
			return getters.uniloginUser?.id;
		}
		return state.trialData?.unilogin_id ?? '';
	},
	schools: state => state.trialData?.schools ?? [],
	educations: state => state.trialData?.educations ?? [],
	schoolId: (state, getters) => {
		if (state.info.schoolId) {
			return state.info.schoolId;
		}
		if (getters.existingUser?.schools?.length == 0) {
			return getters.existingUser.schools[0].unilogin_institution_number;
		}
		return getters.schools.length ? getters.schools[0].id : '';
	},
	schoolExists: (state, getters) => {
		if (!getters.schoolId) {
			return false;
		}
		if (getters.existingUser?.schools?.length > 0) {
			return true;
		}
		return state.trialData?.existing_schools?.includes(getters.schoolId);
	},
	hasSelectedMaterials: state => {
		return state.selectedCourses.length > 0 || state.selectedTools.length > 0;
	},
	hasSelectedEducations: state => {
		return state?.info?.educationIds?.length > 0;
	},
	hasValidEducations: (state, getters) => {
		if (!getters.schoolExists && getters.educations.length && !getters.hasSelectedEducations) {
			return false;
		}
		return true;
	},
	canRegister: (state, getters) => {
		return (
			getters.schoolId &&
			getters.uniloginId &&
			getters.hasSelectedMaterials &&
			getters.hasValidEducations
		);
	},
	courseSelectedIndex: state => course => {
		return state.selectedCourses.findIndex(selectedCourse => {
			return selectedCourse.id === course.id;
		});
	},
	courseIsSelected: (state, getters) => course => {
		return getters.courseSelectedIndex(course) >= 0;
	},
	toolSelectedIndex: state => tool => {
		return state.selectedTools.findIndex(selectedTool => {
			return selectedTool.machine_name === tool.machine_name;
		});
	},
	toolIsSelected: (state, getters) => tool => {
		return getters.toolSelectedIndex(tool) >= 0;
	},
	infoByKey: state => key => {
		return key in state.info ? state.info[key] : '';
	},
	getFieldError: state => field => {
		const error = state.error?.validation[field];
		return Array.isArray(error) && error.length > 0 ? error[0] : null;
	},
	fieldHasError: (state, getters) => field => {
		return getters.getFieldError(field) !== null;
	},
};

const actions = {
	updateInfo({ commit }, values) {
		for (const key in values) {
			commit('setInfoKey', { key, value: values[key] });
		}
	},
	getMaterials() {
		return getMaterials();
	},
	toggleCourse({ commit, getters }, course) {
		const index = getters.courseSelectedIndex(course);
		if (index >= 0) {
			commit('deselectCourse', index);
		} else {
			commit('selectCourse', course);
		}
	},
	toggleTool({ commit, getters }, tool) {
		const index = getters.toolSelectedIndex(tool);
		if (index >= 0) {
			commit('deselectTool', index);
		} else {
			commit('selectTool', tool);
		}
	},
	unilogin({ commit }, { timestamp, user, auth }) {
		return unilogin(timestamp, user, auth).then(data => {
			commit('setTrialData', data);
		});
	},
	uniloginUser({ commit }, { code }) {
		return uniloginUser(code).then(data => {
			commit('setTrialData', data);
		});
	},
	register({ commit, getters, state }) {
		if (!getters.canRegister) {
			return;
		}
		const payload = {
			user: getters.uniloginId,
			school: getters.schoolId,
			email: state.info.email,
			name: state.info.name,
			message: state.info.message,
			data: {
				courses: state.selectedCourses.map(course => course.id),
				tools: state.selectedTools.map(tool => tool.machine_name),
			},
		};
		if (!getters.schoolExists && getters.hasSelectedEducations) {
			payload.education_ids = state.info.educationIds;
		}

		commit('setError', null);

		return register(payload)
			.then(resp => {
				const token = resp.access_token;
				commit('auth/setToken', { token }, { root: true });
				commit('user/setUser', resp.user, { root: true });
				commit('reset');
				return true;
			})
			.catch(err => {
				const error = { message: err.message };
				if (err instanceof ValidationError) {
					error.validation = err.validationErrors;
				}
				commit('setError', error);
				return false;
			});
	},
};

const mutations = {
	reset(state) {
		forgetItem(TRIAL_DATA_CACHE_KEY);
		forgetItem(TRIAL_COURSES_CACHE_KEY);
		forgetItem(TRIAL_TOOLS_CACHE_KEY);
		state.trialData = null;
		state.selectedCourses = [];
		state.selectedTools = [];
		for (const key in state.info) {
			state.info[key] = '';
		}
		state.error = null;
	},
	setError(state, value = null) {
		state.error = value;
	},
	selectCourse(state, course) {
		state.selectedCourses.push(course);
		cacheItem(TRIAL_COURSES_CACHE_KEY, state.selectedCourses.slice());
	},
	deselectCourse(state, index) {
		state.selectedCourses.splice(index, 1);
		cacheItem(TRIAL_COURSES_CACHE_KEY, state.selectedCourses.slice());
	},
	selectTool(state, tool) {
		state.selectedTools.push(tool);
		cacheItem(TRIAL_TOOLS_CACHE_KEY, state.selectedTools.slice());
	},
	deselectTool(state, index) {
		state.selectedTools.splice(index, 1);
		cacheItem(TRIAL_TOOLS_CACHE_KEY, state.selectedTools.slice());
	},
	setTrialData(state, data) {
		state.trialData = data;
		cacheItem(TRIAL_DATA_CACHE_KEY, Object.assign({}, state.trialData));
	},
	setInfoKey(state, { key, value }) {
		Vue.set(state.info, key, value);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
