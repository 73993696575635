<template>
	<div class="base-search">
		<form novalidate @submit.prevent="$emit('submit')">
			<div class="field">
				<div class="control has-icons-left has-icons-right">
					<input
						ref="input"
						v-model="modelValue"
						type="text"
						class="input"
						:class="{ 'is-active': isActive }"
						:placeholder="placeholder"
						autocomplete="off"
						autocorrect="off"
						spellcheck="false"
						@focus="onFocus"
						@blur="onBlur"
						@mouseenter="isHovered = true"
						@mouseleave="isHovered = false"
					/>
					<BaseIcon
						icon="search"
						color="grey-light"
						active-color="blue"
						:active="isActive"
						class="icon is-left"
						has-transition
					/>
					<BaseIcon
						v-if="showResetButton && value"
						icon="cross"
						color="grey-light"
						active-color="blue"
						has-transition
						:active="isActive"
						class="icon is-right is-clickable"
						@click.native="$emit('reset')"
					/>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon';

export default {
	name: 'BaseSearch',
	components: { BaseIcon },
	props: {
		value: {
			type: String,
			required: true,
		},
		placeholder: {
			type: String,
			default: 'Søg...',
		},
		active: {
			type: Boolean,
			default: false,
		},
		showResetButton: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isHovered: false,
			hasFocus: false,
		};
	},
	computed: {
		isActive() {
			return this.active || this.isHovered || this.hasFocus;
		},
		modelValue: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	methods: {
		onFocus() {
			this.hasFocus = true;
			this.$emit('focus');
		},
		onBlur() {
			this.hasFocus = false;
			this.$emit('blur');
		},
		focus() {
			this.$refs.input.focus();
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
.base-search {
	.control .icon {
		height: 2.5rem;
		width: 2.5rem;
		pointer-events: initial;
	}

	.input {
		background-color: $grey-lighterer;
		color: $grey;
		font-size: 1rem;
		border-radius: 2rem;
		border: 0;
		height: 2.5rem;
		line-height: 2.5rem;
		padding-left: 1rem;
		transition: width 200ms ease-out, background-color 200ms linear, color 200ms linear;
		box-shadow: none;

		&.is-active,
		&:focus-within,
		&:hover,
		&:focus {
			background-color: $blue-lightest;
			color: $blue;

			&::-webkit-input-placeholder {
				color: $blue;
			}
			&::-moz-placeholder {
				color: $blue;
			}
			&:-ms-input-placeholder {
				color: $blue;
			}
			&:-moz-placeholder {
				color: $blue;
			}
		}
	}
}
</style>
