<template>
	<BaseDropdown
		v-bind="$attrs"
		ref="dropdown"
		:disabled="disabled"
		@prev-option="onPrevOption"
		@next-option="onNextOption"
		@hide="$emit('hide')"
	>
		<template #trigger="slotProps">
			<slot name="trigger" :active="slotProps.active">
				<BaseInputDropdown
					:size="inputSize"
					:value="displayOption(value) || placeholder"
					:active="slotProps.active"
					:context-class="inputContextClass"
				/>
			</slot>
		</template>

		<template v-if="groupedOptions">
			<template v-for="(optionGroup, groupIndex) in options">
				<div
					:key="`group-title-${groupIndex}`"
					class="dropdown-item has-text-weight-bold"
					:class="{ 'mt-3': groupIndex > 0 }"
					v-text="optionGroup.title"
				/>
				<BaseDropdownSelectItem
					v-for="(option, index) in optionGroup.options"
					:key="`group-${groupIndex}-option-${index}`"
					:ref="`option-${groupIndex}-${index}`"
					:selected="optionIsSelected(option)"
					@click.native="toggleOption(option)"
				>
					<slot name="option" :option="option">
						{{ displayOption(option) }}
					</slot>
				</BaseDropdownSelectItem>
			</template>
		</template>
		<template v-else>
			<BaseDropdownSelectItem
				v-for="(option, index) in options"
				:key="`option-${index}`"
				:ref="`option-${index}`"
				:selected="optionIsSelected(option)"
				@click.native="toggleOption(option)"
			>
				<slot name="option" :option="option" :index="index">
					{{ displayOption(option) }}
				</slot>
			</BaseDropdownSelectItem>
		</template>
	</BaseDropdown>
</template>

<script>
import BaseInputDropdown from './BaseInputDropdown';
import BaseDropdownSelectItem from '@/components/base/dropdown/BaseDropdownSelectItem';
import BaseDropdown from '@/components/base/dropdown/BaseDropdown';

export default {
	name: 'BaseSelect',
	components: { BaseDropdown, BaseInputDropdown, BaseDropdownSelectItem },
	props: {
		value: {
			type: [String, Number, Array, Object, Boolean],
			default: null,
		},
		options: {
			type: Array,
			default: () => [],
		},
		groupedOptions: {
			type: Boolean,
			default: false,
		},
		displayOption: {
			type: Function,
			default: option => option || '',
		},
		optionMatcher: {
			type: Function,
			default: undefined,
		},
		idKey: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: 'Vælg',
		},
		inputSize: {
			type: String,
			default: 'medium',
		},
		inputContextClass: {
			type: String,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		noDeselect: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		optionIsSelected(option) {
			if (this.value === null || option === null) {
				return false;
			}
			return this.optionMatch(option, this.value);
		},
		optionMatch(option1, option2) {
			if (this.optionMatcher) {
				return this.optionMatcher(option1, option2);
			}
			if (this.idKey) {
				return option1[this.idKey] === option2[this.idKey];
			}
			return option1 === option2;
		},
		toggleOption(option) {
			if (!this.optionIsSelected(option)) {
				this.selectOption(option);
			} else {
				this.deselect();
			}
		},
		selectOption(option) {
			this.$emit('input', option);
			this.$refs.dropdown.hide();
		},
		deselect() {
			if (this.noDeselect) {
				return;
			}
			this.$emit('input', null);
		},
		onPrevOption() {
			if (!this.options.length) {
				return;
			}

			const option = this.getPrevOption();
			if (!option) {
				return;
			}
			this.scrollElementIntoView(this.getElementByOption(option));
			this.$emit('input', option);
		},
		getPrevOption() {
			if (!this.groupedOptions) {
				const curIndex = this.options.findIndex(option => this.optionIsSelected(option));
				return this.options[curIndex - 1 < 0 ? this.options.length - 1 : curIndex - 1];
			}
			for (let i = 0; i < this.options.length; i++) {
				const group = this.options[i];
				const curIndex = group.options.findIndex(option => this.optionIsSelected(option));
				if (curIndex === -1) {
					continue;
				}
				if (curIndex - 1 >= 0) {
					return group.options[curIndex - 1];
				}
				const prevGroup = this.options[i - 1 < 0 ? this.options.length - 1 : i - 1];
				if (prevGroup.options.length) {
					return prevGroup.options[prevGroup.options.length - 1];
				}
			}
			if (this.options.length) {
				const group = this.options[this.options.length - 1];
				return group.options.length ? group.options[group.options.length - 1] : null;
			}
			return null;
		},
		onNextOption() {
			if (!this.options.length) {
				return;
			}

			const option = this.getNextOption();
			if (!option) {
				return;
			}
			this.scrollElementIntoView(this.getElementByOption(option));
			this.$emit('input', option);
		},
		getNextOption() {
			if (!this.groupedOptions) {
				const curIndex = this.options.findIndex(option => this.optionIsSelected(option));
				return this.options[curIndex + 1 == this.options.length ? 0 : curIndex + 1];
			}
			for (let i = 0; i < this.options.length; i++) {
				const group = this.options[i];
				const curIndex = group.options.findIndex(option => this.optionIsSelected(option));
				if (curIndex === -1) {
					continue;
				}
				if (curIndex + 1 <= group.options.length - 1) {
					return group.options[curIndex + 1];
				}
				const nextGroup = this.options[i + 1 == this.options.length ? 0 : i + 1];
				if (nextGroup.options.length) {
					return nextGroup.options[0];
				}
			}
			if (this.options.length) {
				const group = this.options[0];
				return group.options.length ? group.options[0] : null;
			}
			return null;
		},
		getElementByOption(option) {
			const ref = this.getRefByOption(option);
			return this.$refs[ref]?.length ? this.$refs[ref][0].$el : null;
		},
		getRefByOption(option) {
			if (!this.groupedOptions) {
				const index = this.options.findIndex(opt => this.optionMatch(opt, option));
				return index >= 0 ? `option-${index}` : null;
			}
			for (let i = 0; i < this.options.length; i++) {
				const index = this.options[i].options.findIndex(opt => this.optionMatch(opt, option));
				if (index === -1) {
					continue;
				}
				return `option-${i}-${index}`;
			}
			return null;
		},
		scrollElementIntoView(elm) {
			if (!elm?.parentElement) {
				return;
			}
			const parent = elm.parentElement;
			if (
				elm.offsetTop + elm.clientHeight < parent.clientHeight &&
				elm.offsetTop > parent.scrollTop
			) {
				// Abort scroll if element is already fully within view
				return;
			}
			parent.scrollTop = Math.max(elm.offsetTop - 20, 0);
		},
	},
};
</script>
