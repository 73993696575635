<template>
	<div class="background">
		<svg class="svg" viewBox="0 0 1440 927" xmlns="http://www.w3.org/2000/svg">
			{{ /* eslint-disable max-len */ }}
			<KinesisElement tag="g" axis="x" :strength="-2">
				<path
					class="st1"
					d="M1192.7,755.8l-95.8-272c-14.5-40.7-59.3-62-100-47.2l-14.5,5.3c-37.3,13.3-78.3-3-96.2-38.4l-30.4-60.9
		c-17.9-35.8-49.4-62.4-87.9-73.4L672,240.8c-29.3-8.7-60.9-7.6-89.4,2.7l-51,18.3c-55.1,19.8-92,71.1-94.7,129.3
		c-2.3,46.8-27,89.4-66.6,114.5l-176,111.8c-39.6,25.1-59.3,71.9-49.8,117.9l28.9,139.6l14.8,52.1h1010.1c4.9,0,9.1-4.2,8.7-9.5
		L1192.7,755.8z"
				/>
				<path
					class="st2"
					d="M1253.9,678.2h-39.6c-3.8,0-6.8-3-6.8-6.8v-47.2c0-3.8-3-6.8-6.8-6.8h-108c-3.8,0-6.8-3-6.8-6.8v-92.8
		c0-3.8-3-6.8-6.8-6.8h-169c-3.8,0-6.8-3-6.8-6.8v-24.3c0-3.8-3-6.8-6.8-6.8H885c-3,0-5.3-1.9-6.5-4.6
		c-5.3-15.2-19.4-25.9-36.1-25.9s-31.2,11-36.1,25.9c-1.1,2.7-3.4,4.6-6.5,4.6h-11.4c-3.8,0-6.8,3-6.8,6.8v47.2c0,3.8-3,6.8-6.8,6.8
		H739c-3.8,0-6.8-3-6.8-6.8v-32.3c0-1.9-0.8-3.8-1.9-4.9L679.6,439c-2.7-2.7-6.8-2.7-9.5,0l-54,54c-1.1,1.5-1.9,3-1.9,4.9v89.4
		c0,3.8-3,6.8-6.8,6.8h-108c-3.8,0-6.8,3-6.8,6.8v92.8c0,3.8-3,6.8-6.8,6.8h-62.4c-3.8,0-6.8,3-6.8,6.8v62.4c0,3.8-3,6.8-6.8,6.8
		H370c-3.8,0-6.8-3-6.8-6.8v-62.4c0-3.8-3-6.8-6.8-6.8H187.5c-3.8,0-6.8,3-6.8,6.8v70c0,3.8-3,6.8-6.8,6.8H88.6
		c-3.8,0-6.8,3-6.8,6.8v136l384.9-0.2h794.1V701.1v-16C1260.8,681.3,1257.7,678.2,1253.9,678.2z"
				/>
				<g>
					<path
						class="st3"
						d="M766.7,422.6H764c-2.7,0-4.9-2.3-4.9-4.9V415c0-2.7,2.3-4.9,4.9-4.9h2.7c2.7,0,4.9,2.3,4.9,4.9v2.7
			C771.7,420.4,769.4,422.6,766.7,422.6z"
					/>
				</g>
				<g>
					<path
						class="st3"
						d="M267,447.7h-2.7c-2.7,0-4.9-2.3-4.9-4.9v-2.7c0-2.7,2.3-4.9,4.9-4.9h2.7c2.7,0,4.9,2.3,4.9,4.9v2.7
			C271.9,445.5,269.6,447.7,267,447.7z"
					/>
				</g>
				<path
					class="st4"
					d="M774.3,227.1h-2.7c-2.7,0-4.9-2.3-4.9-4.9v-2.7c0-2.7,2.3-4.9,4.9-4.9h2.7c2.7,0,4.9,2.3,4.9,4.9v2.7
		C779.3,224.9,777,227.1,774.3,227.1z"
				/>
				<path
					class="st4"
					d="M120.2,637.9h-2.7c-2.7,0-4.9-2.3-4.9-4.9v-2.7c0-2.7,2.3-4.9,4.9-4.9h2.7c2.7,0,4.9,2.3,4.9,4.9v2.7
		C125.1,635.6,122.8,637.9,120.2,637.9z"
				/>
				<path
					class="st4"
					d="M297.4,392.2h-2.7c-2.7,0-4.9-2.3-4.9-4.9v-2.7c0-2.7,2.3-4.9,4.9-4.9h2.7c2.7,0,4.9,2.3,4.9,4.9v2.7
		C302.3,389.9,300.1,392.2,297.4,392.2z"
				/>
				<path
					class="st5"
					d="M956.9,364.1h-2.7c-2.7,0-4.9-2.3-4.9-4.9v-2.7c0-2.7,2.3-4.9,4.9-4.9h2.7c2.7,0,4.9,2.3,4.9,4.9v2.7
		C961.8,361.8,959.5,364.1,956.9,364.1z"
				/>
				<path
					class="st6"
					d="M676.6,329.1L676.6,329.1c8.7,3,15.6,10.3,17.1,19.4l1.9,8.7c0.4,2.3,3.4,2.3,3.8,0l1.9-8.7
		c1.9-9.1,8.4-16.7,17.1-19.4l0,0c1.9-0.8,1.9-3,0-3.8l0,0c-8.7-3-15.6-10.3-17.1-19.4l-1.9-8.7c-0.4-2.3-3.4-2.3-3.8,0l-1.9,8.7
		c-1.9,9.1-8.4,16.7-17.1,19.4l0,0C674.7,326,674.7,328.7,676.6,329.1z"
				/>
				<path
					class="st6"
					d="M443.4,592.6L443.4,592.6c6.5,2.3,11,7.6,12.6,14.1l1.1,6.5c0.4,1.5,2.7,1.5,2.7,0l1.1-6.5
		c1.1-6.5,6.1-11.8,12.6-14.1l0,0c1.1-0.4,1.1-2.3,0-2.7l0,0c-6.5-2.3-11-7.6-12.6-14.1l-1.1-6.5c-0.4-1.5-2.7-1.5-2.7,0l-1.1,6.5
		c-1.1,6.5-6.1,11.8-12.6,14.1l0,0C441.9,590.3,441.9,592.2,443.4,592.6z"
				/>
				<g>
					<path
						class="st3"
						d="M590.6,403.6L590.6,403.6c6.5,2.3,11,7.6,12.6,14.1l1.1,6.5c0.4,1.5,2.7,1.5,2.7,0l1.1-6.5
			c1.1-6.5,6.1-11.8,12.6-14.1l0,0c1.1-0.4,1.1-2.3,0-2.7l0,0c-6.5-2.3-11-7.6-12.6-14.1l-1.1-6.5c-0.4-1.5-2.7-1.5-2.7,0l-1.1,6.5
			c-1.1,6.5-6.1,11.8-12.6,14.1l0,0C589.1,401.3,589.1,403.2,590.6,403.6z"
					/>
				</g>
				<path
					class="st4"
					d="M948.9,461.8L948.9,461.8c6.5,2.3,11,7.6,12.6,14.1l1.1,6.5c0.4,1.5,2.7,1.5,2.7,0l1.1-6.5
		c1.1-6.5,6.1-11.8,12.6-14.1l0,0c1.1-0.4,1.1-2.3,0-2.7l0,0c-6.5-2.3-11-7.6-12.6-14.1l-1.1-6.5c-0.4-1.5-2.7-1.5-2.7,0l-1.1,6.5
		c-1.1,6.5-6.1,11.8-12.6,14.1l0,0C947.8,459.5,947.8,461.4,948.9,461.8z"
				/>
			</KinesisElement>

			<KinesisElement tag="g" axis="x" :strength="2">
				<g>
					<g>
						<path
							class="st7"
							d="M1336.4,841.9c0.7-0.6,1.3-1.4,1.6-2.4l3.8-11.8c1.1-3.8-1.5-7.2-5.3-7.2h-11.6
				c-18.9-36.2-37.3-77.2-53.1-122.9c0.4-0.4,0.7-1,0.8-1.5l4.9-15.2c0.8-2.3-1.1-4.9-3.4-4.9h-9.6c-10.6-33.2-19.8-68.8-26.9-106.5
				c-12.2-64.7-16-123.9-15.2-174.9h0c0,0,0-0.1,0-0.1h0l4.9-9.9c1.5-3.4,2.7-7.2,2.7-11V355c0-3.4-2.7-6.1-6.1-6.1h-9.1v-9.1
				c0-3.4-2.7-6.1-6.1-6.1h-8.8v-55c0-2.6-2.2-4.9-4.9-4.9h-5.7c-2.6,0-4.9,2.3-4.6,4.9v55h-9.1c-3.4,0-6.1,2.7-6.1,6.1v9.1h-0.3
				h-9.1h-0.1c-3.4,0-6,2.6-6.1,6.1v18.6c0,3.8,1.2,7.6,2.7,11l4.9,9.9h0.1v15.2h0.1c0,5.7-0.1,11.6-0.2,17.5
				c-0.2,2.9-0.2,5.9-0.2,8.9c-0.5,16-1.6,32.7-3.1,49.9c-0.4,2.9-0.7,6-0.9,9c-0.7,7-1.5,14.1-2.4,21.2h-0.1
				c-0.7,5-1.5,10.3-2.3,15.2h0.3c-1.2,8.4-2.6,17-4.1,25.6c-0.3,1.4-0.5,2.9-0.8,4.2c-0.5,2.7-1,5.5-1.5,8.3
				c-0.5,2.5-1,5.1-1.5,7.6h-0.1c-0.1,1-0.3,1.9-0.4,2.8c-1.3,6.5-2.7,13-4.1,19.4c-0.7,3-1.4,6-2.1,9c-1,4.2-2,8.3-3,12.5
				c-0.2,0.6-0.3,1.3-0.5,1.9h0.1c-4.6,18.4-9.8,36.1-15.2,53.3h-9.6c-2.7,0-4.6,2.7-3.4,4.9l4.9,15.2c0.2,0.6,0.5,1.1,0.9,1.5
				c-15.9,45.6-34.2,86.7-53.1,122.9h-11.3c-3.8,0-6.5,3.8-5.3,7.2l3.8,11.8c0.3,0.9,0.8,1.6,1.4,2.3c-18.3,33-36.7,61.5-53.4,85.2
				h15.2c0,0,0-0.1,0.1-0.1h22.8l20.5-28.9c11-15.2,28.5-24.3,47.5-24.3c23.2,0,44.5,14.1,53.6,35.4l7.6,17.9h30.4c0,0,0,0.1,0,0.1
				h30.5l7.6-17.9c9.1-21.3,30.4-35.4,53.6-35.4c19,0,36.5,9.1,47.5,24.3l20.5,28.9h22.8h15.2h0
				C1373,903.2,1354.7,874.8,1336.4,841.9z M1169.2,789.7c0,4.6-3.4,8-8,8h-41.1c-5.3,0-9.5-5.7-7.6-11.1l24-60.1
				c1.5-3,4.2-4.9,7.6-4.9h17.1c4.6,0,8,3.4,8,8V789.7z M1207.2,394.6H1192c0-0.1,0-0.1,0-0.1h7.6h0.1h7.5
				C1207.2,394.5,1207.2,394.6,1207.2,394.6z M1263.9,797.7h-41.1c-4.6,0-8-3.4-8-8v-60c0-4.6,3.4-8,8-8h17.1c3.4,0,6.5,1.9,7.6,4.9
				l24,60.1C1273.4,792,1269.6,797.7,1263.9,797.7z"
						/>
						<g>
							<path
								class="st7"
								d="M1222.4,394.5c-0.8,51,3,110.3,15.2,175c30.4,161.7,99.6,282.6,152.1,357.5h-38l-20.5-28.9
					c-11-15.2-28.5-24.3-47.5-24.3c-23.2,0-44.5,14.1-53.6,35.4l-7.6,17.9H1192c0-7.6,0-532.5,0-532.5h30.4V394.5z M1214.8,789.7
					c0,4.6,3.4,8,8,8h41.1c5.7,0,9.5-5.7,7.6-11l-24-60.1c-1.1-3-4.2-4.9-7.6-4.9h-17.1c-4.6,0-8,3.4-8,8V789.7z"
							/>
							<path
								class="st8"
								d="M1146.4,569.5c12.2-64.7,16-124,15.2-175h30.4c0,0,0,524.9,0,532.5h-30.4l-7.6-17.9
					c-9.1-21.3-30.4-35.4-53.6-35.4c-19,0-36.5,9.1-47.5,24.3l-20.5,28.9h-38C1046.7,852.5,1115.9,731.1,1146.4,569.5z
					 M1120.1,797.7h41.1c4.6,0,8-3.4,8-8v-60.1c0-4.6-3.4-8-8-8h-17.1c-3.4,0-6.1,1.9-7.6,4.9l-24,60.1
					C1110.6,792,1114.8,797.7,1120.1,797.7z"
							/>
							<path
								class="st7"
								d="M1222.4,394.5l4.9-9.9c1.5-3.4,2.7-7.2,2.7-11V355c0-3.4-2.7-6.1-6.1-6.1h-24.3v45.6H1222.4z"
							/>
							<path
								class="st8"
								d="M1161.6,394.5l-4.9-9.9c-1.5-3.4-2.7-7.2-2.7-11V355c0-3.4,2.7-6.1,6.1-6.1h39.6v45.6H1161.6z"
							/>
							<path
								class="st7"
								d="M1169.2,348.9h45.6v-9.1c0-3.4-2.7-6.1-6.1-6.1h-33.1c-3.4,0-6.1,2.7-6.1,6.1v9.1H1169.2z"
							/>
							<path
								class="st7"
								d="M1189.3,272.8h5.7c2.7,0,4.9,2.3,4.9,4.9v55.9h-15.2v-55.9C1184.4,275.1,1186.7,272.8,1189.3,272.8z"
							/>
							<g>
								<path
									class="st9"
									d="M1146.4,569.5c12.2-64.7,16-124,15.2-175h15.2c0.8,51-3,110.3-15.2,175
						c-30.4,161.7-99.6,282.6-152.1,357.5h-15.2C1046.7,852.5,1115.9,731.1,1146.4,569.5z"
								/>
							</g>
							<g>
								<path
									class="st10"
									d="M1207.2,394.5h15.2c-0.8,51,3,110.3,15.2,175c30.4,161.7,99.6,282.6,152.1,357.5h-15.2
						c-52.5-74.9-121.7-195.9-152.1-357.5C1210.2,504.8,1206.8,445.8,1207.2,394.5z"
								/>
							</g>
							<path
								class="st10"
								d="M1114.8,698.8h154.4c1.5,0,3-1.1,3.4-2.7l4.9-15.2c0.8-2.3-1.1-4.9-3.4-4.9h-164.3c-2.7,0-4.6,2.7-3.4,4.9
					l4.9,15.2C1111.7,697.6,1113.3,698.8,1114.8,698.8z"
							/>
							<path
								class="st10"
								d="M1051.6,843.3h281.1c2.3,0,4.6-1.5,5.3-3.8l3.8-11.8c1.1-3.8-1.5-7.2-5.3-7.2h-288.7
					c-3.8,0-6.5,3.8-5.3,7.2l3.8,11.8C1047.1,841.8,1049,843.3,1051.6,843.3z"
							/>
							<rect
								x="1161.6"
								y="394.5"
								class="st7"
								width="30.4"
								height="15.2"
							/>
						</g>
						<g>
							<path
								class="st7"
								d="M1158.1,485.8h34.2V501H1157C1157,496.1,1157.4,490.7,1158.1,485.8z"
							/>
							<path
								class="st7"
								d="M1161.6,424.9h30.8v15.2h-31.2C1161.2,435.2,1161.2,429.9,1161.6,424.9z"
							/>
							<path
								class="st7"
								d="M1160.4,455.4h31.9v15.2h-32.7C1159.7,465.6,1160,460.3,1160.4,455.4z"
							/>
							<path
								class="st7"
								d="M1154.7,516.2h37.3v15.2h-39.6C1153.2,526.5,1154,521.2,1154.7,516.2z"
							/>
							<path
								class="st7"
								d="M1138.4,607.5h53.6v15.2h-57.4C1136.1,617.8,1137.2,612.8,1138.4,607.5z"
							/>
							<path
								class="st7"
								d="M1150.5,546.6h41.8v15.2h-44.5C1148.6,556.9,1149.8,551.6,1150.5,546.6z"
							/>
							<path
								class="st7"
								d="M1130.8,637.9h61.2v15.2h-65.4C1128.1,648.2,1129.2,643.3,1130.8,637.9z"
							/>
							<path
								class="st7"
								d="M1144.8,577.1h47.2v15.2h-50.2C1142.9,587.3,1144.1,582.4,1144.8,577.1z"
							/>
						</g>
						<path
							class="st9"
							d="M1160,348.9h15.2c-3.4,0-6.1,2.7-6.1,6.1v18.6c0,3.8,0.8,7.6,2.7,11l4.9,9.9h-15.2l-4.9-9.9
				c-1.5-3.4-2.7-7.2-2.7-11V355C1154,351.5,1156.6,348.9,1160,348.9z"
						/>
						<path
							class="st7"
							d="M1208.7,348.9h-15.2c3.4,0,6.1,2.7,6.1,6.1v18.6c0,3.8-0.8,7.6-2.7,11l-4.9,9.9h15.2l4.9-9.9
				c1.5-3.4,2.7-7.2,2.7-11V355C1214.8,351.5,1212.1,348.9,1208.7,348.9z"
						/>
					</g>
					<g>
						<path
							class="st11"
							d="M825.8,326h227.1c18.3,0,33.1-14.8,33.1-33.1l0,0c0-18.3-14.8-33.1-33.1-33.1h-17.1
				c-17.5,0-33.8-6.8-46.4-19l-17.1-17.1c-12.2-12.2-28.9-19-46.4-19h-51.3c-17.5,0-33.8,6.8-46.4,19L811,240.8
				c-12.2,12.2-19,28.9-19,46.4v6.1C792.3,311.2,807.6,326,825.8,326z"
						/>
						<path
							class="st12"
							d="M1052.9,259.5h-17.1c-17.5,0-33.8-6.8-46.4-19l-17.1-17.1c-12.2-12.2-28.9-19-46.4-19h-33.1
				c17.5,0,33.8,6.8,46.4,19l17.1,17.1c12.2,12.2,28.9,19,46.4,19h17.1c18.3,0,33.1,14.8,33.1,33.1s-14.8,33.1-33.1,33.1h33.1
				c18.3,0,33.1-14.8,33.1-33.1C1086,274.7,1071.1,259.5,1052.9,259.5z"
						/>
					</g>
				</g>
			</KinesisElement>

			<KinesisElement tag="g" axis="x" :strength="5">
				<rect x="841.7" y="683.6" class="st10" width="144.5" height="243.4" />
				<rect x="575.5" y="683.6" class="st8" width="266.2" height="243.4" />
				<rect x="628.8" y="706.4" class="st7" width="144.5" height="220.6" />
				<path
					class="st10"
					d="M573.6,797.7h414.6c3,0,5.7-2.7,5.7-5.7v-11.4c0-3-2.7-5.7-5.7-5.7H573.6c-3,0-5.7,2.7-5.7,5.7V792
		C567.9,795,570.6,797.7,573.6,797.7z"
				/>
				<path
					class="st0"
					d="M879.8,927h68.5v-79.9c0-19-15.2-34.2-34.2-34.2l0,0c-19,0-34.2,15.2-34.2,34.2L879.8,927L879.8,927z"
				/>
				<path
					class="st8"
					d="M575.5,660.7h266.2v-45.6H583.1l-6.1,12.2c-1.1,2.3-1.5,4.6-1.5,6.8V660.7z"
				/>
				<path
					class="st10"
					d="M841.7,660.7h144.5v-27c0-2.3-0.4-4.6-1.5-6.8l-6.1-12.2H841.7V660.7z"
				/>
				<path
					class="st10"
					d="M575.5,698.8L563.7,687c-2.3-2.3-3.4-4.9-3.4-8v-10.7c0-4.2,3.4-7.6,7.6-7.6h273.8v38L575.5,698.8
		L575.5,698.8z"
				/>
				<path
					class="st13"
					d="M986.3,698.8l11.8-11.8c2.3-2.3,3.4-4.9,3.4-8v-10.7c0-4.2-3.4-7.6-7.6-7.6H841.7v38L986.3,698.8L986.3,698.8
		z"
				/>
				<path class="st10" d="M841.7,569.5H971c4.2,0,7.6,3.4,7.6,7.6v38H841.7V569.5z" />
				<path
					class="st8"
					d="M841.7,615.1H583.1v-38c0-4.2,3.4-7.6,7.6-7.6h251L841.7,615.1L841.7,615.1z"
				/>
				<path
					class="st10"
					d="M981.7,599.9H580.1c-2.7,0-4.6-1.9-4.6-4.6v-6.1c0-2.7,1.9-4.6,4.6-4.6h401.6c2.7,0,4.6,1.9,4.6,4.6v6.1
		C986.3,598,984.4,599.9,981.7,599.9z"
				/>
				<rect x="826.5" y="797.7" class="st7" width="15.2" height="129.3" />
				<rect x="826.5" y="698.8" class="st7" width="15.2" height="76.1" />
				<rect x="826.5" y="599.9" class="st7" width="15.2" height="60.9" />
				<rect x="826.5" y="569.5" class="st7" width="15.2" height="15.2" />
				<rect x="583.1" y="599.9" class="st7" width="258.6" height="15.2" />
				<rect x="575.5" y="698.8" class="st7" width="258.6" height="15.2" />
				<rect x="575.5" y="797.7" class="st7" width="258.6" height="15.2" />
				<path
					class="st2"
					d="M644,927h114.1V786.3c0-31.6-25.5-57.1-57-57.1l0,0c-31.6,0-57,25.5-57,57.1L644,927L644,927z"
				/>
				<path
					class="st1"
					d="M701,729.2c9.5,0,18.6,2.3,26.6,6.5c-18.3,9.5-30.4,28.5-30.4,50.6V927H644V786.3
		C644,754.7,669.5,729.2,701,729.2z"
				/>
				<path
					class="st13"
					d="M841.7,797.7h146.8c3,0,5.3-2.3,5.3-5.3v-12.6c0-3-2.3-5.3-5.3-5.3H841.7V797.7z"
				/>
				<path
					class="st13"
					d="M841.7,599.9h140.7c1.9,0,3.8-1.5,3.8-3.8v-7.6c0-1.9-1.5-3.8-3.8-3.8H841.7V599.9z"
				/>
				<path
					class="st13"
					d="M971.8,569.5c3.8,0,6.8,3,6.8,6.8v34.6c0,2.7,0.8,5.3,1.9,7.6l4.2,8.4c1.1,2.3,1.5,4.6,1.5,6.8V927H971V634.1
		c0-2.7-0.8-5.3-1.9-7.6l-4.2-8c-1.1-2.3-1.9-4.9-1.9-7.6v-41.8h8.7v0.4H971.8z"
				/>
				<path
					class="st13"
					d="M898.8,816.3c4.6-2.3,9.9-3.4,15.2-3.4c19,0,34.2,15.2,34.2,34.2V927h-30.4v-79.9
		C917.8,833.8,910.2,822,898.8,816.3z"
				/>
			</KinesisElement>

			<KinesisElement tag="g" axis="x" :strength="10">
				<g>
					<path
						class="st14"
						d="M57,927l20.9-36.5c9.9-17.1,30.4-24.7,48.7-17.9l0,0c17.5,6.5,36.5,0.4,47.2-14.8c1.9-3,4.2-5.7,6.1-8.7
			c8.7-12.6,24.3-19,39.6-16.7l18.6,3c13.7,2.3,25.5,11.8,30.8,24.7l3.8,9.5c5.7,14.1,20.5,22.1,35.8,18.6l0,0
			c14.1-3,28.1,3.8,34.6,16.4l10.6,22.4H57z"
					/>
					<g>
						<g>
							<g>
								<path
									class="st5"
									d="M246.7,845.2c-15.2-2.7-30.4,3.8-39.6,16.7l-6.5,9.1c-10.3,14.8-29.7,20.9-46.8,14.8l0,0
						c-18.3-6.5-38.4,0.8-48.3,17.5l-14.1,24h-35l21.7-36.9c9.9-16.7,30-24,48.3-17.5l0,0c17.1,6.1,36.1,0,46.8-14.8l6.5-9.1
						c8.7-12.6,24.3-19,39.6-16.7l18.6,3c8.7,1.5,16.7,5.7,22.4,12.2L246.7,845.2z"
								/>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path
							class="st15"
							d="M1053.3,545.4h-68.4c-8.7,0-16.4,4.8-20.4,12.5l0,0c-3.9,7.8-11.7,12.5-20.4,12.5h-16.4
				c-4.8,0-8.4,3.6-8.4,8.4v58.6c0,4.8,3.6,8.4,8.4,8.4h78.1c8.7,0,16.4-4.8,20.4-12.5l0,0c3.9-7.8,11.7-12.5,20.4-12.5h7v-75.3
				H1053.3z"
						/>
						<g>
							<path
								class="st3"
								d="M1025.8,578.6c-2.8-4.8-11-5-23.6-1.1c-6.2,2.2-12.9,5.3-19.7,8.9c-4.8,2.8-9.3,5.9-13.2,8.9
					c0-0.6,0.3-1.1,0.3-1.7c2.6-9.2,10.7-15.9,20.6-15.9h0.3c1.4,0,2.6-1.1,2.6-2.5s-1.1-2.5-2.6-2.5c-11.8-0.3-22.5,7.8-25.6,19.5
					c-0.6,2.5-0.9,4.8-0.9,7.3c-9,8.1-12.4,15.3-9.5,20.1c1.4,2.5,4.5,3.9,9,3.9c3.7,0,7.9-0.8,12.9-2.5c2,1.1,4.3,2.2,6.8,2.8
					c2.3,0.6,4.3,0.8,6.5,0.8c4.5,0,9-1.1,12.9-3.6c5.9-3.4,10.1-8.9,11.8-15.9c0.6-2.5,0.9-4.8,0.9-7.3
					C1025,590.3,1028.3,583,1025.8,578.6z M968.4,609.4c-0.6,0.8-0.9,2-0.3,2.8c1.1,2,2.6,3.4,4,5c-8.1,2.2-12.4,1.1-12.9-0.3
					c-0.9-1.7,0.9-6.7,9.5-14.8c4.8-4.2,10.4-8.4,16.9-12c5.9-3.4,11.8-6.2,17.8-8.1c6.2,5.8,9.5,14.4,7.3,22.5
					c-1.4,5.3-4.8,9.7-9.5,12.5c-4.8,2.8-10.4,3.6-16.1,2.2c-4.8-1.1-8.7-3.9-11.5-7.8c2.8-2.8,7.6-7,14.1-10c1.1-0.6,1.7-2,1.1-3.4
					c-0.6-1.1-2-1.7-3.4-1.1 M1008.3,580.8c8.1-2.2,12.4-1.1,12.9,0.3c0.9,1.4-0.3,5.3-6.2,11.4
					C1013.9,588.3,1011.7,583.8,1008.3,580.8z"
							/>
						</g>
						<path
							class="st1"
							d="M1053.3,854.8V541.2c0-2.2,2-4.2,4.2-4.2s4.2,2,4.2,4.2v313.6L1053.3,854.8L1053.3,854.8z"
						/>
					</g>
					<g>
						<path
							class="st15"
							d="M1136.4,722.7l-3.9,3.9c-1.7,1.7-2.5,3.9-2.5,6.2v5c0,4.8,3.9,8.6,8.7,8.6s8.7-3.9,8.7-8.6v-21.5h-10.9
				L1136.4,722.7L1136.4,722.7z"
						/>
						<path
							class="st15"
							d="M1074.2,693.7l-3.9-3.9c-1.7-1.7-3.9-2.5-6.2-2.5h-5c-4.8,0-8.7,3.9-8.7,8.6s3.9,8.6,8.7,8.6h25.7V694
				h-10.6V693.7L1074.2,693.7z"
						/>
						<path
							class="st16"
							d="M1099.6,767.8v14.5l-7.6,2.8c-1.4,0.6-2,2-2,3.4v2c0,1.7,1.4,3.4,3.4,3.4h16.2c1.7,0,3.4-1.4,3.4-3.4v-22.6
				L1099.6,767.8L1099.6,767.8z"
						/>
						<path
							class="st16"
							d="M1128.3,767.8l2.5,14.5l-7,2.8c-1.1,0.6-1.7,2-1.4,3.4l0.6,2.5c0.3,1.7,1.7,2.8,3.1,2.8h15.9
				c2,0,3.6-1.7,3.1-3.9l-3.9-22L1128.3,767.8L1128.3,767.8z"
						/>
						<path
							class="st17"
							d="M1104.3,673.3h-0.6c-4.8,0-8.4-3.6-8.4-8.4V656c0-4.8,3.6-8.4,8.4-8.4h0.6c4.8,0,8.4,3.6,8.4,8.4v8.9
				C1112.7,669.7,1109.1,673.3,1104.3,673.3z"
						/>
						<path
							class="st15"
							d="M1109.7,678.1c-4.3-0.7-7.6-4.9-7.6-9.5V651c0-6.4,5.3-11.7,11.7-11.7h1.6c-0.1,0-0.3-0.1-0.4-0.1h8.7
				c7,0,12.8,5.9,12.8,12.8v12c0,4.5,2.5,8.9,6.4,11.1c1.1,0.7,2.1,1.5,3,2.4L1109.7,678.1z"
						/>
						<path
							class="st16"
							d="M1108.5,686.1c-2.2-2.8-6.2-3.1-9.2-0.8l-8.4,6.2h-16.7c-1.1,0-2.2,0.8-2.2,2.2v8.6c0,1.1,0.8,2.2,2.2,2.2
				h16.7c2.8,0,5.3-0.8,7.6-2.5l8.9-6.4C1110.2,693.4,1110.7,689.2,1108.5,686.1z"
						/>
						<g>
							<path
								class="st16"
								d="M1142.9,675.2c-3.9-2.2-6.4-6.7-6.4-11.1v-12c0-7-5.9-12.8-12.8-12.8h-8.7c4.8,0,8.7,3.9,8.7,8.6v17.3
					c0,7-5.9,12.8-12.8,12.8l0,0v17.3h11.6v25.6h26.9v-34.3C1149.2,681.7,1146.7,677.5,1142.9,675.2z"
							/>
							<path
								class="st2"
								d="M1131.9,720.7c-4.8,0-8.7-3.9-8.7-8.6v-21.5c0-7-5.9-12.8-12.8-12.8l0,0c-7,0-12.8,5.9-12.8,12.8v77.2
					c0,3.6,2.8,6.4,6.4,6.4h4.2c3.6,0,6.4-2.8,6.4-6.4v-29.5c0-2.2,1.4-4.2,3.6-4.8c2.8-0.6,5.3,1.4,5.6,3.9l3.4,32.9
					c0.3,3.4,3.1,5.9,6.4,5.9h4.2c3.9,0,6.7-3.4,6.4-7l-3.6-35.7c4.8,0,8.7-3.9,8.7-8.6v-4.2L1131.9,720.7L1131.9,720.7z"
							/>
							<path
								class="st16"
								d="M1108.5,686.1c-2.2-2.8-6.2-3.1-9.2-0.8l-8.4,6.2h-16.7c-1.1,0-2.2,0.8-2.2,2.2v8.6c0,1.1,0.8,2.2,2.2,2.2
					h16.7c2.8,0,5.3-0.8,7.6-2.5l8.9-6.4C1110.2,693.4,1110.7,689.2,1108.5,686.1z"
							/>
						</g>
						<path
							class="st17"
							d="M1149.2,694.8h-13.1c-2.2,0-4.2-2-4.2-4.2v-0.3c0-2.2,2-4.2,4.2-4.2h13.1V694.8z"
						/>
						<path
							class="st2"
							d="M1149.2,707.6h-13.9c-1.7,0-3.1-1.4-3.1-3.1v-0.2c0-1.7,1.4-3.1,3.1-3.1h13.9V707.6z"
						/>
					</g>
					<path
						class="st18"
						d="M877.8,927.2l43.8-56.3c10.6-13.6,26.8-21.8,44.1-21.8h31c12.3,0,23.2-8.1,26.8-19.8l4.8-15.9
			c3.6-11.7,14.5-19.8,26.8-19.8h114.1c11.4,0,21.8,7,26,17.6l7.8,19.5c4.8,12,13.6,22,25.1,28.1l26.3,14.2
			c11.4,6.2,20.4,16.2,25.1,28.1l10.3,25.9H877.8L877.8,927.2z"
					/>
					<path
						class="st19"
						d="M1066.1,825.5l-4.5,13.4c-7.6,22.9-29.1,38.2-53,38.2h-8.4c-21.8,0-41.8,12.8-50.8,32.6l-8.1,17.6h-63.9
			l43.8-56.3c10.6-13.6,26.8-21.8,44.1-21.8h31c12.3,0,23.2-8.1,26.8-19.8l4.8-15.9c3.6-11.7,14.5-19.8,26.8-19.8h114.1
			c11.2,0,21.2,6.7,25.7,16.7h-107.1C1077.6,810.2,1069.2,816.4,1066.1,825.5z"
					/>
					<path
						class="st19"
						d="M1073.1,927.2l21.8-56.6c3.6-9.7,12.8-15.9,23.2-15.9h45.8c9.2,0,17.6,5.6,20.6,14.2l22.3,58.3H1073.1z"
					/>
					<path
						class="st4"
						d="M1111.6,887.4c3.6-9.7,12.8-15.9,23.2-15.9h45.7c1.7,0,3.6,0.3,5.3,0.6l-1.1-3.1
			c-3.4-8.6-11.4-14.2-20.6-14.2h-45.8c-10.3,0-19.5,6.4-23.2,15.9l-21.8,56.6h23.2L1111.6,887.4z"
					/>
				</g>
				<path
					class="st5"
					d="M1242.7,927.2l-23.3-30.7c-1.8-2.3,0.5-5.5,3.2-5c6.4,1.8,13.3,4.1,20.1,7.3c7.3,3.2,13.7,6.4,19.2,9.6V848
		c0-2.7,3.2-3.7,4.6-1.8c4.6,5.9,10.1,13.7,14.6,23.3c6.9,15.5,9.1,29.7,9.6,38.4c3.7-5,9.6-12.8,19.2-19.2c4.1-2.7,8.2-5,12.3-6.9
		c2.7-1.4,5.5,1.4,4.1,4.1c-5.5,13.7-11,27.4-16.5,41.2L1242.7,927.2L1242.7,927.2z"
				/>
			</KinesisElement>
		</svg>
	</div>
</template>

<script>
import { KinesisElement } from 'vue-kinesis';

export default {
	name: 'AuthLayoutBackground',
	components: {
		KinesisElement,
	},
};
</script>

<style lang="scss" scoped>
.background {
	background-color: #421f54;

	.svg {
		position: fixed;
		bottom: 0;

		.st0 {
			fill: #421f54;
		}
		.st1 {
			fill: #50255f;
		}
		.st2 {
			fill: #341644;
		}
		.st3 {
			fill: #ffffff;
		}
		.st4 {
			fill: #b57fad;
		}
		.st5 {
			fill: #55d38c;
		}
		.st6 {
			fill: #e0b76c;
		}
		.st7 {
			fill: #b882d8;
		}
		.st8 {
			fill: #dea4ea;
		}
		.st9 {
			fill: #ebb8f4;
		}
		.st10 {
			fill: #9762b7;
		}
		.st11 {
			fill: #f5e7f7;
		}
		.st12 {
			fill: #e8bced;
		}
		.st13 {
			fill: #794299;
		}
		.st14 {
			fill: #45ab71;
		}
		.st15 {
			fill: #d94f86;
		}
		.st16 {
			fill: #a63d86;
		}
		.st17 {
			fill: #3bc4ad;
		}
		.st18 {
			fill: #684869;
		}
		.st19 {
			fill: #876080;
		}
	}
}
</style>
