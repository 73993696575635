import MultipleChoice from './MultipleChoice';
import Dropdown from './Dropdown';
import ClickWord from './ClickWord';
import InsertWord from './InsertWord';
import FreeText from './FreeText';
import ReadText from './ReadText';
import Task from './Task';

export function newTask(task) {
	if (!task) {
		return null;
	}

	switch (task?.type?.machine_name) {
		case 'multiplechoice':
			return new MultipleChoice(task);
		case 'dropdown':
			return new Dropdown(task);
		case 'clickword':
			return new ClickWord(task);
		case 'insertword':
			return new InsertWord(task);
		case 'freetext':
			return new FreeText(task);
		case 'readtext':
			return new ReadText(task);
		default:
			return new Task(task);
	}
}
