<template>
	<AppMessage
		v-if="showAppNotification"
		:type="notification.type"
		:dismissible="notification.dismissible"
		:message="notification.message"
		@dismiss="dismiss"
	/>
</template>

<script>
import { cacheItem, getCachedItem } from '@/store/cache';
import AppMessage from '@/components/ui/AppMessage';
import dayjs from 'dayjs';

export default {
	name: 'AppNotification',
	components: { AppMessage },
	props: {
		notification: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			showAppNotification: false,
		};
	},
	computed: {
		updatedAtTimestamp() {
			return dayjs(this.notification.updated_at).unix();
		},
		cacheKey() {
			return `appNotificationDismissed-${this.notification.id}-${this.updatedAtTimestamp}`;
		},
	},
	created() {
		if (!this.warningHasBeenDismissed()) {
			this.showAppNotification = true;
		}
	},
	methods: {
		warningHasBeenDismissed() {
			return getCachedItem(this.cacheKey) !== null;
		},
		dismiss() {
			this.showAppNotification = false;
			cacheItem(this.cacheKey, dayjs());
		},
	},
};
</script>
