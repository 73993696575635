import http from './http';

export function getAppNotifications() {
	return http.get('/notifications/app').then(resp => resp.data.data);
}

export function getNotifications(schoolId) {
	return http.get(`/schools/${schoolId}/user/notifications`).then(resp => resp.data.data);
}

export function markAsRead(schoolId, notificationId) {
	return http.delete(`/schools/${schoolId}/user/notifications/${notificationId}`);
}

export function markAllAsRead(schoolId) {
	return http.delete(`/schools/${schoolId}/user/notifications`);
}
