import Task from './Task';
import Answer from '@/models/answer/Answer';

export default class Dropdown extends Task {
	get groupedDropdownValues() {
		return this.conf.options.reduce((grouped, item) => {
			const key = item.group;
			(grouped[key] = grouped[key] || []).push(item);
			return grouped;
		}, {});
	}

	getGroupedAnswers(answer) {
		if (!(answer instanceof Answer) || !answer.conf) {
			return [];
		}
		return answer.conf.options.reduce((grouped, option) => {
			const key = option.group;
			grouped[key] = option;
			return grouped;
		}, {});
	}
}
