let mouseoverTimeout = null;
let mouseoverState = 0; // 0 = off, 1 = mouseover, 2 = clicked

function getTooltipElements(context) {
	return Array.from(context.querySelectorAll('.glossaryTooltip'));
}

function handleMouseenter(event) {
	clearTimeout(mouseoverTimeout);
	const target = event.currentTarget;
	mouseoverTimeout = setTimeout(() => {
		mouseoverState = 1;
		showGlossaryTooltip(target);
	}, 500);
}

function handleMouseleave() {
	clearTimeout(mouseoverTimeout);
	if (mouseoverState === 2) {
		// Cancel if tooltip is opened by clicking
		return;
	}
	window.dispatchEvent(new CustomEvent('hide-glossary-tooltip'));
	mouseoverState = 0;
}

function handleClick(event) {
	// Clear the mouseover timeout.
	clearTimeout(mouseoverTimeout);
	mouseoverState = 2;

	// Since this is a click event we want to prevent the default action.
	event.preventDefault();

	const target = event.currentTarget;
	showGlossaryTooltip(target);
}

function showGlossaryTooltip(target) {
	window.dispatchEvent(
		new CustomEvent('show-glossary-tooltip', {
			detail: {
				target,
				id: +target.dataset.id,
			},
		}),
	);
}

export default {
	methods: {
		setupGlossaryTooltipListeners(context) {
			if (!context) {
				return;
			}
			for (const element of getTooltipElements(context)) {
				element.addEventListener('click', handleClick);
				element.addEventListener('mouseenter', handleMouseenter);
				element.addEventListener('mouseleave', handleMouseleave);
			}
		},
		unsetGlossaryTooltipListeners(context) {
			if (!context) {
				return;
			}
			for (const element of getTooltipElements(context)) {
				element.removeEventListener('click', handleClick);
				element.removeEventListener('mouseenter', handleMouseenter);
				element.removeEventListener('mouseleave', handleMouseleave);
			}
		},
	},
	beforeDestroy() {
		this.unsetGlossaryTooltipListeners(this.$el);
	},
};
