<template>
	<div>
		<div class="is-clickable" @click="showPopup">
			<div class="is-flex is-flex-direction-column is-align-items-center has-text-centered">
				<div class="pb-5">
					<div class="is-relative">
						<img
							:src="achievement.badge_image_url"
							width="130"
							alt="achievement badge"
							:class="{ 'is-incomplete': achievedLevels === 0 }"
						/>

						<div
							v-for="starIndex in achievement.levels.length"
							:key="starIndex"
							:style="starStyle(starIndex, 3)"
						>
							<BaseIcon
								:color="
									achievedLevels >= starIndex
										? 'yellow-dark'
										: 'grey-light'
								"
								icon="star"
								:size="3"
								no-padding
							/>
						</div>
					</div>
				</div>
				<div class="progress-wrapper mx-auto">
					<BaseProgressBar
						v-if="progress < 100"
						class="progressbar my-4"
						:progress="progress"
						:color-name="achievement.color"
						height="small"
					/>
					<BaseCheckbox
						v-if="progress === 100"
						class="my-1"
						:active-color="achievement.color"
						:selected="true"
						:rounded="true"
					/>
				</div>
				<div class="is-size-6 is-family-secondary">{{ achievement.title }}</div>
				<div class="is-size-8">{{ achievement.description }}</div>
			</div>
		</div>

		<SplitModal
			ref="achievementModal"
			:image-width="5"
			:left-column-classes="[modalBackground, 'has-text-centered', 'has-fixed-img']"
		>
			<template #img>
				<div class="my-6 mx-auto pb-6" style="width: 250px">
					<div class="is-relative">
						<img
							:src="achievement.badge_image_url"
							alt="achievement badge"
							:class="{ 'is-incomplete': achievedLevels === 0 }"
						/>

						<div
							v-for="starIndex in achievement.levels.length"
							:key="starIndex"
							:style="starStyle(starIndex, 8)"
						>
							<BaseIcon
								:color="
									achievedLevels >= starIndex
										? 'yellow'
										: 'grey-light'
								"
								icon="star"
								:size="8"
								no-padding
							/>
						</div>
					</div>
				</div>
			</template>

			<template #body>
				<p class="title is-3 mb-0">{{ achievement.title }}</p>
				<p class="mb-5 is-flex is-align-items-center">
					{{ achievement.description }}
					<ExerciseIcon v-if="difficulty" class="ml-2" :type="exerciseIcon" />
				</p>
				<div class="mb-5">
					<div
						v-for="level in achievement.levels"
						:key="level.id"
						class="columns is-vcentered is-gapless"
					>
						<div class="column is-2">
							<div class="is-flex is-align-items-center">
								<BaseIcon
									:color="
										isLevelCompleted(level)
											? 'yellow'
											: 'grey'
									"
									icon="star"
									:size="3"
									no-padding
								/>
							</div>
						</div>
						<div class="column is-relative">
							<span :class="{ 'completed-step': isLevelCompleted(level) }">
								{{ getLevelText(level) }}
							</span>
							<div style="position: absolute; width: 60%">
								<BaseProgressBar
									class="progressbar"
									:progress="getLevelProgress(level)"
									:color-name="achievement.color"
									height="mini"
								/>
							</div>
						</div>
					</div>
				</div>
			</template>

			<template #footer>
				<div class="progress-wrapper">
					<BaseProgressBar
						class="progressbar"
						:progress="progress"
						:color-name="achievement.color"
					/>
					<BaseCheckbox
						v-if="progress === 100"
						:active-color="achievement.color"
						:selected="true"
						:rounded="true"
						class="ml-4"
					/>
				</div>
			</template>
		</SplitModal>
	</div>
</template>

<script>
import icon from '@/mixins/icon';
import BaseIcon from '@/components/base/BaseIcon';
import SplitModal from '@/components/ui/modal/SplitModal';
import BaseProgressBar from '@/components/base/BaseProgressBar';
import BaseCheckbox from '@/components/base/input/BaseCheckbox';
import ExerciseIcon from '@/components/exercises/ExerciseIcon';

export default {
	name: 'AchievementCard',
	components: {
		BaseIcon,
		SplitModal,
		BaseProgressBar,
		BaseCheckbox,
		ExerciseIcon,
	},
	mixins: [icon],
	props: {
		achievement: {
			type: Object,
			required: true,
		},
	},
	computed: {
		icon() {
			let filename = this.achievement.machine_name;

			if (this.achievement.step != 0) {
				return `${filename}-${this.achievement.step}.svg`;
			}

			return `${filename}.svg`;
		},
		exerciseIcon() {
			if (this.difficulty) {
				return this.getExerciseIcon(this.difficulty);
			}

			return null;
		},
		modalBackground() {
			return this.achievedLevels == 0
				? 'has-background-grey-lightest'
				: `has-background-${this.achievement.color}-dark`;
		},
		progress() {
			for (let i = 0; i < this.achievement.levels.length; i++) {
				const level = this.achievement.levels[i];
				const userAchievementLevel = level.user_achievement_level;
				if (!userAchievementLevel) {
					if (level.amount === null) {
						// if the amount of the level is null, then it is a binary level
						// in which case progress indicates the total level progress
						return Math.min(
							100,
							Math.floor(
								(this.achievedLevels / this.achievement.levels.length) *
									100 *
									100,
							) / 100,
						);
					} else {
						return 0;
					}
				} else if (userAchievementLevel.achieved_at === null) {
					let progress = 0;
					if (i === 0) {
						progress = (userAchievementLevel.amount / level.amount) * 100;
					} else {
						const prevLevel = this.achievement.levels[i - 1];
						if (
							prevLevel.type !== level.type ||
							prevLevel.difficulty !== level.difficulty
						) {
							progress = (userAchievementLevel.amount / level.amount) * 100;
						} else {
							progress =
								((userAchievementLevel.amount - prevLevel.amount) /
									(level.amount - prevLevel.amount)) *
								100;
						}
					}
					return Math.min(100, Math.floor(progress * 100) / 100);
				}
			}
			return 100;
		},
		achievedLevels() {
			const userAchievementLevels = this.achievement.levels.map(l => l.user_achievement_level);
			return userAchievementLevels.filter(ual => ual && ual.achieved_at !== null).length;
		},
		difficulty() {
			return Math.max.apply(
				Math,
				this.achievement.levels.map(level => level.difficulty),
			);
		},
	},
	methods: {
		showPopup() {
			this.$refs.achievementModal.show();
		},
		isLevelCompleted(level) {
			return level.user_achievement_level && level.user_achievement_level.achieved_at !== null;
		},
		starStyle(starIndex, size) {
			const degrees = 45 + this.achievement.levels.length * 10;
			const range = 2 * Math.PI * (degrees / 360);
			const offset = 2 * Math.PI * ((90 - degrees / 2) / 360);
			const factor = (starIndex - 0.5) / this.achievement.levels.length;
			// const narrowFactor = 0.6;
			const narrowFactor = 0.54 + this.achievement.levels.length * 0.02;
			const left = Math.round(Math.cos(offset + factor * range) * 100) * narrowFactor * -1;
			const top = Math.round(Math.sin(offset + factor * range) * 100 - 4);
			const halfWidth = ((size + 1) * 7) / 2;
			return {
				position: 'absolute',
				top: top + '%',
				left: `calc(${50 + left}% - ${halfWidth}px)`,
			};
		},
		getLevelText(achievementLevel) {
			let res = '';
			switch (achievementLevel.type) {
				case 'completed_exercises':
					res += `Løs ${achievementLevel.amount} øvelser`;
					break;
				case 'points':
					res += `Optjen ${achievementLevel.amount} point`;
					break;
				case 'minimum_points':
					res += `Få ${achievementLevel.value} point i ${achievementLevel.amount} øvelser`;
					break;
				case 'login_days':
					res += `${achievementLevel.amount} dage`;
					break;
				case 'completed_homework':
					res += `Løs ${achievementLevel.amount} lektier`;
					break;
				case 'change_profile_image':
					res += 'Skift profilbillede';
					break;
				case 'fill_email':
					res += 'Udfyld mail';
					break;
				case 'attach_uni_login':
					res += 'Tilknyt UNI-Login';
					break;
			}
			switch (achievementLevel.difficulty) {
				case 1:
				case 2:
				case 3:
					res += ` på niveau ${achievementLevel.difficulty}`;
					break;
				case 4:
					res += ' eksamensopgaver';
					break;
			}
			return res;
		},
		getLevelProgress(achievementLevel) {
			if (!achievementLevel.user_achievement_level) {
				return 0;
			}
			if (achievementLevel.amount === null) {
				return 100;
			} else {
				return (achievementLevel.user_achievement_level.amount / achievementLevel.amount) * 100;
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.completed-step {
	text-decoration: line-through;
}
.progress-wrapper {
	display: flex;
	align-items: center;
}
.is-clickable .progress-wrapper {
	display: flex;
	align-items: center;

	.progressbar {
		width: 48px;
		margin-top: 0.2rem;
	}
}
.is-incomplete {
	filter: grayscale(80%);
	opacity: 0.4;
}
</style>
