<template>
	<g>
		<text x="0" y="24">PO</text>
	</g>
</template>

<script>
export default {
	name: 'PrepositionObjectSymbol',
	props: {
		hasMultipleWords: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.$emit('config', {
				symbolWidth: this.$el.getBoundingClientRect().width,
				lineLeft: this.hasMultipleWords,
				lineRight: this.hasMultipleWords,
			});
		});
	},
};
</script>
