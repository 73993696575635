import Designation from '@/models/designation/Designation';

const LANGUAGES = ['danish', 'english', 'german', 'spanish', 'french', 'italian', 'swedish', 'chinese', 'noread'];
const LANGUAGES_REGEX = new RegExp(`([(]{1}[/]?(${LANGUAGES.join('|')})[)]{1})`, 'g');

export function makeDesignation(designation = {}) {
	return designation instanceof Designation ? designation : new Designation(designation);
}

export function convertLanguageTags(text) {
	return (text ?? '').replace(LANGUAGES_REGEX, (_, match, language) => {
		return match.startsWith('(/') ? '</lang>' : `<lang xml:lang="${language}">`;
	});
}

export function designate(text = '', designation = {}, { ignoreLanguageTags = false } = {}) {
	if (!(designation instanceof Designation)) {
		designation = new Designation(designation);
	}
	if (!ignoreLanguageTags) {
		text = convertLanguageTags(text);
	}
	const regex = /\[([^/]+)\/([^/]+)\/(\d+)\]/gi;
	return (text ?? '').replace(regex, (...matches) => {
		const groupId = matches[3];
		const index = designation.getDesignationIndex(groupId);
		return matches[index] ?? matches[0];
	});
}
