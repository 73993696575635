<template>
	<div @mouseenter="isHovered = true" @mouseleave="isHovered = false">
		<slot :is-hovered="isHovered" />
	</div>
</template>

<script>
export default {
	name: 'BaseHoverable',
	data() {
		return {
			isHovered: false,
		};
	},
};
</script>
