<template>
	<div class="columns is-desktop is-variable is-2-mobile is-0-desktop mx-0">
		<div class="column px-0" :class="[`is-${schoolClass ? '6' : '12'}`]">
			<p class="title pl-4 mb-3">Vælg hold</p>
			<BaseDropdownSelectItem
				v-for="(option, index) in schoolClasses"
				:key="`option-${index}`"
				:selected="schoolClassIsSelected(option)"
				@click.native="selectSchoolClass(option)"
			>
				<slot name="option" :option="option" :index="index">
					<strong class="has-text-grey-darker">{{ option.name }}</strong>
				</slot>
			</BaseDropdownSelectItem>
		</div>
		<div v-if="students.length" class="column is-6 px-0">
			<p class="title pl-4 mb-3">Vælg elev</p>
			<BaseDropdownSelectItem
				v-for="(option, index) in students"
				:key="`option-${index}`"
				:selected="studentIsSelected(option)"
				@click.native="selectStudent(option)"
			>
				<slot name="option" :option="option" :index="index">
					<span class="student-name">{{ option.name }}</span>
				</slot>
			</BaseDropdownSelectItem>
		</div>
	</div>
</template>

<script>
import BaseDropdownSelectItem from '@/components/base/dropdown/BaseDropdownSelectItem';

export default {
	name: 'StudentImpersonationSelector',
	components: {
		BaseDropdownSelectItem,
	},
	props: {
		schoolClass: {
			type: Object,
			default: null,
		},
		student: {
			type: Object,
			default: null,
		},
		schoolClasses: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		students() {
			return this.schoolClass
				? [...this.schoolClass.students].sort((a, b) => ('' + a.name).localeCompare(b.name))
				: [];
		},
	},
	methods: {
		schoolClassIsSelected(schoolClass) {
			return schoolClass.id === this.schoolClass?.id;
		},
		selectSchoolClass(schoolClass) {
			this.$emit('select-school-class', schoolClass);
		},
		studentIsSelected(student) {
			return student.id === this.student?.id;
		},
		selectStudent(student) {
			this.$emit('select-student', student);
		},
	},
};
</script>

<style lang="scss" scoped>
:deep(.student-name) {
	text-overflow: ellipsis;
	max-width: 14ch;
	overflow: hidden;
	white-space: nowrap;
	display: inline-block;
	vertical-align: middle;
}
</style>
