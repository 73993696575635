<template>
	<ul>
		<template v-for="(section, i) in sections">
			<HomeworkSectionListItem
				:key="`section-${section.id}`"
				:homework="homework"
				:section="section"
				:dynamic-exercise-number="i + 1"
				:designation="designation"
				@open-section="childSection => $emit('open-section', childSection)"
			/>
			<HomeworkSectionListItem
				v-for="(subSection, n) in getSectionSubSections(section)"
				:key="`section-${subSection.id}`"
				:homework="homework"
				:section="subSection"
				:dynamic-exercise-number="n + 1"
				:designation="designation"
				class="ml-6"
				@open-section="childSection => $emit('open-section', childSection)"
			/>
		</template>
	</ul>
</template>

<script>
import HomeworkSectionListItem from './HomeworkSectionListItem';
import designation from '@/mixins/designation';

export default {
	name: 'HomeworkSectionList',
	components: { HomeworkSectionListItem },
	mixins: [designation],
	props: {
		homework: {
			type: Object,
			required: true,
		},
		sections: {
			type: Array,
			required: true,
		},
	},
	methods: {
		getSectionSubSections(section) {
			if (!section.hasSections()) {
				return [];
			}
			return section.sections.getFlattenedSections(s => !s.isQuestion());
		},
	},
};
</script>
