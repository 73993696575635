import Task from './Task';

export default class ClickWord extends Task {
	getOptions() {
		return this.conf?.options ?? [];
	}

	getCorrectOptions() {
		return this.getOptions().filter(option => {
			return option.is_clickable && option.is_correct;
		});
	}

	getClickedOptions() {
		if (!this.hasAnswer()) {
			return [];
		}
		return this.getAnswer()
			.getOptions()
			.filter(option => option.is_clicked);
	}

	getCorrectClickedOptions() {
		return this.getClickedOptions().filter(option => option.is_correct);
	}
}
