export default class AnswerCounts {
	constructor(counts) {
		this.correct = counts?.correct ?? 0;
		this.semi_correct = counts?.semi_correct ?? 0;
		this.wrong = counts?.wrong ?? 0;
		this.ungraded = counts?.ungraded ?? 0;
	}

	keys() {
		return Object.keys(this);
	}

	increment(counts) {
		this.keys().forEach(key => (this[key] += counts[key]));
	}

	replace(oldCounts, newCounts) {
		this.keys().forEach(key => (this[key] += -oldCounts[key] + newCounts[key]));
	}

	getTotal() {
		return this.keys().reduce((total, key) => total + this[key], 0);
	}

	get total() {
		return this.getTotal();
	}
}
