import { getCourseCategories, getLevelRequirements, getSchoolClassStartLevel } from '@/api/training';

const state = {};

const getters = {};

const actions = {
	getCourseCategories({ rootState }, { courseId }) {
		return getCourseCategories(rootState.user.schoolId, courseId);
	},
	getLevelRequirements() {
		return getLevelRequirements();
	},
	getSchoolClassStartLevel(_, schoolClassId) {
		return getSchoolClassStartLevel(schoolClassId);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
