<template>
	<AppMessage
		v-if="!online"
		type="danger"
		message="Du er i øjeblikket offline. Tjek din internetforbindelse."
		:dismissible="false"
	/>
</template>

<script>
import axios from 'axios';
import AppMessage from '@/components/ui/AppMessage';

const http = axios.create({ timeout: 2000 });

export default {
	name: 'NetworkMonitor',
	components: {
		AppMessage,
	},
	data() {
		return {
			online: true,
		};
	},
	created() {
		this.online = navigator.onLine;

		window.addEventListener('offline', this.handleNetworkEvent);
		window.addEventListener('online', this.handleNetworkEvent);
	},
	beforeDestroy() {
		window.removeEventListener('offline', this.handleNetworkEvent);
		window.removeEventListener('online', this.handleNetworkEvent);
	},
	methods: {
		handleNetworkEvent() {
			/* eslint-disable no-console */
			checkConnection()
				.then(() => {
					console.log('Looks like you are online');
					this.online = true;
				})
				.catch(() => {
					console.log('Looks like you are offline');
					this.online = false;
				});
			/* eslint-enable no-console */
		},
	},
};

function checkConnection(count = 1, retries = 3) {
	return http.get('/generate_204').catch(() => {
		if (count < retries) {
			return delay().then(() => checkConnection(count + 1, retries));
		}
		throw new Error('You are currently offline');
	});
}

function delay() {
	return new Promise(resolve => setTimeout(resolve, 1000));
}
</script>
