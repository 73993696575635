<template>
	<div v-if="to === false" v-bind="$attrs">
		<slot />
	</div>
	<a v-else-if="isExternalLink" v-bind="$attrs" :href="to" target="_blank">
		<slot />
	</a>
	<router-link v-else v-slot="{ isActive, href, navigate }" v-bind="$props" custom>
		<a v-bind="$attrs" :href="href" :class="isActive ? activeClass : inactiveClass" @click="navigate">
			<slot />
		</a>
	</router-link>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
	name: 'AppLink',
	inheritAttrs: false,
	props: {
		...RouterLink.props,
		to: {
			type: [Object, String, Boolean],
			default: false,
		},
		inactiveClass: {
			type: String,
			default: '',
		},
	},
	computed: {
		isExternalLink() {
			return typeof this.to === 'string' && this.to.startsWith('http');
		},
	},
};
</script>
