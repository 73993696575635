import { getUserToken } from '@/api/auth';
import { getCachedItem, cacheItem, forgetItem } from '../cache';
import cloneDeep from 'lodash/cloneDeep';

const tokenKey = 'student-impersonation-token';
const userKey = 'student-impersonation-user';
const schoolClassKey = 'student-impersonation-school-class';

const state = () => ({
	token: getCachedItem(tokenKey) || null,
	user: getCachedItem(userKey) || null,
	schoolClass: getCachedItem(schoolClassKey) || null,
	barToggled: false,
	loading: false,
	entering: false,
	exiting: false,
});

const getters = {
	isActive: state => !!state.token && !!state.user,
	barIsActive: (state, getters) => {
		if (state.entering || state.exiting) {
			return false;
		}
		return state.barToggled || getters.isActive;
	},
	isLoading: state => state.loading,
	student: state => state.user,
	schoolClass: state => state.schoolClass,
	isEntering: state => state.entering,
	isExiting: state => state.exiting,
	shouldShowOverlay: state => state.entering || state.exiting,
};

const actions = {
	impersonate({ commit, rootGetters }, { user, schoolClass }) {
		commit('setEntering', true);
		const school = {
			...cloneDeep(rootGetters['user/school']),
			roles: ['student'],
			isStudentImpersonation: true,
		};
		if (!school.id) {
			return null;
		}
		user.schools = [school];

		commit('setLoading', true);
		return getUserToken(school.id, user.id)
			.then(({ access_token }) => {
				if (!access_token) {
					return false;
				}
				commit('setUser', user);
				commit('setSchoolClass', schoolClass);
				commit('setToken', access_token);
				return true;
			})
			.catch(() => commit('setEntering', false))
			.finally(() => commit('setLoading', false));
	},
	exit({ commit }) {
		commit('setExiting', true);
		return true;
	},
	reset({ commit }) {
		commit('clearUser');
		commit('clearSchoolClass');
		commit('clearToken');
		return true;
	},
	setUser({ commit }, user) {
		commit('setUser', user);
		return true;
	},
	toggleBar({ commit }) {
		commit('toggleBar');
	},
};

const mutations = {
	setToken(state, token) {
		state.token = token;
		cacheItem(tokenKey, token);
	},
	clearToken(state) {
		state.token = null;
		forgetItem(tokenKey);
	},
	setUser(state, user) {
		state.user = user;
		cacheItem(userKey, user);
	},
	clearUser(state) {
		state.user = null;
		forgetItem(userKey);
	},
	setSchoolClass(state, schoolClass) {
		state.schoolClass = schoolClass;
		cacheItem(schoolClassKey, schoolClass);
	},
	clearSchoolClass(state) {
		state.schoolClass = null;
		forgetItem(schoolClassKey);
	},
	toggleBar(state) {
		state.barToggled = !state.barToggled;
	},
	setLoading(state, value = false) {
		state.loading = value;
	},
	setEntering(state, value = false) {
		state.entering = value;
	},
	setExiting(state, value = false) {
		state.exiting = value;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
