import { readTextForSpeech, getTextToSpeechUrls } from '@/api/texttospeech';
import TTSPlayer from '@/libs/TTSPlayer';

var player = null;

const state = () => ({
	isActive: false,
	isLoading: false,
	isPlaying: false,
	isPaused: false,
	word: null,
	mode: null,
});

const getters = {
	audioIsActive: state => state.isActive,
	audioIsLoading: state => state.isLoading,
	audioIsPlaying: state => state.isPlaying,
	audioIsPaused: state => state.isPaused,
	audioIsStopped: state => !state.isPlaying && !state.isPaused,
	audioMode: state => state.mode,
	spokenWord: state => state.word,
};

const actions = {
	readTextForSpeech(_, text) {
		// LEGACY
		return readTextForSpeech(text);
	},
	start({ commit }, { content, language, mode }) {
		player = new TTSPlayer();
		commit('setActive', true);
		commit('setLoading', true);
		commit('setMode', mode);

		player.on('playing', () => {
			commit('setPlaying');
		});
		player.on('pause', () => {
			commit('setPaused');
		});
		player.on('stop', () => {
			commit('setStopped');
		});
		player.on('done', () => {
			commit('setStopped');
		});
		player.on('word', word => {
			commit('setWord', word);
		});
		return getTextToSpeechUrls(content, language).then(srcs => {
			player.setSrcs(srcs).start();
			commit('setLoading', false);
		});
	},
	play() {
		return player ? player.play() : null;
	},
	pause() {
		return player ? player.pause() : null;
	},
	stop() {
		return player ? player.stop() : null;
	},
	close({ commit }) {
		if (player?.isPlaying()) {
			player.stop();
		}
		player = null;
		return commit('setActive', false);
	},
};

const mutations = {
	setActive(state, value = false) {
		state.isActive = value;
	},
	setLoading(state, value = false) {
		state.isLoading = value;
	},
	setPlaying(state) {
		state.isPlaying = true;
		state.isPaused = false;
	},
	setPaused(state) {
		state.isPlaying = false;
		state.isPaused = true;
	},
	setStopped(state) {
		state.isPlaying = false;
		state.isPaused = false;
	},
	setWord(state, word) {
		state.word = word;
	},
	setMode(state, mode) {
		state.mode = mode;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
