import { getNotifications, getAppNotifications, markAsRead, markAllAsRead } from '@/api/notification';
import { getCachedItem, cacheItem } from '../cache';
import { resolveNotification } from '@/models/notification';

const notificationsCacheKey = 'notifications';
const appNotificationsCacheKey = 'app-notifications';

const state = () => ({
	notifications: getCachedItem(notificationsCacheKey) || [],
	appNotifications: getCachedItem(appNotificationsCacheKey) || [],
});

const getters = {
	appNotifications(state) {
		return state.appNotifications;
	},
	userNotifications(state) {
		return state.notifications
			.slice()
			.map(resolveNotification)
			.filter(notification => !!notification);
	},
	unreadUserNotifications(_, getters) {
		return getters.userNotifications.filter(notification => !notification.isRead());
	},
	unreadHomeworkExportReadyNotifications(_, getters) {
		return getters.unreadUserNotifications.filter(
			notification => notification.type === 'HomeworkExportReady',
		);
	},
};

const actions = {
	markAsRead({ dispatch, rootState }, { notificationId }) {
		return markAsRead(rootState.user.schoolId, notificationId).then(() => dispatch('getNotifications'));
	},
	markNotificationsAsRead({ dispatch, rootState }, notificationIds) {
		const requests = notificationIds.map(notificationId =>
			markAsRead(rootState.user.schoolId, notificationId),
		);
		return Promise.all(requests).finally(() => dispatch('getNotifications'));
	},
	markAllAsRead({ dispatch, rootState }) {
		return markAllAsRead(rootState.user.schoolId).then(() => dispatch('getNotifications'));
	},
	getNotifications({ commit, rootState }) {
		if (!rootState.user.schoolId) {
			return;
		}

		return getNotifications(rootState.user.schoolId).then(notifications => {
			commit('setNotifications', notifications);
			return notifications;
		});
	},
	addNotification({ commit }, notification) {
		commit('addNotification', notification);
	},
	getAppNotifications({ commit }) {
		return getAppNotifications().then(notifications => {
			commit('setAppNotifications', notifications);
		});
	},
};

const mutations = {
	setAppNotifications(state, notifications) {
		state.appNotifications = notifications;
		cacheItem(appNotificationsCacheKey, notifications);
	},
	setNotifications(state, notifications) {
		state.notifications = notifications;
		cacheItem(notificationsCacheKey, notifications);
	},
	addNotification(state, notification) {
		state.notifications.unshift(notification);
		state.notifications = state.notifications.slice(0, 7);
		cacheItem(notificationsCacheKey, state.notifications);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
