// Converts first character of a string to uppercase.
export function ucFirst(string) {
	if (!string) {
		return '';
	}
	return string.charAt(0).toUpperCase() + string.slice(1);
}

// Check if a string contains multiple words.
export function containsMultipleWords(string) {
	const word = /([\p{Alphabetic}\p{Mark}\p{Decimal_Number}\p{Connector_Punctuation}\p{Join_Control}]+)/;
	const regex = new RegExp(`${word.source}\\s${word.source}`, 'gu');
	return string.match(regex) !== null;
}

// Converts an integer to a single character. 0 = A, 1 = B, etc.
export function numberToLetter(number) {
	return String.fromCharCode(65 + number);
}

// Truncate string to maxLength
export function truncate(string, length = 50) {
	if (!string) {
		return '';
	}
	return string.substring(0, length) + (string.length > length ? '...' : '');
}

// Truncate string to maxLength without breaking up words.
export function truncateWords(string, length = 300, separator = ' ', suffix = '...') {
	if (!string) {
		return '';
	}
	if (string.length <= length) return string;
	return string.substr(0, string.lastIndexOf(separator, length)) + suffix;
}
