<template>
	<svg
		v-if="symbolName"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		class="grammar-symbol"
		:class="{ 'is-hovered': isHovered, 'is-white': isWhite }"
		:viewBox="viewBox"
		:style="{ top: `${top}px`, left: `${left}px`, width: `${width}px` }"
		@mouseenter="onMouseenter"
		@mouseleave="onMouseleave"
	>
		<SymbolContainer v-bind="containerConfig">
			<component
				:is="`${symbolName}-symbol`"
				:as-text="asText || elementHasTextClass"
				:has-multiple-words="hasMultipleWords"
				:width="width"
				:height="height"
				@config="value => (symbolConfig = value)"
			/>
		</SymbolContainer>
	</svg>
</template>

<script>
import SymbolContainer from './SymbolContainer';
import SubjectSymbol from './SubjectSymbol';
import VerbSymbol from './VerbSymbol';
import VerbLeftSymbol from './VerbLeftSymbol';
import VerbRightSymbol from './VerbRightSymbol';
import DirectObjectSymbol from './DirectObjectSymbol';
import IndirectObjectSymbol from './IndirectObjectSymbol';
import ObjectPredicateSymbol from './ObjectPredicateSymbol';
import SubjectPredicateSymbol from './SubjectPredicateSymbol';
import PrepositionObjectSymbol from './PrepositionObjectSymbol';
import ConjunctionalSymbol from './ConjunctionalSymbol';
import QuestionmarkSymbol from './QuestionmarkSymbol';
import ImplicitSubjectSymbol from './ImplicitSubjectSymbol';
import PreliminarySubjectSymbol from './PreliminarySubjectSymbol';
import AdverbSymbol from './AdverbSymbol';
import { containsMultipleWords } from '@/utils/string';

const minSize = 16; // Minimum render width and height of symbol.
const symbolBaseSize = 32; // Symbols are optimized for this width/height. Used to calc scale.

export default {
	name: 'GrammarSymbol',
	components: {
		SymbolContainer,
		'subject-symbol': SubjectSymbol,
		'verb-symbol': VerbSymbol,
		'verb-left-symbol': VerbLeftSymbol,
		'verb-right-symbol': VerbRightSymbol,
		'direct-object-symbol': DirectObjectSymbol,
		'indirect-object-symbol': IndirectObjectSymbol,
		'object-predicate-symbol': ObjectPredicateSymbol,
		'subject-predicate-symbol': SubjectPredicateSymbol,
		'preposition-object-symbol': PrepositionObjectSymbol,
		'conjunctional-symbol': ConjunctionalSymbol,
		'questionmark-symbol': QuestionmarkSymbol,
		'implicit-subject-symbol': ImplicitSubjectSymbol,
		'preliminary-subject-symbol': PreliminarySubjectSymbol,
		'adverb-symbol': AdverbSymbol,
	},
	props: {
		element: {
			type: HTMLElement,
			required: true,
		},
		asText: {
			type: Boolean,
			default: false,
		},
		isWhite: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			symbolConfig: {},
			isHovered: false,
		};
	},
	computed: {
		offsetParents() {
			const parents = [];
			let element = this.element;
			while (element !== null) {
				const parent = element.offsetParent;
				if (!parent?.classList?.contains('grammar-content')) {
					parents.push(parent);
					element = parent;
				} else {
					element = null;
				}
			}
			return parents;
		},
		parentsOffset() {
			return this.offsetParents.reduce(
				(offset, parent) => {
					offset.top += parent?.offsetTop ?? 0;
					offset.left += parent?.offsetLeft ?? 0;
					return offset;
				},
				{ top: 0, left: 0 },
			);
		},
		top() {
			const lineOffset = 16;
			return this.parentsOffset.top + this.element.offsetTop + lineOffset;
		},
		left() {
			const widthOffset = this.width > this.elementWidth ? (this.width - this.elementWidth) / 2 : 0;
			return this.parentsOffset.left + this.element.offsetLeft - widthOffset;
		},
		width() {
			return Math.max(this.elementWidth, minSize);
		},
		elementWidth() {
			return this.element.getBoundingClientRect().width;
		},
		height() {
			return minSize;
		},
		viewBox() {
			return `0 0 ${this.width} ${this.height}`;
		},
		symbolName() {
			const symbols = [
				{
					classNames: ['under-cross'],
					symbol: 'subject',
				},
				{
					classNames: ['under-circle-plain'],
					symbol: 'verb',
				},
				{
					classNames: ['under-o-minus'],
					symbol: 'verb-left',
				},
				{
					classNames: ['under-minus-o'],
					symbol: 'verb-right',
				},
				{
					classNames: ['under-triangle'],
					symbol: 'direct-object',
				},
				{
					classNames: ['under-square'],
					symbol: 'indirect-object',
				},
				{
					classNames: ['under-wave'],
					symbol: 'adverb',
				},
				{
					classNames: ['under-circle-triangle'],
					symbol: 'object-predicate',
				},
				{
					classNames: ['under-circle-x'],
					symbol: 'subject-predicate',
				},
				{
					classNames: ['ml-under', 'ml-po'],
					symbol: 'preposition-object',
				},
				{
					classNames: ['ml-under', 'ml-k'],
					symbol: 'conjunctional',
				},
				{
					classNames: ['under-questionmark'],
					symbol: 'questionmark',
				},
				{
					classNames: ['under-parenthesis-x'],
					symbol: 'preliminary-subject',
				},
				{
					classNames: ['under-parenthesis-x2'],
					symbol: 'implicit-subject',
				},
				// For some reason className is changed to under-parenthesisx2
				// when .as-text is enabled.
				{
					classNames: ['under-parenthesisx2'],
					symbol: 'implicit-subject',
				},
			];
			const symbol = symbols.find(symbol => {
				for (const className of symbol.classNames) {
					if (!this.element.classList.contains(className)) {
						return false;
					}
				}
				return true;
			});
			return symbol ? symbol.symbol : '';
		},
		elementHasTextClass() {
			return this.element.classList.contains('as-text');
		},
		containerConfig() {
			return Object.assign(
				{
					width: this.width,
					height: this.height,
					asText: this.asText,
					symbolScale: minSize / symbolBaseSize,
				},
				this.symbolConfig,
			);
		},
		hasMultipleWords() {
			return containsMultipleWords(this.element.textContent);
		},
	},
	mounted() {
		this.attachMouseListeners();
	},
	beforeDestroy() {
		this.removeMouseListeners();
	},
	methods: {
		attachMouseListeners() {
			if (!this.element) {
				return;
			}
			this.element.addEventListener('mouseenter', this.onMouseenter);
			this.element.addEventListener('mouseleave', this.onMouseleave);
		},
		removeMouseListeners() {
			if (!this.element) {
				return;
			}
			this.element.removeEventListener('mouseenter', this.onMouseenter);
			this.element.removeEventListener('mouseleave', this.onMouseleave);
		},
		onMouseenter() {
			this.element.classList.add('has-text-blue');
			this.isHovered = true;
		},
		onMouseleave() {
			this.element.classList.remove('has-text-blue');
			this.isHovered = false;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';

.grammar-symbol {
	position: absolute;
	stroke: #000;
	fill: none;

	:deep(text) {
		font: normal 24px sans-serif;
		fill: #000;
	}

	&.is-white {
		stroke: #fff;
		:deep(text),
		:deep(*[fill='black']) {
			fill: #fff;
		}
		:deep(*[stroke='black']) {
			stroke: #fff;
		}
	}

	&.is-hovered {
		stroke: $blue;
		:deep(text),
		:deep(*[fill='black']) {
			fill: $blue;
		}
		:deep(*[stroke='black']) {
			stroke: $blue;
		}
	}
}
</style>
