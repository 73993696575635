<template>
	<div class="schoolclass-card p-5" :class="{ 'is-animated': animate, box: hasBackground }">
		<div v-if="hasTitleSlot"><slot name="title" /></div>
		<div v-if="hasCoursesSlot" class="mt-2"><slot name="courses" /></div>
		<div v-if="hasLevelSlot" class="class-level">
			<slot name="level" />
		</div>
		<div v-if="hasAvatarsSlot" class="avatars">
			<slot name="avatars" />
		</div>
		<div v-if="hasWarningSlot" class="schoolclass-warning"><slot name="warning" /></div>

		<slot />
	</div>
</template>

<script>
export default {
	name: 'SchoolClassCardContainer',
	props: {
		animate: {
			type: Boolean,
			default: false,
		},
		hasBackground: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		hasTitleSlot() {
			return !!this.$slots.title;
		},
		hasCoursesSlot() {
			return !!this.$slots.courses;
		},
		hasLevelSlot() {
			return !!this.$slots.level;
		},
		hasAvatarsSlot() {
			return !!this.$slots.avatars;
		},
		hasWarningSlot() {
			return !!this.$slots.warning;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/custom-variables';

.schoolclass-card {
	position: relative;
	width: 19rem;
	height: 10rem;
	transition: margin 250ms ease;

	.class-level {
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.avatars {
		position: absolute;
		bottom: 0;
		right: 0;
	}

	.schoolclass-warning {
		position: absolute;
		top: -0.5rem;
		right: -0.5rem;
	}

	&.is-animated:hover {
		margin-top: -1rem;
		margin-bottom: 1rem;
		box-shadow: $card-hover-shadow;
	}
}
</style>
