<template>
	<portal>
		<BasicModal ref="modal" title="Din eksport er klar" @hide="onClose">
			<template #body>
				<div
					v-for="(notification, i) in notifications"
					:key="i"
					class="is-flex is-align-items-center is-justify-content-space-between py-3"
				>
					<div>{{ notification.exportTitle }}</div>
					<a class="button is-medium is-primary" :href="notification.actionRoute">
						Download
					</a>
				</div>
			</template>

			<template #footer>
				<div class="buttons is-right">
					<button type="button" class="button is-medium is-primary" @click="hide">
						Ok
					</button>
				</div>
			</template>
		</BasicModal>
	</portal>
</template>

<script>
import BasicModal from '@/components/ui/modal/BasicModal';

export default {
	name: 'ExportDownloadsModal',
	components: {
		BasicModal,
	},
	props: {
		notifications: {
			type: Array,
			required: true,
		},
	},
	mounted() {
		this.$nextTick(this.show);
	},
	methods: {
		show() {
			this.$refs.modal.show();
		},
		hide() {
			this.$refs.modal.hide();
		},
		onClose() {
			if (this.notifications) {
				this.markNotificationsAsRead();
			}
			this.$emit('hide');
		},
		markNotificationsAsRead() {
			return this.$store.dispatch(
				'notification/markNotificationsAsRead',
				this.notifications.map(notification => notification.id),
			);
		},
	},
};
</script>
