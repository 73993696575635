<template>
	<Chart
		type="line"
		:datasets="datasets"
		:labels="labels"
		:options="options"
		:tooltip-label-callback="tooltipLabelCallback"
		:aspect-ratio="aspectRatio"
	/>
</template>

<script>
import Chart from './Chart';
import ChartOptions from './ChartOptions';

export default {
	name: 'LineChart',
	components: { Chart },
	props: {
		datasets: {
			type: Array,
			required: true,
		},
		labels: {
			type: Array,
			required: true,
		},
		tooltipLabelCallback: {
			type: Function,
			default: null,
		},
		optionsOverride: {
			type: Object,
			default: () => ({}),
		},
		aspectRatio: {
			type: [Number, Function],
			default: () => 10 / 4,
		},
		minValue: {
			type: Number,
			default: 0,
		},
		maxValue: {
			type: Number,
			default: 100,
		},
		padding: {
			type: Number,
			default: 20,
		},
	},
	computed: {
		options() {
			return ChartOptions.options([this.defaultOptions, this.optionsOverride]);
		},
		defaultOptions() {
			return {
				layout: {
					padding: {
						left: this.padding,
						right: this.padding,
					},
				},
				elements: {
					point: {
						radius: 6,
					},
				},
				scales: {
					x: {
						offset: true,
						// type: 'linear',
						grid: {
							display: false,
						},
						ticks: {
							// display: false,
						},
					},
					y: {
						// type: 'linear',
						// suggestedMin: this.minValue,
						// suggestedMax: this.maxValue,
						ticks: {
							// display: false,
							// stepSize: 10,
							padding: 20,
						},
						grid: {
							// borderDash: [15, 20],
							drawBorder: false,
							drawTicks: false,
						},
					},
				},
			};
		},
	},
};
</script>
