import { getUserProgress, logActivity } from '@/api/activity';

const state = {};

const getters = {};

const actions = {
	getUserProgress() {
		return getUserProgress();
	},
	logActivity(_, data) {
		return logActivity(data);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
