<template>
	<BaseKeyboardWrapper :active="dismissible" class="base-modal-backdrop" @esc="close">
		<div class="base-modal-container">
			<div
				v-click-outside="{ handler: close }"
				class="base-modal box is-paddingless is-clipped"
				:class="`is-${size}`"
				role="dialog"
			>
				<header class="base-modal-header box-content" :class="{ 'is-centered': centerHeader }">
					<slot name="header">
						<p v-if="title" class="title is-3">{{ title }}</p>
					</slot>
				</header>
				<section class="base-modal-body box-content pt-2">
					<slot name="body"></slot>
				</section>
				<footer
					v-if="hasSlot('footer')"
					class="base-modal-footer box-content has-background-grey-lighterer py-3"
				>
					<slot name="footer"></slot>
				</footer>
				<BaseIcon
					v-if="dismissible"
					class="close-button"
					icon="cross"
					color="grey"
					active-color="black"
					clickable
					no-padding
					@click.native="close"
				/>
			</div>
		</div>
	</BaseKeyboardWrapper>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon';
import BaseKeyboardWrapper from '@/components/base/BaseKeyboardWrapper';

export default {
	name: 'BaseModal',
	components: { BaseIcon, BaseKeyboardWrapper },
	props: {
		title: {
			type: String,
			default: '',
		},
		size: {
			type: String,
			default: 'medium',
		},
		dismissible: {
			type: Boolean,
			default: true,
		},
		centerHeader: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		document.body.classList.add('modal-open');
	},
	beforeDestroy() {
		document.body.classList.remove('modal-open');
	},
	methods: {
		close() {
			if (!this.dismissible) {
				return;
			}
			this.$emit('close');
		},
		hasSlot(name) {
			return this.$slots[name] !== undefined;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
@import '@/assets/sass/abstracts/mixins';

.base-modal-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.85);
	z-index: 30;
	overflow-y: auto;
}
.base-modal-container {
	padding: 2rem 0;
}
.base-modal {
	position: relative;
	background: #fff;
	box-shadow: 2px 2px 20px 1px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;

	width: 90%;
	@include mixin-desktop-up {
		width: 900px;
		&.is-small {
			width: 500px;
		}
	}

	.base-modal-header,
	.base-modal-footer {
		display: flex;
	}
	.base-modal-header {
		position: relative;
		justify-content: space-between;
		padding-bottom: 1rem;
		&.is-centered {
			justify-content: center;
		}
	}

	.base-modal-footer {
		padding-top: 1rem;
		flex-direction: column;
		justify-content: flex-end;
	}

	.close-button {
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
	}
}
</style>
