<template>
	<div>
		<BaseLoader v-if="loading" size="large" class="mt-6" />

		<template v-else>
			<HomeworkTabs
				v-if="hasHomework || isTeacher"
				:homework-tabs="homeworkTabs"
				:is-teacher="isTeacher"
			/>

			<div v-if="isTeacher">
				<div class="is-flex is-align-items-center py-4 mt-5 mb-4 underlined-title">
					<BaseIcon icon="keyhole-cutout" color="purple" no-padding is-fill />
					<div class="title is-5 ml-3">Indsigt</div>
				</div>
				<div class="columns">
					<div class="column is-3 mt-7">
						<ProfileWithStats />
					</div>
					<div class="column is-9 mt-7">
						<TeacherInsights />
					</div>
				</div>
			</div>

			<div v-if="isStudent">
				<div
					v-if="hasHomework"
					class="is-flex is-align-items-center pt-4 mt-5 pb-4 mb-5 underlined-title"
				></div>
				<div class="columns">
					<div class="column is-3 mt-7">
						<ProfileWithStats />
					</div>
					<div class="column is-9 mt-7">
						<LatestAchievements />
						<UnfinishedExercises />
					</div>
				</div>
			</div>
			<div v-else-if="achievements.length > 0">
				<div class="is-flex is-align-items-center py-4 mt-5 mb-4 underlined-title">
					<BaseIcon icon="hexagon-star" color="yellow-dark" no-padding is-fill />
					<div class="title is-5 ml-3">Bedrifter</div>
				</div>

				<div class="columns is-multiline is-variable pt-4">
					<div
						v-for="(achievement, index) in achievements"
						:key="index"
						class="column is-3-mobile is-4-tablet is-3-desktop"
					>
						<AchievementCard :achievement="achievement" />
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import AchievementCard from '@/components/dashboard/AchievementCard';
import BaseIcon from '@/components/base/BaseIcon';
import BaseLoader from '@/components/base/BaseLoader';
import HomeworkTabs from '@/components/dashboard/HomeworkTabs';
import ProfileWithStats from '@/components/user/ProfileWithStats';
import LatestAchievements from '@/components/dashboard/LatestAchievements.vue';
import TeacherInsights from '@/components/dashboard/TeacherInsights.vue';
import UnfinishedExercises from '@/components/dashboard/UnfinishedExercises.vue';
import { mapGetters } from 'vuex';

export default {
	name: 'DashboardView',
	components: {
		AchievementCard,
		BaseIcon,
		BaseLoader,
		HomeworkTabs,
		LatestAchievements,
		ProfileWithStats,
		TeacherInsights,
		UnfinishedExercises,
	},
	data() {
		return {
			loading: false,
			hasHomework: false,
			student: null,
			homeworkTabs: [],
		};
	},
	computed: {
		...mapGetters('user', ['user', 'isTeacher', 'isStudent', 'achievements']),
	},
	created() {
		this.loading = true;
		const requests = [this.loadDashboardData(), this.loadAchievements()];
		Promise.all(requests).finally(() => (this.loading = false));
	},
	methods: {
		loadAchievements() {
			return this.$store.dispatch('user/getAchievements');
		},
		loadDashboardData() {
			return this.$store.dispatch('user/getDashboard').then(response => {
				this.hasHomework = response.has_any_homework;
				this.homeworkTabs = response.homework_tabs;
				this.tips = response.tips;
				this.news = response.news;
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
.underlined-title {
	border-bottom: 4px solid $grey-lighter;
}
</style>
