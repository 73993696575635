import Designation from '@/models/designation/Designation';
import { designate } from '@/utils/grammarContent';

export default {
	props: {
		designation: {
			type: Object,
			default: () => ({}),
		},
	},
	methods: {
		$designate(text, designation) {
			return designate(text, this.$designation(designation));
		},
		$designation(designation = null) {
			return Designation.make(designation ?? this.designation);
		},
		$designationName(designation = null) {
			return this.$designation(designation).getDesignationName() ?? 'latin';
		},
		$refreshCourseDesignation(courseId = 0, schoolClassId = null) {
			return this.$store.dispatch('designation/getSchoolUserCourseSettings', {
				courseId,
				schoolClassId,
			});
		},
	},
};
