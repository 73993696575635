const rememberDays = 30;

export function cookieOptions(remember = false) {
	const cookieOptions = {
		SameSite: 'strict',
		secure: 'https:' === document.location.protocol,
	};

	if (remember) {
		cookieOptions.expires = rememberDays;
	}

	return cookieOptions;
}
