var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-progressbar",class:[
		`has-background-${_vm.backgroundColor}`,
		`height-${_vm.height}`,
		{
			'is-flex': _vm.showCompletedIcon,
			'is-align-items-center': _vm.showCompletedIcon,
			'is-justify-content-flex-end': _vm.showCompletedIcon,
			'is-completed': _vm.showCompletedIcon && _vm.progress === 100,
		},
	]},[(_vm.progress)?_c('div',{staticClass:"base-progressbar-status",class:{
			'no-animation': _vm.noAnimation,
			[`has-background-${_vm.colorName}`]: !_vm.color,
		},style:({
			width: _vm.progress + '%',
			...(_vm.color ? { 'background-color': _vm.color } : {}),
		})},[(_vm.showShine)?_c('div',{staticClass:"base-progressbar-shine"}):_vm._e()]):_vm._e(),(_vm.showCompletedIcon && _vm.progress === 100)?_c('BaseIcon',{staticClass:"has-background-green",attrs:{"icon":"check","color":"white","size":2,"show-background":""}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }