<script>
export default {
	name: 'AppUpdate',
	created() {
		let refreshing = false;
		let initialInstall = false;

		if (!navigator.serviceWorker) {
			return;
		}

		onReady(() => {
			// Check current service worker registration
			navigator.serviceWorker.getRegistration().then(registration => {
				if (!registration || !registration.active) {
					initialInstall = true;
					return;
				}

				// Make sure any waiting service worker registration gets applied immediately.
				if (registration.waiting) {
					registration.waiting.postMessage('skipWaiting');
				}
			});

			// Refresh all open app tabs when a new service worker is installed.
			navigator.serviceWorker.addEventListener('controllerchange', () => {
				if (initialInstall) {
					initialInstall = false;
					return;
				}
				if (refreshing) {
					return;
				}
				refreshing = true;
				Promise.all([
					this.$store.dispatch('app/clearConfigCache'),
					this.$store.dispatch('app/clearUniloginConfigCache'),
					this.$store.dispatch('lix/clearToken'),
				]).finally(() => {
					window.location.reload();
				});
			});
		});
	},
	render() {
		return null;
	},
};

function onReady(handler) {
	if (document.readyState === 'complete') {
		handler();
		return;
	}
	window.addEventListener('load', handler, { once: true });
}
</script>
