<template>
	<BaseKeyboardWrapper
		v-click-outside="{ handler: clickOutside, middleware: clickOutsideMiddleware }"
		:active="isActive"
		class="dropdown"
		:class="{
			'is-disabled': disabled,
			'is-active': isActive,
			'is-right': isRight,
			'is-wide': isWide,
			'is-fullwidth': isFullwidth,
			'no-height-cap': noHeightCap,
			'has-fullwidth-trigger': fullWidthTrigger,
		}"
		:tabindex="!disabled ? 0 : -1"
		@arrow-up="$emit('prev-option')"
		@arrow-down="$emit('next-option')"
		@enter="toggle"
	>
		<div class="dropdown-trigger has-arrow" :class="{ 'is-clickable': !disabled }" @click="toggle">
			<slot name="trigger" :active="isActive" />
		</div>
		<div class="dropdown-menu" role="menu">
			<div class="dropdown-content" @click.stop="onContentClick">
				<slot name="content-header" />
				<div class="dropdown-items">
					<slot />
				</div>
				<slot name="content-footer" />
			</div>
		</div>
	</BaseKeyboardWrapper>
</template>

<script>
import BaseKeyboardWrapper from '@/components/base/BaseKeyboardWrapper';

export default {
	name: 'BaseDropdown',
	components: { BaseKeyboardWrapper },
	props: {
		forceActive: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		isRight: {
			type: Boolean,
			default: false,
		},
		isWide: {
			type: Boolean,
			default: false,
		},
		isFullwidth: {
			type: Boolean,
			default: false,
		},
		noHeightCap: {
			type: Boolean,
			default: false,
		},
		fullWidthTrigger: {
			type: Boolean,
			default: false,
		},
		disableTrigger: {
			type: Boolean,
			default: false,
		},
		closeOnClick: {
			type: Boolean,
			default: false,
		},
		stopPropagation: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			active: false,
		};
	},
	computed: {
		isActive() {
			return this.forceActive || this.active;
		},
	},
	methods: {
		toggle(event) {
			if (this.disableTrigger) {
				return;
			}
			if (this.stopPropagation) {
				event.stopPropagation();
			}
			if (this.active) {
				this.hide();
			} else {
				this.show();
			}
		},
		show() {
			if (this.disabled) {
				return;
			}
			this.active = true;
			this.$emit('show');
		},
		hide() {
			if (this.forceActive) {
				return;
			}
			this.active = false;
			this.$emit('hide');
		},
		clickOutside() {
			this.hide();
		},
		clickOutsideMiddleware() {
			// Only listen for outside clicks if dropdown is open.
			return this.active;
		},
		onContentClick() {
			if (!this.closeOnClick) {
				return;
			}
			this.hide();
		},
	},
};
</script>
