import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import app from './modules/app';
import articles from './modules/articles';
import auth from './modules/auth';
import user from './modules/user';
import school from './modules/school';
import dashboard from './modules/dashboard';
import books from './modules/books';
import assignments from './modules/assignments';
import homework from './modules/homework';
import designation from './modules/designation';
import notification from './modules/notification';
import screenings from './modules/screenings';
import trial from './modules/trial';
import exercise from './modules/exercise';
import texttospeech from './modules/texttospeech';
import tooltips from './modules/tooltips';
import unilogin from './modules/unilogin';
import documentTitle from './modules/documentTitle';
import results from './modules/results';
import tasks from './modules/tasks';
import sections from './modules/sections';
import students from './modules/students';
import search from './modules/search';
import courses from './modules/courses';
import homeworkFlow from './modules/homeworkFlow/';
import homeworkStats from './modules/homework/stats';
import homeworkExport from './modules/homework/export';
import activity from './modules/activity';
import bundles from './modules/bundles';
import schoolClass from './modules/schoolClass';
import schoolClassFlow from './modules/schoolClassFlow';
import email from './modules/email';
import lix from './modules/lix';
import training from './modules/training';
import studentImpersonation from './modules/studentImpersonation';

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
	modules: {
		app,
		articles,
		auth,
		user,
		school,
		dashboard,
		books,
		assignments,
		homework,
		designation,
		notification,
		trial,
		screenings,
		exercise,
		texttospeech,
		tooltips,
		unilogin,
		documentTitle,
		results,
		tasks,
		sections,
		students,
		search,
		courses,
		homeworkFlow,
		homeworkStats,
		homeworkExport,
		activity,
		bundles,
		schoolClass,
		schoolClassFlow,
		email,
		lix,
		training,
		studentImpersonation,
	},
	strict: debug,
});
