import http from './http';

export function getGroups(schoolId) {
	return http.get(`/schools/${schoolId}/unilogin/institution/groups`).then(resp => resp.data.data);
}

export function getStudents(schoolId) {
	return http.get(`/schools/${schoolId}/unilogin/institution/students`).then(resp => resp.data.data);
}

export function getGroupStudents(schoolId, groupId) {
	return http
		.get(`/schools/${schoolId}/unilogin/institution/groups/${groupId}/students`)
		.then(resp => resp.data.data);
}

export function getJoincodeUrl(code) {
	return http
		.post('/auth/unilogin/joincodeurl', {
			code: code,
		})
		.then(resp => resp.data);
}

export function getRedirectUrl(redirect) {
	return http
		.post('/auth/unilogin/redirecturl', {
			redirect: redirect,
		})
		.then(resp => resp.data);
}

export function loginWithCode(code, school_id = null, school_role = null) {
	const payload = { code };
	if (school_id) {
		payload.school_id = school_id;
	}
	if (school_role) {
		payload.school_role = school_role;
	}
	return http.post('/unilogin/login', payload).then(resp => resp.data);
}
