var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',{staticClass:"base-checkbox is-flex-shrink-0",class:{
		'is-active': _vm.isFullySelected,
		'is-rounded': _vm.rounded,
		[`has-background-${_vm.activeColor}`]: _vm.isFullySelected,
		[`has-background-${_vm.inactiveColor}`]: !_vm.isFullySelected,
		[`is-${_vm.size}`]: true,
	},on:{"mouseenter":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[_c('use',{attrs:{"xlink:href":_vm.url}})])
}
var staticRenderFns = []

export { render, staticRenderFns }