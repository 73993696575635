<template>
	<TippyTooltip ref="tooltip" :to-element="target" :visible="target !== null" trigger="manual" max-width="400px">
		<BaseLoader v-if="loading" />

		<div v-else-if="tooltip">
			<GrammarContent :designation="courseDesignation" no-gutters no-container>
				<p class="title">{{ $designate(tooltip.title, courseDesignation) }}</p>
				<div v-html="$designate(tooltip.content, courseDesignation)" />
			</GrammarContent>
		</div>
	</TippyTooltip>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseLoader from '@/components/base/BaseLoader';
import GrammarContent from '@/components/grammar/GrammarContent';
import designation from '@/mixins/designation';

export default {
	name: 'GlossaryTooltip',
	components: { BaseLoader, GrammarContent },
	mixins: [designation],
	data() {
		return {
			tooltip: null,
			loading: false,
			target: null,
		};
	},
	computed: {
		...mapGetters('designation', ['getDesignationByCourseId']),
		courseDesignation() {
			return this.getDesignationByCourseId(this.tooltip?.course_id ?? 0);
		},
	},
	watch: {
		$route() {
			this.close();
		},
	},
	mounted() {
		window.addEventListener('show-glossary-tooltip', this.handleShow);
		window.addEventListener('hide-glossary-tooltip', this.handleHide);
		window.addEventListener('resize', this.close);
	},
	beforeDestroy() {
		window.removeEventListener('show-glossary-tooltip', this.handleShow);
		window.removeEventListener('hide-glossary-tooltip', this.handleHide);
		window.removeEventListener('resize', this.close);
	},
	methods: {
		handleShow(event) {
			this.target = event.detail.target;
			this.$nextTick(() => {
				this.$refs.tooltip.init();
				this.loadTooltip(+event.detail.id);
			});
		},
		loadTooltip(id) {
			// No need to fetch the same tooltip again.
			if (this.tooltip && id === this.tooltip.id) {
				return;
			}

			this.loading = true;
			this.$store
				.dispatch('tooltips/getTooltip', { id })
				.then(tooltip => (this.tooltip = tooltip))
				.catch(err => {
					this.tooltip = {
						id: -1,
						title: 'Der skete desværre en fejl',
						content: err.message,
					};
				})
				.finally(() => (this.loading = false));
		},
		handleHide() {
			this.close();
		},
		close() {
			this.target = null;
		},
	},
};
</script>
