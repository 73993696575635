<template>
	<div>
		<slot />
	</div>
</template>

<script>
export default {
	name: 'BaseKeyboardWrapper',
	props: {
		active: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		window.addEventListener('keydown', this.onKeydown);
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.onKeydown);
	},
	methods: {
		onKeydown(event) {
			if (!this.active || event.metaKey || event.ctrlKey) {
				return;
			}

			// If focus is not on body or inside this wrapper, ignore it
			if (!event.target.isSameNode(document.body) && !this.$el.contains(event.target)) {
				return;
			}

			const data = this.getKeyData(event.keyCode);
			if (!data?.name) {
				return;
			}

			const eventNames = this.getEventsWithListerners(data);
			if (!eventNames.length) {
				return;
			}

			event.preventDefault();
			eventNames.forEach(name => {
				this.$emit(name, { ...data, ...{ name, event } });
			});
		},
		getKeyData(keyCode) {
			const name = this.getBaseKeyName(keyCode);
			if (name) {
				return { name: [name] };
			}

			const letterData = this.getKeyLetterData(keyCode);
			if (letterData) {
				return letterData;
			}

			const numberData = this.getKeyNumberData(keyCode);
			if (numberData) {
				return numberData;
			}

			return null;
		},
		getBaseKeyName(keyCode) {
			const keyMap = {
				9: 'tab',
				13: 'enter',
				27: 'esc',
				32: 'space',
				37: 'arrow-left',
				38: 'arrow-up',
				39: 'arrow-right',
				40: 'arrow-down',
			};
			return keyMap[keyCode] ?? null;
		},
		getKeyLetterData(keyCode) {
			if (keyCode < 65 || keyCode > 90) {
				return null;
			}

			const alphabet = [...'abcdefghijklmnopqrstuvwxyz'];
			const index = keyCode - 65;
			const letter = alphabet[index];
			return { name: ['letter', `letter-${letter}`], index, letter };
		},
		getKeyNumberData(keyCode) {
			if (keyCode < 49 || keyCode > 57) {
				return null;
			}

			const index = keyCode - 49;
			const number = index + 1;
			return { name: ['number', `number-${number}`], index, number };
		},
		getEventsWithListerners(data) {
			const names = Array.isArray(data.name) ? data.name : [data.name];
			return names.filter(name => this.$listeners[name] !== undefined);
		},
	},
};
</script>
