import Model from '@/models/Model';
import SectionCollection from '@/models/section/SectionCollection';

export default class Assignment extends Model {
	processValues(values = {}) {
		if (values.sections && !(values.sections instanceof SectionCollection)) {
			values.sections = new SectionCollection(values.sections);
		}
		return values;
	}

	hasSections() {
		return this.sections !== undefined && this.sections?.length > 0;
	}
}
