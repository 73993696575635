<template>
	<g v-if="width && height">
		<template v-if="!asText">
			<path
				v-for="(path, i) in paths"
				:key="i"
				:d="path"
				fill="none"
				stroke="black"
				stroke-width="2"
			/>
		</template>
		<text v-else x="0" y="24">A</text>
	</g>
</template>

<script>
const bezierWidth = 19;
const bezierHeight = 16;

export default {
	name: 'AdverbSymbol',
	props: {
		asText: {
			type: Boolean,
			default: false,
		},
		hasMultipleWords: {
			type: Boolean,
			default: false,
		},
		width: {
			type: Number,
			default: 0,
		},
		height: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		paths() {
			let fullWidth = 0;
			const paths = [];
			while (fullWidth < this.width) {
				const path = this.createBezierPath(fullWidth);
				paths.push(path);
				fullWidth += bezierWidth;
			}
			return paths;
		},
		scale() {
			return this.height ? this.height / bezierHeight : 1;
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.$emit('config', {
				symbolScale: this.asText ? 0.5 : 1,
				symbolWidth: this.asText ? this.$el.getBoundingClientRect().width : this.width,
				lineLeft: this.asText && this.hasMultipleWords,
				lineRight: this.asText && this.hasMultipleWords,
			});
		});
	},
	methods: {
		createBezierPath(offset = 0) {
			// Map path data points for repeatable bezier curve.
			const xy = [
				[0, bezierHeight / 2],
				[4.75, bezierHeight / 4],
				[9.5, bezierHeight / 2],
				[19, bezierHeight / 2],
			].map(([x, y]) => [(x + offset) * this.scale, y * this.scale]);

			return `M${xy[0][0]} ${xy[0][1]}
				Q${xy[1][0]} ${xy[1][1]}, ${xy[2][0]} ${xy[2][1]} 
				T${xy[3][0]} ${xy[3][1]}`;
		},
	},
};
</script>
