<template>
	<header>
		<Menu />
		<StudentImpersonationBar v-if="isStudentImpersonation" />
	</header>
</template>

<script>
import { mapGetters } from 'vuex';
import Menu from './Menu';
import StudentImpersonationBar from '@/components/studentimpersonation/StudentImpersonationBar';

export default {
	name: 'Header',
	components: {
		Menu,
		StudentImpersonationBar,
	},
	computed: {
		...mapGetters('studentImpersonation', { isStudentImpersonation: 'barIsActive' }),
	},
};
</script>
