import { answerQuestionTask, resetExercise, resetStudentExercise, startExercise } from '@/api/exercise';

import { getUserSectionSecondsRemaining } from '@/utils/exercise';

const state = () => ({
	activeExercise: null,
	activeQuestionIndex: 0,
	activeTaskIndex: 0,
	exerciseExpanded: false,
	recentlyCompletedExercise: false,
	homeworkItems: [],
	homeworkId: null,
	filterContentByHomework: false,
	taskLanguage: 'danish',
});

const getters = {
	activeQuestion(state) {
		if (!state.activeExercise) {
			return null;
		}
		return state.activeExercise?.sections?.[state.activeQuestionIndex];
	},
	activeTask(state, getters) {
		if (!getters.activeQuestion) {
			return null;
		}
		return getters.activeQuestion.tasks?.[state.activeTaskIndex];
	},
	activeHomework(state) {
		if (!state.homeworkId) {
			return null;
		}
		return state.homeworkItems.find(homework => homework.id === state.homeworkId) ?? null;
	},
	getSectionHomework:
		(state, getters, globalState) =>
		(section, user = null) => {
			if (getters.activeHomework) {
				return getters.activeHomework;
			}
			if (!user) {
				user = globalState.user.user;
			}
			return state.homeworkItems.find(item => item.includesSection(section, user)) ?? null;
		},
	getExercisePoints: () => section => {
		return section?.user_progress ? Math.round(section.user_progress.points) : 0;
	},
	hideSection:
		(state, getters, globalState) =>
		(section, user = null) => {
			if (!getters.activeHomework) {
				return false;
			}
			const homework = getters.activeHomework;
			if (homework.isType('training')) {
				return false;
			}
			if (!user) {
				user = globalState.user.user;
			}
			return (
				!homework.includesSection(section, user) &&
				(homework.isScreening() || state.filterContentByHomework)
			);
		},
	getExerciseTimeLimitRemaining: () => section => {
		return getUserSectionSecondsRemaining(section, section?.user_progress?.started_at);
	},
	exerciseTimeLimitHasExpired: (_, getters) => section => {
		return section?.user_progress?.time_expired || getters.getExerciseTimeLimitRemaining(section) === 0;
	},
};

const actions = {
	setActiveExercise({ commit }, { sectionExercise }) {
		let questionIndex = 0;
		let taskIndex = 0;

		// Override sections with only published sections (questions).
		sectionExercise.sections = sectionExercise.sections.filter(q => q.is_published);

		// Figure out active question and task based on existing answers
		OuterLoop: for (let i = 0; i < sectionExercise.sections.length; i++) {
			questionIndex = i;
			for (let j = 0; j < sectionExercise.sections[i].tasks.length; j++) {
				taskIndex = j;
				const task = sectionExercise.sections[i].tasks[j];
				const answer = task.answer;
				// We need to check if the task is of type clickword,
				// since clickword exercises are not considered complete if they have been skipped.
				// TODO: Perhaps introduce an is_skipped property on the answer at some point?
				if (!answer || (task.type.machine_name !== 'clickword' && !answer.is_completed)) {
					break OuterLoop;
				}
			}
		}

		commit('setActiveExercise', sectionExercise);
		commit('setActiveQuestionIndex', questionIndex);
		commit('setActiveTaskIndex', taskIndex);

		// If the exercise has show_all_questions set we should expand the exercise automatically.
		if (sectionExercise?.conf?.show_all_questions) {
			commit('toggleExpandExercise');
		}
	},
	clearActiveExercise({ commit }) {
		commit('clearActiveExercise');
	},
	answerQuestionTask(
		{ rootState, commit },
		{ exercise_section_relation_id, section_relation_id, task_id, data },
	) {
		return answerQuestionTask(
			rootState.user.schoolId,
			exercise_section_relation_id,
			section_relation_id,
			task_id,
			data,
		).then(answer => {
			commit('setTaskAnswer', { section_relation_id, task_id, answer });
			return answer;
		});
	},
	nextQuestion({ state, commit }) {
		const index = state.activeQuestionIndex;
		if (index < state.activeExercise.sections.length - 1) {
			commit('setActiveQuestionIndex', index + 1);
			commit('setActiveTaskIndex', 0);
			return 'question';
		}
		return null;
	},
	nextTask({ state, commit, dispatch }) {
		const index = state.activeTaskIndex;
		if (index < state.activeExercise.sections[state.activeQuestionIndex].tasks.length - 1) {
			commit('setActiveTaskIndex', index + 1);
			return 'question-task';
		}
		return dispatch('nextQuestion');
	},
	setActiveExerciseStarted({ commit, state }) {
		commit('setActiveExerciseUserProgress', { ...state.activeExercise.user_progress, started: true });
	},
	setActiveExerciseCompleted({ commit, state }) {
		commit('setActiveExerciseUserProgress', { ...state.activeExercise.user_progress, completed: true });
		commit('setRecentlyCompletedExercise');
	},
	resetExercise({ rootState }, { section_relation_id, homework_id }) {
		return resetExercise(rootState.user.schoolId, section_relation_id, homework_id);
	},
	resetStudentExercise({ rootState }, { section_relation_id, user_id, homework_id }) {
		return resetStudentExercise(rootState.user.schoolId, section_relation_id, user_id, homework_id);
	},
	startExercise({ commit, state, rootState }, { section_relation_id, homework_id }) {
		return startExercise(rootState.user.schoolId, section_relation_id, homework_id).then(
			({ started_at }) => {
				commit('setActiveExerciseUserProgress', {
					...state.activeExercise.user_progress,
					started_at,
				});
			},
		);
	},
	setActiveExerciseTimeExpired({ commit, state }) {
		commit('setActiveExerciseUserProgress', { ...state.activeExercise.user_progress, time_expired: true });
	},
	toggleExpandExercise({ state, commit }) {
		if (!state.activeExercise) {
			return;
		}
		commit('toggleExpandExercise');
	},
	setHomeworkItems({ commit }, items) {
		commit('setHomeworkItems', items);
	},
	setHomeworkId({ commit, state }, homeworkId) {
		commit('setHomeworkId', homeworkId);
		if (state.homeworkId) {
			commit('setFilterContentByHomework', true);
		}
	},
	setFilterContentByHomework({ commit }, value) {
		commit('setFilterContentByHomework', value);
	},
	setTaskLanguage({ commit }, language) {
		commit('setTaskLanguage', language);
	},
};

const mutations = {
	setActiveExercise(state, exercise) {
		state.activeExercise = exercise;
		state.activeQuestionIndex = 0;
		state.activeTaskIndex = 0;
		state.exerciseExpanded = false;
		state.recentlyCompletedExercise = false;
	},
	clearActiveExercise(state) {
		state.activeExercise = null;
		state.activeQuestionIndex = 0;
		state.activeTaskIndex = 0;
		state.exerciseExpanded = false;
		state.recentlyCompletedExercise = false;
	},
	setActiveQuestionIndex(state, index) {
		state.activeQuestionIndex = index;
	},
	setActiveTaskIndex(state, index) {
		state.activeTaskIndex = index;
	},
	setTaskAnswer(state, { section_relation_id, task_id, answer }) {
		OuterLoop: for (let i = 0; i < state.activeExercise.sections.length; i++) {
			if (state.activeExercise.sections[i].section_relation_id !== section_relation_id) {
				continue;
			}
			for (let j = 0; j < state.activeExercise.sections[i].tasks.length; j++) {
				if (state.activeExercise.sections[i].tasks[j].id === task_id) {
					state.activeExercise.sections[i].tasks[j].answer = answer;
					break OuterLoop;
				}
			}
		}

		state.activeExercise.user_progress.points = state.activeExercise.sections
			.flatMap(s => s.tasks)
			.reduce((sum, task) => {
				if (!task.answer || !task.answer.points) {
					return sum;
				}
				return sum + +task.answer.points;
			}, 0);
	},
	setActiveExerciseUserProgress(state, userProgress) {
		state.activeExercise.user_progress = userProgress;
	},
	toggleExpandExercise(state) {
		state.exerciseExpanded = !state.exerciseExpanded;
	},
	setRecentlyCompletedExercise(state) {
		state.recentlyCompletedExercise = true;
	},
	setHomeworkItems(state, items) {
		state.homeworkItems = items;
	},
	setHomeworkId(state, homeworkId) {
		state.homeworkId = parseInt(homeworkId) > 0 ? parseInt(homeworkId) : null;
	},
	setFilterContentByHomework(state, value) {
		state.filterContentByHomework = value;
	},
	setTaskLanguage(state, language) {
		state.taskLanguage = language;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
