<template>
	<KinesisContainer class="auth-cotainer is-flex is-align-items-center is-relative">
		<component :is="backgroundComponent" class="backdrop" />

		<div class="auth-box">
			<div class="box is-paddingless is-clipped has-background-grey-lighterer">
				<slot />
			</div>
		</div>
	</KinesisContainer>
</template>

<script>
import { KinesisContainer } from 'vue-kinesis';
import EnglishBackground from './auth-backgrounds/EnglishBackground.vue';
import GermanBackground from './auth-backgrounds/GermanBackground.vue';
import FrenchBackground from './auth-backgrounds/FrenchBackground.vue';

const backgroundComponents = [EnglishBackground, GermanBackground, FrenchBackground];

export default {
	name: 'AuthLayout',
	components: {
		KinesisContainer,
		EnglishBackground,
		GermanBackground,
		FrenchBackground,
	},
	computed: {
		backgroundComponent() {
			return backgroundComponents[Math.floor(Math.random() * backgroundComponents.length)];
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
@import '@/assets/sass/abstracts/mixins';

.auth-cotainer {
	height: 100vh;

	.backdrop {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.auth-box {
		position: relative;
		padding: 0 2rem;
		width: 100%;
		.box {
			border-radius: 2rem;
		}
		@include mixin-tablet-up {
			width: 50%;
		}
		@include mixin-desktop-up {
			width: 25%;
			min-width: 420px;
			max-width: 620px;
			padding: 0;
			margin-left: 7%;
			.box {
				min-height: 645px;
			}
		}

		:deep(.form-container) {
			padding-left: 4rem;
			padding-right: 4rem;
		}
	}
}
</style>
