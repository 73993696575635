<template>
	<BaseHoverable>
		<template #default="slotProps">
			<HomeworkCardContainer
				v-tippy="{ content: tooltip }"
				class="homework-create-card is-centered"
				:class="{ 'is-hovered': slotProps.isHovered }"
				:footer-border-color="slotProps.isHovered ? 'grey-light' : 'grey-lighter'"
				@click.native="showPopup"
			>
				<template #image>
					<BaseSkeleton
						rounded
						width="100%"
						height="10rem"
						position="centered"
						:animate="false"
						:color-name="slotProps.isHovered ? 'grey-light' : 'transparent'"
						class="homework-card-image"
					/>
				</template>
				<template #schoolclass
					><BaseSkeleton
						rounded
						width="3rem"
						height="2rem"
						:animate="false"
						:color-name="slotProps.isHovered ? 'grey-light' : 'grey-lighter'"
				/></template>
				<template #course
					><BaseSkeleton
						rounded
						width="3rem"
						height="2rem"
						:animate="false"
						:color-name="slotProps.isHovered ? 'grey-light' : 'grey-lighter'"
				/></template>
				<template #title>
					<BaseSkeleton
						rounded
						width="100px"
						size="normal"
						position="centered"
						:animate="false"
						:color-name="slotProps.isHovered ? 'grey-light' : 'grey-lighter'"
					/>
				</template>
				<template #source>
					<BaseSkeleton
						rounded
						width="60px"
						size="normal"
						position="centered"
						:animate="false"
						:color-name="slotProps.isHovered ? 'grey-light' : 'grey-lighter'"
					/>
				</template>
				<template #deadline>
					<div class="is-flex is-align-items-center is-justify-content-start">
						<div>
							<BaseSkeleton
								circle
								width="20px"
								height="20px"
								:animate="false"
								:color-name="
									slotProps.isHovered
										? 'grey-light'
										: 'grey-lighter'
								"
								class="mr-2"
							/>
						</div>
						<div>
							<BaseSkeleton
								rounded
								width="48px"
								size="normal"
								:animate="false"
								:color-name="
									slotProps.isHovered
										? 'grey-light'
										: 'grey-lighter'
								"
							/>
						</div>
					</div>
				</template>
				<template #duration>
					<div class="is-flex is-align-items-center is-justify-content-end">
						<div>
							<BaseSkeleton
								circle
								width="20px"
								height="20px"
								:animate="false"
								:color-name="
									slotProps.isHovered
										? 'grey-light'
										: 'grey-lighter'
								"
								class="mr-2"
							/>
						</div>
						<div>
							<BaseSkeleton
								rounded
								width="48px"
								size="normal"
								:animate="false"
								:color-name="
									slotProps.isHovered
										? 'grey-light'
										: 'grey-lighter'
								"
							/>
						</div>
					</div>
				</template>
				<template #footer>
					<BaseSkeleton
						rounded
						width="100%"
						size="normal"
						:animate="false"
						:color-name="slotProps.isHovered ? 'grey-light' : 'grey-lighter'"
					/>
				</template>

				<div
					class="create-icon-container is-flex is-justify-content-center is-flex-direction-column"
				>
					<div class="is-flex is-justify-content-center">
						<BaseIcon
							icon="plus"
							class="create-icon"
							:color="slotProps.isHovered ? 'white' : 'blue'"
							:class="`has-background-${
								slotProps.isHovered ? 'blue' : 'white'
							}`"
							:size="8"
							show-background
							has-transition
						/>
					</div>
				</div>
			</HomeworkCardContainer>

			<SplitModal
				ref="createHomeworkModal"
				title="Opret aktivitet"
				:reduce-top-margin="true"
				:image-width="modalImageWidth"
				:left-column-classes="[]"
				class="create-homework-modal"
				:class="{
					'show-materials': showMaterials,
					'show-no-classes': schoolClasses.length === 0,
				}"
			>
				<template #img>
					<img src="/images/homework/create-activity.svg" />
				</template>

				<template #body>
					<div v-if="schoolClasses.length === 0" class="">
						<BaseNoContent
							header="Opret først et hold"
							text="For at oprette en aktivitet skal du først oprette et hold"
						>
							<template #buttons>
								<div class="buttons is-centered">
									<router-link
										:to="{ name: 'school_classes' }"
										class="button is-primary is-medium"
									>
										Opret hold
									</router-link>
								</div>
							</template>
						</BaseNoContent>
					</div>
					<div v-else-if="!showMaterials" class="my-7 pt-3 pb-7 is-flex">
						<BaseField has-icon-right class="dropdown-container">
							<BaseSelect
								v-model="selectedSchoolClass"
								:options="schoolClasses"
								placeholder="Vælg hold"
								id-key="id"
								:display-option="option => (option ? option.name : '')"
								@input="schoolClassSelected"
							/>
						</BaseField>

						<BaseField has-icon-right class="dropdown-container">
							<BaseSelect
								v-model="selectedCourse"
								:options="schoolClassCourses"
								:disabled="selectedSchoolClass === null"
								placeholder="Vælg fag"
								id-key="id"
								:display-option="option => (option ? option.title : '')"
							/>
						</BaseField>
					</div>
					<template v-if="showMaterials">
						<CourseMaterialList
							:course="selectedCourse"
							:school-class="selectedSchoolClass"
							:show-assignment-item="false"
							card-size="small"
						></CourseMaterialList>
					</template>
				</template>

				<template #footer>
					<div class="buttons is-right">
						<button
							:disabled="
								selectedSchoolClass === null || selectedCourse === null
							"
							type="button"
							class="button is-medium is-primary"
							@click="showMaterialSelecter"
						>
							Videre
						</button>
					</div>
				</template>
			</SplitModal>
		</template>
	</BaseHoverable>
</template>

<script>
import HomeworkCardContainer from './HomeworkCardContainer';
import CourseMaterialList from '@/components/homework/create/CourseMaterialList';
import BaseField from '@/components/base/input/BaseField';
import BaseSkeleton from '@/components/base/load/BaseSkeleton';
import BaseIcon from '@/components/base/BaseIcon';
import BaseHoverable from '@/components/base/BaseHoverable';
import BaseNoContent from '@/components/base/BaseNoContent';
import BaseSelect from '@/components/base/input/BaseSelect';
import SplitModal from '@/components/ui/modal/SplitModal';
import { mapGetters } from 'vuex';

export default {
	name: 'HomeworkCreateCard',
	components: {
		HomeworkCardContainer,
		BaseSkeleton,
		BaseIcon,
		BaseField,
		BaseHoverable,
		BaseNoContent,
		BaseSelect,
		CourseMaterialList,
		SplitModal,
	},
	props: {
		tooltip: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			selectedSchoolClass: null,
			selectedCourse: null,
			showMaterials: false,
		};
	},
	computed: {
		...mapGetters('school', ['schoolClasses']),
		schoolClassCourses() {
			if (this.selectedSchoolClass) {
				return this.selectedSchoolClass.courses;
			}
			return [];
		},
		modalImageWidth() {
			return this.schoolClasses.length === 0 || this.showMaterials ? 0 : 4;
		},
	},
	methods: {
		showPopup() {
			this.showMaterials = false;
			this.$refs.createHomeworkModal.show();
		},
		schoolClassSelected(schoolClass) {
			if (schoolClass.courses.length === 0) {
				this.selectedCourse = null;
			}
			if (schoolClass.courses.length === 1) {
				this.selectedCourse = schoolClass.courses[0];
			}
		},
		showMaterialSelecter() {
			this.showMaterials = true;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';

.homework-create-card {
	position: relative;
	cursor: pointer;

	&,
	.homework-card-image {
		border-radius: $radius-large;
		border-width: 3px;
		border-style: solid;
		border-color: $grey-lighter;
		transition: border-color 0.2s ease;

		&.is-hovered {
			border-color: $grey-light;
		}
	}

	.deadline-duration-container {
		> div {
			height: 2rem;
		}
	}

	.create-icon-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		.create-icon {
			box-shadow: $box-shadow;
		}
	}
}
.modal-body {
	margin-top: 1rem !important;
}
</style>

<style lang="scss">
.create-homework-modal {
	.dropdown-items {
		max-height: 130px !important;
	}
	.dropdown-container {
		width: 10rem;
		margin-right: 2rem;
	}
	&.show-no-classes,
	&.show-materials {
		.modal-body {
			padding: 20px 100px !important;
			margin-bottom: 1.5rem !important;
		}
		.modal-left,
		.modal-footer {
			display: none;
		}
	}
	&.show-no-classes {
		.modal-body {
			padding: 20px 0 !important;
			> div {
				> .section {
					width: 100%;
				}
			}
			> .title {
				display: none;
			}
		}
	}
}
</style>
