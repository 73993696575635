import { getAppConfig, getUniloginConfig } from '@/api/app';
import { getCachedItem, cacheItem, forgetItem } from '../cache';

const configCacheKey = 'config';
const uniloginCacheKey = 'uniloginConfig';

const state = () => ({
	config: getCachedItem(configCacheKey) || null,
	uniloginConfig: getCachedItem(uniloginCacheKey) || null,
});

// getters
const getters = {
	uniloginConfig:
		state =>
		(key = null) => {
			if (!key) {
				return state.uniloginConfig;
			}
			return state.uniloginConfig?.[key];
		},
	// TODO: Change this check when new broker is fully implemented
	useLegacyUniloginSSOProxyAuth: state => state.uniloginConfig && 'unilogin_url' in state.uniloginConfig,
};

const actions = {
	getConfig({ state, commit }) {
		if (state.config) {
			return state.config;
		}

		return getAppConfig().then(config => {
			commit('setConfig', config);
			return config;
		});
	},
	clearConfigCache({ commit }) {
		commit('clearConfigCache');
	},
	getUniloginConfig({ commit }) {
		return getUniloginConfig().then(config => {
			commit('setUniloginConfig', config);
		});
	},
	clearUniloginConfigCache({ commit }) {
		commit('clearUniloginConfigCache');
	},
};

const mutations = {
	setConfig(state, config) {
		state.config = config;
		cacheItem(configCacheKey, config);
	},
	clearConfigCache() {
		forgetItem(configCacheKey);
	},
	setUniloginConfig(state, config) {
		state.uniloginConfig = config;
		cacheItem(uniloginCacheKey, config);
	},
	clearUniloginConfigCache() {
		forgetItem(uniloginCacheKey);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
