<template>
	<AppMessage
		v-if="showWarning"
		type="warning"
		message="Advarsel: Du bruger en forældet browser. Skift til Google Chrome eller opdater din browser for
			at undgå tekniske problemer."
		:dismissible="false"
		@dismiss="dismissWarning"
	/>
</template>

<script>
import AppMessage from '@/components/ui/AppMessage';
import { cacheItem, getCachedItem } from '@/store/cache';
import dayjs from 'dayjs';

export default {
	name: 'BrowserCompabilityWarning',
	components: { AppMessage },
	data() {
		return {
			showWarning: false,
		};
	},
	computed: {
		browserIsCompatible() {
			if (
				this.browser.title === 'unknown' ||
				this.browser.title === 'MSIE' ||
				(this.browser.title === 'Safari' && this.browser.version < 14) ||
				(this.browser.title === 'Chrome' && this.browser.version < 80)
			) {
				return false;
			}

			return true;
		},
		warningHasBeenDismissed() {
			const dismissed = getCachedItem('browserWarningDismissed');

			if (!dismissed) {
				return false;
			}

			return dayjs(dismissed).add(1, 'hour').isAfter(dayjs());
		},
		browser() {
			const browser = {
				title: 'unknown',
				version: 0,
			};

			if (/Opera[/\s](\d+\.\d+)/.test(navigator.userAgent)) {
				browser.title = 'Opera';
			} else if (/MSIE (\d+\.\d+);/.test(navigator.userAgent)) {
				browser.title = 'MSIE';
			} else if (/Trident.*rv:(\d+\.\d+)\)/.test(navigator.userAgent)) {
				browser.title = 'MSIE';
			} else if (/Navigator[/\s](\d+\.\d+)/.test(navigator.userAgent)) {
				browser.title = 'Netscape';
			} else if (/Chrome[/\s](\d+\.\d+)/.test(navigator.userAgent)) {
				browser.title = 'Chrome';
			} else if (/Safari[/\s](\d+\.\d+)/.test(navigator.userAgent)) {
				browser.title = 'Safari';
				/Version[/\s](\d+\.\d+)/.test(navigator.userAgent);
				browser.version = new Number(RegExp.$1);
			} else if (/Firefox[/\s](\d+\.\d+)/.test(navigator.userAgent)) {
				browser.title = 'Firefox';
			}

			if (browser.title !== 'unknown' && browser.version === 0) {
				browser.version = parseFloat(new Number(RegExp.$1));
			}

			return browser;
		},
	},
	created() {
		/* eslint-disable no-console */
		// Print the detected browser to the console, so we can see it in Sentry.
		console.log(
			'Detected browser',
			this.browser.title,
			this.browser.version,
			'compatible',
			this.browserIsCompatible,
		);
		/* eslint-enable no-console */

		if (!this.browserIsCompatible && !this.warningHasBeenDismissed) {
			this.showWarning = true;
		}
	},
	methods: {
		dismissWarning() {
			this.showWarning = false;
			cacheItem('browserWarningDismissed', dayjs());
		},
	},
};
</script>
