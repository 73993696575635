<template>
	<img :src="url" class="exercise-icon is-flex-shrink-0" :class="cssClasses" />
</template>

<script>
export default {
	name: 'ExerciseIcon',
	props: {
		type: {
			type: String,
			required: true,
		},
		size: {
			type: String,
			default: 'medium',
		},
		active: {
			type: Boolean,
			default: true,
		},
		stroked: {
			type: Boolean,
			default: false,
		},
		showBackground: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		cssClasses() {
			const classNames = [`is-${this.size}`];
			if (this.showBackground) {
				classNames.push('show-background');
			}
			classNames.push('icon-type-' + this.type);
			return classNames;
		},
		url() {
			const folder = '/images/icons/exercise/';
			if (!this.active) {
				return `${folder}${this.type}-inactive.svg`;
			}
			return `${folder}${this.type}${this.stroked ? '-stroked' : ''}.svg`;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';

.exercise-icon {
	width: 3rem;
	height: 3rem;

	&.is-small {
		width: 1rem;
		height: 1rem;
	}
	&.is-medium {
		width: 2rem;
		height: 2rem;
	}
	&.is-large {
		width: 3rem;
		height: 3rem;
	}
	&.is-xlarge {
		width: 6rem;
		height: 6rem;
	}
	&.show-background {
		padding: 0.4rem;
		border-radius: 50%;
		&.icon-type-difficulty1 {
			background-color: $green-lighter;
		}
		&.icon-type-difficulty2 {
			background-color: $blue-lighterer;
		}
		&.icon-type-difficulty3 {
			background-color: $red-lighter;
		}
		&.icon-type-exam {
			background-color: $purple-lighter;
		}
		&.icon-type-points {
			background-color: $yellow-lighter;
		}
	}
}
</style>
