import http from './http';

export function login(email, password) {
	return http
		.post('/auth/login', {
			email,
			password,
		})
		.then(resp => resp.data);
}

export function unilogin(timestamp, user, auth, hash) {
	return http
		.post('/auth/unilogin', {
			timestamp,
			user,
			auth,
			hash,
		})
		.then(resp => resp.data);
}

export function loginWithSignedUrl(user_id, signature) {
	return http.post(`/auth/autologin?user_id=${user_id}&signature=${signature}`).then(resp => resp.data);
}

export function forgotPassword(email) {
	return http.post('/password/forgot', { email }).then(resp => resp.data);
}

export function resetPassword(token, email, password, passwordConfirm) {
	return http
		.post('/password/reset', {
			token,
			email,
			password,
			password_confirmation: passwordConfirm,
		})
		.then(resp => resp.data);
}

export function getUserToken(schoolId, userId) {
	return http
		.get(`/schools/${schoolId}/students/${userId}/token`, { bypassImpersonation: true })
		.then(resp => resp.data.data);
}
