import http from './http';

export function getDesignationDefaults() {
	return http.get('/designation/defaults').then(resp => resp.data.data);
}

export function updateDesignation(id, data) {
	return http.put(`/designation/${id}`, { data }).then(resp => resp.data);
}

export function getSchoolUserCourseSettings(schoolId, courseId, schoolClassId = null) {
	const params = schoolClassId ? { school_class_id: schoolClassId } : {};
	return http.get(`/schools/${schoolId}/user/designation/${courseId}`, { params }).then(resp => resp.data.data);
}

export function getSchoolUserCoursesSettings(schoolId) {
	return http.get(`/schools/${schoolId}/user/designation`).then(resp => resp.data.data);
}

export function getSchoolTeacherCourseDesignations(schoolId, courseId) {
	return http.get(`/schools/${schoolId}/user/teacherdesignations/${courseId}`).then(resp => resp.data.data);
}
