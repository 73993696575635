<template>
	<div
		class="student-impersonation-bar has-background-blue-light p-4 is-flex is-align-items-center is-justify-content-space-between"
	>
		<div class="is-flex is-align-items-center">
			<div
				class="info-icon has-background-white is-flex is-flex-direction-column is-justify-content-center"
			>
				<p class="title is-5 has-text-blue has-text-centered">!</p>
			</div>
			<p
				class="title has-text-white ml-3"
				v-text="isActive ? 'Du ser nu siden som elev' : 'Vis som elev'"
			/>
		</div>
		<div class="is-flex is-align-items-center">
			<BaseField has-icon-right class="mb-0">
				<StudentImpersonationDropdown
					ref="dropdown"
					:student="student"
					:school-class="schoolClass"
					:school-classes="schoolClasses"
					@select-school-class="onSelectSchoolClass"
					@select-student="onSelectStudent"
					@close="onCloseDropdown"
				/>
			</BaseField>
			<BaseIcon
				v-tippy="{ content: isActive ? 'Log ud som elev' : 'Luk' }"
				icon="cross"
				color="white"
				hover-color="grey-light"
				no-padding
				clickable
				@click.native="exitStudentImpersonation"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseIcon from '@/components/base/BaseIcon';
import BaseField from '@/components/base/input/BaseField';
import StudentImpersonationDropdown from '@/components/studentimpersonation/StudentImpersonationDropdown';

export default {
	name: 'StudentImpersonationBar',
	components: {
		BaseIcon,
		BaseField,
		StudentImpersonationDropdown,
	},
	data() {
		return {
			selectedSchoolClass: null,
			selectedStudent: null,
		};
	},
	computed: {
		...mapGetters('studentImpersonation', {
			isActive: 'isActive',
			barIsActive: 'barIsActive',
			activeSchoolClass: 'schoolClass',
			activeStudent: 'student',
		}),
		...mapGetters('school', ['schoolClasses']),
		schoolClass() {
			return this.selectedSchoolClass ?? this.activeSchoolClass;
		},
		student() {
			return this.selectedStudent ?? this.activeStudent;
		},
	},
	mounted() {
		if (!this.isActive) {
			this.$nextTick(this.openDropdown);
		}
	},
	methods: {
		openDropdown() {
			this.$refs.dropdown?.open();
		},
		onCloseDropdown() {
			if (this.isActive) {
				return;
			}
			this.toggleBar();
		},
		impersonateStudent() {
			return this.$store.dispatch('studentImpersonation/impersonate', {
				user: this.student,
				schoolClass: this.schoolClass,
			});
		},
		exitStudentImpersonation() {
			if (!this.isActive) {
				return;
			}
			return this.$store.dispatch('studentImpersonation/exit');
		},
		toggleBar() {
			return this.$store.dispatch('studentImpersonation/toggleBar');
		},
		onSelectSchoolClass(schoolClass) {
			this.selectedSchoolClass = schoolClass;
		},
		onSelectStudent(student) {
			this.selectedStudent = student;
			this.impersonateStudent();
		},
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
.student-impersonation-bar {
	box-shadow: $navbar-dropdown-boxed-shadow;

	.info-icon {
		padding-top: 1px;
		border-radius: 50%;
		width: 2rem;
		height: 2rem;
	}
}
</style>
