import Section from './Section';
import ItemCollection from '@/libs/ItemCollection';

export default class SectionCollection extends ItemCollection {
	newItem(item = {}) {
		if (item instanceof Section) {
			return item;
		}
		return new Section(item);
	}

	findSection(sectionRelationId) {
		for (const section of this.all()) {
			if (section.section_relation_id == sectionRelationId) {
				return section;
			}
			if (section.hasSections()) {
				const subSection = section.getSections().findSection(sectionRelationId);
				if (subSection) {
					return subSection;
				}
			}
		}
		return null;
	}

	getAllCompleteableSections() {
		return this.all().reduce((totalSections, section) => {
			if (section.isCompleteable()) {
				totalSections.push(section);
			} else if (section.hasSections()) {
				return totalSections.concat(section.sections.getAllCompleteableSections());
			}
			return totalSections;
		}, []);
	}

	getFlattenedSections(filter) {
		return this.all().reduce((totalSections, section) => {
			if (!filter || (filter instanceof Function && filter(section))) {
				totalSections.push(section);
			}
			if (section.hasSections()) {
				return totalSections.concat(section.getSections().getFlattenedSections(filter));
			}
			return totalSections;
		}, []);
	}
}
