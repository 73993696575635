<template>
	<g>
		<g v-if="!asText">
			<circle cx="16" cy="16" r="14" stroke-width="3" />
			<polygon v-if="false" points="9,22 16,9 23,22" stroke-width="3" />
			<polygon
				v-else
				points="6,25 16,7 26,25"
				stroke-width="3"
				transform="translate(4 4) scale(0.75)"
			/>
		</g>
		<text v-else x="0" y="24">OP</text>
	</g>
</template>

<script>
export default {
	name: 'ObjectPredicateSymbol',
	props: {
		asText: {
			type: Boolean,
			default: false,
		},
		hasMultipleWords: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.$emit('config', {
				symbolWidth: this.$el.getBoundingClientRect().width,
				lineLeft: this.hasMultipleWords,
				lineRight: this.hasMultipleWords,
			});
		});
	},
};
</script>
