const state = () => ({
	new_achievements: [],
	achievements: [],
	score: [],
	news: [],
	explorer: [],
});

const getters = {};

const actions = {};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
