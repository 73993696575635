import { getTaskSolution } from '@/api/task';

const state = {};

const getters = {};

const actions = {
	getTaskSolution(_, { taskIds, homeworkId, courseId }) {
		if (!Array.isArray(taskIds)) {
			taskIds = [taskIds];
		}
		return getTaskSolution(taskIds, homeworkId, courseId);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
