import Model from '@/models/Model';
import Answer from '@/models/answer/Answer';

export default class Task extends Model {
	get typeName() {
		return this.type ? this.type.machine_name : '';
	}

	isType(type) {
		return this.typeName == type;
	}

	isGradeable() {
		return this.type ? this.type.gradeable : false;
	}

	hasAnswer() {
		return !!this.answer;
	}

	getAnswer() {
		return this.hasAnswer() ? new Answer(this.answer) : null;
	}
}
