var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"mega-menu-wrapper"},[_c('div',{staticClass:"container pt-5"},[(_vm.menuAssignmentsByCourse.length === 0 && _vm.isStudent)?_c('BaseNoContent',{attrs:{"image-path":"/images/bookshelf.svg","header":"Du har ingen opgavesæt","text":"Tag fat i din lærer for at få adgang til opgavesæt på Minlæring."}}):_c('div',{staticClass:"columns"},_vm._l((_vm.assignmentsByCourseSlice),function(course,index){return _c('div',{key:index,staticClass:"column"},[_c('p',{staticClass:"title is-6 ml-4 mb-3"},[_vm._v(_vm._s(course.title))]),_c('div',_vm._l((course.assignmentTypes),function(assignmentGroup,groupIndex){return _c('router-link',{key:groupIndex,staticClass:"assignment-type",attrs:{"to":{
							name: 'assignments_by_course_and_type',
							params: {
								course_id: assignmentGroup.course_id,
								type_id: assignmentGroup.type_id,
							},
						}}},[_c('AssignmentTypeCard',{attrs:{"assignment-group":assignmentGroup,"designation":_vm.getDesignationByCourseId(course.id)}})],1)}),1)])}),0),_c('div',{staticClass:"mt-5 mb-4 is-flex is-justify-content-center"},[_c('router-link',{staticClass:"button is-medium",attrs:{"to":{ name: 'assignments' },"active-class":""},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Se alle opgavesæt")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }