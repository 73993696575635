<template>
	<BaseDropdown v-bind="$attrs" :disabled="disabled" @show="$emit('show')" @hide="$emit('hide')">
		<template #trigger="slotProps">
			<slot name="trigger" :active="slotProps.active">
				<BaseInputDropdown
					:value="triggerText"
					:size="inputSize"
					:active="slotProps.active"
					:has-selection="value.length > 0 && value.length != options.length"
					v-bind="chevronColor ? { 'chevron-color': chevronColor } : {}"
				/>
			</slot>
		</template>

		<template v-if="!noSelectAll" #content-header>
			<BaseDropdownSelectItem :selected="allOptionsSelected" @click.native="toggleSelectAll">
				Vælg alle
			</BaseDropdownSelectItem>
			<div v-if="searchable" class="mt-1 mb-3 ml-3 mr-3">
				<BaseSearch v-model="search" placeholder="Søg ..." />
			</div>
			<hr v-if="search" class="dropdown-divider has-margin" />
		</template>

		<template v-if="groupedOptions">
			<template v-for="(optionGroup, groupIndex) in options">
				<template v-if="filteredOptions(optionGroup.options).length != 0">
					<template v-if="!search">
						<hr
							:key="`group-divider-top-${groupIndex}`"
							class="dropdown-divider has-margin"
						/>
						<BaseDropdownSelectItem
							:key="`group-title-${groupIndex}`"
							class="has-text-weight-bold"
							:selected="optionsAreSelected(optionGroup.options)"
							@click.native="toggleGroupOptions(optionGroup.options)"
						>
							{{ optionGroup.title }}
						</BaseDropdownSelectItem>
						<hr
							:key="`group-divider-bottom-${groupIndex}`"
							class="dropdown-divider has-margin"
						/>
					</template>
					<BaseDropdownSelectItem
						v-for="(option, index) in filteredOptions(optionGroup.options)"
						:key="`group-${groupIndex}-option-${index}`"
						:selected="optionIsSelected(option)"
						:disabled="option.disabled"
						@click.native="toggleOption(option)"
					>
						<slot name="option" :option="option">
							{{ displayOption(option) }}
						</slot>
					</BaseDropdownSelectItem>
				</template>
			</template>
		</template>
		<template v-else>
			<BaseDropdownSelectItem
				v-for="(option, index) in filteredOptions(options)"
				:key="`option-${index}`"
				:selected="optionIsSelected(option)"
				:disabled="option.disabled"
				@click.native="toggleOption(option)"
			>
				<slot name="option" :option="option">
					{{ displayOption(option) }}
				</slot>
			</BaseDropdownSelectItem>
		</template>

		<template v-if="showResetButton" #content-footer>
			<div class="dropdown-item mt-4">
				<button
					class="button is-medium has-background-grey-lighter has-text-weight-bold is-fullwidth"
					:disabled="selectedOptions.length == 0"
					@click="reset"
				>
					{{ resetButtonText }}
				</button>
			</div>
		</template>
	</BaseDropdown>
</template>

<script>
import BaseDropdownSelectItem from '@/components/base/dropdown/BaseDropdownSelectItem';
import BaseDropdown from '@/components/base/dropdown/BaseDropdown';
import BaseInputDropdown from './BaseInputDropdown';
import BaseSearch from '@/components/base/BaseSearch';

export default {
	name: 'BaseMultiSelect',
	components: {
		BaseDropdown,
		BaseDropdownSelectItem,
		BaseInputDropdown,
		BaseSearch,
	},
	props: {
		value: {
			type: Array,
			default: () => [],
			required: true,
		},
		options: {
			type: Array,
			default: () => [],
		},
		groupedOptions: {
			type: Boolean,
			default: false,
		},
		idKey: {
			type: String,
			default: null,
		},
		displayOption: {
			type: Function,
			default: option => option || '',
		},
		placeholder: {
			type: String,
			default: 'Vælg',
		},
		allSelectedText: {
			type: String,
			default: 'Alle valgt',
		},
		inputSize: {
			type: String,
			default: 'medium',
		},
		resetButtonText: {
			type: String,
			default: 'Ryd',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		noSelectAll: {
			type: Boolean,
			default: false,
		},
		showResetButton: {
			type: Boolean,
			default: false,
		},
		searchable: {
			type: Boolean,
			default: false,
		},
		chevronColor: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			search: '',
		};
	},
	computed: {
		triggerText() {
			if (this.disabled) {
				return '-';
			}
			if (this.selectedOptions.length === 0) {
				return this.placeholder;
			}

			if (this.selectedOptions.length === 1) {
				return this.displayOption(this.selectedOptions[0]);
			}

			return this.allOptionsSelected && this.allSelectedText
				? this.allSelectedText
				: `${this.selectedOptions.length} valgt`;
		},
		selectedOptions() {
			return Array.isArray(this.value) && this.value.length > 0 ? this.value : [];
		},
		allOptions() {
			if (!this.groupedOptions) {
				return this.options.filter(o => !o.disabled);
			}

			return this.options.reduce(
				(options, optionGroup) => options.concat(optionGroup.options.filter(o => !o.disabled)),
				[],
			);
		},
		allOptionsSelected() {
			return this.allOptions.length === this.selectedOptions.length;
		},
	},
	methods: {
		filteredOptions(options) {
			if (!this.searchable || !this.search) {
				return options;
			}
			return options.filter(option => {
				return option.title.toString().toLowerCase().indexOf(this.search.toLowerCase()) >= 0;
			});
		},
		findSelectedOptionIndex(option, selectedOptions = this.selectedOptions) {
			const isObject = item => typeof item === 'object' && item !== null;
			return selectedOptions.findIndex(selectedOption => {
				if (this.idKey && isObject(option) && isObject(selectedOption)) {
					return option[this.idKey] === selectedOption[this.idKey];
				}
				return option === selectedOption;
			});
		},
		optionIsSelected(option) {
			return this.findSelectedOptionIndex(option) >= 0;
		},
		optionsAreSelected(options) {
			for (const option of options) {
				if (!this.optionIsSelected(option)) {
					return false;
				}
			}
			return true;
		},
		toggleOption(option) {
			if (option.disabled === true) {
				return;
			}

			const selectedOptions = [...this.selectedOptions];
			const optionIndex = this.findSelectedOptionIndex(option);
			if (optionIndex >= 0) {
				selectedOptions.splice(optionIndex, 1);
			} else {
				selectedOptions.push(option);
			}
			this.$emit('toggle', option);
			this.$emit('input', selectedOptions);
		},
		toggleGroupOptions(options) {
			const selectedOptions = [...this.selectedOptions];
			if (this.optionsAreSelected(options)) {
				options.forEach(option => {
					const optionIndex = this.findSelectedOptionIndex(option, selectedOptions);
					if (optionIndex >= 0) {
						selectedOptions.splice(optionIndex, 1);
					}
				});
			} else {
				options.forEach(option => {
					if (!this.optionIsSelected(option)) {
						selectedOptions.push(option);
					}
				});
			}
			this.$emit('input', selectedOptions);
		},
		toggleSelectAll() {
			if (this.allOptionsSelected) {
				this.reset();
			} else {
				this.selectAll();
			}
		},
		selectAll() {
			this.$emit('select-all');
			this.$emit(
				'input',
				this.allOptions.filter(o => !o.disabled),
			);
		},
		reset() {
			this.$emit('deselect-all');
			this.$emit('input', []);
		},
	},
};
</script>
