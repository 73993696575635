import cloneDeep from 'lodash/cloneDeep';

export default class Model {
	constructor(values = {}) {
		this.init(this.getAttributes(values));
	}

	getIdentifier() {
		return this.id;
	}

	init(values = {}) {
		const data = this.processValues(Object.assign(this.getDefaultValues(), values));
		for (let key in data) {
			this[key] = data[key];
		}
	}

	getDefaultValues() {
		return {};
	}

	processValues(values = {}) {
		return values;
	}

	getAttributes(item = this) {
		return Object.fromEntries(Object.keys(item).map(key => [key, item[key]]));
	}

	isSameAs(item) {
		return item instanceof this.constructor && item.getIdentifier() === this.getIdentifier();
	}

	clone() {
		return cloneDeep(this);
	}
}
