<template>
	<SplitModal ref="modal" title="Nulstil login" :class="{ 'has-text-left': true }" @hide="onHide">
		<template #img>
			<img src="/images/modals/pushing-buttons.png" />
		</template>

		<template #body>
			<BaseLoader v-if="loading" size="medium" class="mt-6" />

			<template v-else-if="resetDone">
				<p>
					Elevens login er nu nulstillet.<br />
					Eleven kan logge ind med:
				</p>
				<p class="mt-2"><strong>Mail:</strong> {{ email }}</p>
				<p class="mt-2">
					<strong>Kodeord: </strong>
					<span class="password">{{ password }}</span>
				</p>
			</template>

			<template v-else>
				<p class="mb-4">
					For at nulstille elevens login, skal du først indtaste elevens email.
				</p>
				<div class="field">
					<input
						v-model.trim="$v.email.$model"
						class="input"
						type="email"
						placeholder="Indtast email"
						:class="{
							'is-danger': $v.email.$error,
						}"
					/>
					<p v-if="$v.email.$error" class="help is-danger">
						Der skal angives en korrekt email
					</p>
				</div>

				<template v-if="validationErrors.email">
					<p v-for="(err, i) in validationErrors.email" :key="i" class="help is-danger">
						{{ err }}
					</p>
				</template>
			</template>
		</template>

		<template #footer>
			<div class="buttons is-right">
				<button
					v-if="!loading && !resetDone"
					type="button"
					class="button is-medium has-background-grey-lighterer has-text-grey"
					@click="hide"
				>
					Annuller
				</button>
				<button
					v-if="!loading && !resetDone"
					type="button"
					class="button is-medium is-danger has-background-red has-text-white"
					:disabled="$v.$invalid"
					@click="confirm"
				>
					Nulstil
				</button>
				<button
					v-if="resetDone"
					type="button"
					class="button is-medium is-primary"
					@click="hide"
				>
					Færdig
				</button>
			</div>
		</template>
	</SplitModal>
</template>

<script>
import BaseLoader from '@/components/base/BaseLoader';
import SplitModal from '@/components/ui/modal/SplitModal';
import { required, email } from 'vuelidate/lib/validators';
import { ValidationError } from '@/api/errors';
import { mapState } from 'vuex';

export default {
	name: 'ResetStudentLoginModal',
	components: { SplitModal, BaseLoader },
	props: {
		student: {
			type: Object,
			default: null,
		},
	},
	validations: {
		email: {
			required,
			email,
		},
	},
	data: () => ({
		loading: false,
		error: null,
		password: null,
		email: null,
		validationErrors: {},
		resetDone: false,
	}),
	computed: {
		...mapState('user', ['schoolId']),
	},
	methods: {
		reset() {
			this.error = null;
			this.email = null;
			this.validationErrors = {};
			this.resetDone = false;
		},
		onHide() {
			if (this.resetDone) {
				this.$emit('updated', this.student);
			}
			this.reset();
		},
		hide() {
			this.onHide();
			this.$refs.modal.hide();
		},
		show() {
			if (this.student) {
				this.email = this.student.email;
			}

			this.$refs.modal.show();

			// If the student has an email, go ahead and reset.
			if (this.email !== null) {
				this.confirm();
			}
		},
		confirm() {
			this.error = null;
			this.loading = true;
			this.validationErrors = {};
			this.$store
				.dispatch('school/resetStudentLogin', {
					schoolId: this.schoolId,
					studentId: this.student.id,
					data: {
						email: this.email,
					},
				})
				.catch(err => {
					if (err instanceof ValidationError) {
						this.error = err.message;
						this.validationErrors = err.validationErrors;
					}
					this.error = err.message;
				})
				.then(response => {
					if (!this.error) {
						this.password = response.password;
						this.email = response.email;
						this.resetDone = true;
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
