import VueCookie from 'vue-cookie';
import { cookieOptions } from '../cookie';
import { login, unilogin, loginWithSignedUrl, forgotPassword, resetPassword } from '@/api/auth';
import { loginWithCode } from '@/api/unilogin';

const tokenKey = 'token';
const isImpersonationKey = 'isImpersonation';
const uniloginLogoutUrlKey = 'uniloginLogoutUrl';

// initial state
const state = () => ({
	token: VueCookie.get(tokenKey) || null,
	isImpersonation: VueCookie.get(isImpersonationKey) || false,
	uniloginLogoutUrl: VueCookie.get(uniloginLogoutUrlKey) || null,
});

// getters
const getters = {
	isAuthenticated: state => !!state.token,
	token:
		(state, _, rootState) =>
		(bypassImpersonation = false) => {
			if (bypassImpersonation || !rootState?.studentImpersonation?.token) {
				return state.token;
			}
			return rootState.studentImpersonation.token;
		},
	uniloginLogoutUrl: state => state.uniloginLogoutUrl,
};

// actions
const actions = {
	setToken({ commit }, { token, rememberMe }) {
		commit('setToken', { token, rememberMe });
	},
	login({ commit }, { email, password, rememberMe }) {
		return login(email, password).then(resp => {
			const token = resp.access_token;
			commit('clearIsImpersonation');
			commit('setToken', { token, rememberMe });
			commit('user/setUser', resp.user, { root: true });
		});
	},
	// Deprecated
	unilogin({ commit }, { timestamp, user, auth, hash }) {
		return unilogin(timestamp, user, auth, hash).then(resp => {
			const token = resp.access_token;
			commit('clearIsImpersonation');
			commit('setToken', { token, rememberMe: true });
			commit('user/setUser', resp.user, { root: true });
		});
	},
	uniloginWithCode({ commit }, { code, schoolId = null, schoolRole = null }) {
		return loginWithCode(code, schoolId, schoolRole).then(
			({ access_token, user, unilogin_logout_url = null }) => {
				if (!access_token || !user) {
					return;
				}
				commit('clearIsImpersonation');
				commit('setToken', { token: access_token, rememberMe: true });
				commit('user/setUser', user, { root: true });
				if (unilogin_logout_url) {
					commit('setUniloginLogoutUrl', unilogin_logout_url);
				} else {
					commit('clearUniloginLogoutUrl');
				}
				// Auto select schoolId if provided
				if (schoolId) {
					commit('user/setSchoolId', schoolId, { root: true });
				}
			},
		);
	},
	loginWithSignedUrl({ commit }, { user_id, signature }) {
		return loginWithSignedUrl(user_id, signature).then(resp => {
			const token = resp.access_token;
			commit('clearIsImpersonation');
			commit('setToken', { token });
		});
	},
	logout({ commit }) {
		commit('clearToken');
		commit('clearIsImpersonation');
		commit('user/clearUser', null, { root: true });
		commit('user/clearSchool', null, { root: true });
		// Also clear Lix token
		commit('lix/clearToken', null, { root: true });
	},
	forgotPassword(_, { email }) {
		return forgotPassword(email);
	},
	resetPassword(_, { token, email, password, passwordConfirm }) {
		return resetPassword(token, email, password, passwordConfirm);
	},
	setIsImpersonation({ commit }, value) {
		commit('setIsImpersonation', value);
	},
	clearUniloginLogoutUrl({ commit }) {
		commit('clearUniloginLogoutUrl');
	},
};

// mutations
const mutations = {
	setToken(state, { token, rememberMe }) {
		VueCookie.set(tokenKey, token, cookieOptions(rememberMe));
		state.token = token;
	},
	clearToken(state) {
		VueCookie.delete(tokenKey);
		state.token = null;
	},
	setIsImpersonation(state, value) {
		VueCookie.set(isImpersonationKey, value, cookieOptions(false));
		state.isImpersonation = value;
	},
	clearIsImpersonation(state) {
		VueCookie.delete(isImpersonationKey);
		state.isImpersonation = false;
	},
	setUniloginLogoutUrl(state, value) {
		VueCookie.set(uniloginLogoutUrlKey, value, cookieOptions(true));
		state.uniloginLogoutUrl = value;
	},
	clearUniloginLogoutUrl(state) {
		VueCookie.delete(uniloginLogoutUrlKey);
		state.uniloginLogoutUrl = null;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
