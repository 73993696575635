<template>
	<portal>
		<BaseModal :title="homework.title" class="homework-modal" @close="onClose">
			<template #body>
				<BaseLoader v-if="loading" />
				<div v-else>
					<TeacherMessage
						v-if="homework.message"
						:teacher="homework.teacher"
						:message="homework.message"
					/>

					<TeacherMessage
						v-if="feedbackMessage"
						:teacher="feedbackMessage.from_user"
						:message="feedbackMessage.content"
						:updated-at="feedbackMessage.updated_at"
						color="blue-light"
						text-color="white"
					/>

					<HomeworkSectionList
						:homework="homework"
						:sections="sections.all()"
						:designation="currentDesignation"
						@open-section="goToSection"
					/>
				</div>
			</template>

			<template v-if="!loading" #footer>
				<div class="progress-footer">
					<BaseProgressBar
						class="progressbar"
						:progress="homework.progress ? homework.progress : 0"
					/>

					<BaseCheckbox
						v-if="isCompleted"
						active-color="green"
						selected
						rounded
						class="mr-4"
					/>

					<div v-else class="buttons is-right">
						<button class="button is-medium is-primary" @click="goToNextSection">
							Gå til næste del
						</button>
					</div>
				</div>
			</template>
		</BaseModal>
	</portal>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseModal from '@/components/base/modals/BaseModal';
import BaseLoader from '@/components/base/BaseLoader';
import BaseProgressBar from '@/components/base/BaseProgressBar';
import BaseCheckbox from '@/components/base/input/BaseCheckbox';
import TeacherMessage from '../ui/TeacherMessage';
import HomeworkSectionList from './HomeworkSectionList';
import designation from '@/mixins/designation';
import SectionCollection from '@/models/section/SectionCollection';

export default {
	name: 'HomeworkModal',
	components: {
		BaseModal,
		BaseLoader,
		BaseProgressBar,
		BaseCheckbox,
		TeacherMessage,
		HomeworkSectionList,
	},
	mixins: [designation],
	props: {
		homework: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			sections: new SectionCollection([]),
		};
	},
	computed: {
		...mapGetters('designation', ['getDesignationByCourseId']),
		currentDesignation() {
			if (this.homework.designation) {
				return this.$designation(this.homework.designation);
			}
			return this.getDesignationByCourseId(this.homework.course.id, this.homework.school_class_id);
		},
		isCompleted() {
			return this.homework?.progress >= 100;
		},
		nextUnfinishedSection() {
			if (this.isCompleted) {
				return null;
			}

			const nextUnfinishedSection = sections => {
				for (const section of sections) {
					if (!section?.user_progress?.completed) {
						return section;
					}

					if (section?.hasSections() && !isExercise(section)) {
						const subSection = nextUnfinishedSection(section.getSections().all());
						if (subSection) {
							return subSection;
						}
					}
				}
				return null;
			};

			return nextUnfinishedSection(this.sections.all());
		},
		feedbackMessage() {
			if (!this.homework.messages?.length) {
				return null;
			}
			const message = this.homework.messages[0];
			if (message.from_user) {
				return message;
			}
			const userId = message.from_user_id;
			const teacher =
				this.homework.teacher?.id === userId
					? this.homework.teacher
					: this.homework.school_class?.teachers?.find(t => t.id === userId);
			if (!teacher) {
				return null;
			}
			return {
				...message,
				from_user: teacher,
			};
		},
	},
	mounted() {
		if (!this.homework.designation) {
			this.$refreshCourseDesignation(this.homework.course.id, this.homework.school_class_id);
		}
		this.loadSections();
	},
	methods: {
		loadSections() {
			this.loading = true;
			return this.$store
				.dispatch('homework/getStudentHomeworkSectionOverview', {
					homework_id: this.homework.id,
				})
				.then(sections => (this.sections = new SectionCollection(sections)))
				.finally(() => (this.loading = false));
		},
		reset() {
			this.sections = new SectionCollection([]);
		},
		onClose() {
			this.$emit('hide');
			this.reset();
		},
		goToNextSection() {
			this.goToSection(this.nextUnfinishedSection);
		},
		goToSection(section) {
			if (!section) {
				return;
			}
			if (this.homework.type == 'exercises') {
				const parts = section.getPathParts().map(part => {
					return part.id;
				});
				return this.$router.push({
					name: 'exercise_homework',
					params: {
						id: this.homework.id,
					},
					hash: parts.length > 0 ? `#section-${parts[parts.length - 1]}` : '',
				});
			}
			const route = section.getRoute(null, this.homework.id);
			if (!route) {
				return;
			}
			if (route.hash) {
				// Remove hash to avoid scrolling to specific section
				delete route.hash;
			}
			this.$router.push(route);
			this.$emit('change-section');
		},
	},
};

function isExercise(section) {
	return ['exercise', 'follow_up'].indexOf(section?.type?.machine_name) >= 0;
}
</script>

<style lang="scss" scoped>
.teacher-message {
	background-color: #f0f5f8;
	.message-body {
		border: none;

		.title {
			display: flex;
			align-items: center;

			.teacher-avatar {
				margin-right: 0.5rem;
			}
		}
	}
}

.progress-footer {
	display: flex;
	align-items: center;

	.progressbar {
		margin-right: 1rem;
	}
}
</style>
