<template>
	<div class="has-hover-background-blue-lighterer px-4 py-3">
		<p class="title has-text-weight-normal has-text-grey-dark mb-2">
			<span class="has-text-weight-bold has-text-grey-darker">{{ notification.title }}:</span>
			{{ notification.body }}
		</p>
		<p class="title has-text-weight-normal has-text-grey">
			{{ notification.updated_at | dateFormat('DD/MM/YY') }}
		</p>
	</div>
</template>

<script>
import date from '@/mixins/filters/date';

export default {
	name: 'NotificationListItemDetails',
	mixins: [date],
	props: {
		notification: {
			type: Object,
			required: true,
		},
	},
};
</script>
