<template>
	<figure class="avatar" :class="[`is-${size}`, { highlight: highlight }]">
		<span v-if="hasDot" class="icon-dot has-background-red has-border-white"></span>
		<div :class="{ 'clip-half-circle': color }" class="avatar-container">
			<div v-if="color" class="background-color" :style="{ backgroundColor: color.hex }"></div>
			<img alt="avatar" :src="avatar.url" class="is-relative" />
		</div>
	</figure>
</template>

<script>
export default {
	name: 'Avatar',
	props: {
		avatar: {
			type: Object,
			required: true,
		},
		color: {
			type: Object,
			default: () => {},
		},
		size: {
			type: String,
			default: 'medium',
		},
		hasDot: {
			type: Boolean,
			default: false,
		},
		highlight: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
.avatar {
	position: relative;
	.icon-dot {
		position: absolute;
		top: -5%;
		right: -8%;
		border-radius: 50%;
		border-style: solid;
	}
	.avatar-container {
		display: flex;
		width: 100%;
		&.clip-half-circle {
			overflow: hidden;
		}

		.background-color {
			position: absolute;
			bottom: 0;
			left: 0;
			border-radius: 50%;
			//z-index: -1;
			transition: background-color 200ms linear;

			&:after {
				content: '';
				position: absolute;
				top: -5px;
				left: -5px;
				width: calc(100% + 10px);
				height: calc(100% + 10px);
				border-radius: 50%;
				border: 2px solid transparent;
				transition: border-color 200ms linear;
			}
		}
	}

	&.highlight {
		.avatar-container {
			.background-color {
				&:after {
					border: 2px solid $blue;
				}
			}
		}
	}

	$sizes: (
		xsmall: 1.75rem,
		small: 2rem,
		medium: 2.7rem,
		large: 8rem,
		xlarge: 12rem,
	);

	@each $size, $value in $sizes {
		&.is-#{$size} {
			width: $value;
			.icon-dot {
				border-width: calc($value * 0.075);
				height: calc($value * 0.375);
				width: calc($value * 0.375);
			}
			.background-color {
				height: $value;
				width: $value;
			}
			.clip-half-circle {
				border-bottom-left-radius: calc($value / 2);
				border-bottom-right-radius: calc($value / 2);
			}
		}
	}
}
</style>
