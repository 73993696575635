import Errors from './Errors';
import isEqual from 'lodash/isEqual';

export default class Form {
	constructor(data) {
		this.errors = new Errors();
		this.originalData = data;
		this.setData(data);
	}

	data(keys = this.keys()) {
		return Object.fromEntries(
			keys.map(key => {
				return [key, this.get(key)];
			}),
		);
	}

	keys() {
		return Object.keys(this.originalData);
	}

	has(key) {
		return key in this || key in this.originalData;
	}

	get(key, defaultValue = null) {
		if (key in this) {
			return this[key];
		}
		return key in this.originalData ? this.originalData[key] : defaultValue;
	}

	set(key, value) {
		this[key] = value;
	}

	setData(data) {
		for (const key in data) {
			if (this.has(key)) {
				this.set(key, data[key]);
			}
		}
	}

	reset(keys = this.keys()) {
		keys.forEach(key => this.set(key, this.originalData[key]));
		this.errors.clear();
	}

	isModified(keys = this.keys()) {
		if (!Array.isArray(keys)) {
			keys = [keys];
		}
		for (const key of keys) {
			if (this.has(key) && !isEqual(this[key], this.originalData[key])) {
				return true;
			}
		}
		return false;
	}
}
