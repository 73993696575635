<template>
	<div v-show="ready">
		<NetworkMonitor />

		<AppNotification v-for="(notification, n) in appNotifications" :key="n" :notification="notification" />

		<BrowserCompabilityWarning />

		<StudentImpersonationOverlay v-if="shouldShowStudentImpersonationOverlay" />
		<component :is="layout" v-else>
			<ErrorListener>
				<router-view />
			</ErrorListener>
		</component>

		<GlossaryTooltip />

		<AppUpdate />

		<CourseTrialModal
			v-if="trialMaterial"
			:material="trialMaterial"
			:dismissible="trialModalDismissible"
			@hide="onHideTrialModal"
			@trial-started="onTrialStarted"
			@trial-error="onTrialError"
		/>

		<PreferredCoursesModal v-if="shouldSelectPreferredCourses" />
		<ProvideEmailModal v-else-if="shouldProvideEmail" />
		<ExportDownloadsModal
			v-if="unreadHomeworkExportReadyNotifications.length > 0"
			:notifications="unreadHomeworkExportReadyNotifications"
		/>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Layout from '@/components/layout/Layout';
import PublicLayout from '@/components/layout/PublicLayout';
import AuthLayout from '@/components/layout/AuthLayout';
import ErrorListener from '@/components/layout/ErrorListener';
import GlossaryTooltip from '@/components/grammar/GlossaryTooltip';
import AppUpdate from '@/components/ui/AppUpdate';
import BrowserCompabilityWarning from '@/components/ui/BrowserCompabilityWarning';
import AppNotification from '@/components/ui/AppNotification';
import PusherService from '@/libs/PusherService';
import CourseTrialModal from '@/components/base/modals/CourseTrialModal';
import PreferredCoursesModal from '@/components/modals/PreferredCoursesModal';
import ExportDownloadsModal from '@/components/modals/ExportDownloadsModal';
import ProvideEmailModal from '@/components/modals/ProvideEmailModal';
import NetworkMonitor from '@/components/ui/NetworkMonitor';
import StudentImpersonationOverlay from '@/components/studentimpersonation/StudentImpersonationOverlay';

export default {
	name: 'App',
	components: {
		'default-layout': Layout,
		'public-layout': PublicLayout,
		'auth-layout': AuthLayout,
		ErrorListener,
		GlossaryTooltip,
		AppUpdate,
		BrowserCompabilityWarning,
		AppNotification,
		CourseTrialModal,
		PreferredCoursesModal,
		ExportDownloadsModal,
		ProvideEmailModal,
		NetworkMonitor,
		StudentImpersonationOverlay,
	},
	data: () => ({
		// When document.readyState is 'complete'.
		ready: false,

		// User specific Pusher channel.
		userChannel: null,
	}),
	computed: {
		...mapGetters('user', ['user', 'school', 'shouldSelectPreferredCourses', 'shouldProvideEmail']),
		...mapGetters('documentTitle', ['title']),
		...mapGetters('notification', ['appNotifications', 'unreadHomeworkExportReadyNotifications']),
		...mapGetters('studentImpersonation', { shouldShowStudentImpersonationOverlay: 'shouldShowOverlay' }),
		...mapState('bundles', ['trialMaterial', 'trialModalDismissible', 'onTrialFlowComplete']),
		layout() {
			return (this.$route.meta.layout || 'default') + '-layout';
		},
	},
	watch: {
		title(title) {
			document.title = title;
		},
		user(user) {
			if (user) {
				this.subscribeToUserChannel();
			} else {
				this.unsubscribeFromUserChannel();
			}
		},
	},
	mounted() {
		this.$store.dispatch('documentTitle/setBaseTitle', { title: document.title });

		this.$router.onReady(() => {
			this.$nextTick(this.onReady);
		});
	},
	beforeDestroy() {
		this.unsubscribeFromUserChannel();
	},
	methods: {
		onReady() {
			this.ready = true;
			document.body.classList.remove('loading');
			const loaderElement = document.body.querySelector('.page-loader');
			if (loaderElement) {
				loaderElement.remove();
			}

			if (this.user) {
				this.subscribeToUserChannel();
			}
		},
		subscribeToUserChannel() {
			if (this.userChannel) {
				return;
			}

			this.userChannel = PusherService.subscribe(`private-App.Models.User.User.${this.user.id}`);
			if (!this.userChannel) {
				return;
			}

			this.userChannel.bind(
				'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
				notification => {
					if (notification.school_id && notification.school_id !== this.school?.id) {
						return;
					}
					this.$store.dispatch('notification/addNotification', notification);
					if (notification.type === 'HomeworkExportReady') {
						this.$store.dispatch('homeworkExport/exportReady', notification);
					}
				},
			);
		},
		unsubscribeFromUserChannel() {
			if (!this.userChannel) {
				return;
			}

			this.userChannel.unbind_all();
			this.userChannel.unsubscribe();
			this.userChannel = null;
		},
		onHideTrialModal() {
			this.$store.commit('bundles/unsetTrialFlow');
		},
		onTrialStarted(message) {
			// Reload current route
			this.$toasted.show(message, {
				position: 'top-center',
				type: 'success',
				duration: 5000,
			});
			if (this.onTrialFlowComplete) {
				this.onTrialFlowComplete();
			}
		},
		onTrialError(err) {
			this.$toasted.show(err.message, {
				position: 'top-center',
				type: 'error',
				duration: 10000,
			});
		},
	},
};
</script>

<style lang="scss">
@import './assets/sass/app';
</style>
