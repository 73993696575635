import Model from '@/models/Model';
import AnswerCollection from './AnswerCollection';
import { newDate } from '@/utils/date';

export default class AnswerRevision extends Model {
	getDefaultValues() {
		return {
			id: 0,
			user_id: 0,
			section_relation_id: 0,
			section_id: 0,
			homework_id: 0,
			is_completed: false,
			answers: new AnswerCollection(),
		};
	}

	processValues(values = {}) {
		if (!(values.answers instanceof AnswerCollection)) {
			values.answers = new AnswerCollection(values.answers);
		}
		return values;
	}

	hasAnswers() {
		return this.answers?.length > 0;
	}

	getAnswers() {
		if (!this.hasAnswers()) {
			return new AnswerCollection();
		}
		return this.answers instanceof AnswerCollection ? this.answers : new AnswerCollection(this.answers);
	}

	getStartDate() {
		if (this.latest_answer_at) {
			return newDate(this.latest_answer_at);
		}
		return newDate(this.created_at);
	}
}
