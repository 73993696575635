import http from './http';

export function getBooksByCourse(schoolId, all = false) {
	return http
		.get(`/schools/${schoolId}/user/books/course`, {
			params: { all },
		})
		.then(resp => resp.data.data);
}

export function getBook(id, eagerLoad) {
	return http.get(`/books/${id}`, { params: { with: eagerLoad } }).then(resp => resp.data.data);
}

export function getBookChapterSections(book_id, chapter_id) {
	return http.get(`/books/${book_id}/chapters/${chapter_id}/sections`).then(resp => resp.data.data);
}

export function getBookSitemap(book_id) {
	return http.get(`/books/${book_id}/sitemap`).then(resp => resp.data.data);
}

export function getSchoolBook(schoolId, id) {
	return http.get(`/schools/${schoolId}/books/${id}`).then(resp => resp.data.data);
}

export function getSchoolBookChapter(schoolId, book_id, chapter_id) {
	return http.get(`/schools/${schoolId}/books/${book_id}/chapters/${chapter_id}`).then(resp => resp.data);
}

export function getSchoolBookChapterSection(schoolId, book_id, chapter_id, section_id) {
	return http
		.get(`/schools/${schoolId}/books/${book_id}/chapters/${chapter_id}/sections/${section_id}`)
		.then(({ data }) => {
			const section = data.data;
			const homework = data.meta?.homework?.length ? data.meta.homework : [];
			return {
				section,
				homework,
			};
		});
}

export function getMenuBooks(schoolId) {
	return http.get(`/schools/${schoolId}/user/books/menu`).then(resp => resp.data.data);
}
