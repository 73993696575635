import NewBooks from './NewBooks';
import NewCourses from './NewCourses';
import StudentAssignedToSchoolClass from './StudentAssignedToSchoolClass';
import StudentReceivedHomeworkFeedbackMessage from './StudentReceivedHomeworkFeedbackMessage';
import HomeworkExportReady from './HomeworkExportReady';

export function resolveNotification(notification) {
	switch (notification?.type) {
		case 'NewBooks':
			return new NewBooks(notification);
		case 'NewCourses':
			return new NewCourses(notification);
		case 'StudentAssignedToSchoolClass':
			return new StudentAssignedToSchoolClass(notification);
		case 'StudentReceivedHomeworkFeedbackMessage':
			return new StudentReceivedHomeworkFeedbackMessage(notification);
		case 'HomeworkExportReady':
			return new HomeworkExportReady(notification);
		default:
			return null;
	}
}
