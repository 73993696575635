<template>
	<MaterialCard
		image="/images/training/training-card.svg"
		title="Adaptive øvelser"
		description="Lad Minlæring lave en målrettet adaptiv træning."
		:clickable="clickable"
		:size="size"
		:animate="animate"
	></MaterialCard>
</template>

<script>
import MaterialCard from '@/components/course/MaterialCard';

export default {
	name: 'CourseTrainingHomeworkCard',
	components: { MaterialCard },
	props: {
		clickable: {
			type: Boolean,
			default: false,
		},
		animate: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: 'normal',
			validator: value => ['small', 'normal', 'large'].includes(value),
		},
	},
};
</script>
