<template>
	<div>
		<div v-for="(notification, i) in notifications" :key="i">
			<hr class="my-0" />
			<AppLink :to="notification.actionRoute ?? false">
				<NotificationListItemDetails
					:notification="notification"
					@click.native="$emit('clicked-notification')"
				/>
			</AppLink>
		</div>
		<p v-if="!notifications.length" class="px-4">Du har ingen notifikationer</p>
	</div>
</template>

<script>
import AppLink from '@/components/ui/AppLink';
import NotificationListItemDetails from './NotificationListItemDetails';

export default {
	name: 'Notifications',
	components: {
		AppLink,
		NotificationListItemDetails,
	},
	props: {
		notifications: {
			type: Array,
			required: true,
		},
	},
};
</script>
