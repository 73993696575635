<template>
	<canvas />
</template>

<script>
import ChartOptions from './ChartOptions';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
	name: 'Chart',
	props: {
		type: {
			type: String,
			required: true,
		},
		aspectRatio: {
			type: Number,
			default: 1,
		},
		datasets: {
			type: Array,
			required: true,
		},
		labels: {
			type: Array,
			required: true,
		},
		options: {
			type: Object,
			default: () => ({}),
		},
		tooltipLabelCallback: {
			type: Function,
			default: null,
		},
	},
	data() {
		return {
			chart: null,
			renderCount: 0,
		};
	},
	computed: {
		defaultOptions() {
			return {
				aspectRatio: this.aspectRatio,
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						backgroundColor: '#FFF',
						titleColor: '#000',
						bodyColor: '#000',
						padding: 14,
						font: {
							size: 14,
						},
						callbacks: this.tooltipLabelCallback
							? { label: this.tooltipLabelCallback }
							: {},
					},
				},
				animation: this.renderCount <= 1,
			};
		},
		config() {
			return {
				type: this.type,
				data: {
					labels: this.labels,
					datasets: this.datasets,
				},
				options: ChartOptions.options([this.defaultOptions, this.options]),
			};
		},
	},
	watch: {
		datasets: {
			handler() {
				this.$nextTick(this.renderChart);
				this.renderCount++;
			},
			immediate: true,
		},
	},
	mounted() {
		this.$nextTick(this.renderChart);
	},
	methods: {
		renderChart() {
			if (!this.$el) {
				return;
			}
			if (this.chart) {
				this.chart.destroy();
			}

			this.chart = new Chart(this.$el, this.config);
		},
	},
};
</script>
