<template>
	<li class="section-list-item is-clickable" @click.stop="$emit('open-section', section)">
		<p v-if="!isExercise" class="is-flex is-align-items-center py-4">
			<BaseCheckbox
				active-color="green"
				:selected="section.user_progress.completed"
				:rounded="true"
				class="mr-4"
			/>
			<BaseIcon
				v-if="section.isMedia()"
				icon="play"
				color="red"
				:size="5"
				show-background
				class="pl-1 mr-4"
				square
			/>
			<span class="title is-6 has-text-black has-hover-text-grey-dark"
				>{{ section.prefix }} {{ $designate(section.title) }}</span
			>
		</p>

		<div v-else class="columns exercises-section">
			<div class="column is-mobile is-flex is-align-items-center is-8-desktop is-10-tablet">
				<BaseCheckbox
					active-color="green"
					:selected="section.user_progress.completed"
					:rounded="true"
					class="mr-4"
				/>
				<ExerciseImage :exercise="section" :with-difficulty-icon="homework.type == 'grammar'" />
				<div class="section-text ml-3">
					<p class="title is-8 has-text-grey">
						{{ exercisePrefix }}
					</p>
					<p class="subtitle is-6 has-text-black has-text-weight-bold">
						{{ $designate(section.title) }}
					</p>
				</div>
			</div>

			<div
				class="column is-flex is-align-items-center is-justify-content-center"
				:class="['is-2', 'is-hidden-mobile', 'is-hidden-tablet-only']"
			>
				<TippyTooltip placement="bottom" style="width: 100%">
					<template #trigger>
						<BaseProgressBar
							:progress="section.user_progress.progress"
							show-completed-icon
						/>
					</template>
					<p>Du har løst {{ round(section.user_progress.progress) }}% af denne øvelse.</p>
				</TippyTooltip>
			</div>

			<div
				v-if="homework.has_visible_results"
				class="column is-flex is-align-items-center is-justify-content-center"
				:class="['is-2-tablet', 'is-2-desktop', 'is-hidden-mobile']"
			>
				<TippyTooltip v-if="section.gradeable" placement="bottom">
					<template #trigger>
						<PointsBox
							:is-active="section.user_progress.progress > 0"
							:value="section.user_progress.points"
						/>
					</template>
					<p>Dit resultat på en skala fra 0 - 100 point.</p>
				</TippyTooltip>
				<TippyTooltip v-else placement="bottom">
					<template #trigger>
						<PointsBox :is-active="section.user_progress.progress > 0" />
					</template>
					<p>Denne øvelse giver ikke point.</p>
				</TippyTooltip>
			</div>
		</div>
	</li>
</template>

<script>
import BaseCheckbox from '@/components/base/input/BaseCheckbox';
import BaseIcon from '@/components/base/BaseIcon';
import ExerciseImage from '@/components/exercises/ExerciseImage';
import BaseProgressBar from '@/components/base/BaseProgressBar';
import PointsBox from '@/components/ui/PointsBox.vue';
import designation from '@/mixins/designation';
import { round } from '@/utils/math';

export default {
	name: 'HomeworkSectionListItem',
	components: {
		BaseCheckbox,
		BaseIcon,
		ExerciseImage,
		BaseProgressBar,
		PointsBox,
	},
	mixins: [designation],
	props: {
		homework: {
			type: Object,
			required: true,
		},
		section: {
			type: Object,
			required: true,
		},
		dynamicExerciseNumber: {
			type: Number,
			default: null,
		},
	},
	computed: {
		isExercise() {
			return this.section.isExercise();
		},
		childSections() {
			if (!this.section || !this.section.sections || this.isExercise) {
				return [];
			}
			return this.section.sections;
		},
		exercisePrefix() {
			if (this.homework.type === 'exercises' && this.isExercise && this.dynamicExerciseNumber) {
				return `Øvelse ${this.dynamicExerciseNumber}`;
			}
			return this.section.prefix;
		},
	},
	methods: {
		round,
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';

.section-list-item {
	.exercises-section {
		margin-top: 0;
		margin-bottom: 0;

		.column {
			.section-text {
				width: 100%;
			}
		}

		&:hover {
			border-radius: $radius;
			background-color: $blue-lightest;

			.column,
			+ .exercises-list-item .column {
				border-color: transparent;
			}
		}
	}
}
</style>
