<template>
	<div
		class="points-box title m-0 is-flex is-justify-content-center is-align-content-center is-flex-direction-column"
		:class="[`is-${size}`, colorClass, { 'is-active': isActive, 'is-highlighted': isHighlighted }]"
	>
		<span>{{ formattedValue }}{{ units }}</span>
	</div>
</template>

<script>
import { round } from '@/utils/math';

export default {
	name: 'PointsBox',
	props: {
		size: {
			type: String,
			default: 'medium',
		},
		value: {
			type: [Number, String],
			default: null,
		},
		isActive: {
			type: Boolean,
			default: true,
		},
		isHighlighted: {
			type: Boolean,
			default: false,
		},
		activeColor: {
			type: String,
			default: undefined,
		},
		units: {
			type: String,
			default: '',
		},
	},
	computed: {
		formattedValue() {
			if (!this.isNumberValue) {
				return this.value ?? '...';
			}
			return round(this.value);
		},
		isNumberValue() {
			return typeof this.value === 'number';
		},
		colorClass() {
			if (!this.isActive) {
				return 'is-grey';
			}
			if (!this.isNumberValue) {
				return 'is-blue';
			}
			const color = this.activeColor ?? this.defaultActiveColor;
			return `is-${color}`;
		},
		defaultActiveColor() {
			return this.value >= 85 ? 'green' : this.value >= 30 ? 'yellow' : 'red';
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
@import '@/assets/sass/abstracts/mixins';

.points-box {
	border-radius: 1rem;
	text-align: center;
	transition: background-color 250ms linear;

	&.is-small {
		font-size: 0.875rem;
		width: 2.6rem;
		height: 2.4rem;
	}
	&.is-medium {
		font-size: 1rem;
		width: 3.5rem;
		height: 3rem;
	}

	&.is-grey {
		background-color: $grey-lighterer;
		color: $grey-dark;
	}
	&.is-blue {
		background-color: $blue-lighterer;
		color: $blue;
	}
	&.is-green {
		background-color: $green-lighter;
		color: $green;
	}
	&.is-yellow {
		background-color: $yellow-lighter;
		color: $yellow;
	}
	&.is-red {
		background-color: $red-lighter;
		color: $red;
	}

	&.is-active {
		&.is-highlighted,
		&:hover {
			color: $white;
			&.is-grey {
				background-color: $grey;
			}
			&.is-blue {
				background-color: $blue;
			}
			&.is-green {
				background-color: $green;
			}
			&.is-yellow {
				background-color: $yellow;
			}
			&.is-red {
				background-color: $red;
			}
		}
	}
}

@include mixin-widescreen-only {
	.points-box {
		&.is-medium {
			width: 3rem;
			height: 3rem;
		}
	}
}
</style>
