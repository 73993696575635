<template>
	<div
		class="material-card"
		:class="[
			{ 'is-stacked': isStacked, 'is-clickable': clickable, 'animate-on-hover': animate },
			`is-${size}`,
		]"
	>
		<div class="material-card-image-container">
			<img :src="image" />
			<div v-if="hasTopRightSlot" class="top-right">
				<slot name="top-right" />
			</div>
			<div v-else-if="count !== null" class="top-right">
				<BaseTag size="medium" color="transparent-dark">
					{{ count }}
				</BaseTag>
			</div>
		</div>
		<BaseProgressBar v-if="progress !== undefined" class="my-3" :progress="progress" />
		<p class="title is-6 mt-4">{{ title }}</p>
		<p v-if="description" class="subtitle is-9" v-html="description" />
	</div>
</template>

<script>
import BaseTag from '@/components/base/BaseTag';
import BaseProgressBar from '@/components/base/BaseProgressBar';

export default {
	name: 'MaterialCard',
	components: {
		BaseTag,
		BaseProgressBar,
	},
	props: {
		image: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			default: '',
		},
		count: {
			type: [Number, String],
			default: null,
		},
		clickable: {
			type: Boolean,
			default: false,
		},
		animate: {
			type: Boolean,
			default: false,
		},
		progress: {
			type: Number,
			default: undefined,
		},
		isStacked: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: 'normal',
			validator: value => ['small', 'normal', 'large'].includes(value),
		},
	},
	computed: {
		hasTopRightSlot() {
			return this.$slots['top-right'] !== undefined;
		},
	},
};
</script>
