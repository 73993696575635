<template>
	<img class="course-flag" :class="`is-${size || 'medium'}`" :src="imageSrc" />
</template>

<script>
export default {
	name: 'CourseFlag',
	props: {
		image: {
			type: String,
			default: () => '',
		},
		course: {
			type: Object,
			default: null,
		},
		size: {
			type: String,
			default: 'medium',
		},
	},
	computed: {
		imageSrc() {
			if (this.image) {
				return this.image;
			}
			return this.course.image ?? this.course.flag_image ?? null;
		},
	},
};
</script>

<style lang="scss" scoped>
.course-flag {
	width: auto;

	&.is-xsmall {
		height: 1rem;
	}
	&.is-small {
		height: 1.5rem;
	}
	&.is-medium,
	&.is-default {
		height: 2rem;
	}
	&.is-large {
		height: 3rem;
	}
	&.is-xlarge {
		height: 4rem;
	}
}
</style>
