import http from './http';

export function getCourse(id) {
	return http.get(`/courses/${id}`).then(resp => resp.data.data);
}

export function getCourses() {
	return http.get('/courses').then(resp => resp.data.data);
}

export function getSchoolCourses(schoolId) {
	return http.get(`/schools/${schoolId}/courses`).then(resp => resp.data.data);
}

export function getSchoolClassCourseDesignation(schoolClassId, courseId) {
	return http.get(`/classes/${schoolClassId}/courses/${courseId}/designation`).then(resp => resp.data.data);
}

export function addCourseDesignation(courseId, identifierType, identifier, data) {
	return http
		.post(`/courses/${courseId}/designation`, { [identifierType]: identifier, data })
		.then(resp => resp.data);
}
